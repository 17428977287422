import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { addNewContent, updateContent } from "./content.api";

const ConfContent = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [contentData, setcontentData] = useState({
    contentType: "",
    contentCode: "",
    createdBy: 1,
  });

  useEffect(() => {
    if (editId && type === "EDIT") {
      if (selectedItem) {
        setcontentData(selectedItem);
      }
    }

    return () => {};
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setcontentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    if (type === "ADD") {
      callMethod = addNewContent(contentData);
    } else {
      let data = {
        contentCode: contentData.contentCode,
        contentType: contentData.contentType,
        updatedBy: 1,
      };
      callMethod = updateContent(editId, data);
    }

    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          console.log(res);
          setSuccess();
          setcontentData({
            contentType: "",
            contentCode: "",
            createdBy: 1,
          });
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      })
      .finally(setLoading(false));
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"sm"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Content" : "Edit Content"}</h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={contentData.contentType}
                    fullWidth
                    label="Type"
                    name="contentType"
                  />
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={contentData.contentCode}
                    fullWidth
                    label="Content Code"
                    name="contentCode"
                  />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfContent;
