import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import PanelDetailsFields from "../PanelDetailsFields";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import { createTenantPanels } from "../companyOnBoard.api";
import { useSelector } from "react-redux";

const ConfTenantPanel = (props) => {
  const {
    type,
    editId,
    hadnleCloseModal,
    open,
    selectedItem,
    activeCountries,
    tenantId,
    setAlert,
    setdataChanged,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const [panelDetails, setPanelDetails] = useState([
    {
      panelName: "",
      panelType: "",
      webAppLink: "",
      mobileAppLink: "",
    },
  ]);

  const handleAddPanel = () => {
    let newpanel = {
      panelName: "",
      panelType: "",
      webAppLink: "",
      mobileAppLink: "",
    };
    let duplicate = [...panelDetails];
    duplicate.push(newpanel);
    setPanelDetails(duplicate);
  };

  const handleRemovePanel = (index) => {
    let duplicate = [...panelDetails];
    if (index < 0 || duplicate.length < 2) return;
    duplicate.splice(index, 1);
    setPanelDetails(duplicate);
  };

  useEffect(() => {
    if (editId && type === "EDIT") {
    }

    return () => {
      setPanelDetails([
        {
          panelName: "",
          panelType: "",
          webAppLink: "",
          mobileAppLink: "",
        },
      ]);
      setSelectedCountries([]);
      setdataChanged(false);
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e, dataName, index) => {
    const { value, name } = e.target;
    let duplicate = [...panelDetails];
    duplicate[index][name] = value;
    setPanelDetails(duplicate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let callMethod;
    let selectedCountryIds = [];

    let dupCountryList = [...selectedCountries];
    dupCountryList.map((item) => {
      selectedCountryIds.push(item.countryId);
    });

    if (selectedCountryIds.length <= 0) {
      setAlert("Select atleast one Country");
      return;
    }
    if (type === "ADD") {
      let data = {
        tenantId: tenantId,
        createdBy: adminId,
        countryIdArray: [...selectedCountryIds],
        panelDetail: [...panelDetails],
      };
      callMethod = createTenantPanels(data);
    } else {
      //   callMethod = updateCategory(editId, data);
    }

    setLoading(true);
    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
          console.log(res);
          setAlert("Success");
          hadnleCloseModal();
          setdataChanged(true);
        }
      })
      .catch((err) => {
        setAlert("Something went wrong!");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"md"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Panels" : "Edit Panels"}</h4>
            </DialogTitle>
            <DialogContent>
              <MultipleValueDropDown
                sx={{ mt: 2 }}
                value={selectedCountries}
                options={activeCountries}
                label={"Select Countries"}
                objkey={"countryName"}
                onChange={(value) => {
                  setSelectedCountries(value);
                }}
              />
              <Stack mt={2} direction={"row"} justifyContent={"flex-end"}>
                <IconButton onClick={handleAddPanel}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Stack>
              {panelDetails &&
                panelDetails.length > 0 &&
                panelDetails.map((item, index) => (
                  <PanelDetailsFields
                    handleChange={handleOnInputchange}
                    handleRemovePanel={handleRemovePanel}
                    data={item}
                    key={index}
                    index={index}
                  />
                ))}
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              {/* <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button> */}
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfTenantPanel;
