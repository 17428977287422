import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getOptionTranslation,
  updateOptionTranslation,
} from "../translateQuestions/panelQuestions.api";

const ViewOptionsModal = (props) => {
  const {
    open,
    handleClose,
    selectedItem,
    obj,
    setAlert,
    setDataFetched,
    onLyView,
  } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  useEffect(() => {
    console.log("view only", onLyView);
    console.log("selected Item", selectedItem);
    if (selectedItem?.panelQsnId && selectedItem?.qsnTranslationId) {
      setModalLoading(true);
      const { panelQsnId, qsnTranslationId } = selectedItem;
      getOptionTranslation(qsnTranslationId, panelQsnId)
        .then((res) => {
          if (res?.status === 200) {
            const { optiontranslation } = res.data.data;
            setOptions(optiontranslation);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => setModalLoading(false));
    }

    return () => {
      setOptions([]);
    };
  }, [selectedItem]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let duplicate = [...options];
    duplicate[index][name] = value;
    setOptions(duplicate);
  };

  const handleSubmit = () => {
    const finalOptions = [];

    options?.length > 0 &&
      options?.map((item) => {
        finalOptions.push({
          qsnTranslationId: selectedItem?.qsnTranslationId,
          optionStatement: item?.optionTransStatement,
          qsnOptionId: item?.qsnOptionId,
          qsnOptionTranslationId: item?.qsnOptionTranslationId,
        });
      });

    if (selectedItem?.qsnTranslationId) {
      setLoading(true);
      updateOptionTranslation(selectedItem?.qsnTranslationId, finalOptions)
        .then((res) => {
          if (res?.status === 200) {
            console.log("success");
            setAlert("Success");
            handleClose();
            setDataFetched(true);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
          console.log("err", err);
        })
        .finally(() => setLoading(false));
    } else {
      setAlert("Something went wrong");
    }
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={open} onClose={handleClose}>
      {!obj && (
        <DialogTitle>
          <Typography sx={{ px: 4 }} component={"h4"} variant={"p"}>
            Options
          </Typography>
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 1.5, height: modalLoading ? 500 : "auto" }}>
        {obj ? (
          modalLoading ? (
            <CircularProgress className="loader" />
          ) : (
            <Stack sx={{ p: 2, mx: 3.2 }} spacing={2}>
              <Stack direction={"row"} spacing={2}>
                <Typography
                  sx={{ width: "100%" }}
                  component={"h4"}
                  variant={"p"}
                >
                  Options
                </Typography>
                {!onLyView && (
                  <Typography
                    sx={{ width: "100%" }}
                    component={"h4"}
                    variant={"p"}
                  >
                    Edit Options
                  </Typography>
                )}
              </Stack>
              {options?.map((item, index) => (
                <Stack key={index} direction={"row"} spacing={2}>
                  <TextField
                    size="small"
                    fullWidth
                    disabled
                    value={item?.optionStatement}
                    label={`Option ${index + 1}`}
                  />
                  {!onLyView && (
                    <TextField
                      size="small"
                      fullWidth
                      value={item?.optionTransStatement}
                      label={`Option ${index + 1}`}
                      name={"optionTransStatement"}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                </Stack>
              ))}
            </Stack>
          )
        ) : (
          selectedItem &&
          selectedItem?.option?.map((item, index) => (
            <>
              <Typography sx={{ padding: "5px 0" }}>{`${index + 1}. ${
                item?.optionStatement
              }`}</Typography>
              <Divider />
            </>
          ))
        )}
      </DialogContent>
      {obj && !onLyView && (
        <DialogActions sx={{ mx: 8 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
          >
            save
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ViewOptionsModal;
