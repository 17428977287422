import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import PropTypes from "prop-types";
import LabelWithSwitch from "../../../utils/labelWithSwitch/LabelWithSwitch";
import {
  getPanelAdminUserFeatures,
  getPanelCountries,
} from "../companyOnBoard.api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SinglePanel = () => {
  const { id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [panelCountries, setPanelCountries] = useState([]);
  const [panelAdminFeatures, setPanelAdminFeatures] = useState([]);
  const [panelUserfeatures, setPanelUserFeatures] = useState([]);
  const [panelName, setPanelName] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  useEffect(() => {
    setPanelName(location.state?.panelName);
    setPreviousPage(location.state?.link);
    return () => {
      setPanelName("");
    };
  }, [location]);

  const [tab, setTab] = useState(0);
  const handleTabChange = (e, newvalue) => {
    setTab(newvalue);
  };

  useEffect(() => {
    if (tab !== null && id) {
      console.log("tab", tab);
      setLoading(true);
      switch (tab) {
        case 0:
          getPanelCountries(id)
            .then((res) => {
              if (res?.status === 200) {
                setPanelCountries(res.data.data);
                console.log("panel countries", res.data.data);
              }
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => setLoading(false));
          break;
        case 1:
          getPanelAdminUserFeatures(id, "system")
            .then((res) => {
              if (res?.status === 200) {
                console.log("admin features", res.data.data);
                const { data } = res.data;
                const unique = [
                  ...new Map(
                    data.map((item) => [item["featureId"], item])
                  ).values(),
                ];
                setPanelAdminFeatures(unique);
              }
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => setLoading(false));
          break;
        case 2:
          getPanelAdminUserFeatures(id, "user")
            .then((res) => {
              if (res?.status === 200) {
                setPanelUserFeatures(res.data.data);
              }
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => setLoading(false));
          break;
        default:
          setLoading(false);
          break;
      }
    }
    return () => {
      setPanelCountries([]);
      setPanelAdminFeatures([]);
    };
  }, [tab, id]);

  const countries = [
    {
      name: "India",
    },
    {
      name: "India",
    },
    {
      name: "India",
    },
    {
      name: "India",
    },
    {
      name: "India",
    },
    {
      name: "India",
    },
    {
      name: "India",
    },
  ];
  const adminFeatures = [
    { name: "feature one" },
    { name: "feature one" },
    { name: "feature one" },
    { name: "feature one" },
    { name: "feature one" },
    { name: "feature one" },
    { name: "feature one" },
  ];
  const userFeatures = [
    {
      country: {
        name: "India",
        features: [
          {
            name: "Feature one",
          },
          {
            name: "Feature two",
          },
        ],
      },
    },
    {
      country: {
        name: "Nepal",
        features: [
          {
            name: "Feature two",
          },
          {
            name: "Feature three",
          },
        ],
      },
    },
  ];

  const Countries = () => {
    return (
      <TabPanel value={tab} index={0}>
        <Box sx={{ width: 400, height: 400, overflowY: "auto" }}>
          {panelCountries &&
            panelCountries.length > 0 &&
            panelCountries.map((item, index) => (
              <LabelWithSwitch
                label={item}
                objkey={"countryName"}
                key={index}
              />
            ))}
        </Box>
      </TabPanel>
    );
  };

  const AdminFeatures = () => {
    return (
      <TabPanel value={tab} index={1}>
        <Box sx={{ width: 400, height: 400, overflowY: "auto" }}>
          {panelAdminFeatures &&
            panelAdminFeatures.length > 0 &&
            panelAdminFeatures.map((item, index) => (
              <LabelWithSwitch
                label={item.features}
                objkey={"feature"}
                key={index}
              />
            ))}
        </Box>
      </TabPanel>
    );
  };

  const UserFeatures = () => {
    return (
      <TabPanel value={tab} index={2}>
        <Box sx={{ width: 400, height: 400, overflowY: "auto" }}>
          {panelUserfeatures &&
            panelUserfeatures.length > 0 &&
            panelUserfeatures.map((item, index) => {
              return (
                <LabelWithSwitch
                  hide
                  label={item}
                  objkey={"countryName"}
                  key={index}
                />
              );
            })}
        </Box>
      </TabPanel>
    );
  };

  let ComponentToRender;

  switch (tab) {
    case 0:
      ComponentToRender = Countries;
      break;
    case 1:
      ComponentToRender = AdminFeatures;
      break;
    case 2:
      ComponentToRender = UserFeatures;
      break;

    default:
      break;
  }
  return (
    <>
      <TopHeading
        link={previousPage}
        showButton={Boolean(false)}
        heading={panelName}
      />
      <Box
        sx={{
          mx: 4,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          height: "calc(100vh - 150px)",
          px: 0,
        }}
      >
        <Tabs
          sx={{
            backgroundColor: "#3F72AF",
            px: 1,
            pt: 1,
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            "& .css-u3w7nj.Mui-selected": {
              color: "#E8F3D6",
            },
            "& .css-17852t2-MuiTabs-indicator": {
              backgroundColor: "#DBE2EF",
              height: "5px",
            },
            "& .css-1ru3v2g": {
              backgroundColor: "#DBE2EF",
              height: "5px",
            },
            "& .css-pzr01a-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: "#E8F3D6",
            },
          }}
          value={tab}
          onChange={handleTabChange}
        >
          <Tab
            sx={{
              textTransform: "capitalize",
              color: "#DBE2EF",
            }}
            value={0}
            label={"Countries"}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: "capitalize",
              color: "#DBE2EF",
            }}
            value={1}
            label={"Admin features"}
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              textTransform: "capitalize",
              color: "#DBE2EF",
            }}
            value={2}
            label={"User features"}
            {...a11yProps(2)}
          />
        </Tabs>
        <Box
          sx={{ height: loading ? 400 : "auto", position: "relative", px: 2 }}
        >
          {loading ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "30%",
                right: "87%",
                transform: "translate(50%,-80%)",
              }}
            />
          ) : (
            <ComponentToRender />
          )}
        </Box>
      </Box>
    </>
  );
};

export default SinglePanel;
