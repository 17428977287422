import React, { useState, useEffect } from 'react'
import { TextField, Box, Stack, CircularProgress } from "@mui/material";
import { ColorButton } from '../StyledComponents/ColorButton';
import SuccessPage from './SuccessPage';
import { resetPassword, verifyUser } from './ForgotPassword.api';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FailurePage from './FailurePage';

const ForgotPassword = () => {

  const [status, setstatus] = useState(0);
  const [password, setpassword] = useState();
  const [confirmPassword, setconfirmPassword] = useState();
  const [loading, setloading] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const handleClickShowPassword = () => setshowPass((showPass) => !showPass);

  const handleClickShowConfirmPassword = () => setshowConfirmPass((showConfirmPass) => !showConfirmPass);
  
  
  // const userVerifyHandler = () =>{
  //   console.log("User Verification...");
  //   verifyUser(token).then(res => {
  //     console.log(res);
  //     if (res?.status === 200) {
  //       setstatus(1);
  //     } else {
  //       setstatus(3);
  //     }
  //   })
  //   .catch(err =>{
  //     setstatus(3);
  //     console.log(err);
  //   })
  // }

  useEffect(() => {
    function userVerifyHandler() {
      console.log("User Verification...");
      verifyUser(token)
        .then((res) => {
          console.log(res);
          if (res?.status === 200) {
            setstatus(1);
          } else {
            setstatus(3);
          }
        })
        .catch((err) => {
          setstatus(3);
          console.log(err);
        });
    };
    userVerifyHandler();
  }, []);

  const handleSubmit = () =>{
    if (password === confirmPassword && password.length >= 8) {
      setloading(true);
      console.log(password.length);
      resetPassword(token, { password: password })
        .then((res) => {
          console.log(res);
          if (res?.status >= 200 && res?.status <= 299) {
            setstatus(2);
          }
          else{
            alert(res.response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setloading(false);
        });
    } else if (password != confirmPassword) {
      alert("Password aren't the same");
    } else if (password.length < 8) {
      alert("Password must be minimum 8 characters");
    }
  }

  // if (!status) {
  //   return (
  //     <Box
  //       display="flex"
  //       flexDirection="column"
  //       width="100vw"
  //       height="100vh"
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <div
  //         style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "2rem" }}
  //       >
  //         Create New Password
  //       </div>
  //       <ColorButton onClick={userVerifyHandler}>Continue</ColorButton>
  //     </Box>
  //   );
  // } else 
  if (!status) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="5rem" />
      </Box>
    );
  } else if (status === 2) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <SuccessPage />
      </Box>
    );
  } else if (status === 3) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <FailurePage/>
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        marginBottom="2.5rem"
      >
        <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
          Create New Password
        </div>
        <div style={{ textAlign: "left" }}>
          Your new password must be
          <br /> different from your previously used
          <br />
          passwords
        </div>
      </Box>
      <Stack
        direction={"column"}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"initial"}
        width={"22rem"}
        marginBottom={"2rem"}
        paddingLeft={"1rem"}
        paddingRight={"1rem"}
      >
        <TextField
          onChange={(e) => setpassword(e.target.value)}
          fullWidth
          type={showPass ? "text" : "password"}
          label={"Password"}
          name="Password"
          helperText="Must be atleast 8 characters"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                {showPass ? (
                  <Visibility
                    className="cursor_pointer"
                    onClick={handleClickShowPassword}
                  />
                ) : (
                  <VisibilityOff onClick={handleClickShowPassword} />
                )}
              </InputAdornment>
            ),
          }}
          // value={userData.miniSurveyName}
        />
        <TextField
          onChange={(e) => setconfirmPassword(e.target.value)}
          fullWidth
          type={showConfirmPass ? "text" : "password"}
          label={"Confirm Password"}
          helperText="Both passwords must match"
          name="confirmPassword"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                {showConfirmPass ? (
                  <Visibility
                    className="cursor_pointer"
                    onClick={handleClickShowConfirmPassword}
                  />
                ) : (
                  <VisibilityOff onClick={handleClickShowConfirmPassword} />
                )}
              </InputAdornment>
            ),
          }}
          // value={userData.miniSurveyName}
        />
      </Stack>
      <ColorButton width="10rem" onClick={handleSubmit} loading={loading}>
        Confirm
      </ColorButton>
    </Box>
  );
}

export default ForgotPassword