import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { addUser, getUserById, updateUser } from "./user.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRoles } from "../roles/roles.api";
import { useSelector } from "react-redux";
import AnimatedPage from "../animatedPage/AnimatedPage";
import { Pattern, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDebounce } from "../../hooks/useDebounce";
import { checkEmail } from "../companyOnBoard/companyOnBoard.api";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../assets/js/validateEmail";
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

const ConfUser = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
  } = props;
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const loggedInUserId = useSelector((state) => state.auth.userData.adminId);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    contact: "",
    email: "",
    password: "",
    createdBy: loggedInUserId,
    roleId: "",
    // tenantId: 6,
  });

  const [validationError, setValidationError] = useState({
    email: "",
    contact: "",
  });

  useDebounce(() => checkIfMailExists(), 1000, [userData.email]);
  useDebounce(() => checkPhoneNumber(), 1000, [userData.contact]);

  function checkPhoneNumber() {
    if (userData.contact) {
      if (!validatePhoneNumber(userData.contact)) {
        setValidationError((prev) => ({
          ...prev,
          contact: "Please enter a valid phone number",
        }));
        return;
      }
    }
  }

  function checkIfMailExists() {
    if (
      userData?.email &&
      validateEmail(userData.email) &&
      userData?.email !== currentEmail
    ) {
      console.log("checking mail for validation");
      let data = {
        email: userData.email,
      };
      checkEmail(data)
        .then((res) => {
          if (res?.status != 200) {
            setValidationError((prev) => ({
              ...prev,
              email: "Mail already exists.",
            }));
          }
        })
        .catch((err) => {
          console.log("error checking the mail", err.message);
        });
    }
  }

  useEffect(() => {
    if (roleSelected) {
      setSelectedRole(roleSelected);
      setUserData((prev) => ({
        ...prev,
        roleId: roleSelected.roleId,
      }));
    }
    return () => {
      setSelectedRole({});
    };
  }, [roleSelected, open]);
  useEffect(() => {
    getRoles()
      .then((res) => {
        if (res?.status === 200) {
          setroles(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setroles([]);
      setValidationError({
        contact: "",
        email: "",
      });
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "email" || name === "contact") {
      setValidationError((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    console.log("validation", validationError);
  }, [validationError]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem.roleId &&
      editId &&
      type === "EDIT" &&
      roles &&
      roles.length > 0
    ) {
      const role = roles.find((item) => item.roleId === selectedItem.roleId);
      if (role) {
        setSelectedRole(role);
      }
    }
    if (editId && type === "EDIT") {
      setPageLoading(true);
      if (selectedItem) {
        setUserData(selectedItem);
        setCurrentEmail(selectedItem.email);
        setPageLoading(false);
      }
    }
    return () => {
      setCurrentEmail("");
      setUserData({
        firstName: "",
        lastName: "",
        middleName: "",
        contact: "",
        email: "",
        password: "",
        createdBy: loggedInUserId,
        roleId: "",
        // tenantId: 6,
      });
    };
  }, [editId, selectedItem, open, roles]);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("validation", validationError);

    if (validationError?.email !== "" || validationError?.contact !== "") {
      return;
    }

    if (type === "ADD" && confirmPassword !== userData.password) {
      setAlert("Password doesn't match");
      return;
    }
    if (userData.password) {
      if (checkPassword(userData.password) === false) {
        setAlert(
          "Password must contain min 8 letter, with at least a symbol, upper and lower case letters and a number"
        );
        return;
      }
    }
    if (userData.roleId === "") {
      setAlert("Please select a role");
      return;
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      callMethod = addUser(userData);
    } else {
      let data = {
        contact: userData.contact,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        middleName: userData.middleName,
        updatedBy: loggedInUserId,
        roleId: selectedRole.roleId,
      };
      callMethod = updateUser(editId, data);
    }

    console.log("here");
    callMethod
      .then((res) => {
        console.log("added", res);
        if (res?.status === 201 || res?.status === 200) {
          setSuccess();
          setUserData({
            firstName: "",
            lastName: "",
            middleName: "",
            contact: "",
            email: "",
            password: "",
            createdBy: loggedInUserId,
            roleId: "",
            // tenantId: 6,
          });
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRoleDropDownChange = (value) => {
    setSelectedRole(value);
    setUserData((prev) => ({
      ...prev,
      roleId: value.roleId,
    }));
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>{type === "ADD" ? "Add User" : "Edit User"}</DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  fullWidth
                  label={"First Name"}
                  name="firstName"
                  type={"text"}
                  required
                  value={userData.firstName}
                />
                <TextField
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  fullWidth
                  label={"Middle Name"}
                  name="middleName"
                  type={"text"}
                  value={userData.middleName}
                />
                <TextField
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  fullWidth
                  label={"Last Name"}
                  name="lastName"
                  type={"text"}
                  required
                  value={userData.lastName}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  helperText={validationError.contact}
                  error={Boolean(validationError.contact)}
                  type={"number"}
                  label={"Phone Number"}
                  name="contact"
                  required
                  value={userData.contact}
                />
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"email"}
                  label={"Email"}
                  name="email"
                  error={Boolean(validationError.email)}
                  helperText={validationError?.email}
                  required
                  value={userData.email}
                />
              </Stack>
              {/* <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                <Stack sx={{ width: "100%" }}>
                  <p>Role</p>
                  <AsyncDropdown
                    disabled={props.disableDropDown}
                    value={selectedRole}
                    handleChange={handleRoleDropDownChange}
                    objKey="role"
                    options={roles}
                  />
                </Stack>
              </Stack> */}
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                {type === "ADD" && (
                  <>
                    <TextField
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                      label={"Password"}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      required
                      value={confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      onChange={handleInputChange}
                      fullWidth
                      label={
                        type === "ADD" ? "Confirm Password" : "New Password"
                      }
                      type={showConfirmPassword ? "text" : "password"}
                      name="password"
                      required
                      value={userData.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {type === "ADD" ? "Save" : "Update"}
          </LoadingButton>
          <Button variant="outlined" onClick={hadnleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfUser;
