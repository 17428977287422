import axios from "axios";

export const getAllAffiliateDetail = async () => {
  try {
    const res = await axios.get(
      `/api/systemUser/getAllAffiliateDetail`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getAllCallbackType = async () => {
  try {
    const res = await axios.get(
      `/api/systemUser/getAllCallbackType`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const createAffiliateDetail = async (data) => {
  try {
    const res = await axios.post(
      `/api/systemUser/createAffiliateDetail`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const updateAffiliateDetail = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/systemUser/updateAffiliateDetail?affiliateId=${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getAffiliateDetail = async (affiliateId) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getAffiliateDetailById?affiliateId=${affiliateId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
