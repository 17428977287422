import { useEffect } from 'react'
import { Link } from 'react-router-dom';

const SurveyLink = (props) => {
  const item = props.item;
    useEffect(() => {
      const stateObject = { type: "EDIT", surveyId: item.SurveyId };
      const storageKey = `surveyState_${item.SurveyId}`;
        localStorage.setItem(storageKey, JSON.stringify(stateObject));
        return () => {
          localStorage.removeItem(storageKey);
        };
      }, [item.SurveyId]);

  return (
    <>
    <Link
      to={`/admin/edit-survey/${item.SurveyId}`} target="_blank">
      {item.SurveyId}
    </Link>
    </>
  )
}

export default SurveyLink
