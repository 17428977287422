import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./index.css";
const SearchBar = (props) => {
  const { searchQuery, setSearchQuery, options, keyCode, searchLoading, setSearchresultId } = props;

  const [open, setOpen] = useState(false);
  const [optionss, setOptions] = React.useState([]);
  const loading = open && optionss.length === 0;
  return (
    <Autocomplete
      style={props?.style}
      value={props?.value}
      filterOptions={(x) => x}
      size="small"
      id="asynchronous-demo"
      onChange={(event, newValue) => {
        setSearchresultId(newValue)
      }}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false); 
      }}
      // isOptionEqualToValue={(option, value) =>
      //   option['attributes'][keyCode] === value['attributes'][keyCode]
      // }
      getOptionLabel={(option) => option['attributes'] ? option['attributes'][keyCode] : ""}
      options={options}
      loading={searchLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyUp={(e) => setSearchQuery(e.target.value)}
          label={props.label ? props.label : "Search Here"}
          name={props.name ? props.name : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                {searchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};


export default SearchBar;
