import React from "react";
import { Stack } from "@mui/material";
const BouncingDotsLoader = (props) => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mt={3}>
      <p>Please Wait</p>
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Stack>
  );
};

export default BouncingDotsLoader;