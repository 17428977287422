import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import HiveIcon from "@mui/icons-material/Hive";
import {
  fetchAllCountry,
  getCountryById,
  getTenantCountry,
} from "../country/country.api";
import { getTenantProfile } from "../tenantProfile/tenantProfile.api";
import styled from "@emotion/styled";

function LanguageModal(props) {
  const { countryid, countryName } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={"View Languages"}>
        <IconButton onClick={handleOpen} sx={{ ml: "auto" }}>
          <LanguageIcon fontSize="10" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClick={handleClose}>
        <DialogTitle>
          <Typography sx={{ textTransform: "capitalize" }}>
            {countryName}'s Languages
          </Typography>
        </DialogTitle>
      </Dialog>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ViewDetailsModal = (props) => {
  const [allFeatures, setAllFeatures] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const { open, handleClose, selectedTenantId, tenantAdminDetails } = props;
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const [tenantAdminDetail, setTenantAdminDetail] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    isActive: "",
  });
  const [tenantProfileDetails, setTenantProfileDetails] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    countryId: "",
  });
  const [value, setValue] = useState(0);
  const handleChange = (e, val) => {
    setValue(val);
  };

  useEffect(() => {
    if (selectedTenantId) {
      getTenantProfile(selectedTenantId)
        .then((res) => {
          if (res?.status === 200) {
            setTenantProfileDetails(res.data.data.profile[0]);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      setAllFeatures([]);
      setallCountries([]);
      setTenantProfileDetails({
        address: "",
        city: "",
        state: "",
        zip: "",
        countryId: "",
      });
    };
  }, [selectedTenantId]);

  // get country by id
  const [tenantCountry, setTenantCountry] = useState({});
  // set slected tenant admin details
  useEffect(() => {
    if (tenantAdminDetails && selectedTenantId) {
      setTenantAdminDetail(tenantAdminDetails);
    }
  }, [tenantAdminDetails, selectedTenantId]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog onClose={handleClose} maxWidth={"md"} fullWidth={true} open={open}>
      <DialogTitle sx={{ px: 0.5 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            backgroundColor: "#3F72AF",
            color: "#ffffff",
            px: 1,
            pt: 1,
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            "& .css-17852t2-MuiTabs-indicator": {
              backgroundColor: "#DBE2EF",
              height: "5px",
            },
            "& .css-1ru3v2g": {
              backgroundColor: "#DBE2EF",
              height: "5px",
            },
            "& .css-u3w7nj.Mui-selected": {
              color: "#ffffff",
            },
            "& .css-pzr01a-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: "#ffffff",
            },
            // "& .css-pzr01a-MuiButtonBase-root-MuiTab-root.Mui-selected": {
            //   color: "#ffffff",
            // },
          }}
        >
          <Tab
            sx={{ textTransform: "capitalize", color: "#DBE2EF" }}
            label="Tenant Admin"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: "capitalize", color: "#DBE2EF" }}
            label="Tenant Profile"
            {...a11yProps(1)}
          />
          {/* <Tab
            sx={{ textTransform: "capitalize", color: "#DBE2EF" }}
            label="Features and Countries"
            {...a11yProps(2)}
          /> */}
        </Tabs>
      </DialogTitle>
      <DialogContent sx={{ height: 350 }}>
        <TabPanel value={value} index={0}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Name"}
                InputProps={{ readOnly: true }}
                value={tenantAdminDetail?.firstName}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Email"}
                InputProps={{ readOnly: true }}
                value={tenantAdminDetail?.email}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Contact"}
                InputProps={{ readOnly: true }}
                value={tenantAdminDetail?.contact}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Status"}
                InputProps={{ readOnly: true }}
                value={tenantAdminDetail?.isActive ? "Active" : "Inactive"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container
            direction={"row"}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            p={2}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label={"Address"}
                InputProps={{ readOnly: true }}
                value={tenantProfileDetails?.address}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"State"}
                InputProps={{ readOnly: true }}
                value={tenantProfileDetails?.state}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"City"}
                InputProps={{ readOnly: true }}
                value={tenantProfileDetails?.city}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"Zip"}
                InputProps={{ readOnly: true }}
                value={tenantProfileDetails?.zip}
              />
            </Grid>
            {/* <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"Country"}
                InputProps={{ readOnly: true }}
                value={tenantCountry?.countryName}
              />
            </Grid> */}
          </Grid>
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <Stack>
            <Stack>
              <Typography variant="h6" component={"p"}>
                Features
              </Typography>
              <Grid
                container
                direction={"row"}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                p={2}
              >
                {allFeatures &&
                  allFeatures.length > 0 &&
                  allFeatures.map((item, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <HiveIcon fontSize="30" />
                        <Typography>{item.features.feature}</Typography>
                      </Stack>
                    </Grid>
                  ))}
              </Grid>
            </Stack>
            <Stack>
              <Typography variant="h6" component={"p"}>
                Countries
              </Typography>
              <Grid
                container
                direction={"row"}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                p={2}
              >
                {allCountries &&
                  allCountries.length > 0 &&
                  allCountries.map((item, index) => (
                    <Grid
                      direction={"row"}
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                    >
                      <>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <HiveIcon fontSize="25" />
                            <Typography>
                              {item?.country?.countryName}
                            </Typography>
                          </Stack>
                          <LanguageModal countryName={item?.countryName} countryid={item.countryId} />
                        </Stack>
                      </>
                    </Grid>
                  ))}
              </Grid>
            </Stack>
          </Stack>
        </TabPanel> */}
      </DialogContent>
    </Dialog>
  );
};

export default ViewDetailsModal;
