import { LoadingButton } from "@mui/lab";
import { Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import { dropDowns } from "./AddProfileQuestionaire";

const FilterQuesions = (props) => {
  const {
    countryWithLanguage,
    panels,
    categories,
    setSelectedCategories,
    selectedCategories,
    handleDropDownChange,
    selectedlanguage,
    selectedpanel,
    handleSearch,
    selectedCountryWithLanguage,
    setSelectedCountryWithLanguage,
    countries,
    selectedCountry,
    setRemovedCategory,
    tableLoading,
  } = props;

  return (
    <Paper sx={{ px: 6, py: 1, mx: 2, mb: 2 }} elevation={1}>
      <Typography variant="h6" my={1}>
        Filter Questions
      </Typography>
      <Stack
        direction={"row"}
        sx={{ mb: 2 }}
        spacing={2}
        alignItems={"flex-start"}
      >
        <AsyncDropdown
          value={selectedpanel}
          objKey={"panelName"}
          options={panels}
          small
          label={"Select Panel"}
          handleChange={(value) => handleDropDownChange(value, dropDowns.PANEL)}
        />
        <AsyncDropdown
          value={selectedCountry}
          options={countries}
          objKey={"countryName"}
          small
          label={"Select Country"}
          handleChange={(value) =>
            handleDropDownChange(value, dropDowns.COUNTRY)
          }
        />
        <AsyncDropdown
          value={selectedlanguage}
          objKey={"countryLanguage"}
          options={countryWithLanguage}
          small
          label={"Select Language"}
          handleChange={(value) =>
            handleDropDownChange(value, dropDowns.LANGUAGE)
          }
        />
        {/* <MultipleValueDropDown
          value={selectedCountryWithLanguage}
          options={countryWithLanguage}
          objkey={"countryLanguage"}
          small
          label={"Select Languages"}
          onChange={(value) => setSelectedCountryWithLanguage(value)}
        /> */}
        <MultipleValueDropDown
          value={selectedCategories}
          options={categories}
          objkey={"category"}
          small
          label={"Select Categories"}
          removeCheck
          setRemovedCategory={setRemovedCategory}
          onChange={(value) => {
            setSelectedCategories(value);
          }}
        />
        <LoadingButton
          loading={tableLoading}
          onClick={handleSearch}
          variant="contained"
          sx={{ px: 4, textTransform: "capitalize" }}
        >
          Search
        </LoadingButton>
      </Stack>
    </Paper>
  );
};

export default FilterQuesions;
