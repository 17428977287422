import {
  Box,
  Button,
  Checkbox,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setPanelSidebarItems } from "../../features/panelSidebarItemSlice";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { SnackBarAlert } from "../../utils/SnackBar";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import { getActivepanels } from "../managePanel/managePanel.api";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  assignPanelQuestions,
  getAllQuestionsForPanel,
} from "./assignQuestions.api";
import { questions } from "./data";
import { LoadingButton } from "@mui/lab";

const AssignQuestions = () => {
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const labels = ["", "Question", "Category", "Type"];
  const rows = [];
  const [activePanels, setActivePanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});

  const [dbSelectedQuestions, setDbSelectedQuestions] = useState([]);
  const [dbNotSelectedQuestions, setDbNotSelectedQuestions] = useState([]);

  const [panelQuestions, setPanelQuestions] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [deSelectedQuestions, setDeselectedQuestions] = useState([]);
  const [dataAdded, setDataAdded] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (adminId) {
      getActivepanels(adminId)
        .then((res) => {
          if (res?.status === 200) {
            setActivePanels(res.data.data);
            console.log("active panels", res.data.data);
            setSelectedPanel(res.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return () => {
      setActivePanels([]);
      setDataAdded(false);
    };
  }, [adminId]);

  const handleDropdownChange = (value) => {
    if (value) {
      setSelectedPanel(value);
    }
  };

  useEffect(() => {
    console.log("fetching");
    if (selectedPanel?.panelId) {
      setTableLoading(true);
      getAllQuestionsForPanel(adminId, selectedPanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            console.log("panel Questions", data);
            setPanelQuestions(data);

            let duplicate = [];
            let duplicateNot = [];

            // get the ids of the selected and not selected questions coming from the database
            data.map((item) => {
              if (item?.isSelected) {
                if (duplicate.includes(Number(item.masterQsnId))) return;
                duplicate.push(Number(item.masterQsnId));
              }
              if (!item?.isSelected) {
                if (duplicateNot.includes(Number(item.masterQsnId))) return;
                duplicateNot.push(Number(item.masterQsnId));
              }
            });

            setDbNotSelectedQuestions(duplicateNot);
            setDbSelectedQuestions(duplicate);
            setCheckedQuestions(duplicate);
            setDeselectedQuestions(duplicateNot);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setAlert("Something went wrong! Try again");
        })
        .finally(() => setTableLoading(false));
    }
    return () => {
      setPanelQuestions([]);
      setDbSelectedQuestions([]);
      setDbNotSelectedQuestions([]);
      setCheckedQuestions([]);
      setDeselectedQuestions([]);
    };
  }, [selectedPanel, alert]);

  // useEffect(() => {
  //   if (questions && questions.length > 0) {
  //     setPanelQuestions(questions);
  //     let duplicate = [];
  //     let duplicateNot = [];

  //     // get the ids of the selected and not selected questions coming from the database
  //     questions.map((item) => {
  //       if (item.isSelected) {
  //         if (duplicate.includes(Number(item.id))) return;
  //         duplicate.push(Number(item.id));
  //       }
  //       if (!item.isSelected) {
  //         if (duplicateNot.includes(Number(item.id))) return;
  //         duplicateNot.push(Number(item.id));
  //       }
  //     });

  //     setDbNotSelectedQuestions(duplicateNot);
  //     setDbSelectedQuestions(duplicate);
  //     setCheckedQuestions(duplicate);
  //     setDeselectedQuestions(duplicateNot);
  //   }
  //   return () => {
  //     setPanelQuestions([]);
  //     setDbSelectedQuestions([]);
  //     setDbNotSelectedQuestions([]);
  //     setCheckedQuestions([]);
  //     setDeselectedQuestions([]);
  //   };
  // }, [questions]);

  const handleCheckBoxChange = (e, value, id, index) => {
    let duplicatePanelQuestions = [...panelQuestions];
    duplicatePanelQuestions[index]["isSelected"] = value;
    setPanelQuestions(duplicatePanelQuestions);

    let duplicaiteCheckedQuestions = [...checkedQuestions];
    let duplcaiteDeSelectedQuestions = [...deSelectedQuestions];

    // if check box value is true
    if (value) {
      // check if the id already conatins in the array
      if (duplicaiteCheckedQuestions.includes(id)) return;
      // if not
      duplicaiteCheckedQuestions.push(id);

      // remove the element from deselected array
      const index = duplcaiteDeSelectedQuestions.findIndex((i) => i === id);
      duplcaiteDeSelectedQuestions.splice(index, 1);
    }

    // vice versa
    if (!value) {
      if (duplcaiteDeSelectedQuestions.includes(id)) return;
      duplcaiteDeSelectedQuestions.push(id);

      const index = duplicaiteCheckedQuestions.findIndex((i) => i === id);
      duplicaiteCheckedQuestions.splice(index, 1);
    }
    setCheckedQuestions(duplicaiteCheckedQuestions);
    setDeselectedQuestions(duplcaiteDeSelectedQuestions);
  };

  const handleSubmit = () => {
    // take only those ids which where not selected in the database
    const finalSelecttion = checkedQuestions.filter((i) =>
      dbNotSelectedQuestions.includes(i)
    );

    // take only those ids which where selected in the database
    const finalDeSelecttion = deSelectedQuestions.filter((i) =>
      dbSelectedQuestions.includes(i)
    );

    let duplicate = [...panelQuestions];

    const selectedQuestions = duplicate.filter(function (item) {
      return finalSelecttion.indexOf(item.masterQsnId) != -1;
    });

    for (let i = 0; i < selectedQuestions.length; i++) {
      if (
        selectedQuestions[i].option &&
        selectedQuestions[i].option.length > 0
      ) {
        let optionsStaments = [];
        for (let j = 0; j < selectedQuestions[i].option.length; j++) {
          console.log("HERE", selectedQuestions[i].option[j]);
          optionsStaments.push(selectedQuestions[i].option[j].optionStatement);
        }
        selectedQuestions[i]["optionStatement"] = [...optionsStaments];
      }
    }

    let data = {
      createdBy: adminId,
      panelId: selectedPanel.panelId,
      deselected: [...finalDeSelecttion],
      selected: [...selectedQuestions],
    };

    setLoading(true);
    assignPanelQuestions(data)
      .then((res) => {
        if (res?.status >= 200 && res?.status <= 299) {
          setAlert("Success");
          setDataAdded(true);
        }
      })
      .catch((err) => {
        setAlert("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  panelQuestions.length > 0 &&
    panelQuestions.map((item, index) =>
      rows.push([
        <Checkbox
          checked={item.isSelected}
          onChange={(e, value) =>
            handleCheckBoxChange(e, value, item.masterQsnId, index)
          }
        />,
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Tooltip title={item.question}>
            <InfoOutlinedIcon fontSize="8" />
          </Tooltip>
          <Typography>{item.qsnShortForm}</Typography>
        </Stack>,
        <Typography>{item?.categories?.category}</Typography>,
        <Typography>{item?.masterqsntype?.questionType}</Typography>,
      ])
    );
  return (
    <>
      <TopHeading showButton={Boolean(false)} heading="Assign Questions" />
      <Stack mb={2} sx={{ width: "40%", px: 3 }}>
        <AsyncDropdown
          value={selectedPanel}
          options={activePanels}
          objKey={"panelName"}
          label="Select panel"
          handleChange={handleDropdownChange}
        />
      </Stack>
      <Box mb={2}>
        <CustomTable pageLoading={tableLoading} labels={labels} data={rows} />
      </Box>
      <LoadingButton
        loading={loading}
        sx={{
          display: "block",
          ml: "auto",
          mr: 4,
          textTransform: "capitalize",
        }}
        variant={"contained"}
        onClick={handleSubmit}
      >
        Add to panel
      </LoadingButton>

      <SnackBarAlert open={alert} handleClose={() => setAlert(false)} />
    </>
  );
};

export default AssignQuestions;
