import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";

const AssignedSurveyCountries = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [assignedCountries, setassignedCountries] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      console.log(data)
      setassignedCountries(data);
    }
    return () => {
      setassignedCountries(data);
    };
  }, [data]);

  const rows = [];
  const labels = ["Name", "Status"];
  assignedCountries &&
    assignedCountries.length > 0 &&
    assignedCountries.map((item) =>
      rows.push([
        item.country?.countryName,
        <CustomMuiSwitch checked={item.country?.isActive} />,
      ])
    );
  return (
    <>
    <AnimatedPage>
      <TopHeading showButton={false} heading="Survey Countries" />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
    </AnimatedPage>
    </>
  );
};

export const EnhancedAssignedSurveyCountries = Hoc(AssignedSurveyCountries, { name: "assigned-survey-countries" });
