import { useState, useEffect } from 'react';
const useExcelCell = (fetchDataCallback) => {
    const [data, setData] = useState(null);
  
    useEffect(() => {
       fetchDataCallback()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setData(data);
          } else {
            throw new Error('Failed to fetch data');
          }
        })
        .catch((error) => {
          console.log('Error:', error.message);
          // Handle error case if necessary
        });
    }, [fetchDataCallback]);
  
    const handleClick = () => {
        console.log('Data:', data);
        return data;
      };
  
    return {
      data,
      handleClick,
    };
  };

  export default useExcelCell;