import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import { fetchAllCountry } from "../country/country.api";
import Hoc from "../HOC/Hoc";
import CreateIcon from "@mui/icons-material/Create";
import {
  createTenantProfile,
  getCountryById,
  getTenantProfile,
  updateTenantProfile,
} from "./tenantProfile.api";
import { useSelector } from "react-redux";
import AnimatedPage from "../animatedPage/AnimatedPage";
import { dummyProfile } from "../../config/keys";
import ChanegPassword from "./ChanegPassword";

const TenantProfile = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [languages, setLanguages] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [country, setCountry] = useState({});
  const [countries, setcountries] = useState([]);
  // const [valueChanged, setValueChanged] = useState(false);
  const [submittingForm, setsubmittingForm] = useState(false);
  const [profileExists, setProfileExists] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [changePassword, setChangePassword] = useState(false);

  const [tenantProfileData, setTenantProfileData] = useState({
    name: "",
    email: "",
  });

  const uploadImageRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    setPageLoading(true);
    getTenantProfile(adminId)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.data) {
            let { data } = res.data;
            setTenantProfileData(() => ({
              name: data.firstName,
              email: data.contactEmail,
            }));
            setProfileExists(true);
          } else {
            setProfileExists(false);
          }
        } else {
          setError("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong!");
      })
      .finally(() => setPageLoading(false));

    // fetchAllCountry()
    //   .then((res) => {
    //     if (res?.status === 200) {
    //       setcountries(res.data.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("couldnt fetch countries", err);
    //     setError("Something went wrong!");
    //   });

    return () => {
      setTenantProfileData({
        name: "",
        email: "",
      });
    };
  }, []);

  // useEffect(() => {
  //   if (countries && countries.length > 0 && tenantProfileData?.countryId) {
  //     const item = countries.find(
  //       (i) => i.countryId === tenantProfileData?.countryId
  //     );
  //     setCountry(item);
  //   }
  //   return () => {
  //     setCountry({});
  //   };
  // }, [countries]);

  // const handleInputChange = (e) => {
  //   setValueChanged(true);
  //   const { value, name } = e.target;
  //   console.log(e.target);
  //   setTenantProfileData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleDropDownChange = (value) => {
  //   setValueChanged(true);
  //   setCountry(value);
  //   setTenantProfileData((prev) => ({
  //     ...prev,
  //     countryId: value.countryId,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   setsubmittingForm(true);
  //   e.preventDefault();
  //   let callMethod;
  //   if (!profileExists) {
  //     let data = {
  //       tenantId: adminId,
  //       profilePicUrl: "Url",
  //       address: tenantProfileData.address,
  //       city: tenantProfileData.city,
  //       state: tenantProfileData.state,
  //       zip: tenantProfileData.zip,
  //       countryId: tenantProfileData.countryId,
  //       createdBy: adminId,
  //     };
  //     callMethod = createTenantProfile(data);
  //   } else {
  //     let data = {
  //       tenantId: adminId,
  //       profilePicUrl: "Url",
  //       address: tenantProfileData.address,
  //       city: tenantProfileData.city,
  //       state: tenantProfileData.state,
  //       zip: tenantProfileData.zip,
  //       countryId: tenantProfileData.countryId,
  //       updatedBy: adminId,
  //     };
  //     callMethod = updateTenantProfile(adminId, data);
  //   }

  //   callMethod
  //     .then((res) => {
  //       if (res?.status === 200 || res?.status === 201) {
  //         setAlert("Successfully updated");
  //         // setValueChanged(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setError("Something went wrong!");
  //     })
  //     .finally(() => setsubmittingForm(false));
  // };

  const handleImageClick = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const image = URL.createObjectURL(file);
      setImagePreview(image);
    }
  };
  const handleShowChangePassword = () => {
    setChangePassword((prev) => !prev);
  };
  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={false}
          openModal={handleOpenModal}
          heading="Tenant Profile"
        />
        <Box sx={{ mx: 4 }}>
          {pageLoading ? (
            <CircularProgress className="loader" />
          ) : (
            <Paper elevation={2} sx={{ p: 2, height: "calc(100vh - 120px)" }}>
              <Stack
                direction={"row"}
                spacing={4}
                sx={{ mx: "auto", px: 4, py: 5 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <img
                    id="profile-image"
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "0.5rem",
                      objectFit: "cover",
                      cursor: "pointer",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    }}
                    onClick={handleImageClick}
                    src={imagePreview ? imagePreview : dummyProfile}
                    alt={"profile image"}
                  />
                  <input
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                    type={"file"}
                    ref={uploadImageRef}
                    style={{ display: "none" }}
                  />
                  <TextField
                    value={tenantProfileData?.name}
                    fullWidth
                    label="Name"
                    aria-readonly
                    name="name"
                  />
                  <TextField
                    value={tenantProfileData?.email}
                    fullWidth
                    label="Email"
                    aria-readonly
                    name="email"
                  />
                  {/* <TextField value={country} fullWidth label="Country" /> */}
                  {/* <Stack direction={"column"}> */}
                  {/* <Typography>Country</Typography> */}
                  {/* {valueChanged && (
                      <Stack direction={"row"}>
                        <LoadingButton
                          loading={submittingForm}
                          type={"submit"}
                          sx={{ width: "150px", marginLeft: "auto" }}
                          variant="contained"
                        >
                          {profileExists ? "Update" : "Create"}
                        </LoadingButton>
                      </Stack>
                    )} */}
                </Stack>
                <ChanegPassword
                  changePassword={changePassword}
                  handleShowChanegPassword={handleShowChangePassword}
                />
              </Stack>
            </Paper>
          )}
        </Box>
      </AnimatedPage>
    </>
  );
};

export const EnhancedTenantProfile = Hoc(TenantProfile, {
  name: "tenant-profile",
});
