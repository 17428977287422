import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import {
    CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { SnackBarAlert } from "../../../utils/SnackBar";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import styled from "@emotion/styled";
import { Stack } from "@mui/system";
import { createAffiliateDetail, getAffiliateDetail, updateAffiliateDetail, getAllCallbackType } from "../affiliateMaster.api";
import { useNavigate } from "react-router-dom";
import { fetchAllCountry } from "../../country/country.api";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
const CreateAffiliate = () => {
    const MyComponent = styled("button")({
        backgroundColor: "white",
        color: "black",
        padding: "16px",
        borderWidth: "1px",
        borderRadius: "10px",
        "&:hover": {
            backgroundColor: "black",
            color: "white",
        },
    });

    const dropDowns = { CALLBACKTYPE: "CALLBACKTYPE", REQUESTTYPE: "REQUESTTYPE", PAYOUTTYPE: "PAYOUTTYPE" };

    const [alert, setAlert] = useState(false);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countriesSelected, setCountriesSelected] = useState([]);
    const [callbackType, setCallbackType] = useState([]);
    const [selectedCallbackType, setSelectedCallbackType] = useState([]);
    const [requestTypeSelected, setRequestTypeSelected] = useState([]);
    const [payoutTypeSelected, setpayoutTypeSelected] = useState([]);
    const [mobileUrlCopied, setMobileUrlCopied] = useState(false);
    const [webUrlCopied, setWebUrlCopied] = useState(false);
    const [callbackUrlCopied, setCallbackUrlCopied] = useState(false);
    const adminId = useSelector((state) => state.auth.userData?.adminId);
    const requestTypes = [
        { requestTypeId: "get", requestType: "GET" },
        { requestTypeId: "post", requestType: "POST" }]
    const payoutTypes = [
        { payoutTypeId: 1, payoutType: "Signup" },
        { payoutTypeId: 2, payoutType: "DOI" }]

    const [affiliateData, setAffiliateData] = useState({
        id: null,
        panelId: 30,
        affiliateName: "",
        affiliateUrl: "",
        affiliateWebUrl: "",
        affiliateCallbackUrl: "",
        callbackTypeId: 0,
        payoutType: 0,
        requestType: "",
        cost: 0,
        createdBy: adminId,
        countryIds: []
    });

    const handleCloseAlert = () => {
        setAlert(false);
    };

    const [validationError, setvalidationError] = useState({
        affiliateName: "",
        countries: "",
        callbackTypeId: "",
        payoutType: "",
        requestType: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAffiliateData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setvalidationError((prev) => ({
            ...prev,
            [name]: "",
        }));
    };



    useEffect(() => {
        fetchAllCountry()
            .then((res) => {
                if (res?.status === 200) {
                    setCountries(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getAllCallbackType()
            .then((res) => {
                if (res?.status === 200) {
                    setCallbackType(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [adminId])

    const type = location.pathname.split('/')[2];
    let affiliateId = parseInt(location.pathname.split('/')[3]);
    useEffect(() => {
        if (type === "edit-affiliate") {
            if (affiliateId) {
                getAffiliateDetail(affiliateId).then((res) => {
                    if (res.status === 200) {
                        const affilicatedata = res.data.data[0];
                        console.log("edit get aff by id", affilicatedata)
                        setAffiliateData(affilicatedata);
                        const stringarray = affilicatedata.countries.split(',')
                        const countryArray = [];
                        if (countries?.length > 0 && stringarray.length > 0) {
                            stringarray.map((x, i) => {
                                const item = countries.find((i) => i.countryId === Number(x));
                                if (item) {
                                    countryArray.push(item);
                                    return item;
                                }
                                return null;
                            })
                            console.log(countryArray);
                            setCountriesSelected(countryArray)
                        }

                        if (callbackType?.length > 0) {
                            const item = callbackType.find((i) => i.id === affilicatedata.callbackTypeId);
                            if (item) {
                                setSelectedCallbackType(item);
                            }
                        }

                        
                        if (requestTypes?.length > 0) {
                            const item = requestTypes.find((i) => i.requestTypeId === affilicatedata.requestType);
                            if (item) {
                                setRequestTypeSelected(item);
                            }
                        }

                        if (payoutTypes?.length > 0) {
                            const item = payoutTypes.find((i) => i.payoutTypeId === affilicatedata.payoutType);
                            if (item) {
                                setpayoutTypeSelected(item);
                            }
                        }


                    } else {
                        console.log(res);
                    }

                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }, [location, countries, affiliateId, callbackType, type])

    const navigate = useNavigate();

    const handleSubmit = () => {
        console.log("clicked", type);
        debugger
        let data = { ...affiliateData };
        data.countryIds = countriesSelected.map(obj => obj.countryId);
        data.callbackTypeId = selectedCallbackType.id
        data.requestType = requestTypeSelected.requestTypeId;
        data.payoutType = payoutTypeSelected.payoutTypeId;

        let duplicate = { ...validationError };

        if (data.affiliateName === undefined || data.affiliateName === "") {
            duplicate["affiliateName"] = "Affiliate name is required";
        }
        if (data.callbackTypeId === undefined || data.callbackTypeId === 0) {
            duplicate["callbackTypeId"] = "Callback type is required";
        }
        if (data.requestType === undefined || data.requestType === "") {
            duplicate["requestType"] = "Request Type is required";
        }
        if (data.payoutType === undefined || data.payoutType === 0) {
            duplicate["payoutType"] = "Payout Type is required";
        }
        if (countriesSelected && countriesSelected.length < 1) {
            duplicate["countries"] = "Selecte Atleast One Country";
        }

        setvalidationError(duplicate);
        const noError = Object.values(duplicate).every(
            (x) => x === null || x === ""
        );
        if (!noError) {
            return;
        }

        setLoading(true);
        if (type === "edit-affiliate") {
            updateAffiliateDetail(data.id, data)
                .then((res) => {
                    if (res?.status === 200) {
                        setAlert("Affiliated Updated successfully");
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setAlert("Something went wrong");
                })
                .finally(() => setLoading(false));
        } else {
            createAffiliateDetail(data)
                .then((res) => {
                    console.log("create aff resp: ", res.data)
                    if (res?.status === 200) {
                        navigate(`/admin/edit-affiliate/${res?.data?.data?.id}`);
                        setAlert("Affiliated created successfully");
                        setAffiliateData({
                            id: null,
                            panelId: 30,
                            affiliateName: "",
                            affiliateUrl: "",
                            affiliateWebUrl: "",
                            affiliateCallbackUrl: "",
                            callbackTypeId: 0,
                            payoutType: 0,
                            requestType: "",
                            cost: 0,
                            createdBy: adminId,
                            countryIds: []
                        })

                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setAlert("Something went wrong");
                })
                .catch(() => setLoading(false));
        }
    };

    const handleCancel = () => {
        navigate(`/admin/affiliate-master`);
    }

    const handleCopyClick = (type) => {
        if (type === 'mobile') {
            setMobileUrlCopied(true);
        } else if (type === 'web') {
            setWebUrlCopied(true);
        }
        else {
            setCallbackUrlCopied(true);
        }
    };

    const handleDropDownChange = (value, dropDownName) => {
        switch (dropDownName) {
            case dropDowns.CALLBACKTYPE:
                setSelectedCallbackType(value);
                setvalidationError((prev) => ({
                    ...prev,
                    callbackTypeId: "",
                }));
                break;
            case dropDowns.REQUESTTYPE:
                setRequestTypeSelected(value);
                setvalidationError((prev) => ({
                    ...prev,
                    requestType: "",
                }));
                break;
            case dropDowns.PAYOUTTYPE:
                setpayoutTypeSelected(value);
                setvalidationError((prev) => ({
                    ...prev,
                    payoutType: "",
                }));
                break;

            default:
                break;
        }
    };

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
    
        // Validate if the pressed key is a valid digit (0-9) or a decimal point
        if (!/^\d$/.test(keyValue) && keyValue !== ".") {
            event.preventDefault(); // Prevent the key press from being registered
        }
    };
    
    return (
        <>
            <TopHeading
                link={"/admin/affiliate-master"}
                heading={"Affiliate Master Detail"}
                showButton={Boolean(false)}
            />
            <Box sx={{ mx: 4, mt: 4 }}>
                <Stack direction={"row"} alignItems={"flex-start"}>
                    <Paper elevation={2} sx={{ my: 4, p: 2, mr: 2, width: "100%" }}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columnSpacing={{ xs: 2, md: 15 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{ mb: 4 }}
                        >
                            <Grid item xs={2} sm={4} md={4}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name={"affiliateName"}
                                    value={affiliateData?.affiliateName}
                                    onChange={handleInputChange}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "2px solid #f0f0f0",
                                        },
                                    }}
                                    label={"Affiliate Name"}
                                    error={Boolean(validationError.affiliateName)}
                                    helperText={validationError.affiliateName}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <AsyncDropdown
                                    value={selectedCallbackType}
                                    options={callbackType}
                                    objKey={"callbackType"}
                                    stylized
                                    label={"CallbackType"}
                                    handleChange={(value) =>
                                        handleDropDownChange(value, dropDowns.CALLBACKTYPE)
                                    }
                                    error={Boolean(validationError?.callbackTypeId)}
                                    helperText={validationError?.callbackTypeId}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <AsyncDropdown
                                    value={requestTypeSelected}
                                    options={requestTypes}
                                    objKey={"requestType"}
                                    stylized
                                    label={"requestType"}
                                    handleChange={(value) =>
                                        handleDropDownChange(value, dropDowns.REQUESTTYPE)
                                    }
                                    error={Boolean(validationError?.requestType)}
                                    helperText={validationError?.requestType}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={12}>
                                <CopyToClipboard text={affiliateData?.affiliateUrl} onCopy={() => handleCopyClick("mobile")}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        name={"affiliateUrl"}
                                        value={affiliateData?.affiliateUrl}
                                        onChange={handleInputChange}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "2px solid #f0f0f0",
                                            },
                                        }}
                                        label={"Mobile traffic URL"}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <IconButton disabled={mobileUrlCopied}>
                                                    {mobileUrlCopied ? <CheckCircleIcon /> : <FileCopyIcon />}
                                                </IconButton>
                                            ),
                                        }}
                                        variant="filled"
                                    />
                                </CopyToClipboard>
                            </Grid>
                            <Grid item xs={2} sm={4} md={12}>
                                <CopyToClipboard text={affiliateData?.affiliateWebUrl} onCopy={() => handleCopyClick("web")}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        name={"affiliateWebUrl"}
                                        value={affiliateData?.affiliateWebUrl}
                                        onChange={handleInputChange}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "2px solid #f0f0f0",
                                            },
                                        }}
                                        label={"Website traffic URL"}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <IconButton disabled={webUrlCopied}>
                                                    {webUrlCopied ? <CheckCircleIcon /> : <FileCopyIcon />}
                                                </IconButton>
                                            ),
                                        }}
                                        variant="filled"
                                    />
                                </CopyToClipboard>
                            </Grid>
                            <Grid item xs={2} sm={4} md={12}>
                                <CopyToClipboard text={affiliateData?.affiliateCallbackUrl} onCopy={() => handleCopyClick("callback")}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        name={"affiliateCallbackUrl"}
                                        value={affiliateData?.affiliateCallbackUrl}
                                        onChange={handleInputChange}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "2px solid #f0f0f0",
                                            },
                                        }}
                                        label={"Callback URL"}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton disabled={callbackUrlCopied}>
                                                    {callbackUrlCopied ? <CheckCircleIcon /> : <FileCopyIcon />}
                                                </IconButton>
                                            ),
                                        }}
                                        error={Boolean(validationError.affiliateCallbackUrl)}
                                        helperText={validationError.affiliateCallbackUrl}
                                    />
                                </CopyToClipboard>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <MultipleValueDropDown
                                    value={countriesSelected}
                                    options={countries}
                                    objkey={"countryName"}
                                    label={"Select Countries"}
                                    onChange={(value) => {
                                        setCountriesSelected(value);
                                        if (value) {
                                            setvalidationError((prev) => ({
                                                ...prev,
                                                countries: "",
                                            }));
                                        }
                                    }}
                                    error={Boolean(validationError.countries)}
                                    helperText={validationError.countries}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <AsyncDropdown
                                    value={payoutTypeSelected}
                                    options={payoutTypes}
                                    objKey={"payoutType"}
                                    stylized
                                    label={"payoutType"}
                                    handleChange={(value) =>
                                        handleDropDownChange(value, dropDowns.PAYOUTTYPE)
                                    }
                                    error={Boolean(validationError?.payoutType)}
                                    helperText={validationError?.payoutType}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name={"cost"}
                                    type={'number'}
                                    value={affiliateData?.cost}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "2px solid #f0f0f0",
                                        },
                                    }}
                                    label={"Cost"}
                                    error={Boolean(validationError.cost)}
                                    helperText={validationError.cost}
                                />
                            </Grid>
                        </Grid>
                        <Stack mt={2} direction={"row"} spacing={2} justifyContent="flex-end">
                            <MyComponent disabled={loading} onClick={handleSubmit}>
                                {loading ? (
                                    <CircularProgress
                                        size={"21px"}
                                        sx={{ margin: "0 14px", color: "black", padding: 0 }}
                                    />
                                ) : type === "edit-affiliate" ? (
                                    "Update"
                                ) : (
                                    "Save"
                                )}
                            </MyComponent>
                            <MyComponent onClick={handleCancel}>Cancel</MyComponent>
                        </Stack>
                    </Paper>
                </Stack>

            </Box>
            <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
        </>
    )
}

export default CreateAffiliate;