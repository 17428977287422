import axios from "axios";

export const verifyUser = async (token) => {
  try {
    const res = await axios.post(
      `/api/user/verifyForgotPassword?token=${token}`
    );
    return res;
  } catch (error) {
    console.log("Failed verifying user", error.message);
    return error;
  }
};

export const resetPassword = async (token, body) => {
  try {
    const res = await axios.post(`/api/user/resetPassword?token=${token}`, body);
    return res;
  } catch (error) {
    console.log("Failed updating password", error.message);
    return error;
  }
};
