import axios from "axios"

export const getAllMembersBytenantId = async(id) => {
    try {
        const res = await axios(`/api/Company/getAllTeam/${id}`);
        return res;
    } catch (error) {
        console.log('failed fetching', error)
    }
}
export const getRolessBytenantId = async(id) => {
    try {
        const res = await axios(`/api/systemUser/getRoleByTenant/${id}`);
        return res;
    } catch (error) {
        console.log('failed fetching', error)
    }
}

export const addPanelMember = async(data) => {
    try {
        const res = await axios.post('/api/Company/createPanelMemberAndAssign', data);
        return res;
    } catch (error) {
        console.log('failed creating member', error.message)
    }
}