import * as React from "react";
import { styled } from "@mui/material/styles";
// import { LoadingButton, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const ColorButton = styled(LoadingButton)(({ theme }) => ({
  //   color: theme.palette.getContrastText('blue'),
  color: "white",
  backgroundColor: "#2560D8",
  borderRadius: "0.7rem",
  letterSpacing: "4px",
  padding: "15px 20px",
  fontWeight: "bold",
  fontSize: "1.1rem",
  boxShadow: "3px 3px 3px gray",
  "&:active": {
    backgroundColor: "#2560D8",
  },
}));

