import axios from "axios";
import { searchAxios } from "../../test/searchAxios";
import { strapiAxios, testAxios } from "../../test/testAxios";
const header = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const getAllMembers = async (page, limit) => {
  try {
    const res = await axios.get(`/api/Company/getMemberByRole/2`);
    return res;
  } catch (error) {
    return error.message;
  }
};
export const addUser = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createSystemMember", data);
    return res;
  } catch (error) {
    return error.message;
  }
};

export const getUserById = async (id) => {
  try {
    const res = await strapiAxios.get(`/api/restaurants/${id}`);
    return res;
  } catch (error) {
    return error.message;
  }
};

export const updateUser = async (id, data) => {
  try {
    const res = await axios.patch(`/api/systemUser/updateMember/${id}`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteMember = async(id) => {
  try {
    const res = await axios.delete(`/api/systemUser/deleteMember/${id}`)
    return res;
  } catch (error) {
    console.log('failed', error)
  }
}
// export const getAllUsers = async() => {
//     try {
//         const res = await testAxios.get('/products');
//         return res;
//     } catch (error) {
//         return error.message;
//     }
// }

export const searchUser = async (query) => {
  try {
    const res = await searchAxios(`/search/?q=${query}`);
    return res;
  } catch (error) {
    return error.message;
  }
};
