import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import { fetchAllCountry } from "../country/country.api";
import Hoc from "../HOC/Hoc";
import CreateIcon from "@mui/icons-material/Create";
import {
  createTenantProfile,
  getCountryById,
  getPanelProfile,
  updateTenantProfile,
} from "./panelProfile.api";
import { useSelector } from "react-redux";
import AnimatedPage from "../animatedPage/AnimatedPage";
import { dummyProfile } from "../../config/keys";

const TenantProfile = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [languages, setLanguages] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [country, setCountry] = useState({});
  const [countries, setcountries] = useState([]);
  const [valueChanged, setValueChanged] = useState(false);
  const [submittingForm, setsubmittingForm] = useState(false);
  const [profileExists, setProfileExists] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const tenantId = useSelector((state) => state.auth.userData?.tenantId);

  const [tenantProfileData, setTenantProfileData] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    countryId: "",
  });

  const uploadImageRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    setPageLoading(true);
    getPanelProfile(adminId)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.data) {
            setTenantProfileData(res.data.data[0]);
            setProfileExists(true);
          } else {
            setProfileExists(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong!");
      })
      .finally(() => setPageLoading(false));

    fetchAllCountry()
      .then((res) => {
        if (res?.status === 200) {
          setcountries(res.data.data);
        }
      })
      .catch((err) => {
        console.log("couldnt fetch countries", err);
        setError("Something went wrong!");
      });

    return () => {
      setTenantProfileData({
        address: "",
        city: "",
        state: "",
        zip: "",
        countryId: "",
      });
    };
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0 && tenantProfileData.countryId) {
      const item = countries.find(
        (i) => i.countryId === tenantProfileData.countryId
      );
      setCountry(item);
    }
    return () => {
      setCountry({});
    };
  }, [countries]);

  const handleInputChange = (e) => {
    setValueChanged(true);
    const { value, name } = e.target;
    console.log(e.target);
    setTenantProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropDownChange = (value) => {
    setValueChanged(true);
    setCountry(value);
    setTenantProfileData((prev) => ({
      ...prev,
      countryId: value.countryId,
    }));
  };

  const handleSubmit = (e) => {
    setsubmittingForm(true);
    e.preventDefault();
    let callMethod;
    if (!profileExists) {
      let data = {
        tenantId: tenantId,
        profilePicUrl: "Url",
        address: tenantProfileData.address,
        city: tenantProfileData.city,
        state: tenantProfileData.state,
        zip: tenantProfileData.zip,
        countryId: tenantProfileData.countryId,
        createdBy: adminId,
      };
      callMethod = createTenantProfile(data);
    } else {
      let data = {
        tenantId: tenantId,
        profilePicUrl: "Url",
        address: tenantProfileData.address,
        city: tenantProfileData.city,
        state: tenantProfileData.state,
        zip: tenantProfileData.zip,
        countryId: tenantProfileData.countryId,
        updatedBy: adminId,
      };
      callMethod = updateTenantProfile(adminId, data);
    }

    callMethod
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setAlert("Successfully updated");
          setValueChanged(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong!");
      })
      .finally(() => setsubmittingForm(false));
  };

  const handleImageClick = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const image = URL.createObjectURL(file);
      setImagePreview(image);
    }
  };
  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={false}
          openModal={handleOpenModal}
          heading="Panel Profile"
        />
        <Box sx={{ mx: 4 }}>
          {pageLoading ? (
            <CircularProgress className="loader" />
          ) : (
            <form onSubmit={handleSubmit}>
              <Paper elevation={2} sx={{ p: 2, height: "calc(100vh - 120px)" }}>
                <Stack
                  direction={"row"}
                  spacing={4}
                  sx={{ mx: "auto", px: 4, py: 5 }}
                >
                  <img
                    id="profile-image"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "0.5rem",
                      objectFit: "cover",
                      cursor: "pointer",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    }}
                    onClick={handleImageClick}
                    src={
                      imagePreview
                        ? imagePreview
                        : dummyProfile
                    }
                    alt={"profile image"}
                  />
                  <input
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                    type={"file"}
                    ref={uploadImageRef}
                    style={{ display: "none" }}
                  />
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <TextField
                      value={tenantProfileData.address}
                      fullWidth
                      label="Address"
                      onChange={handleInputChange}
                      name="address"
                    />
                    <TextField
                      value={tenantProfileData.city}
                      fullWidth
                      label="City"
                      onChange={handleInputChange}
                      name="city"
                    />
                    <TextField
                      value={tenantProfileData.state}
                      fullWidth
                      label="State"
                      onChange={handleInputChange}
                      name="state"
                    />
                    <TextField
                      onChange={handleInputChange}
                      name="zip"
                      value={tenantProfileData.zip}
                      fullWidth
                      type={"number"}
                      label="Zip"
                    />
                    {/* <TextField value={country} fullWidth label="Country" /> */}
                    {/* <Stack direction={"column"}> */}
                      <AsyncDropdown
                        label={"Country"}
                        value={country}
                        handleChange={handleDropDownChange}
                        objKey={"countryName"}
                        options={countries}
                      />
                    {/* </Stack> */}
                    {valueChanged && (
                      <Stack direction={"row"}>
                        <LoadingButton
                          loading={submittingForm}
                          type={"submit"}
                          sx={{ width: "150px", marginLeft: "auto" }}
                          variant="contained"
                        >
                          {profileExists ? "Update" : "Create"}
                        </LoadingButton>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Paper>
            </form>
          )}
        </Box>
      </AnimatedPage>
    </>
  );
};

export const EnhancedPanelProfile = Hoc(TenantProfile, {
  name: "panel-profile",
});
