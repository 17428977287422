import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterId: null
}

const masterQuestionSlice = createSlice({
    name: 'masterQuestion',
    initialState,
    reducers: {
        setFilterId: ( state, {payload}) => {
            state.filterId = payload
        }
    }
})

export const {setFilterId} = masterQuestionSlice.actions
export default masterQuestionSlice.reducer;