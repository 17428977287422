import axios from "axios";

// export const createSurvey = async (data) => {
//   try {
//     const res = await axios.post("/api/Company/createSurvey", data);
//     return res;
//   } catch (error) {
//     console.log("err adding survey", error.message);
//   }
// };

// /api/company/sendNotification?surveyId=1
export const notification = async (id) => {
  try {
    const res = await axios.post(
      `/api/company/sendNotification?surveyId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err sending notifications", error.message);
  }
};

export const updateSurvey = async (id, data) => {
  try {
    const res = await axios.patch(`/api/Company/updateSurvey/${id}`, data);
    return res;
  } catch (error) {
    console.log("err adding survey", error.message);
  }
};

export const getAllSurvey = async () => {
  try {
    const res = await axios.get("/api/Company/getAllSurvey");
    return res;
  } catch (error) {
    console.log("err fetching survey", error.message);
  }
};
// export const deleteSurvey = async (id) => {
//   try {
//     const res = await axios.delete(`/api/Company/deleteSurvey/${id}`);
//     return res;
//   } catch (error) {
//     console.log("err deleting survey", error.message);
//   }
// };

export const getIncentive = async (panelId, cpi) => {
  try {
    const res = await axios.get(
      `/api/Company/getCompletedPoints?panelId=${panelId}&cpi=${cpi}`
    );
    return res;
  } catch (error) {
    console.log("err fetching", error.message);
  }
};

export const createSurvey = async (panelId, data) => {
  try {
    const res = await axios.post(
      `/api/Company/createPanelSurvey?panelId=${panelId}`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getSurveyByPanelId = async (panelId) => {
  try {
    const res = await axios.get(
      `/api/Company/getPanelSurvey?panelId=${panelId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const deleteSurvey = async (id) => {
  try {
    const res = await axios.delete(`/api/Company/deleteSurvey?surveyId=${id}`);
    return res;
  } catch (error) {
    console.log("failed deleting", error);
  }
};

export const getClient = async () => {
  try {
    const res = await axios.get(`/api/Company/getClient`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const getStudyTypes = async () => {
  try {
    const res = await axios.get(`/api/Company/getStudyType`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const getIndustries = async () => {
  try {
    const res = await axios.get(`/api/Company/getIndustry`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const getPanelCountryLanguage = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getPanelCountryLanguage/${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getSurveyDetails = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getSurveyDetail?surveyId=${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateSurveyDetails = async (id, data) => {
  try {
    const res = await axios.put(
      `/api/Company/updatePanelSurvey?surveyId=${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getSurveyQualification = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getSurveyQualification?surveyId=${id}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const getQualificationQuestions = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getUserSignupQsnForAdmin?panelId=11`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const createSurveyQualification = async (data) => {
  try {
    const res = await axios.post(
      `/api/Company/createSurveyQualification`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteQualification = async (id) => {
  try {
    const res = await axios.delete(
      `/api/Company/deleteSurveyQualification?qualificationId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getQualificationById = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getQualification?qualificationId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getSurveyQualificationQuestions = async (
  languageId,
  countryId
) => {
  try {
    const res = await axios.get(
      `/api/company/allMasterQsn?countryId=${countryId}&languageId=${languageId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const updateSampleCount = async (id) => {
  try {
    const res = await axios.get(`/api/company/findLatestSurveyUser/${id}`);
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const updateQualification = async (id, data) => {
  try {
    const res = await axios.put(`/api/company/updateQualification/${id}`, data);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
export const getEmailSamples = async (surveyId) => {
  try {
    const res = await axios.get(
      `/api/company/getemailsamples?surveyId=${surveyId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const createNoToSendEmailBatch = async (data) => {
  try {
    const res = await axios.post(`/api/company/createEmailBatchManualy`,data);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getEmailBatchHistory = async (surveyId) => {
  try {
    const res = await axios.get(
      `/api/company/getEmailBatch?surveyId=${surveyId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
