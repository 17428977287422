import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthRoutes = () => {
  const currentUserRoleId = useSelector((state) => state.auth.userData?.roleId);
  const superAdmin = "/admin/users";
  const systemAdmin = "/admin/company-on-board";
  const companyAdmin = "/admin/tenant-profile";
  const panelAdmin = "/admin/panel-profile";
  const panelMember = "/admin/assigned-panels";

  let selectedPath;
  if (currentUserRoleId === 1) {
    selectedPath = superAdmin;
  } else if (currentUserRoleId === 2) {
    selectedPath = systemAdmin;
  } else if (currentUserRoleId === 3) {
    selectedPath = companyAdmin;
  } else if (currentUserRoleId === 4) {
    selectedPath = panelAdmin;
  } else {
    selectedPath = panelMember;
  }

  const user = useSelector((state) => state.auth.user);
  return user === null ? <Outlet /> : <Navigate to={selectedPath} />;
};

export default AuthRoutes;
