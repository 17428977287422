import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfirmAlert from "../../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import AnimatedPage from "../../animatedPage/AnimatedPage";
import Hoc from "../../HOC/Hoc";
import { getTranslatedCategories } from "../category.api";
import ConfCategory from "../ConfCategory";

const CategoryTranslationsPage = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [translatedCategories, settranslatedCategories] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [openTranslateModal, setOpenTranslateModal] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setTableLoading(true);
      getTranslatedCategories(id)
        .then((res) => {
          if (res?.status === 200) {
            settranslatedCategories(res.data.data);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          console.log(err.message);
        });
    }
    return () => {
      settranslatedCategories(data);
    };
  }, [id]);

  useEffect(() => {
    if (translatedCategories && translatedCategories.length > 0) {
      const item = data.find((item) => item.id === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleOpenTranslateModal = () => {
    setOpenTranslateModal(true);
  }
  const handleCLoseTranslateModal = () => {
    setOpenTranslateModal(false);
  }

  const rows = [];
  const labels = ["Name", "Country", "Language", "Panel", "Status", "Actions"];
  translatedCategories &&
    translatedCategories.length > 0 &&
    translatedCategories.map((item) =>
      rows.push([
        item.category,
        item.category,
        item.category,
        item.category,
        <CustomMuiSwitch checked={item.isActive} />,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item.category_Id);
              handleOpenTranslateModal();
            }}
          >
            <Create />
          </IconButton>
          {/* <ConfirmAlert
            msg={"Are you sure you want to delete?"}
            onClickEvent={() => handleDelete(item.id)}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert> */}
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={false}
          link={"/admin/category"}
          openModal={handleOpenModal}
          heading="Category Translations"
        />
         <ConfCategory
          open={openTranslateModal}
          loading={loading}
          type="TRANSLATE"
          hadnleCloseModal={handleCLoseTranslateModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
        />
        <CustomTable labels={labels} data={rows} pageLoading={tableLoading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedCategoryTranslationsPage = Hoc(CategoryTranslationsPage, {
  name: "",
});
