import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { addUser, getUserById, updateUser } from "../users/user.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRoles } from "../roles/roles.api";
import { getAllFeature } from "../feature/feature.api";
import FeaturesSelect from "./FeaturesSelect";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  checkEmail,
  createTenantAdmin,
  createTenantFeature,
  getTenantPanels,
  updateTenant,
} from "./companyOnBoard.api";
import { useSelector } from "react-redux";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";
import { fetchAllCountry, getActiveCountries } from "../country/country.api";
import { useDebounce } from "../../hooks/useDebounce";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../assets/js/validateEmail";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PanelDetailsFields from "./PanelDetailsFields";
import { getTenantProfile } from "../tenantProfile/tenantProfile.api";

export const TENANT_ADMIN_DETAIS = "tenant_admin_details";
export const TENANT_PANEL_DETAIS = "tenant_panel_details";
export const FEATURES_AND_COUNTRY = "features_and-countries";

const ConfCompanyOnBoard = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
  } = props;
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [features, setFeatures] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [countries, setCounries] = useState([]);
  // const [featuresSelected, setFeaturesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [countrySelected, setCountrySelected] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [panelHelperText, setPanelHelperText] = useState("");

  // tenant admin state
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    contact: "",
    email: "",
    contactEmail: "",
    password: "",
    createdBy: adminId,
    roleId: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    tenantCountryId: "",
  });

  // panel details
  const [panelDetails, setPanelDetails] = useState([
    {
      panelName: "",
      webAppLink: "",
      mobileAppLink: "",
      panelType: "",
    },
  ]);

  const handleAddPanel = () => {
    let newpanel = {
      panelName: "",
      webAppLink: "",
      mobileAppLink: "",
      panelType: "",
    };
    let currentPanelIndex = panelDetails.length - 1;
    let duplicate = [...panelDetails];
    if (
      duplicate[currentPanelIndex].panelName === "" ||
      duplicate[currentPanelIndex].panelType === ""
    ) {
      setSecondFormValidation(() => ({
        error: "Fill in the current panel details before adding new one",
      }));
      return;
    }
    duplicate.push(newpanel);
    setPanelDetails(duplicate);

    // let validationDuplicate = [...secondFormValidation];
    // validationDuplicate.push(newpanel);
    // setSecondFormValidation(validationDuplicate);
  };

  const handleRemovePanel = (index) => {
    let duplicate = [...panelDetails];
    if (index < 0 || duplicate.length < 2) return;
    duplicate.splice(index, 1);
    setPanelDetails(duplicate);

    // let validationDuplicate = [...secondFormValidation];
    // validationDuplicate.splice(index, 1);
    // setSecondFormValidation(validationDuplicate);
  };

  // tenant deatils
  // const [tenantDetails, setTenantDetails] = useState({
  //   address: "",
  //   city: "",
  //   zip: "",
  //   state: "",
  //   countryId: "",
  //   created_by: adminId,
  // });

  useDebounce(() => checkIfMailExists("email", userData.email), 1000, [
    userData.email,
  ]);
  useDebounce(
    () => checkIfMailExists("contactEmail", userData.contactEmail),
    1000,
    [userData.contactEmail]
  );

  useDebounce(() => checkIfPhoneNumberExist(userData.contact), 1000, [
    userData.contact,
  ]);

  // first form validation
  const [validationError, setValidationError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    email: "",
    contactEmail: "",
    contact: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: null,
  });

  // seconf form validation
  const [secondFormValidation, setSecondFormValidation] = useState({
    error: "",
  });

  // third form validation

  const [thirdFormValidation, setThirdFormValidation] = useState({
    countries: "",
  });
  function checkIfMailExists(name, email) {
    if (email && name && validateEmail(email) && open && type === "ADD") {
      console.log("checking mail for validation");
      let data = {
        email: email,
      };
      checkEmail(data)
        .then((res) => {
          if (res?.status != 200) {
            setValidationError((prev) => ({
              ...prev,
              [name]: "Mail already exists.",
            }));
          }
        })
        .catch((err) => {
          console.log("error checking the mail", err.message);
        });
    }
  }

  function checkIfPhoneNumberExist(phone) {
    console.log("checking phone number");
    if (phone && type === "ADD") {
      if (!validatePhoneNumber(phone)) {
        setValidationError((prev) => ({
          ...prev,
          contact: "Invalid contact number",
        }));
      }
    }
  }
  // stepper state and functions
  const [steps, setSteps] = useState([
    "Create Tenant Admin",
    "Panel Detail",
    "Select Countries",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    console.log("clicked", step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    // validation for first form;
    if (activeStep === 0) {
      console.log("userdata", userData);
      let duplicate = { ...validationError };
      console.log(duplicate);
      if (userData.firstName === "") {
        duplicate["firstName"] = "Tenant name is required";
      }
      if (userData.email === "") {
        duplicate["email"] = "Email is required";
      }
      if (userData.contactEmail === "") {
        duplicate["contactEmail"] = "Contact Email is required";
      }
      if (userData.contact === "") {
        duplicate["contact"] = "Contact is required";
      }
      if (userData.password === "" && type === "ADD") {
        duplicate["password"] = "Password is required";
      }
      if (
        userData.password !== "" &&
        userData.password !== confirmPassword &&
        type === "ADD"
      ) {
        duplicate["password"] = "Password doesn't match";
        duplicate["confirmPassword"] = "Password doesn't match";
      }
      if (userData.address === "") {
        duplicate["address"] = "Address is required";
      }
      if (userData.city === "") {
        duplicate["city"] = "City is required";
      }
      if (userData.state === "") {
        duplicate["state"] = "State is required";
      }
      if (userData.zip === "") {
        duplicate["zip"] = "Zip is required";
      }
      if (!userData.tenantCountryId) {
        duplicate["country"] = "Country is required";
      }

      setValidationError(duplicate);
      const noError = Object.values(duplicate).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }

      // setting panel name as tenant name by defau;t
      if (type === "ADD") {
        let panelDetailsCopy = [...panelDetails];
        panelDetailsCopy[0]["panelName"] = userData.firstName;
        setPanelDetails(panelDetailsCopy);
      }
    } else if (activeStep === 1) {
      if (
        panelDetails[0].panelName === "" ||
        panelDetails[0].panelType === ""
      ) {
        let duplicate = { ...secondFormValidation };
        duplicate.error = "Enter all the required panel details";
        setSecondFormValidation(duplicate);

        const noError = Object.values(duplicate).every(
          (x) => x === null || x === ""
        );
        if (!noError) {
          return;
        }
      }
    } else if (activeStep == 2) {
      let duplicate = { ...thirdFormValidation };
      if (countriesSelected && countriesSelected.length < 1) {
        duplicate["countries"] = "Select Atleast One Country";
      }
      // if (featuresSelected && featuresSelected.length < 1) {
      //   duplicate["features"] = "Selecte Atleast One Feature";
      // }
      setThirdFormValidation(duplicate);
      const noError = Object.values(duplicate).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }
    }
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // end stepper state and functions

  useEffect(() => {
    if (roleSelected) {
      setSelectedRole(roleSelected);
      setUserData((prev) => ({
        ...prev,
        roleId: roleSelected.roleId,
      }));
    }
    return () => {
      setSelectedRole({});
      setActiveStep(0);
      setCompleted({});
      setCountriesSelected([]);
      // setFeaturesSelected([]);
    };
  }, [roleSelected, open]);

  useEffect(() => {
    getActiveCountries()
      .then((res) => {
        if (res?.status === 200) {
          setCounries(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getRoles()
      .then((res) => {
        if (res?.status === 200) {
          setroles(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setroles([]);
    };
  }, [open]);

  const handleInputChange = (e, dataTypeName, index) => {
    const { name, value } = e.target;
    switch (dataTypeName) {
      case TENANT_ADMIN_DETAIS:
        setUserData((prev) => ({
          ...prev,
          [name]: value,
        }));

        setValidationError((prev) => ({
          ...prev,
          [name]: "",
        }));

        if (name === "password") {
          setValidationError((prev) => ({
            ...prev,
            confirmPassword: "",
          }));
        }
        break;
      case TENANT_PANEL_DETAIS:
        let panelDetailsCopy = [...panelDetails];
        panelDetailsCopy[index][name] = value;
        setPanelDetails(panelDetailsCopy);
        setSecondFormValidation(() => ({
          error: "",
        }));

        // let validationPanelDetail = [...secondFormValidation];
        // validationPanelDetail[index][name] = "";
        // setSecondFormValidation(validationPanelDetail);

        // setPanelDetails((prev) => ({
        //   ...prev,
        //   [name]: value,
        // }));
        // setSecondFormValidation((prev) => ({
        //   ...prev,
        //   [name]: "",
        // }));
        break;
      default:
        break;
    }
  };

  // handle selected entry for edit
  useEffect(() => {
    if (
      selectedItem &&
      selectedItem.roleId &&
      editId &&
      type === "EDIT" &&
      roles &&
      roles.length > 0
    ) {
      const role = roles.find((item) => item.roleId === selectedItem.roleId);
      if (role) {
        setSelectedRole(role);
      }
    }

    if (
      selectedItem?.tenantCountryId &&
      type === "EDIT" &&
      countries?.length > 0
    ) {
      const country = countries.find(
        (item) => item.countryId === selectedItem?.tenantCountryId
      );
      if (country) {
        setCountrySelected(country);
        setUserData((prev) => ({
          ...prev,
          tenantCountryId: country.countryId,
        }));
      }
    }

    if (selectedItem && type === "EDIT") {
      // remove select country step
      let duplicate = [...steps];
      duplicate.splice(2, 1);
      setSteps(duplicate);

      const dataFetched = [];

      // fetch tenant panels
      if (selectedItem.adminId) {
        setPageLoading(true);
        getTenantPanels(selectedItem.adminId)
          .then((res) => {
            if (res?.status === 200) {
              dataFetched[0] = true;
              const fetchedTenantPanels = res.data.data;
              let formattedTenantPanels = [];

              if (fetchedTenantPanels && fetchedTenantPanels.length > 0) {
                fetchedTenantPanels.map((item) => {
                  formattedTenantPanels.push({
                    panelId: item.panelId,
                    panelName: item.panelName,
                    webAppLink: item.webAppLink,
                    mobileAppLink: item.mobileAppLink,
                    panelType: item.panelType,
                  });
                });
              }
              setPanelDetails(formattedTenantPanels);
            }
          })
          .catch((err) => {
            console.log("err", err.message);
          });

        // fetch tenant profile

        getTenantProfile(selectedItem.adminId)
          .then((res) => {
            if (res?.status === 200) {
              let { profile } = res.data.data;
              let userDataDuplicate = { ...selectedItem };
              userDataDuplicate.address = profile[0].address;
              userDataDuplicate.city = profile[0].city;
              userDataDuplicate.zip = profile[0].zip;
              userDataDuplicate.state = profile[0].state;
              setUserData(userDataDuplicate);
              dataFetched[1] = true;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setPageLoading(false));
      }
    }
    return () => {
      setUserData({
        firstName: "",
        lastName: "",
        middleName: "",
        contact: "",
        email: "",
        contactEmail: "",
        password: "",
        createdBy: adminId,
        roleId: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        tenantCountryId: "",
      });
      setPanelDetails([
        {
          panelName: "",
          webAppLink: "",
          mobileAppLink: "",
          panelType: "",
        },
      ]);
      setCountrySelected({});
      // setfeaturesAssigned([]);
      setValidationError({
        email: "",
        firstName: "",
        lastName: "",
        email: "",
        contactEmail: "",
        contact: "",
        password: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: null,
      });

      setSecondFormValidation([
        {
          panelName: "",
          webAppLink: "",
          mobileAppLink: "",
          panelType: "",
        },
      ]);

      setThirdFormValidation({
        countries: "",
      });
      setConfirmPassword("");
    };
  }, [editId, selectedItem, open, roles]);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let selectedCountriesIds = [];
    if (type === "ADD") {
      if (countriesSelected?.length <= 0) {
        setThirdFormValidation((prev) => ({
          ...prev,
          countries: "Select Atleast One Country",
        }));
        return;
      }
      if (countriesSelected && countriesSelected?.length > 0) {
        countriesSelected.map((item) =>
          selectedCountriesIds.push(item.countryId)
        );
      }
    }

    setLoading(true);
    console.log("clicked");
    let callMethod;
    if (type === "ADD") {
      let tenantAdminData = {
        firstName: userData.firstName,
        contactEmail: userData.contactEmail,
        contact: userData.contact,
        email: userData.email,
        roleId: roleSelected.roleId,
        password: userData.password,
        createdBy: adminId,
        address: userData.address,
        city: userData.city,
        state: userData.state,
        zip: userData.zip,
        tenantCountryId: userData.tenantCountryId,
        countryIdArray: selectedCountriesIds,
        // featureId: selectedFeaturesIds,
      };
      let tenantpanelDetails = {
        ...panelDetails,
      };

      let data = {
        ...tenantAdminData,
        panelDetail: tenantpanelDetails,
      };

      console.log("final data", data);
      callMethod = createTenantAdmin(data);
    } else {
      console.log("tenant update");
      let tenantAdminData = {
        firstName: userData.firstName,
        contactEmail: userData.contactEmail,
        contact: userData.contact,
        email: userData.email,
        roleId: 3,
        updatedBy: adminId,
        address: userData.address,
        city: userData.city,
        state: userData.state,
        zip: userData.zip,
        tenantCountryId: userData.tenantCountryId,
        panelDetail: panelDetails,
      };
      callMethod = updateTenant(tenantAdminData, selectedItem?.adminId);
    }

    callMethod
      .then((res) => {
        console.log("res", res);
        if (res?.status >= 200 && res?.status <= 299) {
          console.log("added", res.data.data);
          setSuccess();
          setUserData({
            firstName: "",
            lastName: "",
            middleName: "",
            contact: "",
            email: "",
            password: "",
            createdBy: adminId,
            roleId: "",
          });
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRoleDropDownChange = (value) => {
    setSelectedRole(value);
    setUserData((prev) => ({
      ...prev,
      roleId: value.roleId,
    }));
  };
  const handleCountryDropDownChange = (value) => {
    setCountrySelected(value);
    setUserData((prev) => ({
      ...prev,
      tenantCountryId: value.countryId,
    }));
    setValidationError((prev) => ({
      ...prev,
      country: "",
    }));
  };

  return (
    <Dialog
      // fullScreen
      fullWidth={"true"}
      maxWidth="lg"
      open={open}
      onClose={hadnleCloseModal}
    >
      <form onSubmit={handleSubmit} noValidate>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle my={4}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        {/* tenant admin */}
        {activeStep === 0 && (
          <DialogContent style={{ paddingBottom: 0 }}>
            <Box padding={2} sx={{ position: "relative", minHeight: 400 }}>
              {pageLoading ? (
                <CircularProgress className="loader" />
              ) : (
                <Stack direction={"column"} spacing={2}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <TextField
                      onChange={(e) =>
                        handleInputChange(e, TENANT_ADMIN_DETAIS)
                      }
                      fullWidth
                      error={Boolean(validationError.firstName)}
                      helperText={validationError.firstName}
                      label={"Tenant name"}
                      name="firstName"
                      type={"text"}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      required
                      value={userData.firstName}
                    />
                    {/* <TextField
                    onChange={(e)=>handleInputChange(e)}
                    fullWidth
                    label={"Middle Name"}
                    name="middleName"
                    type={"text"}
                    value={userData.middleName}
                  />
                  <TextField
                    error={Boolean(validationError.lastName)}
                    helperText={validationError.lastName}
                    onChange={(e)=>handleInputChange(e)}
                    fullWidth
                    label={"Last Name"}
                    name="lastName"
                    type={"text"}
                    required
                    value={userData.lastName}
                  /> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <TextField
                      onChange={(e) =>
                        handleInputChange(e, TENANT_ADMIN_DETAIS)
                      }
                      fullWidth
                      error={Boolean(validationError.contact)}
                      helperText={validationError.contact}
                      type={"number"}
                      label={"Contact Number"}
                      name="contact"
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      required
                      value={userData.contact}
                    />

                    <TextField
                      onChange={(e) =>
                        handleInputChange(e, TENANT_ADMIN_DETAIS)
                      }
                      error={Boolean(validationError.contactEmail)}
                      helperText={validationError?.contactEmail}
                      fullWidth
                      type={"text"}
                      label={"Contact Email"}
                      name="contactEmail"
                      autoComplete="false"
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      required
                      value={userData.contactEmail}
                    />
                    <TextField
                      onChange={(e) =>
                        handleInputChange(e, TENANT_ADMIN_DETAIS)
                      }
                      error={Boolean(validationError.email)}
                      helperText={validationError?.email}
                      fullWidth
                      type={"email"}
                      label={"Login Email"}
                      name="email"
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      required
                      value={userData.email}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                    alignItems="flex-end"
                  >
                    {/* <Stack sx={{ width: "100%" }}>
                    <p>Role</p>
                    <AsyncDropdown
                      disabled={props.disableDropDown}
                      value={selectedRole}
                      handleChange={handleRoleDropDownChange}
                      objKey="role"
                      options={roles}
                    />
                  </Stack> */}
                  </Stack>

                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                    alignItems="flex-end"
                  >
                    {type === "ADD" && (
                      <>
                        <TextField
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          fullWidth
                          label={"Password"}
                          error={Boolean(validationError.password)}
                          helperText={validationError.password}
                          type={showPassword ? "text" : "password"}
                          autoComplete="none"
                          name="password"
                          required
                          value={confirmPassword}
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowPassword((prev) => !prev)
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          onChange={(e) =>
                            handleInputChange(e, TENANT_ADMIN_DETAIS)
                          }
                          fullWidth
                          label={
                            type === "EDIT"
                              ? "New Password"
                              : "Confirm Password"
                          }
                          type={showConfirmPassword ? "text" : "password"}
                          error={Boolean(validationError?.confirmPassword)}
                          helperText={validationError?.confirmPassword}
                          name="password"
                          disabled={Boolean(type === "EDIT")}
                          required
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmPassword((prev) => !prev)
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          value={userData.password}
                        />
                      </>
                    )}
                  </Stack>
                  <Typography
                    sx={{
                      background: "#3c87ff",
                      p: 1,
                      color: "#ffffff",
                      borderRadius: 1,
                    }}
                    fontWeight={500}
                  >
                    Tenant Address details
                  </Typography>
                  <Stack direction={"column"} spacing={2}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <TextField
                        onChange={(e) =>
                          handleInputChange(e, TENANT_ADMIN_DETAIS)
                        }
                        fullWidth
                        type={"text"}
                        label={"Address"}
                        error={Boolean(validationError.address)}
                        helperText={validationError.address}
                        name="address"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        required
                        value={userData.address}
                      />
                      <TextField
                        onChange={(e) =>
                          handleInputChange(e, TENANT_ADMIN_DETAIS)
                        }
                        fullWidth
                        type={"text"}
                        error={Boolean(validationError.city)}
                        helperText={validationError.city}
                        label={"City"}
                        name="city"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        required
                        value={userData.city}
                      />
                      <TextField
                        onChange={(e) =>
                          handleInputChange(e, TENANT_ADMIN_DETAIS)
                        }
                        fullWidth
                        type={"text"}
                        error={Boolean(validationError.state)}
                        helperText={validationError.state}
                        label={"State"}
                        name="state"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        required
                        value={userData.state}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems="flex-end"
                    >
                      <TextField
                        onChange={(e) =>
                          handleInputChange(e, TENANT_ADMIN_DETAIS)
                        }
                        fullWidth
                        type={"number"}
                        error={Boolean(validationError.zip)}
                        helperText={validationError.zip}
                        label={"Zip"}
                        name="zip"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        required
                        value={userData.zip}
                      />
                      <AsyncDropdown
                        value={countrySelected}
                        error={Boolean(validationError.country)}
                        helperText={validationError.country}
                        label={"Country"}
                        objKey={"countryName"}
                        options={countries}
                        handleChange={handleCountryDropDownChange}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Box>
          </DialogContent>
        )}
        {/* tenant panel detail */}
        {activeStep === 1 && (
          <DialogContent style={{ paddingBottom: 0 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              {secondFormValidation.error && (
                <Typography sx={{ color: "red", fontSize: "14px" }}>
                  {secondFormValidation.error}
                </Typography>
              )}
              {type === "ADD" && (
                <IconButton sx={{ ml: "auto" }} onClick={handleAddPanel}>
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </Stack>
            {panelDetails &&
              panelDetails.length > 0 &&
              panelDetails.map((item, index) => (
                <PanelDetailsFields
                  handleAddPanel={handleAddPanel}
                  handleRemovePanel={handleRemovePanel}
                  handleChange={handleInputChange}
                  data={item}
                  key={index}
                  index={index}
                  type={type}
                />
              ))}
          </DialogContent>
        )}
        {/* feature and couintry selection */}

        {activeStep === 2 && (
          <DialogContent>
            <Stack py={2} spacing={2}>
              <MultipleValueDropDown
                value={countriesSelected}
                options={countries}
                objkey={"countryName"}
                label={"Select Countries"}
                onChange={(value) => {
                  setCountriesSelected(value);
                  if (value) {
                    setThirdFormValidation((prev) => ({
                      ...prev,
                      countries: "",
                    }));
                  }
                }}
                error={Boolean(thirdFormValidation.countries)}
                helperText={thirdFormValidation.countries}
              />
              {/* <MultipleValueDropDown
                value={featuresSelected}
                objkey={"feature"}
                options={features}
                label={"Select Features"}
                onChange={(value) => {
                  setFeaturesSelected(value);
                  if (value) {
                    setThirdFormValidation((prev) => ({
                      ...prev,
                      features: "",
                    }));
                  }
                }}
                error={Boolean(thirdFormValidation.features)}
                helperText={thirdFormValidation.features}
              /> */}
              {/* <Stack>
                <Typography>Select Features</Typography>
                <Grid container direction={'column'}>
                  {features &&
                    features.length > 0 &&
                    features.map((item, index) => (
                      <Grid key={index} item xs={4}>
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Stack> */}
            </Stack>
          </DialogContent>
        )}

        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          {/* {allStepsCompleted() && (
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type={"submit"}
            >
              {type === "ADD" ? "Save" : "Update"}
            </LoadingButton>
          )}
          <Button onClick={handleComplete}>
            {completedSteps() === totalSteps() - 1 ? "Finish" : "Complete Step"}
          </Button> */}
          {allStepsCompleted() ? (
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type={"submit"}
            >
              {type === "ADD" ? "Save" : "Update"}
            </LoadingButton>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, textTransform: "capitalize" }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep === steps.length - 1 ? (
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  type={"submit"}
                >
                  {type === "ADD" ? "Save" : "Update"}
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleComplete}
                >
                  Next
                </Button>
              )}
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfCompanyOnBoard;
