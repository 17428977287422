import axios from "axios";

export const getActivepanels = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getActiveTenantPanel?tenantId=${id}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getRedemptionRequests = async (id) => {
  try {
    const res = await axios.get(
      `/api/company/getPanelistRedemptionHistory?panelId=${id}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const approveRequest = async (id, data, panelGuid) => {
  try {
    const res = await axios.patch(
      `/api/company/updateRedeemRequestStatus?redeemRequestId=${id}&panelGuid=${panelGuid}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Couldn't perform the action", error.message);
    return error;
  }
};

export const getRedeemptionRequestsByFilter = async (panelId,data) => {
  try {
    const res = await axios.post(
      `/api/company/getRedemptionRequest?panelId=${panelId}`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};