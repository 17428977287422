import axios from "axios";

export const getAllCategories = async () => {
  try {
    // /api/systemUser/getTenantCategory
    const res = await axios.get("/api/systemUser/getAllCategory/");
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getActiveCategories = async () => {
  try {
    // /api/systemUser/getTenantCategory
    const res = await axios.get("/api/systemUser/getActiveCategory/");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSystemTenantCategories = async (id) => {
  console.log("panelid", id);
  try {
    let res;
    if (id) {
      res = await axios.get("/api/systemUser/getTenantCategory", {
        params: {
          panelId: id,
        },
      });
    } else {
      res = await axios.get("/api/systemUser/getTenantCategory");
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addCategory = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createCategory", data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateCategory = async (id, data) => {
  try {
    const res = await axios.patch(`/api/systemUser/updateCategory/${id}`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (id) => {
  try {
    const res = await axios.delete(`/api/systemUser/deleteCategory/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTranslatedCategories = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getTranslatedCategory?categoryId=${id}`
    );
    return res;
  } catch (error) {
    console.log("error fetching questions", error.message);
  }
};
