import axios from "axios";

export const addMiniSurvey = async (data) => {
  try {
    const res = await axios.post("/api/company/createMiniSurvey", data);
    return res;
  } catch (error) {
    console.log("failed creating member", error.message);
    return error;
  }
};

export const updateMiniSurvey = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/company/updateMiniSurvey?miniSurveyId=${id}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteMiniSurvey = async (id) => {
  try {
    const res = await axios.delete(
      `/api/company/deleteMiniSurvey?miniSurveyId=${id}`
    );
    return res;
  } catch (error) {
    console.log("failed deleting", error);
  }
};


export const getActivepanels = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getActiveTenantPanel?tenantId=${id}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllSurvey = async () => {
  try {
    const res = await axios.get("/api/Company/getAllSurvey");
    return res;
  } catch (error) {
    console.log("err fetching survey", error.message);
  }
};


export const createSurvey = async (panelId, data) => {
  try {
    const res = await axios.post(
      `/api/Company/createPanelSurvey?panelId=${panelId}`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getSurveyByPanelId = async (panelId) => {
  try {
    const res = await axios.get(
      `/api/company/getPanelAllMiniSurvey?panelId=${panelId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};



export const getSurveyDetails = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getSurveyDetail?surveyId=${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateSurveyDetails = async (id, data) => {
  try {
    const res = await axios.put(
      `/api/Company/updatePanelSurvey?surveyId=${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
