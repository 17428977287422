import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRolessBytenantId } from "../manageMembers/manageMember.api";
import { addTangoCard, updateTangoCard } from "./TangoCard.api";
import { useSelector } from "react-redux";
// import { getAllPanel } from "../panel/panel.api";
import { getActivepanels } from "../managePanel/managePanel.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";

const ConfTangoCard = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
    miniSurveyCode,
    selectedMiniSurveyName,
    selectedMiniSurveyPoints,
    selectedMiniSurveyLoi,
    activePanels,
    setDataUpdated,
    selectedPanelId,
    dataUpdated,
  } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPanels, setSelectedPanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [currentEmail, setCurrentEmail] = useState("");
  const [selectedPanel, setselectedPanel] = useState("");

  const [userData, setUserData] = useState({
    panelId: null,
    customerIdentifier: "",
    accountIdentifier: "",
    tangoUsername: "",
    tangoPassword: "",
    createdBy: adminId,
  });

  useEffect(() => {
    if (selectedItem) {
      setUserData({
        panelId: selectedItem.panelId,
        customerIdentifier: selectedItem.customerIdentifier,
        accountIdentifier: selectedItem.accountIdentifier,
        tangoUsername: selectedItem.tangoUsername,
        tangoPassword: selectedItem.tangoPassword,
        createdBy: selectedItem.createdBy,
      });
    }
  }, [selectedItem])
  

  // useEffect(() => {
  //   if (roleSelected) {
  //     setSelectedRole(roleSelected);
  //     setUserData((prev) => ({
  //       ...prev,
  //       roleId: roleSelected.roleId,
  //     }));
  //   }
  //   return () => {
  //     setSelectedRole({});
  //   };
  // }, [roleSelected, open]);

  // useEffect(() => {
  //   getRolessBytenantId(adminId)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         console.log("roles", res.data.data);
  //         setroles(res.data.data);
  //       } else {
  //         console.log(res);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   getActivepanels(adminId).then((res) => {
  //     if (res?.status === 200) {
  //       setPanels(res.data.data);
  //     }
  //   });
  //   return () => {
  //     setroles([]);
  //     setPanels([]);
  //   };
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   console.log("slected item", selectedItem);
  //   console.log("roles", roles);
  //   console.log("type", type);
  //   if (
  //     selectedItem &&
  //     selectedItem.roleId &&
  //     type === "EDIT" &&
  //     roles &&
  //     roles.length > 0
  //   ) {
  //     const role = roles.find(
  //       (item) => Number(item.roleId) === Number(selectedItem.roleId)
  //     );
  //     console.log("role", role);
  //     if (role) {
  //       setSelectedRole(role);
  //     }
  //   }
  //   if (editId && type === "EDIT") {
  //     setPageLoading(true);
  //     if (selectedItem) {
  //       setUserData(selectedItem);
  //       setCurrentEmail(selectedItem?.email);
  //       setPageLoading(false);
  //     }
  //   }
  //   return () => {
  //     setUserData({
  //       panelId: null,
  //       customerIdentifier: "",
  //       accountIdentifier: "",
  //       tangoUsername: "",
  //       tangoPassword: "",
  //       createdBy: adminId,
  //     });
  //     setSelectedPanels([]);
  //     setLoading(false);
  //   };
  // }, [editId, selectedItem, open, roles]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let panelIds = [];
    if (selectedPanels && selectedPanels.length > 0) {
      selectedPanels.map((item) => panelIds.push(item.panelId));
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      let newData = {
        panelId: selectedPanel.panelId,
        customerIdentifier: userData.customerIdentifier,
        accountIdentifier: userData.accountIdentifier,
        tangoUsername: userData.tangoUsername,
        tangoPassword: userData.tangoPassword,
        createdBy: adminId,
      };
      callMethod = addTangoCard(newData);
    } else {
      let data = {
        panelId: userData.panelId,
        customerIdentifier: userData.customerIdentifier,
        accountIdentifier: userData.accountIdentifier,
        tangoUsername: userData.tangoUsername,
        tangoPassword: userData.tangoPassword,
        updatedBy: adminId,
      };
      callMethod = updateTangoCard(selectedItem.id, data);
    }

    callMethod
      .then((res) => {
        console.log("added", res);
        if (res?.status === 201 || res?.status === 200) {
          setDataUpdated(!dataUpdated);
          setSuccess();
          setUserData({
            panelId: null,
            customerIdentifier: "",
            accountIdentifier: "",
            tangoUsername: "",
            tangoPassword: "",
            createdBy: adminId,
          });
        } else if (res.response.data.message === "MiniSurvey already exist") {
          setAlert("MiniSurvey already exist");
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePanelDropDownChange = (value) => {
    setselectedPanel(value);
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>
          {type === "ADD" ? "Add Tango Card" : "Edit Tango Card"}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
             {type === "ADD" &&
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <AsyncDropdown
                    label={"Panel Name"}
                    disabled={props.disableDropDown}
                    value={selectedPanel}
                    handleChange={(value) => handlePanelDropDownChange(value)}
                    name="panelId"
                    objKey="panelName"
                    options={activePanels}
                  />
                </Stack>
                }
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <TextField
                      onChange={handleInputChange}
                      fullWidth
                      type={"text"}
                      label={"Tango Username"}
                      name="tangoUsername"
                      required
                      value={userData.tangoUsername}
                    />
                    <TextField
                      onChange={handleInputChange}
                      fullWidth
                      type={"text"}
                      label={"Password"}
                      name="tangoPassword"
                      required
                      value={userData.tangoPassword}
                    />
                  </Stack>
              
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"text"}
                    label={"Customer Identifier"}
                    name="customerIdentifier"
                    required
                    value={userData.customerIdentifier}
                  />
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"text"}
                    label={"Account Identifier"}
                    name="accountIdentifier"
                    required
                    value={userData.accountIdentifier}
                  />
                </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              ></Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {type === "ADD" ? "Save" : "Update"}
          </LoadingButton>
          <Button variant="outlined" onClick={hadnleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfTangoCard;
