import { Edit, LocationOn } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dummyProfile } from "../../config/keys";
import { getRoleById } from "../roles/roles.api";
// import "./styles.css";

const LoggedInAdminProfile = (props) => {
  const userData = useSelector((state) => state.auth?.userData);
  const [currentRole, setCurrentRole] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userData && userData.roleId) {
      getRoleById(userData.roleId)
        .then((res) => {
          if (res?.status === 200) {
            setCurrentRole(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setCurrentRole({});
    };
  }, [userData.roleId]);

  const profileImage =
    "https://images.pexels.com/photos/3777943/pexels-photo-3777943.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  return (
    <Card sx={props.sx}>
      <Box sx={{ p: 1, py: 2, display: "flex", alignItems: "center" }}>
        <Link
          to={
            userData.roleId && userData?.roleId === 3 && "/admin/tenant-profile"
          }
        >
          <Avatar
            sx={{ height: 65, width: 65 }}
            variant="rounded"
            src={dummyProfile}
            alt="Profile-image"
          />
        </Link>
        <Stack px={2} spacing={0.5} alignItems="flex-start">
          <Typography sx={{ textTransform: "capitalize" }} fontWeight={700}>
            {userData?.firstName}
          </Typography>
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
          ) : (
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="body2"
              color="text.secondary"
            >
              {currentRole?.role}
            </Typography>
          )}
        </Stack>
      </Box>
    </Card>
  );
};

export default LoggedInAdminProfile;
