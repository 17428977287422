import { Card, Stack, Typography } from "@mui/material";
import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const CountMeta = (props) => {
  const { data } = props;
  return (
    <Stack direction={"column"} alignItems="center" spacing={1}>
      <Typography component={"h5"} variant="p">
        <i
          style={{ fontWeight: 500, fontSize: "1rem", color: "black" }}
          className={data.icon}
        />
      </Typography>
      <Typography component={"h6"} fontSize={"12px"} variant="p">
        {data.count}
      </Typography>
      <Typography component={"h6"} fontSize={"12px"} variant="p">
        {data.label}
      </Typography>
    </Stack>
  );
};

const SampleCount = (props) => {
  const { sampleData } = props;
  return (
    <Card sx={{ my: 2, p: 2, width: "100%" }}>
      <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
        {sampleData?.map((item, index) => (
          <CountMeta data={item} key={index} />
        ))}
      </Stack>
    </Card>
  );
};

export default SampleCount;
