import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import { questions } from "../assignQuestions/data";
import { getActivepanels } from "../managePanel/managePanel.api";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TranslateIcon from "@mui/icons-material/Translate";
import ConfQuestionStatment from "./ConfQuestionStatement";
import ConfTranslateQuestion from "./ConfTranslateQuestion";
import { getActiveCategories } from "../category/category.api";
import { RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getPnaelQuestions } from "./panelQuestions.api";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getAllanswerTypes } from "../answerType/answerType.api";
import { SnackBarAlert } from "../../utils/SnackBar";
import { getPanelCountryLanguage } from "../survey/survey.api";
import ViewOptionsModal from "../masterQuestions/ViewOptionsModal";

const STATEMENT = "statement";
const TRANSLATE = "translate";
const TranslateQuestions = () => {
  const [selectedPanel, setSelectedPanel] = useState({});
  const [activePanels, setActivePanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [panelQuestions, setPanelQuestions] = useState([]);
  const [activecategories, setActivecategories] = useState([]);

  const [countryLanguage, setCountryLanguage] = useState([]);

  const [answerTypes, setAnswerTypes] = useState([]);

  const [openTranslateModal, setOpenTranslateModal] = useState(false);
  const [openStamenetModal, setOpenStamenetModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const [alert, setAlert] = useState(false);

  const handleOpenModal = (modal) => {
    switch (modal) {
      case STATEMENT:
        setOpenStamenetModal(true);
        break;
      case TRANSLATE:
        setOpenTranslateModal(true);
        break;

      default:
        break;
    }
  };
  const handleCloseModal = (modal) => {
    switch (modal) {
      case STATEMENT:
        setOpenStamenetModal(false);
        break;
      case TRANSLATE:
        setOpenTranslateModal(false);
        break;

      default:
        break;
    }
    setOpenStamenetModal(false);
  };

  useEffect(() => {
    if (adminId) {
      getActivepanels(adminId)
        .then((res) => {
          if (res?.status === 200) {
            setActivePanels(res.data.data);
            console.log("active panels", res.data.data);
            setSelectedPanel(res.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      getActiveCategories()
        .then((res) => {
          if (res?.status === 200) {
            setActivecategories(res.data.data);
          }
        })
        .catch((err) => console.log(err.message));
    }

    getAllanswerTypes()
      .then((res) => {
        if (res?.status === 200) {
          setAnswerTypes(res.data.data);
        }
      })
      .catch((err) => console.log(err.message));
    return () => {
      setActivePanels([]);
      setActivecategories([]);
    };
  }, [adminId]);

  useEffect(() => {
    if (selectedPanel?.panelId) {
      setTableLoading(true);
      getPnaelQuestions(selectedPanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            setPanelQuestions(res.data.data);
            console.log("questions", res.data.data);
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setTableLoading(false));

      getPanelCountryLanguage(selectedPanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            console.log("country language", res.data.data);
            setCountryLanguage(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {
      setPanelQuestions([]);
      setCountryLanguage([]);
    };
  }, [selectedPanel]);

  const handleDropdownChange = (value) => {
    if (value) {
      setSelectedPanel(value);
    }
  };

  const [openViewModal, setOpenViewModal] = useState(false);
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };

  const [selectedQuestion, setSelectedQuestion] = useState({});
  useEffect(() => {
    if (editId) {
      const item = panelQuestions.find((i) => i.panelQsnId === editId);
      setSelectedQuestion(item);
    }
    return () => {
      setSelectedQuestion({});
    };
  }, [editId]);

  const labels = ["Id", "Question", "Category", "Type"];
  const rows = [];

  panelQuestions &&
    panelQuestions.map((item) =>
      rows.push([
        <Typography>{item?.panelQsnId}</Typography>,
        <Link
          style={{
            padding: "0",
            margin: 0,
            textDecoration: "none",
            color: "#6e6d6d",
          }}
          to={`translated/${item.panelQsnId}`}
          state={{ panelId: selectedPanel?.panelId }}
        >
          <Typography>{item.qsnShortForm}</Typography>
        </Link>,
        <Typography>{item.categories?.category}</Typography>,
        <Typography>{item?.questiontype?.questionType}</Typography>,
      ])
    );
  return (
    <>
      <TopHeading showButton={Boolean(false)} heading={"Panel Questions"} />
      <Stack mb={2} sx={{ width: "40%", px: 3 }}>
        <AsyncDropdown
          value={selectedPanel}
          options={activePanels}
          objKey={"panelName"}
          label="Select panel"
          handleChange={handleDropdownChange}
        />
      </Stack>
      <ConfQuestionStatment
        type={"ADD"}
        hadnleCloseModal={() => handleCloseModal(STATEMENT)}
        open={openStamenetModal}
        activecategories={activecategories}
        selectedItem={selectedQuestion}
        answerTypes={answerTypes}
        editId={editId}
      />
      <ConfTranslateQuestion
        hadnleCloseModal={() => handleCloseModal(TRANSLATE)}
        type={"TRANSLATE"}
        open={openTranslateModal}
        selectedItem={selectedQuestion}
        editId={editId}
        answerTypes={answerTypes}
        setAlert={setAlert}
        countryLanguage={countryLanguage}
      />
      <CustomTable pageLoading={tableLoading} data={rows} labels={labels} />
      <ViewOptionsModal
        open={openViewModal}
        handleClose={handleCloseViewModal}
        selectedItem={selectedQuestion}
      />
      <SnackBarAlert open={alert} handleClose={() => setAlert(false)} />
    </>
  );
};

export default TranslateQuestions;

// // <Typography>{item.type}</Typography>,
//         // <Stack direction={"row"} spacing={1}>
//           {/* <IconButton
//             onClick={() => {
//               setEditId(item.panelQsnId);
//               handleOpenModal(STATEMENT);
//             }}
//           >
//             <Tooltip title={"Change question statement"}>
//               <SettingsSuggestIcon />
//             </Tooltip>
//           </IconButton> */}
//           {/* <IconButton
//             onClick={() => {
//               setEditId(item.panelQsnId);
//               handleOpenModal(TRANSLATE);
//             }}
//           >
//             <Tooltip title={"Translate"}>
//               <TranslateIcon />
//             </Tooltip>
//           </IconButton> */}
//           {/* {item?.option?.length > 0 && (
//             <IconButton
//               onClick={() => {
//                 handleOpenViewModal();
//                 setEditId(item?.panelQsnId);
//               }}
//               sx={{ p: 0 }}
//             >
//               <Tooltip title="View Options">
//                 <RemoveRedEye sx={{ padding: 0 }} />
//               </Tooltip>
//             </IconButton>
//           )} */}
//         // </Stack>,
