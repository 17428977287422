import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const ShowForSuperAdmin = [
  "System admin",
  "Tenant Onboard",
  "System Config",
  "Country",
  "Feature",
  "Category",
  "Master Questions",
];
const ShowForSystemAdmin = [
  "Master Questions",
  "Tenant Onboard",
  "Category",
  "System Config",
  "Country",
  "Feature",
  "Master Questions",
];

const showForCompanyAdmin = [
  "Create Roles",
  "Manage Member",
  // "Tenant Profile",
  "Manage Panel",
  // "Tenant Features",
  "Category",
  "Master Questions",
  // "Survey Countries",
  "Panel Members",
  "Assign Questions",
  "Panel Questions",
  // "Features",
  "Redemption Request",
  // "Tango Cards",
  "Mini Survey",
  "Survey Creation",
  // "Poll Creation",
  // "Community and discussion creation",
  "User Profile Questionaire",
  "Panelist Details",
  "Affiliate Report",
  "Affiliate Master"
];

const SideBarItem = ({ open, route }) => {
  const panelSidebarItems = useSelector(
    (state) => state.panelSidebarItems?.panelSidebarItems
  );
  const [selectedArrayForRole, setSelectedArrayForRole] = useState([]);
  const currentUserRoleId = useSelector((state) => state.auth.userData?.roleId);
  const showForPanelAdmin = [
    // "Panel Profile",
    "Category",
    "Master Questions",
    "Survey Countries",
    "Features",
    "Panel Members",
    "Survey Creation",
    "Poll Creation",
    "Community and discussion creation",
    "User Profile Questionaire",
    "Panelist Details",
    "Affiliate Report",
    "Affiliate Master"
  ];

  const [showForPanelMember, setShowForPanelMember] = useState([
    "Assigned Panels",
  ]);

  useEffect(() => {
    if (panelSidebarItems && panelSidebarItems.length > 0) {
      let duplicate = [...showForPanelMember];
      panelSidebarItems.map((item) => duplicate.push(item.name));
      setShowForPanelMember(duplicate);
    }
    return () => {
      setShowForPanelMember(["Assigned Panels"]);
    };
  }, [panelSidebarItems]);

  useEffect(() => {
    if (currentUserRoleId) {
      switch (currentUserRoleId) {
        case 1:
          setSelectedArrayForRole([...ShowForSuperAdmin]);
          break;
        case 2:
          setSelectedArrayForRole([...ShowForSystemAdmin]);
          break;
        case 3:
          setSelectedArrayForRole([...showForCompanyAdmin]);
          break;
        case 4:
          setSelectedArrayForRole([...showForPanelAdmin]);
          break;
        default:
          setSelectedArrayForRole([...showForPanelMember]);
          break;
      }
    }
    return () => {
      setSelectedArrayForRole([]);
    };
  }, [currentUserRoleId, showForPanelMember]);

  const [openNested, setopenNested] = useState(true);

  const handleClick = () => {
    setopenNested(!openNested);
  };

  if (route.children) {
    return (
      <List
        sx={{
          display: selectedArrayForRole.includes(route.name) ? "block" : "none",
        }}
      >
        <ListItem
          className="list-item"
          disablePadding
          sx={{ display: "block", padding: "0" }}
        >
          <NavLink
            style={{ pointerEvents: route.disabled && "none" }}
            end
            exact={"true"}
            to={route.path || ""}
            className={({ isActive }) =>
              !isActive ? "link-text" : "link-text active"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
              }}
            >
              <Tooltip title={open ? "" : route.name}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className={route.icon} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="list-item-text"
                primary={route.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
          {open ? (
            openNested ? (
              <IconButton onClick={handleClick}>
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton onClick={handleClick}>
                <ExpandMore />
              </IconButton>
            )
          ) : (
            ""
          )}
        </ListItem>
        <Collapse in={openNested} timeout="auto" unmountOnExit>
          <div className={open && "sidebar-child"}>
            {route.children.map((child, index) => (
              <SideBarItem route={child} open={open} key={index} />
            ))}
          </div>
        </Collapse>
      </List>
    );
  } else {
    return (
      <List
        sx={{
          display: selectedArrayForRole.includes(route.name) ? "block" : "none",
        }}
      >
        <ListItem
          className="list-item"
          disablePadding
          sx={{ display: "block", padding: "0" }}
        >
          <NavLink
            end
            to={route.path || ""}
            className={({ isActive }) =>
              !isActive ? "link-text" : "link-text active"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
              }}
            >
              <Tooltip title={open ? "" : route.name}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <i className={route.icon} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={route.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
      </List>
    );
  }
};

export default SideBarItem;
