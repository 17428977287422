import axios from "axios";

export const getCompanyMembers = async (page, limit) => {
  try {
    const res = await axios.get(`/api/Company/getMemberByRole/3`);
    return res;
  } catch (error) {
    return error.message;
  }
};

export const createTenantFeature = async (data) => {
  try {
    const res = axios.post("/api/Company/createTenantFeature", data);
    return res;
  } catch (error) {
    console.log("failed creating tenant profile", error.message);
  }
};

export const createTenantAdmin = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createTenantAdmin", data);
    return res;
  } catch (error) {
    console.log("error creating tenant admin", error.message);
  }
};

export const checkEmail = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/checkForEmail", data);
    return res;
  } catch (error) {
    return error;
  }
};

export const getTenantPanels = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getTenantPanel?tenantId=${id}`);
    return res;
  } catch (error) {
    console.log("err fetching panels", error.message);
  }
};

export const getPanelCountries = async (id) => {
  // send panel id
  try {
    const res = await axios.get(
      `/api/systemUser/getPanelCountry?panelId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err fetching panels countries", error.message);
  }
};

export const getPanelAdminUserFeatures = async (id, featureType) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getPanelAdminFeature?panelId=${id}&featureType=${featureType}`
    );
    return res;
  } catch (error) {
    console.log("error fetching admin features", error.message);
  }
};

export const createTenantPanels = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createNewPanel", data);
    return res;
  } catch (error) {
    console.log("error adding tenant panels", error.message);
  }
};

export const updateTenant = async (data, tenatId) => {
  try {
    const res = await axios.patch(
      `/api/systemUser/updateTenantPanel?tenantId=${tenatId}`,
      data
    );
    return res;
  } catch (error) {
    console.log("error", error.message);
  }
};
