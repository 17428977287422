import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";

const AssociatedPanelMembers = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [panelMembers, setpanelMembers] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      console.log(data);
      setpanelMembers(data);
    }
    return () => {
      setpanelMembers(data);
    };
  }, [data]);

  const rows = [];
  const labels = ["Name", "Email", "Contact", "Role", "Status"];
  panelMembers &&
    panelMembers.length > 0 &&
    panelMembers.map((item) =>
      rows.push([
        item.panelmember.firstName + " " + item.panelmember.lastName,
        item.panelmember.email,
        item.panelmember.contact,
        item.panelmember.role.role,
        <CustomMuiSwitch checked={item.panelmember.isActive} />,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading showButton={false} heading="Panel Members" />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedAssociatedPanelMembers = Hoc(AssociatedPanelMembers, {
  name: "panel-members",
});
