import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomMuiSwitch from "../../../../utils/customMuiSwicth/CustomMuiSwitch";
import { getQualificationById, updateQualification } from "../../survey.api";

const ViewQualification = (props) => {
  const { open, handleClose, selectedQualification, questions, setAlert } =
    props;
  const [currentOptions, setCurrentOptions] = useState([]);
  const [dboptions, setDboptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const [qualificationData, setQualificationData] = useState({
    qualDescription: "",
    qualificationType: "",
    from: "",
    to: "",
    inputValue: "",
  });

  useEffect(() => {
    if (questions && questions.length > 0) {
      const item = questions.find(
        (item) => item?.masterQsnId === qualificationData?.masterQsnId
      );
      let ids = [];
      qualificationData?.qualoption?.map((item) => {
        ids.push(item?.masterQsnOptionId);
      });

      console.log("ids", ids);
      if (item?.option) {
        let duplicate = [...item.option];
        duplicate.map((item) => {
          if (ids.includes(item.masterQsnOptionId)) {
            item["selected"] = true;
          } else {
            item["selected"] = false;
          }
        });
        setCurrentOptions(duplicate);
      }
    }

    return () => {
      setCurrentOptions([]);
    };
  }, [questions, qualificationData, open]);

  useEffect(() => {
    if (selectedQualification) {
      getQualificationById(selectedQualification)
        .then((res) => {
          if (res?.status === 200) {
            setQualificationData(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      setQualificationData({
        qualDescription: "",
        qualificationType: "",
        from: "",
        to: "",
        inputValue: "",
      });
    };
  }, [selectedQualification, open]);

  const hadnleCheck = (value, index) => {
    let duplicate = [...currentOptions];
    duplicate[index]["selected"] = value;
    setCurrentOptions(duplicate);
  };

  const handleSelectAll = (e, value) => {
    let duplicate = [...currentOptions];
    duplicate?.map((item) => {
      item["selected"] = value;
    });
    setCurrentOptions(duplicate);
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setQualificationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let finalData = {
      qualificationType: qualificationData.qualificationType,
      updatedBy: adminId,
    };

    if (finalData.qualificationType === "range") {
      finalData["from"] = qualificationData.from;
      finalData["to"] = qualificationData.to;
    }
    if (finalData.qualificationType === "input") {
      finalData["inputValue"] = qualificationData.inputValue;
    }

    if (finalData.qualificationType === "option") {
      let ids = [];
      currentOptions.map((item, index) => {
        if (item?.selected) {
          ids.push({masterQsnOptionId: item?.masterQsnOptionId});
        }
      });
      finalData["option"] = ids;
      if (ids.length <= 0) {
        setAlert("Selecta atleast one option");
        return;
      }
    }
    setLoading(true);
    updateQualification(selectedQualification, finalData)
      .then((res) => {
        if (res?.status >= 200 && res?.status <= 299) {
          setAlert("Qualification Updated Successfully");
          handleClose();
        }
      })
      .catch((err) => {
        setAlert("Something went wrong please try again");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography sx={{ ml: 3 }} component={"h5"} variant={"p"}>
          Qualification
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ m: 2, mt: 1 }} spacing={2}>
          <TextField
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #f0f0f0",
              },
            }}
            name={"qualDescription"}
            value={qualificationData?.qualDescription}
            label={"Qualification description"}
            aria-readonly
            disabled
          />
          {qualificationData?.qualificationType === "option" && (
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #f0f0f0",
                },
              }}
              name={"qualDescription"}
              value={qualificationData?.qualificationType}
              label={"Type"}
              aria-readonly
              disabled
            />
          )}
          {qualificationData?.qualificationType !== "option" && (
            <Stack
              sx={{ border: "1px solid #f5f5f5", p: 1 }}
              direction={"row"}
              spacing={2}
            >
              <Typography>Input Field</Typography>
              <CustomMuiSwitch
                handleChange={() =>
                  setQualificationData((prev) => ({
                    ...prev,
                    qualificationType:
                      prev.qualificationType === "range" ? "input" : "range",
                  }))
                }
                checked={
                  qualificationData?.qualificationType === "range"
                    ? true
                    : false
                }
              />
              <Typography>Range</Typography>
            </Stack>
          )}
          {qualificationData?.qualificationType === "input" && (
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #f0f0f0",
                },
              }}
              value={qualificationData.inputValue}
              label={"Input value"}
              name={"inputValue"}
              onChange={handleChangeInput}
            />
          )}
          {qualificationData?.qualificationType === "range" && (
            <Stack direction={"row"} spacing={2}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                value={qualificationData.from}
                label={"From"}
                name={"from"}
                onChange={handleChangeInput}
              />
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                value={qualificationData.to}
                label={"To"}
                name={"to"}
                onChange={handleChangeInput}
              />
            </Stack>
          )}
          {qualificationData?.qualificationType === "option" && (
            <Stack>
              <FormControlLabel
                onChange={handleSelectAll}
                control={<Checkbox />}
                label={
                  <Typography sx={{ fontSize: "15px" }}>Select All</Typography>
                }
              />
              <Stack direction={"row"} flexWrap={"wrap"}>
                {currentOptions &&
                  currentOptions?.map((item, index) => (
                    <>
                      <FormControlLabel
                        key={index}
                        onChange={(e, value) => hadnleCheck(value, index)}
                        control={
                          <Checkbox
                            checked={Boolean(item.selected)}
                            sx={{ fontSize: "6px" }}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: "15px" }}>
                            {item["optionStatement"]}
                          </Typography>
                        }
                      />
                    </>
                  ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 4 }}>
        <Stack direction={"row"} spacing={2}>
          <button onClick={handleClose} className="addButton outline">
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="addButton"
          >
            {loading ? (
              <CircularProgress
                size={"21px"}
                sx={{ margin: "0 14px", color: "white", padding: 0 }}
              />
            ) : (
              "Update"
            )}
          </button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ViewQualification;
