export const homeRoutes = [
  {
    "route": "/*",
    "serve": "/index.html",
    "statusCode": 200
  },
  {
    path: "/admin/survey",
    name: "Survey Creation",
    icon: "bi bi-grid",
  },
  {
    path: "/admin/panelists",
    name: "Panelist Details",
    icon: "bi bi-list-ul",
  },
  
  {
    path: "/admin/panelist-redemption-request",
    name: "Redemption Request",
    icon: "bi bi-cash-coin",
  },
  {
    path: "/admin/user-profile-questionaire",
    name: "User Profile Questionaire",
    icon: "bi bi-person-lines-fill",
  },
  {
    path: "/admin/master-questions",
    name: "Master Questions",
    icon: "bi bi-question-circle",
  },
  {
    path: "/admin/mini-survey",
    name: "Mini Survey",
    icon: "bi bi-journal",
  },
  {
    path: "/admin/affiliate-report",
    name: "Affiliate Report",
    icon: "bi bi-file-earmark-bar-graph",
  },
  {
    path: "/admin/affiliate-master",
    name: "Affiliate Master",
    icon: "bi bi-person-video2",
  },
  {
    path: "/admin/roles",
    name: "Create Roles",
    icon: "bi bi-toggles",
  },
  {
    path: "/admin/users",
    name: "System admin",
    icon: "bi bi-people",
  },
  {
    path: "/admin/company-on-board",
    name: "Tenant Onboard",
    icon: "bi bi-boxes",
  },
  {
    path: "/admin/tenant-profile",
    name: "Tenant Profile",
    icon: "bi bi-person-circle",
  },
  {
    path: "/admin/panel-profile",
    name: "Panel Profile",
    icon: "bi bi-person-circle",
  },
  {
    path: "/admin/manage-members",
    name: "Manage Member",
    icon: "bi bi-person-video2",
  },
  {
    path: "/admin/panel-admin",
    name: "Manage Panel",
    icon: "bi bi-building",
  },
  
  {
    path: "/admin",
    disabled: true,
    name: "System Config",
    icon: "bi bi-columns-gap",
    children: [
      {
        path: "/admin/country",
        name: "Country",
        icon: "bi bi-flag",
      },
      {
        path: "/admin/language",
        name: "Language",
        icon: "bi bi-translate",
      },
      {
        path: "/admin/feature",
        name: "Feature",
        icon: "bi bi-sliders2",
      },
    ],
  },

  //   {
  //     path: "/admin/content",
  //     name: "Content",
  //     icon: "bi bi-card-heading",
  //   },
  //   {
  //     path: "/admin/permission",
  //     name: "Permission",
  //     icon: "i bi-shield",
  //   },
  {
    path: "/admin/category",
    name: "Category",
    icon: "bi bi-collection",
  },
  {
    path: "/admin/assigned-panels",
    name: "Assigned Panels",
    icon: "bi bi-collection",
  },
  {
    path: "/admin/tenant-features",
    name: "Tenant Features",
    icon: "bi bi-tags",
  },
  
  // {
  //   path: "/admin/assign-questions",
  //   name: "Assign Questions",
  //   icon: "bi bi-bookmark-plus",
  // },
  // {
  //   path: "/admin/panel-questions",
  //   name: "Panel Questions",
  //   icon: "bi bi-translate",
  // },
  {
    path: "/admin/assigned-survey-countries",
    name: "Survey Countries",
    icon: "bi bi-flag",
  },
  {
    path: "/admin/assigned-features",
    name: "Features",
    icon: "bi bi-command",
  },
  
  {
    path: "/admin/associated-panel-members",
    name: "Panel Members",
    icon: "bi bi-people",
  },
  
  {
    path: "/admin/tango-card",
    name: "Tango Cards",
    icon: "bi bi-wallet2",
  },
  
  
  {
    path: "/admin/poll-creation",
    name: "Poll Creation",
    icon: "bi bi-file-bar-graph",
  },
  {
    path: "/admin/community-discussion",
    name: "Community and discussion creation",
    icon: "bi bi-chat-left-dots",
  },
  
  
  //   {
  //     path: "/admin/answer-type",
  //     name: "Answer type",
  //     icon: "bi bi-bar-chart-steps",
  //   },
];
