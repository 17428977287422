import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { addCountry, updateCountry } from "./country.api";
import { useSelector } from "react-redux";

const ConfCountry = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setError,
    setAlert,
    setEditId,
    editId,
    selectedCountry,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const [countrydata, setcountrydata] = useState({
    countryName: "",
    countryCode: "",
    countryFlag: "",
    ISDCode: "",
    nativeLanguageId: 1,
    createdBy: adminId,
  });

  useEffect(() => {
    if (editId && type === "EDIT") {
      setFormLoading(true);
      if (selectedCountry) {
        setcountrydata(selectedCountry);
        setFormLoading(false);
      }
    }
    return () => {
      setFormLoading(false);
      setcountrydata({
        countryName: "",
        countryCode: "",
        countryFlag: "",
        ISDCode: "",
        nativeLanguageId: 1,
        createdBy: adminId,
      });
    };
  }, [editId, open, selectedCountry]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setcountrydata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    if (type === "ADD") {
      callMethod = addCountry(countrydata);
    } else {
      let data = {
        countryCode: countrydata.countryCode,
        countryName: countrydata.countryName,
        ISDCode: countrydata.ISDCode,
        countryFlag: countrydata.countryFlag,
        updatedBy: adminId,
      };
      callMethod = updateCountry(editId, data);
    }

    callMethod
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setSuccess();
          setcountrydata({
            countryName: "",
            countryCode: "",
            countryFlag: "",
            ISDCode: "",
            nativeLanguageId: 1,
            createdBy: adminId,
          });
        } else {
          setError("Something went Wrong! Please try agian.");
        }
      })
      .catch((err) => {
        setError("Something went Wrong! Please try agian.");
        console.log(err);
      })
      .finally(setLoading(false));
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    const img = URL.createObjectURL(image);
    setImagePreview(img);
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"md"}
        PaperProps={{
          sx: {
            height: formLoading ? 300 : "auto",
          },
        }}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Country" : "Edit Country"}</h4>
            </DialogTitle>
            <DialogContent>
              <Stack sx={{ py: 2 }} direction={"column"} spacing={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <TextField
                    required
                    fullWidth
                    name="countryName"
                    label="Country Name"
                    value={countrydata.countryName}
                    onChange={handleOnInputchange}
                  />
                  <TextField
                    required
                    fullWidth
                    name="countryCode"
                    label="Country Code"
                    value={countrydata.countryCode}
                    onChange={handleOnInputchange}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  {/* <TextField
                    required
                    fullWidth
                    name="countryFlag"
                    label="Country Flag"
                    value={countrydata.countryFlag}
                    onChange={handleOnInputchange}
                  /> */}
                  {/* <Stack
                    sx={{ width: "100%" }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Typography>Upload Country Flag</Typography>
                    <input
                      onChange={handleImage}
                      style={{ width: "100%" }}
                      type={"file"}
                    />
                  </Stack> */}
                  <TextField
                    required
                    fullWidth
                    name="ISDCode"
                    label="ISD code"
                    value={countrydata.ISDCode}
                    onChange={handleOnInputchange}
                  />
                </Stack>
                {imagePreview && (
                  <Stack>
                    <img
                      accept="image/png, image/jpeg"
                      alt={"country flag"}
                      style={{ objectFit: "cover" }}
                      height={200}
                      width={200}
                      src={imagePreview}
                    />
                  </Stack>
                )}
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfCountry;
