import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Add } from "@mui/icons-material";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  addMasterQuestion,
  createQuestionProfile,
  updateMasterQuestion,
} from "./masterQuestion.api";
import { useSelector } from "react-redux";
import SinglePunchOptions from "./SinglePunchOptions";

const ConfMasterQuestion = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    answerTypes,
    languagetypes,
    paneltypes,
    categoryTypes,
    setOpenAddToPanelModal,
    panelId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const [masterQuestionData, setmasterQuestionData] = useState({
    qsnShortForm: "",
    question: "",
    languageId: 1,
    questionTypeId: null,
    createdBy: adminId,
    optionStatement: [],
    questionCatId: null,
  });

  const [selectedPanel, setselectedPanel] = useState({});
  // const [selectedLanguage, setselectedLanguage] = useState({});
  const [selectedAnswerType, setselectedAnswerType] = useState({});
  const [selectedCategoryType, setselectedCategoryType] = useState({});
  const [displayText, setDisplayText] = useState("");

  const [options, setOptions] = useState([
    {
      optionStatement: "",
    },
    {
      optionStatement: "",
    },
  ]);

  const [removedOptions, setRemovedOptions] = useState([]);

  useEffect(() => {
    if (type === "EDIT") {
      if (
        selectedItem &&
        selectedItem.questionTypeId &&
        editId &&
        answerTypes &&
        answerTypes.length > 0
      ) {
        const item = answerTypes.find(
          (i) => i.questionTypeId === selectedItem.questionTypeId
        );
        setselectedAnswerType(item);
      }
      // if (
      //   selectedItem &&
      //   selectedItem.languageId &&
      //   editId &&
      //   languagetypes &&
      //   languagetypes.length > 0
      // ) {
      //   const item = languagetypes.find(
      //     (i) => i.id === selectedItem.languageId
      //   );
      //   // setselectedLanguage(item);
      // }
      if (
        selectedItem &&
        selectedItem.questionCatId &&
        editId &&
        categoryTypes &&
        categoryTypes.length > 0
      ) {
        const item = categoryTypes.find(
          (i) => Number(i.categoryId) === Number(selectedItem.questionCatId)
        );
        setselectedCategoryType(item);
      }
      console.log("here", editId);
      if (editId) {
        console.log("here");
        setFormLoading(true);
        if (selectedItem) {
          console.log("Selected", selectedItem);
          setmasterQuestionData(selectedItem);
          setOptions(selectedItem.option);
        }
        setFormLoading(false);
      }
    }

    return () => {
      setOptions([
        {
          optionStatement: "",
        },
        {
          optionStatement: "",
        },
      ]);
      setmasterQuestionData({
        qsnShortForm: "",
        question: "",
        languageId: 1,
        questionTypeId: null,
        createdBy: adminId,
        optionStatement: [],
        questionCatId: null,
      });
      setselectedPanel({});
      setselectedAnswerType({});
      // setselectedLanguage({});
      setselectedCategoryType({});
      setRemovedOptions([]);
      setDisplayText("");
      setLoading(false);
    };
  }, [editId, open, selectedItem, type]);

  useEffect(() => {
    console.log("removed Options", removedOptions);
  }, [removedOptions]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setmasterQuestionData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOptionInputChange = (e, index) => {
    const { value, name } = e.target;
    let duplicate = [...options];
    duplicate[index][name] = value;
    setOptions(duplicate);
  };

  const handleAddNewOption = () => {
    let duplicate = [...options];
    let newEntry = {
      option: "",
    };
    duplicate.push(newEntry);
    setOptions(duplicate);
  };

  const handleRemoveOption = (index) => {
    let duplicate = [...options];
    if (index < 0 || duplicate.length < 2) return;
    if (type === "EDIT" || type === "ADD_TO_PANEL") {
      let removedOptionsId = [];
      removedOptionsId.push(duplicate[index].questionOptionId);
      setRemovedOptions(removedOptionsId);
    }
    duplicate.splice(index, 1);
    setOptions(duplicate);
  };

  const mainRoleIds = [1, 2, 3, 4];
  const multiType = [5, 8];

  const handleSubmit = (e) => {
    e.preventDefault();
    let callMethod;

    let opts = [];
    options.map((item) => opts.push(item.optionStatement));
    if (
      masterQuestionData.questionTypeId === null ||
      // masterQuestionData.languageId === null ||
      // masterQuestionData.panelId === null ||
      masterQuestionData.questionCatId === null
    ) {
      setAlert("Please Fill all the Fiels");
      return;
    }
    setLoading(true);
    if (type === "ADD") {
      if (
        multiType.includes(masterQuestionData.questionTypeId) &&
        opts.length < 2 &&
        opts[1] === ""
      ) {
        setAlert("Please select atleast two options.");
        setLoading(false);
        return;
      }

      // if (masterQuestionData.questionTypeId === 8) {
      //   if (opts[0] === "" || opts[1] === "") {
      //     setAlert("Please fill in both the options");
      //     setLoading(false);
      //     return;
      //   }
      // }

      let duplicate = masterQuestionData;

      duplicate.optionStatement = [...opts];

      if (
        masterQuestionData.questionTypeId !== 8 &&
        masterQuestionData.questionTypeId !== 5
      ) {
        delete duplicate["optionStatement"];
      }

      if (roleId === 3) {
        duplicate["tenantId"] = adminId;
      }
      console.log("duplicate", duplicate);

      callMethod = addMasterQuestion(duplicate);
    } else if (type === "EDIT") {
      let data = {
        question: masterQuestionData.question,
        updatedBy: adminId,
        questionTypeId: masterQuestionData.questionTypeId,
        qsnShortForm: masterQuestionData.qsnShortForm,
        // languageId: masterQuestionData.languageId,
        // panelId: masterQuestionData.panelId,
        questionCatId: masterQuestionData.questionCatId,
      };
      if (removedOptions && removedOptions.length > 0) {
        data.deselected = [...removedOptions];
      }
      let optionIds = [];
      if (options && options.length > 0) {
        options.map((item) => optionIds.push(item.questionOptionId));
      }
      if (optionIds && optionIds.length > 0) {
        data.questionOptionId = [...optionIds];
      }
      if (data.questionTypeId === 5) {
        data.optionStatement = [...opts];
      }
      callMethod = updateMasterQuestion(editId, data);
    } else {
      let data = {
        question_Id: editId,
        panelId: adminId,
        question: displayText,
        questionTypeId: masterQuestionData.questionTypeId,
        questionCatId: masterQuestionData.questionCatId,
        createdBy: adminId,
      };
      if (data.questionTypeId === 5) {
        data.optionStatement = [...opts];
      }
      callMethod = addMasterQuestion(data);
    }

    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
          console.log(res);
          setSuccess();
          if (type === "ADD_TO_PANEL") {
            setOpenAddToPanelModal(false);
          }
          setmasterQuestionData({
            qsnShortForm: "",
            question: "",
            languageId: 1,
            questionTypeId: null,
            createdBy: adminId,
            optionStatement: [],
            questionCatId: null,
          });
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleDropDownChange = (objKey, value, idName) => {
    console.log("*******");
    console.log(objKey);
    console.log(value);
    console.log("*******");
    // if (objKey === "languageId") {
    //   setselectedLanguage(value);
    // }
    // if (objKey === "panelId") {
    //   setselectedPanel(value);
    // }
    if (objKey === "questionTypeId") {
      console.log("selected answer type", value);
      setselectedAnswerType(value);
    }
    if (objKey === "questionCatId") {
      setselectedCategoryType(value);
    }
    if (value) {
      setmasterQuestionData((prev) => ({
        ...prev,
        [objKey]: value[idName],
      }));
    }
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"md"}
        sx={{ p: 2 }}
        PaperProps={{
          sx: {
            height: formLoading ? 300 : "auto",
          },
        }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>
                {type === "ADD" && "Add Master Question"}
                {type === "EDIT" && "Edit Master Question"}
                {type === "ADD_TO_PANEL" && "Question translation"}
              </h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                {type != "ADD_TO_PANEL" && (
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={masterQuestionData.qsnShortForm}
                    fullWidth
                    disabled={type === "ADD_TO_PANEL" && true}
                    label="Question Short form"
                    name="qsnShortForm"
                  />
                )}
                <Stack direction={"row"} spacing={2} alignItems={"flex-end"}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={masterQuestionData.question}
                    fullWidth
                    disabled={type === "ADD_TO_PANEL" && true}
                    label="Question"
                    name="question"
                  />
                </Stack>
                {/* {type === "ADD_TO_PANEL" ? (
                  <Stack
                    direction={"column"}
                    spacing={2}
                    alignItems={"flex-end"}
                  >
                    <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
                      <TextField
                        onChange={(e) => setDisplayText(e.target.value)}
                        value={displayText}
                        fullWidth
                        required
                        label="Question Translation"
                        name="displayText"
                      />
                      <AsyncDropdown
                        options={paneltypes}
                        objKey={"firstName"}
                        label="Select Panel"
                        handleChange={(value) =>
                          handleDropDownChange("panelId", value, "panelId")
                        }
                      />
                    </Stack>
                    <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
                      <AsyncDropdown label="Select Country" />
                      <AsyncDropdown label="Select Language" />
                    </Stack>
                  </Stack>
                ) : (
                  ""
                )} */}
                <Stack direction={"row"} spacing={2} alignItems={"flex-end"}>
                  <Stack sx={{ width: "100%" }} direction={"column"}>
                    <AsyncDropdown
                      label={"Question Type"}
                      value={selectedAnswerType}
                      objKey={"questionType"}
                      options={answerTypes && answerTypes}
                      handleChange={(value) =>
                        handleDropDownChange(
                          "questionTypeId",
                          value,
                          "questionTypeId"
                        )
                      }
                    />
                  </Stack>
                  <Stack sx={{ width: "100%" }} direction={"column"}>
                    <AsyncDropdown
                      label={"Category"}
                      value={selectedCategoryType}
                      objKey={"category"}
                      options={categoryTypes && categoryTypes}
                      handleChange={(value) =>
                        handleDropDownChange(
                          "questionCatId",
                          value,
                          "categoryId"
                        )
                      }
                    />
                  </Stack>
                </Stack>
                {/* <Stack direction={"row"} spacing={2} alignItems={"flex-end"}>
                  <Stack sx={{ width: "100%" }} direction={"column"}>
                    <p>Language</p>
                    <AsyncDropdown
                      value={selectedLanguage}
                      objKey={"language"}
                      options={languagetypes && languagetypes}
                      handleChange={(value) =>
                        handleDropDownChange("languageId", value, "id")
                      }
                    />
                  </Stack>
                </Stack> */}
                <Stack direction={"column"} spacing={2}>
                  {/* {masterQuestionData.questionTypeId &&
                    masterQuestionData.questionTypeId === 8 && (
                      <SinglePunchOptions
                        handleChange={handleOptionInputChange}
                        options={options}
                      />
                    )} */}
                  {masterQuestionData.questionTypeId &&
                    multiType.includes(masterQuestionData.questionTypeId) && (
                      <Stack
                        direction={"row"}
                        alignItems="flex-end"
                        justifyContent="space-between"
                      >
                        <Typography>Options</Typography>
                        {type !== "EDIT" && (
                          <IconButton onClick={handleAddNewOption}>
                            <Add />
                          </IconButton>
                        )}
                      </Stack>
                    )}

                  {masterQuestionData.questionTypeId &&
                    multiType.includes(masterQuestionData.questionTypeId) &&
                    options &&
                    options.length > 0 &&
                    options.map((item, index) => (
                      <Stack direction={"row"}>
                        <TextField
                          required
                          onChange={(e) => handleOptionInputChange(e, index)}
                          value={item.optionStatement}
                          fullWidth
                          label={`Option ${index + 1}`}
                          name="optionStatement"
                        />
                        <IconButton onClick={() => handleRemoveOption(index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Stack>
                    ))}
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "EDIT" ? "Update" : "Save"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfMasterQuestion;
