import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import {
  Stack, TextField, Tooltip, Paper, Grid,
  MenuItem, ListItemText, Select, Checkbox, FormControl, InputLabel,
  ListItemIcon
} from "@mui/material";
import TopHeading from "../../utils/TopHeading/TopHeading";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { getActivepanels } from "../managePanel/managePanel.api";
import { getAffiliateDetailbyId } from "./affiliateReport.api"
import CustomDataTable from "../../views/CustomTable/CustomDataTable";
import ExcelCell from "../../views/Excel/ExcelCell";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { getPanelCountryLanguage } from "../survey/survey.api";
import { getAffiliateReportsByFilter } from "../affiliateReport/affiliateReport.api";

const AffiliateReport = () => {
  const [selectedPanel, setSelectedPanel] = useState({});
  const [affiliateData, setAffiliateData] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = useState(true);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const filename = `Affiliate_Report_${moment(new Date()).format('YYYY-MM-DD hh:mm')}`
  const [filterData, setFilterData] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    maxDate: moment().format("YYYY-MM-DD"),
  })
  const [CountryNames, setCountryNames] = useState([{}]);
  const [filterRequestData, setfilterRequestData] = useState({});
  const [loadAllCountries, setLoadAllCountries] = useState([]);

  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  // const isAllSelected = CountryNames.length > 0 && selected.length === CountryNames.length;
  const [loadAllFilterCountries, setLoadAllFilterCountries] = useState("");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (Object.keys(selectedPanel).length > 0) {
      getPanelCountryLanguage(selectedPanel?.panelId)
        .then((res) => {
          if (res?.status === 200) {
            setCountryNames(res.data.data);
            let countriesArr = [];
            countriesArr = res.data.data.map(function (element) {
              return element.countryCode;
            });
            setLoadAllCountries(countriesArr);
            fetchData(countriesArr);
            setSelected(countriesArr);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedPanel]);

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setSelectedPanel({});
    };
  }, [adminId]);

  const fetchData = (countries) => {
    if (countries.length > 0) {
      filterRequestData.panelId = selectedPanel?.panelId;
      filterRequestData.startDate = filterData.startDate;
      filterRequestData.endDate = filterData.endDate;
      filterRequestData.country = countries;
      getAffiliateReportsByFilter(filterRequestData)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setAffiliateData(res.data.data);

          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => setPageloading(false));
    }
  }

  const headers = ['Affiliate Name', 'Payout Type', 'Panelist Id', 'Country Code', 'Email', 'Email Verify', 'Transaction Id', 'Campaign Id', 'SubCampaign Id', 'Affiliate Url', 'IpAddress', 'Device Id', 'Callback Url', 'Visitor Id', 'User Agent', 'Browser', 'Browser Version', 'OS', 'OS Version', 'Brand', 'Model'];
  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: 'Affiliate ID',
          selector: (row) => row.affiliateId,
          sortable: true,
          minWidth: "100px",
          maxWidth: "120px",
        },
        {
          name: 'Affiliate Name',
          selector: (row) =>(<Tooltip title={row.affiliateName}>
          <span>{row.affiliateName}</span></Tooltip>),
          sortable: true
        },
        {
          name: 'Traffic',
          selector: (row) => row.starts,
          sortable: true,
          cell: (row) => (
            <>
              {row.affiliateId != 44 ? (
                <Tooltip title="Download Report">
                  <span>
                  {document.getElementById('demo-multiple-checkbox').innerText.length > 1 ? (
                    <ExcelCell
                      fetchDataCallback={() => getAffiliateDetailbyId(row.affiliateId,document.getElementById('demo-multiple-checkbox').innerText.replace(/\s/g,""),document.getElementById('countryStartDate').value,document.getElementById('countryEndDate').value)}
                      headers={headers}
                      filename={filename}
                      value={row.starts} />
                  ):(<ExcelCell
                    fetchDataCallback={() => getAffiliateDetailbyId(row.affiliateId,document.getElementById('countryFilterData').value,document.getElementById('countryStartDate').value,document.getElementById('countryEndDate').value)}
                    headers={headers}
                    filename={filename}
                    value={row.starts} />)
                  }
                  </span>
                </Tooltip>
              ) : (<span>NA</span>)}

            </>
          ),
        },
        {
          name: 'Joined',
          selector: (row) => row.joined,
          sortable: true,
        },
        {
          name: 'DOI',
          selector: (row) => row.DOI,
          sortable: true,
        },
        {
          name: <div>Survey Starts</div>,
          selector: (row) => row.attempt,
          sortable: true,
        },
        {
          name: <div>Survey Completes</div>,
          selector: (row) => row.surveyComplete,
          sortable: true,
        },
      ]);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    //setPage(1);
  };

  const handleSearchFilter = (e) => {
    if (Object.keys(selectedPanel).length > 0) {

      setPending(true);
      setPageloading(true);
      filterRequestData.startDate = filterData.startDate;
      filterRequestData.endDate = filterData.endDate;

      if (document.getElementById('demo-multiple-checkbox').innerText.length > 1) {
        filterRequestData.country = document.getElementById('demo-multiple-checkbox').innerText.split(", ");
     
      }
      else {
        // filterRequestData.country = loadAllCountries;
        filterRequestData.country = null;
        setLoadAllFilterCountries(loadAllCountries.toString());
      }

      setfilterRequestData({
        panelId: selectedPanel?.panelId,
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        country: filterRequestData.country
      })

      getAffiliateReportsByFilter(filterRequestData)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setAffiliateData(res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => {
          setPageloading(false);
          setPending(false);
        });
    }
    return () => {
      setAffiliateData([]);
    };
    //setPage(1);
  };

  const filteredData = affiliateData.filter((item) => {
    const values = Object.values(item);
    return values.find((value) =>
      value != null &&
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    })
    );
     filterRequestData.startDate = filterData.startDate;
     filterRequestData.endDate = filterData.endDate;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] !== "all") {
      setSelected(value);
    }

    if (value[value.length - 1] === "all") {
      setSelected(selected.length === loadAllCountries.length ? [] : loadAllCountries);
      if (isAllSelected) {
        setIsAllSelected(false);
      }
      else {
        setIsAllSelected(true);
      }
      return;
    }
    else if (value[value.length - 1] !== "all" && value.length !== loadAllCountries.length) {
      setIsAllSelected(false);
    }
    else if (value[value.length - 1] !== "all" && value.length === loadAllCountries.length) {
      setIsAllSelected(true);
    }
    // setSelected(value);
  };
  return (
    <>
      <AnimatedPage>
        <TopHeading showButton={false} heading="Affiliate Report" />
        <Paper sx={{ px: 6, py: 1, mx: 2, mb: 2 }} elevation={1}>
          {/* <Typography variant="h6" my={1}>
        Filter Affiliate Report
      </Typography> */}
          <Stack
            direction={"row"}
            sx={{ mb: 2, my: 2 }}
            spacing={2}
            alignItems={"flex-start"}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3}>
              <input id="countryFilterData" value={loadAllCountries.toString()} type="hidden" />
              <input id="countryStartDate" value={filterRequestData.startDate} type="hidden" />
              <input id="countryEndDate" value={filterRequestData.endDate} type="hidden" />

                <TextField
                  fullWidth
                  InputProps={{ inputProps: { max: filterData.maxDate } }}
                  name={"startDate"}
                  value={filterData.startDate}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"Start Date"}
                  type={"date"}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
                <TextField
                  fullWidth
                  InputProps={{ inputProps: { max: filterData.maxDate } }}
                  type={"date"}
                  name={"endDate"}
                  value={filterData.endDate}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"End Date"}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} sx={{ mx: 2 }}>
                <FormControl sx={{ width: 205 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Country</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selected}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    variant="outlined"
                    label="Country"
                  >
                    <MenuItem
                      value="all"
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem>
                    {CountryNames.map((option) => (
                      <MenuItem key={option.countryCode} value={option.countryCode}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option.countryCode) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.countryName + '-' + option.language} />
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>

              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <LoadingButton disabled={moment(filterData.endDate).diff(moment(filterData.startDate), 'days') < 0}
                  // loading={tableLoading}
                  onClick={handleSearchFilter}
                  variant="contained"
                  sx={{ px: 4, textTransform: "capitalize", my: 1 }}
                >
                  Search
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </Paper>

        <Stack spacing={2} sx={{ mx: 2, my: 2 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                type="search"
                value={searchQuery}
                fullWidth
                label="Search"
                name="Search"
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
          <div>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              pagination={true}
              progressPending={pending}
              highlightOnHover
            />
          </div>
        </Stack>
      </AnimatedPage>
    </>
  )
}

export const EnhancedAffliateReport = Hoc(AffiliateReport, { name: "" });
