import axios from "axios";

export const getAllQuestionsForPanel = async (tenantId, panelId) => {
  try {
    const res = await axios.get(
      `/api/Company/getSelectedDeselectedQsn?tenantId=${tenantId}&panelId=${panelId}`
    );
    return res;
  } catch (error) {
    console.log("error fetching panel questions", error.message);
  }
};

export const assignPanelQuestions = async(data) => {
    try {
        const res = await axios.post('/api/Company/createPanelQuestion', data);
        return res;
    } catch (error) {
        console.log('error assigning questions', error.message)       
    }
}