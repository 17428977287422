import {
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    Select,
  } from "@mui/material";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import React from "react";
  import "./styles.css";
import { useDispatch } from "react-redux";
import { setFilterId } from "../../features/masterQuestionSlice";
  
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        paddingBlock: '1rem',
        paddingInline: '0.5rem'
      },
    },
  };
  
  const FilterDropDown = (props) => {
    const [selectedOption, setselectedOption] = React.useState(props.value ? props.value : "");
    const [open, setOpen] = React.useState(false);
  
    const dispatch = useDispatch();

    const handleChange = (event) => {
      setselectedOption(event.target.value);
      let id = event.target.value.categoryId;
      if(id){
          dispatch(setFilterId(id))
      }
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
      <FormControl sx={{ m: 0, width: 200 }} size="small">
        <InputLabel id="demo-simple-select-label">{props.name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          IconComponent={KeyboardArrowDownIcon}
          value={selectedOption}
          label={props.name}
          onChange={handleChange}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            "& .MuiRadio-root": {
              display: "none",
            },
            "& .css-tlelie-MuiListItemText-root": {
              textAlign: "start",
              margin: "0.2rem 0.2rem",
            },
          }}
        >
          {/* <div style={{ position: "relative" }}>
            <h4 className="dropdown-heading">{props.name}</h4>
        {!props.displayEmpty &&
            <span className="blue"></span>
        }
          </div> */}
  
          {props.options.length > 1 && props.options.map((item, index) => (
            <MenuItem className="dropdown-option" key={index} value={item}>
              <ListItemText className="option-text" primary={item.category} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  
  export default FilterDropDown;
  