import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../httpHandlers";

const initialState = {
  categories: [],
  miniSurvey: [],
  loading: true,
};

export const fetchMiniSurveys = createAsyncThunk(
  "mini-surveys/fetch",
  async (obj, { rejectWithValue }) => {
    try {
      const { panel_id } = obj;
      const res = getData(
        `/api/company/getPanelAllMiniSurvey?panelId=${panel_id}`
      );
      if (!res) {
        return rejectWithValue(res);
      }
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const fetchCategories = createAsyncThunk(
  "categories/fetch",
  async (obj, { rejectWithValue }) => {
    try {
      const res = getData(`/api/systemUser/getActiveCategory/`);
      if (!res) {
        return rejectWithValue(res);
      }
      return res;
    } catch (error) {
      return error;
    }
  }
);

const listSlice = createSlice({
  name: "listSlice",
  initialState,
  reducers: {
    getMiniSurveyItem: (state, action) => {
      //   console.log("here");
      //   const { id } = action.payload;
      //   console.log("mini survey id", id);
      //   if (state?.miniSurvey?.length > 0) {
      //     const item = state?.miniSurvey?.find(
      //       (item) => item?.miniSurveyId === id
      //     );
      //     return item;
      //   }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMiniSurveys.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchMiniSurveys.fulfilled, (state, action) => {
        state.loading = true;
        state.miniSurvey = action?.payload?.data?.data;
      })
      .addCase(fetchMiniSurveys.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = true;
        state.categories = action?.payload?.data?.data;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = true;
      });
  },
});

export const { getMiniSurveyItem } = listSlice.actions;
export default listSlice.reducer;
