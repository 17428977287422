import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomMuiSwitch from "../../../utils/customMuiSwicth/CustomMuiSwitch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Create, Delete } from "@mui/icons-material";
import { Link, useLocation, useParams } from "react-router-dom";
import { green, red } from "@mui/material/colors";
import { SnackBarAlert } from "../../../utils/SnackBar";
import ConfTenantPanel from "./confTenantpanels";
import { getTenantPanels } from "../companyOnBoard.api";
import { getActiveCountries } from "../../country/country.api";

const TenantPanels = () => {
  const [alert, setAlert] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [tenantPanels, setTenantPanels] = useState([]);
  const [activeCountries, setactiveCountries] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [dataChanged, setdataChanged] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTenantPanels(id)
        .then((res) => {
          if (res?.status === 200) {
            setTenantPanels(res.data.data);
            console.log("tenant panels", res.data.data);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
          console.log(err.message);
        })
        .finally(() => setLoading(false));
      getActiveCountries()
        .then((res) => {
          if (res?.status === 200) {
            setactiveCountries(res.data.data);
          }
        })
        .catch((err) => {
          console.log("error fetching countries", err);
        });
    }
    return () => {
      setTenantPanels([]);
    };
  }, [id, dataChanged]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const labels = [
    "Name",
    "Type",
    "Mobile Link",
    "Website Link",
    "Status",
    // "Actions",
  ];
  const rows = [];

  const handleCloseAlert = () => {
    setAlert(false);
  };

  tenantPanels &&
    tenantPanels.length > 0 &&
    tenantPanels.map((item) =>
      rows.push([
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={`/admin/tenant-panels/panel/${item.panelId}`}
          state={{ panelName: item.panelName, link: location.pathname }}
        >
          <Typography variant="p" fontWeight={600}>
            {item.panelName}
          </Typography>
        </Link>,
        <Typography>{item.panelType}</Typography>,

        item.mobileAppLink ? (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(item?.mobileAppLink);
              setAlert("Link copied to clipboard");
            }}
          >
            <Tooltip title={item?.mobileAppLink}>
              <CheckCircleIcon sx={{ color: green[500] }} />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton>
            <CancelIcon sx={{ color: red[500] }} />
          </IconButton>
        ),
        item.webAppLink ? (
          <IconButton
            onClick={() => {
              setAlert("Link copied to clipboard");
              navigator.clipboard.writeText(item?.webAppLink);
            }}
          >
            <Tooltip title={item?.webAppLink}>
              <CheckCircleIcon sx={{ color: green[500] }} />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton>
            <CancelIcon sx={{ color: red[500] }} />
          </IconButton>
        ),
        <CustomMuiSwitch checked={item.isActive} />,
        // <Stack direction={"row"}>
        //   <IconButton>
        //     <Create />
        //   </IconButton>
        //   <IconButton>
        //     <Delete />
        //   </IconButton>
        // </Stack>,
      ])
    );
  return (
    <>
      <TopHeading
        openModal={handleOpenAddModal}
        link={"/admin/company-on-board"}
        heading={"Tenant Panels"}
      />
      <CustomTable pageLoading={loading} data={rows} labels={labels} />
      <ConfTenantPanel
        open={openAddModal}
        hadnleCloseModal={handleCloseAddModal}
        type="ADD"
        setAlert={setAlert}
        activeCountries={activeCountries}
        tenantId={id}
        setdataChanged={setdataChanged}
      />
      <SnackBarAlert open={alert} handleClose={() => handleCloseAlert()} />
    </>
  );
};

export default TenantPanels;
