import axios from "axios";

export const getAllanswerTypes = async () => {
  try {
    const res = await axios.get("/api/Company/getQuestionType");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addAnswerType = async (data) => {
  try {
    const res = await axios.post("/api/Company/createAnswerType", data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateAnswerType = async (id, data) => {
  try {
    const res = await axios.patch(`/api/Company/updateAnswerType/${id}`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAnswerType = async (id) => {
  try {
    const res = await axios.delete(`/api/Company/deleteAnswerType/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
