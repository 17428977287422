import React from "react";
import { Box } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { ColorButton } from "../StyledComponents/ColorButton";

const SuccessPage = () => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#2560D8"
        height="50px"
        width="50px"
        borderRadius="50%"
        marginBottom="5rem"
      >
        <CheckRoundedIcon
          style={{ color: "white", fontSize: "2.5rem", fontWeight: "bold" }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginBottom="1rem"
        >
          <text
            style={{ color: "#2560D8", fontWeight: "bold", fontSize: "1.6rem" }}
          >
            Password Reset
          </text>
          <text style={{ textAlign: "center" }}>
            Congratulations!!!
            <br/> You have successfully
            <br /> reset your password
          </text>
          <text
            style={{ marginTop:'2rem' }}
          >
            Go back to the app
          </text>
        </Box>
        {/* <ColorButton variant="contained" size="medium">
          Continue
        </ColorButton> */}
      </Box>
    </>
  );
};

export default SuccessPage;
