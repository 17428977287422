import React from "react";
import { SnackBarAlert } from "../../utils/SnackBar";
import { fetchRequests } from "../Requests/requests.api";
import { getAllMembers } from "../users/user.api";
import { fetchAllCountry, getTenantCountry } from "../country/country.api";
import { getAllRoles } from "../roles/roles.api";
import { getAllLanguages } from "../language/language.api";
import { getAllFeature } from "../feature/feature.api";
import { getAllContent } from "../content/content.api";
import { getAllPermission } from "../permission/permission.api";
import {
  getActiveCategories,
  getAllCategories,
  getSystemTenantCategories,
} from "../category/category.api";
import { getAllanswerTypes } from "../answerType/answerType.api";
import { getAllPanel } from "../panel/panel.api";
import { getAllMasterQuestion } from "../masterQuestions/masterQuestion.api";
import { getCompanyMembers } from "../companyOnBoard/companyOnBoard.api";
import { getAllMembersBytenantId } from "../manageMembers/manageMember.api";
import { useSelector } from "react-redux";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import {
  getActivepanels,
  getAllPanelAdmins,
} from "../managePanel/managePanel.api";
import { getAssociatedPanelMembers } from "../associatedPanelMembers/assciatedmembers.api";
import { getAllSurvey } from "../survey/survey.api";
import { getAllPanelProfile } from "../userProdileQuestionaire/userProfileQuestionaire.api";

const PANEL_ADMIN_ROLE_ID = 4;

const Hoc = (WrappedComponent, entity) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        loading: true,
        page: 1,
        limit: 10,
        pageCount: 0,
        alert: false,
        open: false,
        added: false,
        editModal: false,
        showPagination: true,
        userId: props.userId,
        tenantId: props.tenantId,
        panelId: props.panelId,
        permissions: props?.permissions,
        panelMember: props?.panelMember,
        roleId: props?.roleId,
      };
    }

    setPage(value) {
      if (value) {
        this.setState({ ...this.state, page: value });
      }
    }
    setAlert(msg) {
      if (msg) {
        this.setState({ ...this.state, alert: msg });
      }
    }
    closeAlert() {
      this.setState({ ...this.state, alert: false });
    }
    openModal() {
      this.setState({ ...this.state, open: true });
    }
    closeModal() {
      this.setState({ ...this.state, open: false });
    }
    openEditModal() {
      this.setState({ ...this.state, editModal: true });
    }
    closeEditModal() {
      this.setState({ ...this.state, editModal: false });
    }

    setSuccess() {
      this.setState({
        ...this.state,
        added: Boolean(true),
        open: false,
        editModal: false,
        alert: "Success",
      });
    }
    setError(msg) {
      this.setState({
        ...this.state,
        added: Boolean(false),
        open: false,
        editModal: false,
        alert: msg,
      });
    }
    setShowPagination() {
      this.setState({ ...this.state, showPagination: true });
    }
    hidePagination() {
      this.setState({ ...this.state, showPagination: false });
    }

    fetchTableData(name) {
      this.setState({ ...this.state, loading: true });
      switch (name) {
        case "country":
          fetchAllCountry()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "roles":
          getAllRoles(this.state.userId).then((res) => {
            this.setState({
              ...this.state,
              data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
              loading: false,
            });
          });
          break;
        case "feature":
          getAllFeature().then((res) => {
            if (res?.data) {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            }
          });
          break;
        case "content":
          getAllContent().then((res) => {
            if (res?.data) {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            }
          });
          break;
        case "permission":
          getAllPermission().then((res) => {
            if (res?.data) {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            }
          });
          break;
        case "category":
          if (this.state.roleId === 3) {
            getActiveCategories().then((res) => {
              if (res?.data) {
                this.setState({
                  ...this.state,
                  data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                  loading: false,
                });
              }
            });
          } else {
            getAllCategories().then((res) => {
              if (res?.data) {
                this.setState({
                  ...this.state,
                  data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                  loading: false,
                });
              }
            });
          }
          break;
        case "users":
          getAllMembers()
            .then((res) => {
              if (res?.status === 200) {
                this.setState({
                  ...this.state,
                  data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                  loading: false,
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: false,
                });
              }
            })
            .catch((err) => {
              console.log(err.message);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "company-on-board":
          getCompanyMembers().then((res) => {
            this.setState({
              ...this.state,
              data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
              loading: false,
            });
          });
          break;
        case "language":
          getAllLanguages()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "answerType":
          getAllanswerTypes()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "panel":
          getAllPanel()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "masterQuestion":
          getAllMasterQuestion(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "manage-member":
          getAllMembersBytenantId(this.state.userId)
            .then((res) => {
              if (res?.status === 200) {
                this.setState({
                  ...this.state,
                  data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                  loading: false,
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: false,
                });
              }
            })
            .catch((err) => {
              this.setState({
                ...this.state,
                loading: false,
              });
              console.log(err);
            });
          break;
        case "company-features":
          getFeaturesByTenantId(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "panel-admins":
          getActivepanels(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "survey":
          getAllSurvey()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "assigned-survey-countries":
          getTenantCountry(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "assigned-features":
          getAllFeature()
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "panel-members":
          getAssociatedPanelMembers(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        case "panel-profile":
          getAllPanelProfile(this.state.userId)
            .then((res) => {
              this.setState({
                ...this.state,
                data: res?.data?.data?.length > 0 ? [...res?.data?.data] : [],
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                loading: false,
              });
            });
          break;
        default:
          break;
      }
    }

    componentDidMount() {
      console.log("mount");
      this.fetchTableData(entity.name);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.page !== this.state.page || this.state.added === true) {
        this.fetchTableData(entity.name);
        this.setState({ ...this.state, added: false, showPagination: true });
      }
    }
    render() {
      let {
        data,
        loading,
        page,
        pageCount,
        alert,
        open,
        editModal,
        showPagination,
        permissions,
        panelMember,
        panelId,
      } = this.state;

      return (
        <>
          <WrappedComponent
            setAlert={(msg) => this.setAlert(msg)}
            pageCount={pageCount}
            page={page}
            setPage={(value) => this.setPage(value)}
            data={data}
            loading={loading}
            openModal={open}
            handleOpenModal={() => this.openModal()}
            handleCloseModal={() => this.closeModal()}
            openEditModal={editModal}
            handleOpenEditModal={() => this.openEditModal()}
            handleCloseEditModal={() => this.closeEditModal()}
            showPagination={showPagination}
            setShowPagination={() => this.setShowPagination()}
            hidePagination={() => this.hidePagination()}
            setSuccess={() => this.setSuccess()}
            setError={(msg) => this.setError(msg)}
            permissions={permissions}
            panelMember={panelMember}
            panelId={panelId}
          />

          <SnackBarAlert open={alert} handleClose={() => this.closeAlert()} />
        </>
      );
    }
  };
};

export default Hoc;
