import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { deleteCategory } from "./category.api";
import ConfCategory from "./ConfCategory";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getAllPanelAdmins } from "../managePanel/managePanel.api";

const Category = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [categories, setcategories] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [questionTranslationModal, setQuestionTranslationModal] =
    useState(false);

  const [panels, setPanels] = useState([]);

  const handleOpenQuestionTranslationModal = () => {
    setQuestionTranslationModal(true);
  };
  const handleCloseQuestionTranslationModal = () => {
    setQuestionTranslationModal(false);
  };

  useEffect(() => {
    if (data) {
      setcategories(data);
    }
    return () => {
      setcategories(data);
    };
  }, [data]);

  // get panels for the current tenant
  useEffect(() => {
    if (roleId === 3) {
      getAllPanelAdmins(adminId, 4)
        .then((res) => {
          if (res?.status === 200) {
            setPanels(res.data.data);
          }
        })
        .catch((err) => console.log(err.message));
    }
    return () => {
      setPanels([]);
    };
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.categoryId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (id) => {
    deleteCategory(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const SYSTEM_IDS = [1, 2];

  const rows = [];
  const labels = ["Name", "Status", "Actions"];
  if (!SYSTEM_IDS.includes(roleId)) {
    labels[1] = "";
  } else {
    labels[2] = "";
  }

  categories &&
    categories.length > 0 &&
    categories.map((item) =>
      rows.push([
        item?.category,
        SYSTEM_IDS.includes(roleId) && (
          <CustomMuiSwitch checked={item.isActive} />
        ),
        <Stack direction={"row"} alignItems="flex-end">
          {item.type === "panel" && (
            <>
              <IconButton
                onClick={() => {
                  setEditId(item.categoryId);
                  handleOpenEditModal();
                }}
              >
                <Create />
              </IconButton>
              <ConfirmAlert
                msg={"Are you sure you want to delete?"}
                onClickEvent={() => handleDelete(item.categoryId)}
              >
                <IconButton>
                  <Delete />
                </IconButton>
              </ConfirmAlert>
            </>
          )}
          {roleId && roleId === 3 && (
            <>
              <IconButton
                onClick={() => {
                  setEditId(item.categoryId);
                  handleOpenQuestionTranslationModal();
                }}
              >
                <Tooltip title="Category translation">
                  <AddIcon />
                </Tooltip>
              </IconButton>
              <Link
                style={{
                  padding: "2px",
                  margin: 0,
                  textDecoration: "none",
                  color: "#555555",
                }}
                to={`/admin/categiry-translations/${item.categoryId}`}
              >
                <Tooltip title="View translations">
                  <RemoveRedEyeIcon />
                </Tooltip>
              </Link>
            </>
          )}
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={roleId === 1 || roleId === 2 ? true : false}
          openModal={handleOpenModal}
          heading="Category"
        />
        <ConfCategory
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
        />
        <ConfCategory
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
        />
        <ConfCategory
          open={questionTranslationModal}
          loading={loading}
          type="TRANSLATE"
          hadnleCloseModal={handleCloseQuestionTranslationModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
          panels={panels}
        />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedCategory = Hoc(Category, { name: "category" });
