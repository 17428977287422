import axios from "axios"

export const getAllPermission = async () => {
    try {
        const res = await axios.get('/api/systemUser/getAllPermission');
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const addPermission = async(data) => {
    try {
        const res = await axios.post('/api/systemUser/createPermission', data)
        return res;
    } catch (error) {
        console.log(error);
    }
}
export const updatePermission = async(id, data) => {
    try {
        const res = await axios.patch(`/api/systemUser/updatePermission/${id}`, data)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const deletePermission = async(id) => {
    try {
        const res=  await axios.delete(`/api/systemUser/deletePermission/${id}`)
        return res;
    } catch (error) {
        console.log(error)
    }
}