import {
  Create,
  Delete,
  RemoveRedEye,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { getActivepanels } from "../RedemptionRequest/Redemption.api";
import ConfTangoCard from "./ConfTangoCard";
import { getTangoCards, deleteTangoCard } from "./TangoCard.api";

const TangoCard = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;

  //redux
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const isPanelMember = useSelector(
    (state) => state.panelMemberPermissions.isPanelMember
  );
  const memberPermissions = useSelector(
    (state) => state.panelMemberPermissions.memberPermissions
  );

  //states
  const [activePanels, setActivePanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [pageloading, setPageloading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [tangoCards, settangoCards] = useState([]);
  const [editId, seteditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedPanelId, setselectedPanelId] = useState();
  const [miniSurveyCode, setminiSurveyCode] = useState();
  const [selectedMiniSurveyName, setselectedMiniSurveyName] = useState();
  const [selectedMiniSurveyPoints, setselectedMiniSurveyPoints] = useState();
  const [selectedMiniSurveyLoi, setselectedMiniSurveyLoi] = useState();
  const [showPassword, setshowPassword] = useState(false);

  //data
  const rows = [];
  const labels = [
    "Panel Name",
    "Customer Identifier",
    "Account Identifier",
    "Tango Username",
    "Tango Password",
    "Actions",
  ];

  //functions
  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case "PANEL":
        console.log("selected panel", value);
        setSelectedPanel(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setActivePanels(data);
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setActivePanels([]);
      setSelectedPanel({});
      setDataUpdated(false);
    };
  }, [adminId]);

  useEffect(() => {
    if (selectedPanel) {
      setPageloading(true);
      getTangoCards(adminId)
        .then((res) => {
          if (res?.status === 200) {
            // var collectedData = res.data.data;
            // collectedData.map((d) => (
            //   d["showPassword"]=false
            // ))
            settangoCards(res.data.data);
            console.log("Tango cards", res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => setPageloading(false));
    }
    return () => {
      settangoCards([]);
    };
  }, [selectedPanel, dataUpdated]);

  //deleting Tango card
  const handleDeleteTangoCard = (id) => {
    console.log("id", id);
    setDataUpdated(false);
    if (id) {
      deleteTangoCard(id)
        .then((res) => {
          if (res?.status === 200) {
            setAlert("Successfully deleted");
            setDataUpdated(true);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        });
    }
  };

  //Populating minisurvay tables
  var collectedData = tangoCards;
  var tempArr = [];
  collectedData &&
    collectedData.length > 0 &&
    collectedData.map((d, index) => (tempArr[index] = false));

  collectedData &&
    collectedData.length > 0 &&
    collectedData.map((item, index) =>
      rows.push([
        item?.panelName,
        item?.customerIdentifier,
        item?.accountIdentifier,
        item?.tangoUsername,
        <IconButton
          size="small"
          onClick={() => {
            // item.showPassword = !item.showPassword;
            tempArr[index] = !tempArr[index];
            console.log(tempArr);
          }}
        >
          {/* {tempArr[index]===true ? item.tangoPassword : "******"} */}
          {item?.tangoPassword}
        </IconButton>,
        <Stack direction={"row"}>
          {/* EDIT ------------------ */}
          <IconButton
            onClick={() => {
              seteditId(item.id);
              setSelectedItem(item);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>

          {/* DELETE ------------------------- */}
          {isPanelMember ? (
            memberPermissions &&
            memberPermissions.length > 0 &&
            memberPermissions.includes("per_del_svy") && (
              <ConfirmAlert
                msg={"Are you sure you want to delete?"}
                onClickEvent={() => handleDeleteTangoCard(item.id)}
              >
                <IconButton>
                  <Delete />
                </IconButton>
              </ConfirmAlert>
            )
          ) : (
            <ConfirmAlert
              msg={"Are you sure you want to delete?"}
              onClickEvent={() => handleDeleteTangoCard(item.id)}
            >
              <IconButton>
                <Delete />
              </IconButton>
            </ConfirmAlert>
          )}
        </Stack>,
      ])
    );

  return (
    <>
      <AnimatedPage>
        <TopHeading heading="Tango Cards" openModal={handleOpenModal} />
        {/* <Stack sx={{ mx: 4, my: 2 }}>
          <AsyncDropdown
            handleChange={(value) => handleDropDownChange(value, "PANEL")}
            value={selectedPanel}
            options={activePanels}
            objKey={"panelName"}
            stylized
            label={"Select Panel"}
          />
        </Stack> */}
        <ConfTangoCard
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <ConfTangoCard
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedPanelId={selectedPanelId}
          selectedItem={selectedItem}
          miniSurveyCode={miniSurveyCode}
          selectedMiniSurveyName={selectedMiniSurveyName}
          selectedMiniSurveyPoints={selectedMiniSurveyPoints}
          selectedMiniSurveyLoi={selectedMiniSurveyLoi}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <CustomTable labels={labels} data={rows} pageLoading={pageloading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedTangoCard = Hoc(TangoCard, { name: "" });
