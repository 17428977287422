import axios from "axios"

export const getRestaurants = async(page, pageSize) => {
    try{
        const res = await axios.get(`api/restaurants?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=publishedAt:DESC`);
        return res;
    }
    catch (error) {
        console.log(error)
    }
} 

export const addRestaurant = async(data) => {
    try{
        const res = await axios.post('api/restaurants', data);
        return res;
    }catch(error){
        console.log(error);
    }
}

export const deleteRestaurant = async(id) => {
    try{
        const res = await axios.delete(`api/restaurants/${id}`)
        return res;
    }
    catch(err){
        console.log(err);
    }
}

export const getRestaurantById = async(id) => {
    try{
        const res = await axios.get(`api/restaurants/${id}`)
        return res;
    }
    catch(error){
        console.log(error)
    }
}

export const updateResturant = async(id, data) => {
    try {
        const res =  await axios.put(`api/restaurants/${id}`, data)
        return res;
    } catch (error) {
        console.log(error)
    }
}