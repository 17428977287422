import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import './index.css'

const AuthLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
  }, []);
  
  return (
    <div className='container'>
        <Outlet />
    </div>
  )
}

export default AuthLayout