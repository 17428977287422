import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ViewCountryLanguageModal = (props) => {
  const { open, handleClose, selectedItem } = props;
  console.log(selectedItem);

  const [countryLanguage, setCountryLanguage] = useState([]);
  useEffect(() => {
    if (selectedItem) {
      setCountryLanguage(selectedItem.countryLanguage);
    }
    return () => {
      setCountryLanguage([]);
    };
  }, [selectedItem]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ background: "#3f72af", mb: 2 }}>
        <Typography sx={{ color: "#fafafa" }} component={"h4"} variant={"p"}>
          Country and languages
        </Typography>
      </DialogTitle>
      <DialogContent>
        {countryLanguage?.map((item, index) => (
          <Stack key={index}>
            <div>
              <Typography>Name: {item?.countryName}</Typography>
              <Typography>
                Language: {item?.countryLanguage}
              </Typography>
              <Divider sx={{ my: 1.5 }} />
            </div>
          </Stack>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ViewCountryLanguageModal;
