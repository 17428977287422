import {
  Create,
  Delete,
  RemoveRedEye,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { deleteMember } from "../users/user.api";
import ConfPanelAdmin from "./ConfPanelAdmin";
import ViewDetailsModal from "./ViewDetailsModal";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PanelAdmin = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [members, setmembers] = useState([]);
  const [editId, setEditId] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedPanelId, setselectedPanelId] = useState(null);
  const location = useLocation();
  const roleSelected = {
    roleId: 4,
    roleGuid: "73bc34c8-956d-450e-805e-3c48848b0d1e",
    role: " Tenant Panel Admin",
    tenantId: null,
    isActive: true,
    createdBy: 1,
    updatedBy: null,
    createdAt: "2022-11-11T13:08:49.984Z",
    updatedAt: "2022-11-11T13:08:49.984Z",
  };

  useEffect(() => {
    if (data) {
      console.log("heyy", data);
      setmembers(data);
    }
    return () => {
      setmembers(data);
    };
  }, [data]);

  useEffect(() => {
    if (editId && members && members.length > 0) {
      const item = members.find((member) => member.adminId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }
    return () => {
      setSelectedItem({});
    };
  }, [editId, members]);

  const handleDelete = (id) => {
    deleteMember(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const rows = [];
  const labels = ["Name", "Type", "Mobile link", "Website Link", "Actions"];
  members &&
    members.length > 0 &&
    members.map((member) =>
      rows.push([
        <Typography
          // onClick={() => {
          //   handleOpenViewModal();
          //   setEditId(member.adminId);
          // }}
          sx={{ textTransform: "capitalize" }}
        >
          {member.panelName}
        </Typography>,
        member.panelType,
        member.mobileAppLink ? (
          <IconButton
            onClick={() => {
              setAlert("Link copied to clipboard");
              navigator.clipboard.writeText(member?.mobileAppLink);
            }}
          >
            <Tooltip title={member?.mobileAppLink}>
              <CheckCircleIcon sx={{ color: green[500] }} />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton>
            <CancelIcon sx={{ color: red[500] }} />
          </IconButton>
        ),
        member.webAppLink ? (
          <IconButton
            onClick={() => {
              setAlert("Link copied to clipboard");
              navigator.clipboard.writeText(member?.webAppLink);
            }}
          >
            <Tooltip title={member?.webAppLink}>
              <CheckCircleIcon sx={{ color: green[500] }} />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton>
            <CancelIcon sx={{ color: red[500] }} />
          </IconButton>
        ),
        <IconButton>
          <Tooltip title={"view features"}>
            <Link
              style={{ textDecoration: "none", color: "#555555" }}
              state={{ panelName: member.panelName, link: location.pathname }}
              to={`/admin/tenant-panels/panel/${member.panelId}`}
            >
              <RemoveRedEye />
            </Link>
          </Tooltip>
        </IconButton>,
        // <CustomMuiSwitch checked={member.isActive} />,
        // <Stack direction="row" spacing={1}>
        //   <IconButton
        //     onClick={() => {
        //       setEditId(member.adminId);
        //       handleOpenEditModal();
        //     }}
        //   >
        //     <Create />
        //   </IconButton>
        //   <ConfirmAlert
        //     onClickEvent={() => handleDelete(member.adminId)}
        //     msg={"Are you sure?"}
        //   >
        //     <IconButton>
        //       <Delete />
        //     </IconButton>
        //   </ConfirmAlert>
        // </Stack>,
      ])
    );

  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={false}
          openModal={handleOpenModal}
          heading="Panel Details"
        />
        <ConfPanelAdmin
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          roleSelected={roleSelected}
        />
        <ConfPanelAdmin
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
          roleSelected={roleSelected}
        />
        <ViewDetailsModal
          handleClose={handleCloseViewModal}
          open={openViewModal}
          selectedPanelId={editId}
          selectedItem={selectedItem}
        />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedPanelAdmin = Hoc(PanelAdmin, { name: "panel-admins" });
