import { AppBar, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getUserSurveyParticipation } from "./verifyReturnStatus.api";
import logo from "../../assets/images/logo.png";
import BouncingDotsLoader from "../../views/BouncingDotsLoader";
const VerifyReturnStatus = () => {
  const { id, token } = useParams();
  const [message, setMessage] = useState("");
  const success = [2];
  const error = [3, 4, 9];

  const [loading, setLoading] = useState(true);
  let ranonce = false;

  useEffect(() => {
    if (id && token) {
      if (!ranonce) {
        ranonce = true;
        setMessage(
          <div className="center-text">
            <CircularProgress loading={loading} />
            <BouncingDotsLoader />
          </div>
        );
        getUserSurveyParticipation(token, id)
          .then((res) => {
            if (res?.status >= 200 && res?.status <= 299) {
              const userSurvey = res?.data?.data;
              if (userSurvey.userStatus === 2) {
                setMessage(
                  <div className="center-text">
                    <p className="message-status">
                      Your answers have been received and this survey is now
                      completed.
                    </p>
                    <p>{`You earned ${userSurvey.completedPoints} points.`}</p>
                    <BouncingDotsLoader />
                  </div>
                );
              } else if (userSurvey.userStatus === 13) {
                setMessage(
                  <div className="center-text">
                    <p className="message-status">
                      We're sorry, but we have reached our quota limits for this
                      survey. Please try another survey.
                    </p>
                    <BouncingDotsLoader />
                  </div>
                );
              } else {
                setMessage(
                  <div className="center-text">
                    <p className="message-status">
                      Thank you for your time, unfortunately your profile does
                      not correspond to the one we were looking for in this
                      study. Please try another survey.
                    </p>
                    <BouncingDotsLoader />
                  </div>
                );
              }
            }
            else {
              setMessage(
                <div className="center-text">
                  <p className="message-status">Invalid Request URL</p>
                  <BouncingDotsLoader />
                </div>
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            setMessage(
              <div className="center-text">
                <p className="message-status">
                  Thank you for your time, unfortunately your profile does not
                  correspond to the one we were looking for in this study.
                  Please try another survey.
                </p>
                <BouncingDotsLoader />
              </div>
            );
          })
          .finally(() => setLoading(false));
      }
    }
    return () => { };
  }, [id, token]);

  return (
    <>
      <AppBar
        sx={{
          px: 2,
          py: 1.4,
          background: "#ffffff",
          height: "100px",
          justifyContent: "center",
        }}
      >
        <div className="app-bar-logo">
          <img src={logo} alt={"logo"} />
        </div>
      </AppBar>
      <div className="centered-conatiner">{message}</div>;
    </>
  );
};

export default VerifyReturnStatus;
