import { Stack, Typography } from "@mui/material";
import React from "react";
import CustomMuiSwitch from "../customMuiSwicth/CustomMuiSwitch";

const LabelWithSwitch = (props) => {
  const { label, child, objkey, hide } = props;
  return (
    <>
      <Stack
        sx={{
          mb: 1,
          pl: child ? "20px" : 0,
          // boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
          py: 1,
        }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        {label && (
          <Typography variant="p" sx={{ textTransform: "capitalize" }}>
            {label[objkey]}
          </Typography>
        )}

        {!hide && <CustomMuiSwitch checked={label?.isActive} />}
      </Stack>
      {label?.FeatureArray &&
        label.FeatureArray.map((item, index) => (
          <LabelWithSwitch
            child
            label={item}
            objkey={"feature"}
            key={"index"}
          />
        ))}
    </>
  );
};

export default LabelWithSwitch;
