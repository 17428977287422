import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPanelMember: false,
    memberFeatures: [],
    memberPermissions: [],
}

const PanelMemberPermissionsSlice = createSlice({
    name: 'panelMemberPermissions',
    initialState,
    reducers:{
        setMemberFeatuers: (state, {payload}) => {
            state.memberFeatures = payload
        },
        setMemberPermissions: (state, {payload}) => {
            state.memberPermissions = payload
        },
        setPanelMember: (state, {payload}) => {
            state.isPanelMember = payload
        }
    }

})

export const {setMemberFeatuers, setMemberPermissions, setPanelMember} = PanelMemberPermissionsSlice.actions;
export default PanelMemberPermissionsSlice.reducer;