import axios from "axios"

export const getAllFeature = async() => {
    try {
        const res = await axios.get('/api/systemUser/getFeature')
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const addNewFeature = async (data) => {
    try {
        const res = await axios.post('/api/systemUser/createFeature', data);
        return res;
    } catch (error) {
        console.log(error)
    }
}
export const updateFeature = async (id, data) => {
    try {
        const res = await axios.patch(`/api/systemUser/updateFeature/${id}`, data);
        return res;
    } catch (error) {
        console.log(error)
    }
}
export const deleteFeature = async (id) => {
    try {
        const res = await axios.delete(`/api/systemUser/deleteFeature/${id}`);
        return res;
    } catch (error) {
        console.log(error)
    }
}