import axios from "axios";

export const getAllMiniSurveys = async (id) => {
  try {
    const res = await axios.get(
      `/api/company/getPanelAllMiniSurvey?panelId=${id}`
    );
    return res;
  } catch (error) {
    console.log("failed getting miniSurvey", error.message);
    return error;
  }
};

export const getSpecificMiniSurveys = async (pid, cid) => {
  try {
    const res = await axios.get(
      `/api/Company/getPanelProfileQsn/?panelId=${pid}&countryId=${cid}`
    );
    return res;
  } catch (error) {
    console.log("failed getting specific mini Survey", error.message);
    return error;
  }
};

// /api/Company/getPanelProfileQsn/?panelId=42&countryId=1

export const getPanelQuestionForCreation = async (catIds) => {
  try {
    const res = await axios.get(`/api/Company/getAllPanelQuestion`, {
      params: {
        categoryIds: JSON.stringify(catIds),
      },
    });
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getCountryBypanelId = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getPanelCountry?panelId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getlanguageByCountryId = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getCountryLanguage?countryId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
export const getCategoryByPanelId = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getPanelCategory?panelId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getAllPanelProfile = async (id) => {
  try {
    const res = axios.get(`/api/Company/getAllPanelProfileQsn?tenantId=${id}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const createQuestionProfile = async (data) => {
  try {
    const res = await axios.post("/api/Company/createQsnProfile", data);
    return res;
  } catch (error) {
    console.log(error.message);
    return error;
  }
};

export const getPanelUserProfileQsn = async (
  panelId,
  countryId,
  languageId
) => {
  try {
    const res = await axios.get(
      `/api/Company/getPanelProfileQsn/?panelId=${panelId}&countryId=${countryId}&languageId=${languageId}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const getUserProfileQuestion = async (profileId) => {
  try {
    const res = await axios.get(`/api/company/getProfileQsn/${profileId}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateUserProfileQuestion = async (id, data) => {
  try {
    const res = await axios.patch(`/api/company/updateQsnProfile/${id}`, data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteUserProfileQuestion = async (profileId) => {
  try {
    const res = await axios.delete(
      `/api/Company/deleteQsnProfile/${profileId}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
