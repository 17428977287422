import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./styles.css";
import { green } from "@mui/material/colors";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { completeSurvey } from "../auth/auth.api";
import { SnackBarAlert } from "../../utils/SnackBar";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import logo from "../../assets/images/logo.png";
const VerifySurvey = () => {
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [token, setTokenId] = useState(null);
  const [panelid, setPanelId] = useState(null);

  const [searchParams] = useSearchParams();
  const status = [
    { id: 2, title: "Complete" },
    { id: 3, title: "Client Over Quota" },
    { id: 4, title: "Client Terminate" },
    { id: 9, title: "Client Security Terminate" },
  ];
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState({});

  useEffect(() => {
    const token = searchParams.get("TID");
    if (token) {
      setTokenId(token);
    }
    const id = searchParams.get("PID");
    if (id) {
      setPanelId(id);
    }
  }, [location]);

  const [alert, setAlert] = useState(false);
  // const handleVerify = () => {
  //   if (id) {
  //     setLoading(true);
  //     let data = {
  //       surveyGuid: id,
  //     };
  //     completeSurvey(data)
  //       .then((res) => {
  //         if (res?.status === 200) {
  //           setVerified(true);
  //           console.log(res.data);
  //         } else if (res?.response?.status === 404) {
  //           setAlert("Your survey could not be completed.");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("asdas", err);
  //         setAlert("Your survey could not be completed.");
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // };

  const handleChangeStatus = (val) => {
    setSelectedStatus(val);
  };

  const handleSendResStatus = () => {
    const finalData = {};
    finalData["resStatus"] = selectedStatus.id;
    if (!selectedStatus?.id) {
      setAlert("Select status");
      return;
    }
    if (!token) {
      setAlert("Invalid URL! Please check your URL and try again");
      return;
    }
    navigate(`/${selectedStatus.id}/${token}`);
  };

  return (
    <>
      <AppBar
        sx={{
          px: 2,
          py: 1.4,
          background: "#1a1a1a",
          height: "100px",
          justifyContent: "center",
        }}
      >
        <div className="app-bar-logo">
          <img src={logo} alt={"logo"} />
        </div>
      </AppBar>
      <div className="centered-conatiner">
        {/* {verified ? (
          <Stack alignItems={"center"} spacing={1}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: "57px", color: green[600] }}
            />
            <p className="message">Thank you</p>
          </Stack>
        ) : loading ? (
          <CircularProgress />
        ) : (
          <button onClick={handleVerify} className="verify-btn">
            Submit Survey
          </button>
        )} */}
        <div className="flex-row send-status-container">
          <AsyncDropdown
            label={"Select Status"}
            value={selectedStatus}
            handleChange={handleChangeStatus}
            options={status}
            objKey={"title"}
          />
          <Button
            onClick={handleSendResStatus}
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            Submit
          </Button>
        </div>
      </div>

      <SnackBarAlert open={alert} handleClose={() => setAlert(false)} />
    </>
  );
};

export default VerifySurvey;
