import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import { deleteAnswerType } from "./answerType.api";
import ConfAnswerType from "./ConfAnswertype";

const AnswerType = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [answertypes, setanswertypes] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      setanswertypes(data);
    }
    return () => {
      setanswertypes(data);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.answerTypeId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (id) => {
    deleteAnswerType(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rows = [];
  const labels = ["Type", "Status", "Actions"];
  answertypes &&
    answertypes.length > 0 &&
    answertypes.map((item) =>
      rows.push([
        item.answerType,
        <CustomMuiSwitch checked={item.isActive} />,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item.answerTypeId);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>
          <ConfirmAlert msg={'Are you sure you want to delete?'} onClickEvent={() => handleDelete(item.answerTypeId)}>
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
      <TopHeading openModal={handleOpenModal} heading="Answer type" />
      <ConfAnswerType
        open={openModal}
        loading={loading}
        type="ADD"
        hadnleCloseModal={handleCloseModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
      />
      <ConfAnswerType
        open={openEditModal}
        loading={loading}
        type="EDIT"
        hadnleCloseModal={handleCloseEditModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
        editId={editId}
        selectedItem={selectedItem}
      />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
    </>
  );
};

export const EnhancedAnswerType = Hoc(AnswerType, { name: "answerType" });
