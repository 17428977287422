import { Create, Delete } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmAlert from "../../utils/ConfirmAlert";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import { getAllContent } from "../content/content.api";
import { getAllFeature } from "../feature/feature.api";
import Hoc from "../HOC/Hoc";
import ConfRole from "./ConfRole";
import PermissionMenu from "./permissionsMenu/PermissionMenu";
import { deleteRole, getAllFeaturePermission } from "./roles.api";

const Roles = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;

  const [roles, setRoles] = useState([]);
  const [editId, setEditId] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [featurePermissions, setFeaturePermissions] = useState([]);

  const [featuers, setFeatuers] = useState([]);
  const [contents, setContents] = useState([]);
  const [featureAndContents, setFeatureAndContents] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  // check if user is panel member
  const isPanelMember = useSelector(
    (state) => state.panelMemberPermissions?.isPanelMember
  );
  // get all the member permissions
  const memberPermissions = useSelector(
    (state) => state.panelMemberPermissions?.memberPermissions
  );

  useEffect(() => {
    getAllContent()
      .then((res) => {
        if (res?.status === 200) {
          setContents(res.data.data);
          console.log("contents", res.data.data);
        }
      })
      .catch((err) => console.log(err));
    getFeaturesByTenantId(adminId)
      .then((res) => {
        if (res?.status === 200) {
          console.log("features by tenant", res.data.data);
          setFeatuers(res.data.data);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      setFeatuers([]);
      setContents([]);
      setFeatureAndContents([]);
    };
  }, []);

  useEffect(() => {
    if (
      (featuers && featuers.length > 0) ||
      (contents && contents.length > 0)
    ) {
      setFeatureAndContents([...featuers, ...contents]);
    }
    return () => {
      setFeatureAndContents([]);
    };
  }, [featuers, contents]);

  useEffect(() => {
    getAllFeaturePermission(adminId)
      .then((res) => {
        if (res?.status == 200) {
          setFeaturePermissions(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      setFeaturePermissions([]);
    };
  }, [data]);

  useEffect(() => {
    setRoles(data);
    console.log(data);
    return () => {
      setRoles([]);
    };
  }, [data]);

  useEffect(() => {
    if (editId && roles && roles.length > 0) {
      const item = roles.find((role) => role.roleGuid === editId);
      if (item) {
        console.log("selected role", item.permission);
        setSelectedItem(item);
      }
    }
    return () => {
      setSelectedItem({});
    };
  }, [editId]);

  const handleDelete = (id) => {
    deleteRole(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rolePermissions = ["per_ed_role", "per_del_role", "per_cr_role"];
  const multipleExist = memberPermissions.every((value) => {
    return rolePermissions.includes(value);
  });
  const labels = ["Role Type", "Permission", "Actions"];
  const rows = [];

  roles &&
    roles.length > 0 &&
    roles.map((role) =>
      rows.push([
        role.role,
        <PermissionMenu
          grantedPermission={role.permission}
          featurePermissions={featurePermissions}
        />,
        <Stack direction="row">
          {isPanelMember ? (
            memberPermissions &&
            memberPermissions.length > 0 &&
            memberPermissions.includes("per_ed_role") && (
              <IconButton
                onClick={() => {
                  setEditId(role.roleGuid);
                  handleOpenEditModal();
                }}
              >
                <Create />
              </IconButton>
            )
          ) : (
            <IconButton
              onClick={() => {
                setEditId(role.roleGuid);
                handleOpenEditModal();
              }}
            >
              <Create />
            </IconButton>
          )}
          {isPanelMember ? (
            memberPermissions &&
            memberPermissions.length > 0 &&
            memberPermissions.includes("per_del_role") && (
              <ConfirmAlert
                onClickEvent={() => handleDelete(role.roleGuid)}
                msg={"Are you sure?"}
              >
                <IconButton>
                  <Delete />
                </IconButton>
              </ConfirmAlert>
            )
          ) : (
            <ConfirmAlert
              onClickEvent={() => handleDelete(role.roleGuid)}
              msg={"Are you sure?"}
            >
              <IconButton>
                <Delete />
              </IconButton>
            </ConfirmAlert>
          )}
        </Stack>,
      ])
    );

  return (
    <>
      <AnimatedPage>
        <TopHeading
          parentPage={"roles"}
          openModal={handleOpenModal}
          heading={"Roles"}
        />
        <ConfRole
          open={openModal}
          type="ADD"
          setSuccess={setSuccess}
          setError={setError}
          hadnleCloseModal={handleCloseModal}
          featurePermissions={featurePermissions}
          featureList={featureAndContents}
        />
        <ConfRole
          open={openEditModal}
          type="EDIT"
          editId={editId}
          selectedItem={selectedItem}
          setSuccess={setSuccess}
          setError={setError}
          hadnleCloseModal={handleCloseEditModal}
          featurePermissions={featurePermissions}
          featureList={featureAndContents}
        />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedRoles = Hoc(Roles, { name: "roles" });
