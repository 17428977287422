import React, { useEffect, useState } from 'react'
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { SnackBarAlert } from "../../../utils/SnackBar";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import { getPanelistById } from "../panelistDetail.api";
import PanelistForm from './panelistForm';
const EditPanelist = () => {
  const [alert, setAlert] = useState(false);
  const location = useLocation();
  const [editData, setEditData] = useState({});
  const [fetchingdata, setFetchingdata] = useState(false);
  const [panelistResdata, setPanelistResdata] = useState({});

  useEffect(() => {
    const panelistId = parseInt(location.pathname.split('/')[3]);
    if (panelistId) {
      setFetchingdata(true);
      getPanelistById(panelistId)
        .then((res) => {
          if (res?.status === 200) {
            console.log("panelist edit data", res.data.data[0]);
            setEditData(res.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          setAlert("Somthing went wrong");
        })
        .finally(() => setFetchingdata(false));
    }
  }, [location, panelistResdata]);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  return (
    <>
      <TopHeading
        link={"/admin/panelists"}
        heading={"Panelist Detail"}
        showButton={Boolean(false)}
      />
      <Box sx={{ mx: 4, mt: 4 }}>
        {
          (fetchingdata ? (<CircularProgress className="loader" />) : (
            <PanelistForm
              setAlert={setAlert}
              setPanelistResdata={setPanelistResdata}
              editData={editData}
              type={"EDIT"}
            />
          ))}
      </Box>
      <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
    </>
  )
}

export default EditPanelist
