import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SideBarItem from "./SibeBarItem";
import { homeRoutes } from "../../../src/routes";
import { useDispatch, useSelector } from "react-redux";
import { setShowSideBar } from "../../features/sideBarSlice";
import { logout } from "../../features/authSlice";
import Person2Icon from "@mui/icons-material/Person2";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import {
  getAllPanelAdmins,
  getPanelsofMember,
} from "../../components/managePanel/managePanel.api";
import { setPanelSidebarItems } from "../../features/panelSidebarItemSlice";
import LoggedInAdminProfile from "../../components/loggedInAdminProfile/LoggedInAdminProfile";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 1),
  minHeight: 'auto'
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NewSideBar() {
  const dispatch = useDispatch();
  const tenantId = useSelector((state) => state.auth.userData?.tenantId);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const [routes, setroutes] = React.useState([]);
  const [routesExtra, setRoutesExtra] = React.useState([]);
  const [children, setChildren] = React.useState([]);
  const [childrenRoutes, setChildrenRoutes] = React.useState([]);
  // const panelAdminAdded = useSelector((state) => state.panelSideBarItems?.panelAdminAdded);

  const mainRoleIds = [1, 2, 3, 4];
  React.useEffect(() => {
    if (roleId && !mainRoleIds.includes(roleId)) {
      getPanelsofMember(adminId).then((res) => {
        if (res?.status === 200) {
          setChildren(res.data.data);
        }
      });
    }
    return () => {
      setChildren([]);
      setChildrenRoutes([]);
    };
  }, [roleId]);

  React.useEffect(() => {
    if (children && children.length > 0) {
      let duplicate = [...children];
      let childrenRoutesDup = [];
      duplicate.map((item) =>
        childrenRoutesDup.push({
          path: `/admin/panel/${item.member.adminId}`,
          name: `${item.member.firstName}`,
          icon: "bi bi-app",
        })
      );
      if (childrenRoutesDup && childrenRoutesDup.length > 0) {
        setChildrenRoutes(childrenRoutesDup);
        dispatch(setPanelSidebarItems(childrenRoutesDup));
      }
    }

    return () => {
      setChildrenRoutes([]);
    };
  }, [children]);

  React.useEffect(() => {
    if (homeRoutes && homeRoutes.length > 0) {
      setroutes(homeRoutes);
    }
    return () => {
      setroutes([]);
    };
  }, [homeRoutes]);

  React.useEffect(() => {
    if (routes && routes.length > 0) {
      let duplicate = [...routes];
      if (childrenRoutes && childrenRoutes.length > 0) {
        const itemIndex = duplicate.findIndex(
          (item) => item.name === "Assigned Panels"
        );
        if (itemIndex) {
          duplicate[itemIndex]["children"] = childrenRoutes;
        }
      }
      setRoutesExtra(duplicate);
    }
    return () => {
      setRoutesExtra([]);
    };
  }, [routes, childrenRoutes]);
  const theme = useTheme();
  const open = useSelector((state) => state.sideBar.sideBarOpen);

  const handleDrawerOpen = () => {
    dispatch(setShowSideBar());
  };

  const handleDrawerClose = () => {
    dispatch(setShowSideBar());
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer className="snackbar" variant="permanent" open={open}>
        <DrawerHeader>
          {open && <LoggedInAdminProfile sx={{ my:2, width: '90%' }} />}
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        {routesExtra &&
          routesExtra.length > 0 &&
          routesExtra.map((route, index) => (
            <SideBarItem open={open} route={route} key={index} />
          ))}
      </Drawer>
    </Box>
  );
}

// {/* <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{
//               marginRight: 5,
//               ...(open && { display: 'none' }),
//             }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <div
//             style={{ marginLeft: 'auto' }}>
//             <IconButton
//               color="inherit"
//               aria-label="open menu"
//               onClick={handleClick}
//               edge="end"
//               sx={{
//                 marginLeft: 'auto',
//                 // ...(open && { display: 'none' }),
//               }}
//             >
//               <Person2Icon />
//             </IconButton>
//           </div>
//           <Menu
//             id="demo-positioned-menu"
//             aria-labelledby="demo-positioned-button"
//             anchorEl={anchorEl}
//             open={openMenu}
//             onClose={handleClose}
//             sx={{
//               marginTop: '35px'
//              }}
//             anchorOrigin={{
//               vertical: 'top',
//               horizontal: 'left',
//             }}
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'left',
//             }}
//           >
//             <MenuItem onClick={handleClose}>
//               <Link className='rm-text-decor' to='profile'>Profile</Link>
//             </MenuItem>
//             {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
//             <MenuItem onClick={handleLogout}>Logout</MenuItem>
//           </Menu>
//           {/* <Typography variant="h6" noWrap component="div">
//             Mini variant drawer
//           </Typography> */}
//         </Toolbar>
//       </AppBar> */}
