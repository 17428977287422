import {
  Create,
  Delete,
  RemoveRedEye,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
// import { getActivepanels } from "../managePanel/managePanel.api";
import { getActivepanels } from "./miniSurvey.api";
import ConfMiniSurvey from "./ConfMiniSurvey";
import { deleteMiniSurvey, getSurveyByPanelId } from "./miniSurvey.api";
// import ViewCountryLanguageModal from "./ViewCountryLanguageModal";

const MiniSurvey = (props) => {

    const {
      data,
      loading,
      page,
      setPage,
      pageCount,
      handleOpenModal,
      handleCloseModal,
      openModal,
      setSuccess,
      setError,
      openEditModal,
      handleOpenEditModal,
      handleCloseEditModal,
      setAlert,
      alert,
      closeAlert,
      showPagination,
      setShowPagination,
      hidePagination,
    } = props;

    //redux
    const adminId = useSelector((state) => state.auth.userData?.adminId);
    const isPanelMember = useSelector(
      (state) => state.panelMemberPermissions.isPanelMember
    );
    const memberPermissions = useSelector(
      (state) => state.panelMemberPermissions.memberPermissions
    );

    //states
    const [activePanels, setActivePanels] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState({});
    const [pageloading, setPageloading] = useState(false);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [minisurveys, setminisurveys] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedPanelId, setselectedPanelId] = useState();
    const [editId, seteditId] = useState({});
    const [miniSurveyCode, setminiSurveyCode] = useState();
    const [selectedMiniSurveyName, setselectedMiniSurveyName] = useState();
    const [selectedMiniSurveyPoints, setselectedMiniSurveyPoints] = useState();
    const [selectedMiniSurveyLoi, setselectedMiniSurveyLoi] = useState();

    //data
const rows = [];
const labels = [
    "Mini Survey Name",
    "Mini Survey Code",
    "Points",
    "LOI",
    "Actions",
];

    //functions
    const handleDropDownChange = (value, dropDownName) => {
      switch (dropDownName) {
        case "PANEL":
          console.log("selected panel", value);
          setSelectedPanel(value);
          break;

        default:
          break;
      }
    };

    useEffect(() => {
      getActivepanels(adminId)
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setActivePanels(data);
            setSelectedPanel(data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return () => {
        setActivePanels([]);
        setSelectedPanel({});
        setDataUpdated(false);
      };
    }, [adminId]);

    useEffect(() => {
      if (selectedPanel) {
        setPageloading(true);
        getSurveyByPanelId(selectedPanel?.panelId)
          .then((res) => {
            if (res?.status === 200) {
              setminisurveys(res.data.data);
            }
          })
          .catch((err) => {
            console.log("err", err.message);
          })
          .finally(() => setPageloading(false));
      }
      return () => {
        setminisurveys([]);
      };
    }, [selectedPanel, dataUpdated]);

    const handleDeleteSurvey = (id) => {
      console.log("id", id);
      setDataUpdated(false);
      if (id) {
        deleteMiniSurvey(id)
          .then((res) => {
            if (res?.status === 200) {
              setAlert("Successfully deleted");
              setDataUpdated(true);
            }
          })
          .catch((err) => {
            setAlert("Something went wrong");
          });
      }
    };

    //Populating minisurvay tables
    minisurveys &&
      minisurveys.length > 0 &&
      minisurveys.map((item) =>
        rows.push([
          item.miniSurveyName,
          item.miniSurveyCode,
          item.points,
          item.loi,
          <Stack direction={"row"}>
            {/* EDIT ------------------ */}
            <IconButton
              onClick={() => {
                seteditId(item.miniSurveyId);
                setselectedPanelId(item.panelId)
                setminiSurveyCode(item.miniSurveyCode);
                setselectedMiniSurveyName(item.miniSurveyName);
                setselectedMiniSurveyPoints(item.points);
                setselectedMiniSurveyLoi(item.loi);
                let str = item.loi;
        let words = str.split(" ");
        words.pop();
        let newStr = words.join(" ");
        console.log(newStr);
        item.loi = parseInt(newStr)
                setSelectedItem(item);
                handleOpenEditModal();
              }}
            >
              <Create />
            </IconButton>

            {/* DELETE ------------------------- */}
            {isPanelMember ? (
              memberPermissions &&
              memberPermissions.length > 0 &&
              memberPermissions.includes("per_del_svy") && (
                <ConfirmAlert
                  msg={"Are you sure you want to delete?"}
                  onClickEvent={() => handleDeleteSurvey(item.miniSurveyId)}
                >
                  <IconButton>
                    <Delete />
                  </IconButton>
                </ConfirmAlert>
              )
            ) : (
              <ConfirmAlert
                msg={"Are you sure you want to delete?"}
                onClickEvent={() => handleDeleteSurvey(item.miniSurveyId)}
              >
                <IconButton>
                  <Delete />
                </IconButton>
              </ConfirmAlert>
            )}
          </Stack>,
        ])
      );

  return (
    <>
      <AnimatedPage>
        <TopHeading heading="Mini Survey" openModal={handleOpenModal} />
        <Stack sx={{ mx: 4, my: 2 }}>
          <AsyncDropdown
            handleChange={(value) => handleDropDownChange(value, "PANEL")}
            value={selectedPanel}
            options={activePanels}
            objKey={"panelName"}
            stylized
            label={"Select Panel"}
          />
        </Stack>
        <ConfMiniSurvey
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <ConfMiniSurvey
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedPanelId={selectedPanelId}
          selectedItem={selectedItem}
          miniSurveyCode={miniSurveyCode}
          selectedMiniSurveyName={selectedMiniSurveyName}
          selectedMiniSurveyPoints={selectedMiniSurveyPoints}
          selectedMiniSurveyLoi={selectedMiniSurveyLoi}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <CustomTable labels={labels} data={rows} pageLoading={pageloading} />
      </AnimatedPage>
    </>
  );
}

export const EnhancedMiniSurvey = Hoc(MiniSurvey, { name: "" });