import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import { SnackBarAlert } from "../../../utils/SnackBar";
import TableWithSelect from "../../../utils/tableWithSelect/TableWithSelect";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import { getActiveCategories } from "../../category/category.api";
import { getCountryWithLanguage } from "../../country/country.api";
import { getActivepanels } from "../../managePanel/managePanel.api";
import { questions } from "../data";
import {
  createQuestionProfile,
  getCategoryByPanelId,
  getCountryBypanelId,
  getlanguageByCountryId,
  getPanelQuestionForCreation,
  getAllMiniSurveys,
} from "../userProfileQuestionaire.api";
import FilterQuesions from "./FilterQuesions";

export const dropDowns = {
  LANGUAGE: "language",
  PANEL: "panel",
  COUNTRY: "country",
};

const AddProfileQuestionaire = () => {
  const [alert, setAlert] = useState(false);
  const [panels, setpanels] = useState([]);
  const [countries, setcountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [languages, setlanguages] = useState([]);
  const [countryWithLanguage, setCountryWithLanguage] = useState([]);
  const [categories, setcategories] = useState([]);
  const [miniSurveys, setminiSurveys] = useState([]);
  const [selectedMiniSurvey, setselectedMiniSurvey] = useState();
  // var miniSurveyIdData = [];

  const [selectedCountryWithLanguage, setSelectedCountryWithLanguage] =
    useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedlanguage, setselectedlanguage] = useState({});
  const [selectedpanel, setSelectedpanel] = useState({});

  const [dbSelectedQuestions, setdbSelectedQuestions] = useState([]);
  const [dbNotSelectedQuestions, setdbNotSelectedQuestions] = useState([]);

  const [tenantQuestions, settenantQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [deSelectedQuestions, setDeselectedQuestions] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [loading, setLoading] = useState(false);
  const [removedCategory, setRemovedCategory] = useState({});
  const [tableLoading, setTableLoading] = useState(false);

  const [miniSurveyIdData, setminiSurveyIdData] = useState([]);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          console.log("active panels", res.data.data);
          setpanels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getActiveCategories()
      .then((res) => {
        if (res?.status === 200) {
          setcategories(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("err", err.messsage);
      });
    return () => {
      setpanels([]);
      setSelectedpanel({});
    };
  }, [adminId]);

  // get countries and category for the selected panel
  useEffect(() => {
    if (selectedpanel?.panelId) {
      setSelectedCategories([]);
      setSelectedCountryWithLanguage([]);
      setSelectedCountry({});
      getCountryBypanelId(selectedpanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setcountries(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getAllMiniSurveys(selectedpanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            setminiSurveys(res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err.messsage);
        });
    }
    return () => {
      setcountries([]);
      // settenantQuestions([]);
      setminiSurveys([]);
    };
  }, [selectedpanel]);

  // get languages by country id
  useEffect(() => {
    if (selectedCountry?.countryId) {
      setSelectedCountryWithLanguage([]);
      getlanguageByCountryId(selectedCountry?.countryId)
        .then((res) => {
          if (res?.status === 200) {
            setCountryWithLanguage(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err.messsage);
        });
    }
    return () => {
      setCountryWithLanguage([]);
    };
  }, [selectedCountry]);

  const getCategoryObj = (id) => {
    if (categories?.length > 0) {
      const item = categories?.find((item) => item?.categoryId === id);
      return item;
    }
    return null;
  };

  const getMiniSurveyObj = (id) => {
    if (miniSurveys?.length > 0) {
      const item = miniSurveys?.find((item) => item?.miniSurveyId === id);
      return item;
    }
    return null;
  };

  useEffect(() => {
    console.log("removed category", removedCategory);
    let questionsToBeRemoved = [];
    let finalQuestions = [];
    if (tenantQuestions.length > 0) {
      let duplicate = [...tenantQuestions];
      for (let i = 0; i < duplicate.length; i++) {
        if (
          Number(duplicate[i].questionCatId) ===
          Number(removedCategory.categoryId)
        ) {
          questionsToBeRemoved.push(duplicate[i].panelQsnId);
        } else {
          finalQuestions.push(duplicate[i]);
        }
      }
    }

    settenantQuestions(finalQuestions);

    console.log("before", checkedQuestions);
    let duplicateCheckedQuestions = [...checkedQuestions];
    console.log("questions to be removed", questionsToBeRemoved);
    const filteredArray = duplicateCheckedQuestions.filter(function (x) {
      return questionsToBeRemoved.indexOf(x) < 0;
    });
    console.log("after", filteredArray);
    setCheckedQuestions([...filteredArray]);
  }, [removedCategory]);

  // useEffect(() => {
  //   if (tenantQuestions && tenantQuestions.length > 0) {
  //     let duplicate = [...dbSelectedQuestions];
  //     let duplicateNot = [...dbNotSelectedQuestions];
  //     // get the ids of the selected and not selected questions coming from the database
  //     tenantQuestions.map((item) => {
  //       if (item.isSelected) {
  //         if (duplicate.includes(Number(item.id))) return;
  //         duplicate.push(Number(item.id));
  //       }
  //       if (!item.isSelected) {
  //         if (duplicateNot.includes(Number(item.id))) return;
  //         duplicateNot.push(Number(item.id));
  //       }
  //     });

  //     setdbSelectedQuestions(duplicate);
  //     setdbNotSelectedQuestions(duplicateNot);
  //     setCheckedQuestions(duplicate);
  //     setDeselectedQuestions(duplicateNot);
  //   }
  //   return () => {
  //     setCheckedQuestions([]);
  //     setDeselectedQuestions([]);
  //     setdbSelectedQuestions([]);
  //     setdbNotSelectedQuestions([]);
  //   };
  // }, [tenantQuestions]);

  // const handleCheckBoxChange = (e, value, id, index) => {
  //   let duplicatePanelQuestions = [...tenantQuestions];
  //   duplicatePanelQuestions[index]["isSelected"] = value;
  //   settenantQuestions(duplicatePanelQuestions);

  //   let duplicaiteCheckedQuestions = [...checkedQuestions];
  //   let duplcaiteDeSelectedQuestions = [...deSelectedQuestions];

  //   // if check box value is true
  //   if (value) {
  //     // check if the id already conatins in the array
  //     if (duplicaiteCheckedQuestions.includes(id)) return;
  //     // if not
  //     duplicaiteCheckedQuestions.push(id);

  //     // remove the element from deselected array
  //     const index = duplcaiteDeSelectedQuestions.findIndex((i) => i === id);
  //     duplcaiteDeSelectedQuestions.splice(index, 1);
  //   }

  //   // vice versa
  //   if (!value) {
  //     if (duplcaiteDeSelectedQuestions.includes(id)) return;
  //     duplcaiteDeSelectedQuestions.push(id);

  //     const index = duplicaiteCheckedQuestions.findIndex((i) => i === id);
  //     duplicaiteCheckedQuestions.splice(index, 1);
  //   }
  //   setCheckedQuestions(duplicaiteCheckedQuestions);
  //   setDeselectedQuestions(duplcaiteDeSelectedQuestions);
  // };

  const handleCheckBoxChange = (e, value, id, index) => {
    let duplicatePanelQuestions = [...tenantQuestions];
    duplicatePanelQuestions[index]["isSelected"] = value;
    settenantQuestions(duplicatePanelQuestions);

    let duplicaiteCheckedQuestions = [...checkedQuestions];
    let duplcaiteDeSelectedQuestions = [...deSelectedQuestions];

    // if check box value is true
    if (value) {
      // check if the id already conatins in the array
      if (duplicaiteCheckedQuestions.includes(id)) return;
      // if not
      duplicaiteCheckedQuestions.push(id);

      // remove the element from deselected array
      const index = duplcaiteDeSelectedQuestions.findIndex((i) => i === id);
      duplcaiteDeSelectedQuestions.splice(index, 1);
    }

    // vice versa
    if (!value) {
      if (duplcaiteDeSelectedQuestions.includes(id)) return;
      duplcaiteDeSelectedQuestions.push(id);

      const index = duplicaiteCheckedQuestions.findIndex((i) => i === id);
      duplicaiteCheckedQuestions.splice(index, 1);
    }

    setCheckedQuestions(duplicaiteCheckedQuestions);
    setDeselectedQuestions(duplcaiteDeSelectedQuestions);
  };

  // const handleSubmit = () => {
  //   console.log("db seleted questions", dbSelectedQuestions);
  //   console.log("db not seleted questions", dbNotSelectedQuestions);

  //   console.log("seleted questions", checkedQuestions);
  //   console.log("not seleted questions", deSelectedQuestions);

  //   // take only those ids which where not selected in the database
  //   const finalSelecttion = checkedQuestions.filter((i) =>
  //     dbNotSelectedQuestions.includes(i)
  //   );

  //   // take only those ids which where selected in the database
  //   const finalDeSelecttion = deSelectedQuestions.filter((i) =>
  //     dbSelectedQuestions.includes(i)
  //   );

  //   let data = {
  //     // panelid: selectedPanel.panelId,
  //     seletedQuestions: [...finalSelecttion],
  //     deSelectedQuestions: [...finalDeSelecttion],
  //   };

  //   console.log("data", data);
  // };

  // useEffect(() => {
  //   if (checkedQuestions && checkedQuestions.length > 0) {
  //     if (tenantQuestions.length > 0) {

  //       settenantQuestions(duplicate);
  //     }
  //   }
  // }, [tenantQuestions]);

  const handleSubmit = () => {
    if (!selectedpanel?.panelId) {
      setAlert("Select panel");
      return;
    }
    if (!selectedCountry?.countryId) {
      setAlert("Select Country");
      return;
    }
    if (!selectedlanguage?.languageId) {
      setAlert("Select Language");
      return;
    }

    // if (selectedCountryWithLanguage?.length <= 0) {
    //   setAlert("Select atleast one language");
    //   return;
    // }
    // let selectedLanguageIds = [];

    // selectedCountryWithLanguage?.map((item) => {
    //   selectedLanguageIds.push(Number(item.languageId));
    // });

    if (selectedCategories.length <= 0) {
      setAlert("Select atleast one category");
      return;
    }
    let selectedCatIds = [];
    selectedCategories?.map((item) => {
      selectedCatIds.push(item?.categoryId);
    });

    if (checkedQuestions.length <= 0) {
      setAlert("Select atleast one question");
      return;
    }

    const questionData = [];

    let duplicateQuestions = [...tenantQuestions];
    let dupCheckedQuestions = [...checkedQuestions];

    duplicateQuestions.map(
      (item, index) =>
        (duplicateQuestions[index]["miniSurveyKey"] =
          miniSurveyIdData[index] === -1 ? null : miniSurveyIdData[index])
    );

    for (let i = 0; i < duplicateQuestions.length; i++) {
      for (let j = 0; j < dupCheckedQuestions.length; j++) {
        if (duplicateQuestions[i].panelQsnId === dupCheckedQuestions[j]) {
          questionData.push(duplicateQuestions[i]);
        }
      }
    }

    console.log("data", questionData);

    const finalQuestionData = [];
    questionData?.map((item, index) => {
      if (item?.isSelected) {
        finalQuestionData.push({
          masterQsnId: item?.masterQsnId,
          categoryId: item?.questionCatId,
          miniSurveyId: item?.miniSurveyKey,
          isRequired: item?.isRequired ? true : false,
        });
      }
    });

    const data = {
      panelId: selectedpanel?.panelId,
      countryId: selectedCountry.countryId,
      languageId: selectedlanguage?.languageId,
      // categoryId: selectedCatIds,
      questionConfig: [ ...finalQuestionData ],
      createdBy: adminId,
    };

    setLoading(true);
    createQuestionProfile(data)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          console.log("success");
          setAlert("Successfully created");
          settenantQuestions([]);
          setCheckedQuestions([]);
          setSelectedCategories([]);
          setlanguages([]);
          // setSelectedCountryWithLanguage([]);
          setSelectedCountry({});
        } else {
          if (res.response.data.message === "Profile Already Created") {
            setAlert("Profile already exists");
          } else {
            setAlert("Something went wrong");
          }
        }
      })
      .catch((err) => {
        setAlert("Something went wrong");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const labels = ["", "Question", "Mini Survey", "Category", "Type", "Requied"];

  const rows = [];

  const handleDropDownChange = (value, dropDownName) => {
    console.log(value);
    switch (dropDownName) {
      case dropDowns.LANGUAGE:
        setselectedlanguage(value);
        break;
      case dropDowns.PANEL:
        setSelectedpanel(value);
        break;
      case dropDowns.COUNTRY:
        setSelectedCountry(value);
        break;

      default:
        break;
    }
  };

  const handleFetchFilterQuestions = () => {
    // console.log("Clicked");
    // const panelId = selectedpanel?.panelId;
    // const categoryId = selectedCategories;
    // const countryLangId = selectedlanguage?.countryLangId;

    // let selectedCategiryIds = [];

    // if (selectedCategories && selectedCategories.length > 0) {
    //   selectedCategories.map((item) => {
    //     selectedCategiryIds.push(item.categoryId);
    //   });
    // }

    // console.log("selected categories", selectedCategiryIds);

    // let paramString = "";
    // selectedCategiryIds.map((item) => {
    //   paramString = `${paramString}categoryId=${item}&`;
    // });

    // const finalParams = `panelId=${panelId}&countryLangId=2&${paramString}`;
    // console.log("params", finalParams.slice(0, -1));

    // get questions

    if (selectedCategories.length > 0) {
      let catIds = [];
      selectedCategories.map((item) => {
        catIds?.push(Number(item.categoryId));
      });
      setTableLoading(true);
      getPanelQuestionForCreation(catIds)
        .then((res) => {
          if (res?.status === 200) {
            let arr = [];
            for (var i = 0; i < res.data.data.length; i++) {
              arr.push(-1);
            }
            setminiSurveyIdData(arr);
            console.log("questions", res.data.data);
            if (checkedQuestions.length > 0) {
              const { data } = res.data;
              let duplicate = [...data];
              if (data?.length > 0) {
                for (let i = 0; i < checkedQuestions.length; i++) {
                  for (let j = 0; j < duplicate.length; j++) {
                    if (
                      Number(checkedQuestions[i]) ===
                      Number(duplicate[j].panelQsnId)
                    ) {
                      duplicate[j]["isSelected"] = true;
                    }
                  }
                }
              }
              settenantQuestions(duplicate);
            } else {
              settenantQuestions(res.data.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setAlert("Error fetching questions. Please try again");
        })
        .finally(() => setTableLoading(false));
    } else {
      return;
    }
  };

  useEffect(() => {
    console.log(miniSurveyIdData);
  }, [miniSurveyIdData]);

  const questionCategory = [];
  const handleQuestionCategoryDropDownChange = (value, index) => {
    // console.log("value", value?.categoryId);
    let duplicate = [...tenantQuestions];
    duplicate[index]["questionCatId"] = value?.categoryId;
    settenantQuestions(duplicate);
  };

  const handleMiniSurveyDropDownChange = (value, index) => {
    setselectedMiniSurvey(value);
    console.log("index", value);

    let duplicate = [...miniSurveyIdData];
    duplicate[index] = value.miniSurveyId;
    setminiSurveyIdData(duplicate);
    console.log("miniSurveyIdData", miniSurveyIdData);
  };

  const handleClickRequired = (e, value, index) => {
    let duplicate = [...tenantQuestions];
    console.log("check", value);
    duplicate[index]["isRequired"] = value;
    settenantQuestions(duplicate);
  };

  // useEffect(() => {
  //   console.log("tenant questions", tenantQuestions);
  //   // for(var i = 0; i < tenantQuestions.length; i++) {
  //   //   miniSurveyIdData.push(-1);
  //   // }
  // }, [tenantQuestions]);

  tenantQuestions.map((item, index) =>
    rows.push([
      <Checkbox
        checked={Boolean(item.isSelected)}
        value={item.id}
        onChange={(e, value) =>
          handleCheckBoxChange(e, value, item.panelQsnId, index)
        }
      />,
      item.qsnShortForm,
      //MINI SURVEY DROPDOWN
      <AsyncDropdown
        fullWidth={false}
        handleChange={(value) => handleMiniSurveyDropDownChange(value, index)}
        value={getMiniSurveyObj(item?.miniSurveyId)}
        objKey={"miniSurveyName"}
        options={miniSurveys}
        small
        label={"Select Mini Survey"}
      />,
      <AsyncDropdown
        fullWidth={false}
        handleChange={(value) =>
          handleQuestionCategoryDropDownChange(value, index)
        }
        value={getCategoryObj(item?.questionCatId)}
        objKey={"category"}
        options={categories}
        small
        label={"Select Category"}
      />,
      item?.questionType,
      <Checkbox
        checked={Boolean(item?.isRequired)}
        onChange={(e, value) => handleClickRequired(e, value, index)}
      />,
    ])
  );

  return (
    <Box sx={{ px: 2 }}>
      <TopHeading
        link={"/admin/user-profile-questionaire"}
        showButton={false}
        heading={"Add User Profile  Questionaire"}
      />
      <FilterQuesions
        panels={panels}
        categories={categories}
        countries={countries}
        countryWithLanguage={countryWithLanguage}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        handleDropDownChange={handleDropDownChange}
        selectedlanguage={selectedlanguage}
        selectedpanel={selectedpanel}
        handleSearch={handleFetchFilterQuestions}
        setSelectedCountryWithLanguage={setSelectedCountryWithLanguage}
        selectedCountryWithLanguage={selectedCountryWithLanguage}
        selectedCountry={selectedCountry}
        setRemovedCategory={setRemovedCategory}
        tableLoading={tableLoading}
      />
      <CustomTable auto data={rows} labels={labels} />
      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{ display: "block", ml: "auto", mt: 1, mr: 4 }}
        onClick={handleSubmit}
      >
        Save
      </LoadingButton>
      <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
    </Box>
  );
};

export default AddProfileQuestionaire;
