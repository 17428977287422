import { createSlice } from '@reduxjs/toolkit'
import React from 'react'

const initialState = {
    panelSidebarItems: [],
    panelAdminAdded: false,
}
const panelSideBarItemSlice = createSlice({
    name: "panelSideBarItems",
    initialState,
    reducers:{
        setPanelSidebarItems: (state, {payload}) => {
            state.panelSidebarItems = payload
        },
        setPanelAdminAdded: (state) => {
            state.panelAdminAdded = true
        }
    }
}) 
export const {setPanelSidebarItems, setPanelAdminAdded} = panelSideBarItemSlice.actions
export default panelSideBarItemSlice.reducer