import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const MultipleValueDropDown = (props) => {
  const {
    label,
    options,
    objkey,
    childobjkey,
    onChange,
    removeCheck,
    setRemovedCategory,
    stylized,
    backUpKey,
  } = props;

  const [dropDownOptions, setDropDownOptions] = useState([]);
  useEffect(() => {
    if (options && options.length > 0) {
      setDropDownOptions([...options]);
    }
    return () => {
      setDropDownOptions([]);
    };
  }, [options]);

  const handleChange = (e, newvalue, reason, detail) => {
    onChange(newvalue);
    if (reason === "removeOption") {
      if (removeCheck) {
        setRemovedCategory(detail.option);
      }
    }
  };

  const dropDownStyles = React.useMemo(() => {
    return {
      background: "#FFFFFF",
      borderRadius: "10px",
      width: props.width ? 220 : "100%",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        border: stylized && "2px solid #F0F0F0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        border: stylized && "2px solid #F0F0F0",
      },
    };
  }, [stylized]);

  return (
    <Autocomplete
      sx={{ width: "100%", ...props.sx }}
      style={dropDownStyles}
      multiple
      size={props.small && "small"}
      value={props.value}
      onChange={handleChange}
      id="tags-outlined"
      options={dropDownOptions}
      disableCloseOnSelect
      getOptionLabel={(option) =>
        childobjkey
          ? option[objkey][childobjkey]
          : option[objkey] || option[backUpKey]
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.helperText}
          label={label}
          placeholder={label}
        />
      )}
    />
  );
};

export default MultipleValueDropDown;
