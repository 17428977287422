import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { addCategory, updateCategory } from "./category.api";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getAllLanguages } from "../language/language.api";
import { getAllPanel } from "../panel/panel.api";

const ConfCategory = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    panels,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const [displayText, setDisplayText] = useState("");
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedPanel, setSelectedPanel] = useState({});
  const [categoryData, setcategoryData] = useState({
    category: "",
    languageId: null,
    createdBy: adminId,
  });

  useEffect(() => {
    if (open) {
      getAllLanguages()
        .then((res) => {
          if (res?.status === 200) {
            setLanguages(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      // getAllPanel
    }
    if (editId) {
      if (type === "EDIT" || type === "TRANSLATE") {
        if (selectedItem) {
          console.log("selected item", selectedItem);
          setcategoryData(selectedItem);
        }
      }
    }
    return () => {
      setLanguages([]);
      setcategoryData({
        category: "",
        languageId: null,
        createdBy: adminId,
      });
      setDisplayText("");
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setcategoryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    let catType;
    if (type === "ADD") {
      let data = { ...categoryData };
      data["languageId"] = 1;
      callMethod = addCategory(data);
    } else if (type === "TRANSLATE") {
      let data = {};
      data = {
        category_Id: editId,
        category: displayText,
        createdBy: adminId,
        languageId: 1,
      };
      callMethod = addCategory(data);
    } else {
      let data = {
        ...categoryData,
        updatedBy: adminId,
      };
      callMethod = updateCategory(editId, data);
    }

    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
          console.log(res);
          setSuccess();
          if (type === "TRANSLATE") {
            hadnleCloseModal();
          }
          setcategoryData({
            category: "",
            languageId: null,
            createdBy: adminId,
          });
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      })
      .finally(setLoading(false));
  };

  // const handleDropDownChange = (value) => {
  //   if (value && value.id) {
  //     setSelectedLanguage(value);
  //     setcategoryData((prev) => ({
  //       ...prev,
  //       languageId: value.id,
  //     }));
  //   }
  // };

  const handleDropDownChange = (objKey, value, idName) => {
    console.log("*******");
    console.log(objKey);
    console.log(value);
    console.log(idName);
    console.log("*******");
    switch (objKey) {
      case "panel":
        setSelectedPanel(value);
        break;
      case "language":
        setSelectedLanguage(value);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"sm"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>
                {type === "ADD" && "Add Category"}
                {type === "EDIT" && "Edit Category"}
                {type === "TRANSLATE" && "Category translation"}
              </h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                <Stack direction={"column"} spacing={2}>
                  <TextField
                    required
                    disabled={type === "TRANSLATE" ? true : false}
                    onChange={handleOnInputchange}
                    value={categoryData.category}
                    fullWidth
                    label="Name"
                    name="category"
                  />
                  {type === "TRANSLATE" && (
                    <>
                      <AsyncDropdown
                        value={selectedPanel}
                        label={"Select Panel"}
                        objKey={"firstName"}
                        options={panels}
                        handleChange={(value) =>
                          handleDropDownChange("panel", value, "panelId")
                        }
                      />
                      <AsyncDropdown
                        value={selectedLanguage}
                        label={"Select Country"}
                        objKey={"language"}
                        options={languages}
                        handleChange={(value) =>
                          handleDropDownChange("country", value, "id")
                        }
                      />
                      <AsyncDropdown
                        value={selectedLanguage}
                        label={"Select language"}
                        objKey={"language"}
                        options={languages}
                        handleChange={(value) =>
                          handleDropDownChange("language", value, "id")
                        }
                      />
                    </>
                  )}
                </Stack>
                {type === "TRANSLATE" && (
                  <Stack direction={"column"} spacing={2}>
                    <TextField
                      required
                      onChange={(e) => setDisplayText(e.target.value)}
                      value={displayText}
                      fullWidth
                      label="Display Text"
                      name="display_category"
                    />
                  </Stack>
                )}
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfCategory;
