import axios from "axios";

export const fetchAllCountry = async () => {
  try {
    const res = await axios.get("/api/systemUser/getAllCountry");
    return res;
  } catch (error) {
    return error;
  }
};
export const getCountryById = async (id) => {
  try {
    const res = await axios.get(`/api/systemUser/getCountry/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const addCountry = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createcountry", data);
    console.log("added", res);
    return res;
  } catch (error) {
    return error;
  }
};

export const updateCountry = async (id, data) => {
  try {
    const res = await axios.patch(`/api/systemUser/updateCountry/${id}`, data);
    return res;
  } catch (error) {
    console.log("update country failed", error);
  }
};

export const deleteCountry = async (id) => {
  try {
    const res = await axios.delete(`/api/systemUser/deleteCountry/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTenantCountry = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getTenantCountry?tenantId=${id}`
    );
    return res;
  } catch (error) {
    console.log("errpt fetching tenant country");
  }
};

export const getActiveCountries = async () => {
  try {
    const res = await axios.get("/api/systemUser/getActiveCountry");
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const getCountryWithLanguage = async () => {
  try {
    const res = await axios.get(
      `/api/systemUser/getCountryLanguage?panelId=${17}`
    );
    return res;
  } catch (error) {
    console.log("error fetching country and language", error.message);
  }
};
