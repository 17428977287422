import { createSlice } from '@reduxjs/toolkit'
import React from 'react'

const initialState = {
    sideBarOpen: false,
    drawerWidth: 300,
}
const sideBarSlice = createSlice({
    name: "sideBar",
    initialState,
    reducers:{
        setShowSideBar: (state) => {
            state.sideBarOpen = !state.sideBarOpen
        }
    }
}) 
export const {setShowSideBar} = sideBarSlice.actions
export default sideBarSlice.reducer