import { Logout } from "@mui/icons-material";
import { Box, Button, IconButton, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/authSlice";
import { setPanelAdminAdded } from "../../features/panelSidebarItemSlice";
import FilterDropDown from "../filterDropDown/FilterDropDown";
import SearchBar from "../SearchBar/SearchBar";
import "./index.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";

const TopHeading = (props) => {
  const dispatch = useDispatch();
  const {
    heading,
    openModal,
    showSearchBar,
    showButton = true,
    setSearchQuery,
    searchQuery,
    options,
    handleAddQualification,
    nextPageBtn,
  } = props;

  const themeStyles = useMemo(() => {
    return {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 22px",
      alignItems: "center",
      margin: "5px 20px 15px 20px",
      borderRadius: "0.5rem",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  let code = "";
  switch (props.parentPage) {
    case "master-questions":
      code = "per_cr_pf_qsn";
      break;
    case "roles":
      code = "per_cr_role";
      break;
    case "survey":
      code = "per_cr_svy";
      break;

    default:
      break;
  }

  const isPanelMember = useSelector(
    (state) => state.panelMemberPermissions?.isPanelMember
  );
  const memberPermissions = useSelector(
    (state) => state.panelMemberPermissions?.memberPermissions
  );

  return (
    <Box style={themeStyles}>
      {props.link && (
        <IconButton sx={{ mr: 2 }}>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={props.link}
          >
            <ArrowBackIcon />
          </Link>
        </IconButton>
      )}
      <h2 className="heading">{heading}</h2>
      <Stack direction={"row"} spacing={2} marginLeft="auto">
        {props.showFilter && (
          <FilterDropDown name="Filter by category" options={options} />
        )}

        {showSearchBar && <SearchBar {...props} />}
        {showButton &&
          (isPanelMember ? (
            memberPermissions &&
            memberPermissions.length > 0 &&
            memberPermissions.includes(code) && (
              <Button onClick={openModal} variant="outlined" color="secondary">
                ADD
              </Button>
            )
          ) : (
            <Button onClick={openModal} variant="outlined" color="secondary">
              ADD
            </Button>
          ))}

        {props.addLink && (
          <Link style={{ textDecoration: "none" }} to={props.addLink}>
            <Button variant="outlined">Add</Button>
          </Link>
        )}
        {nextPageBtn && (
          <Link
            state={{ ...nextPageBtn?.stateData }}
            className="rm-text-decor"
            to={nextPageBtn.link}
          >
            <Button color="secondary" variant="outlined">
              {nextPageBtn.text}
            </Button>
          </Link>
        )}
        {!isPanelMember && (
          <IconButton onClick={handleLogout}>
            <Logout />
          </IconButton>
        )}
        {/* <button onClick={() => dispatch(setPanelAdminAdded())}>fetch</button> */}
      </Stack>
    </Box>
  );
};

export default TopHeading;
