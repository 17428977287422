import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box, CircularProgress } from "@mui/material";
import styles from "./styles.modules.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3F72AF",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9F7F7",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#DBE2EF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const baseURL = "http://localhost:1337";

export default function CustomTable(props) {
  const { data, labels, page, totalPages, showPagination } = props;

  const handlePageChange = (e, value) => {
    props.setPage(value);
  };

  function getExtension(filename) {
    if (filename === undefined && typeof filename !== "string") return false;
    return filename.split(".").pop().toLowerCase();
  }
  return (
    <>
      <Box padding={!props.noPadding && 3} pt={0} pb={0}>
        {showPagination && (
          <Stack
            spacing={2}
            alignItems={"flex-end"}
            sx={{ my: "10px" }}
          >
            <Pagination
              page={page}
              count={totalPages}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        )}
        <TableContainer
          style={{
            height: props.auto ? "auto" : "600px",
            position: "relative",
            overflowY: "scroll",
          }}
          className={styles.table}
          component={Paper}
        >
          {props.pageLoading ? (
            <CircularProgress
              className={styles.loader}
              style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%,-50%)",
              }}
            />
          ) : (
            <Table
              stickyHeader={Boolean(true)}
              sx={{ minWidth: 700 }}
              aria-label="customized table"
            >
              <TableHead>
                <StyledTableRow>
                  {labels &&
                    labels.length > 0 &&
                    labels.map((label, index) => (
                      <StyledTableCell key={index}>{label}</StyledTableCell>
                    ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.length > 0 &&
                  data.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        {row.map((item, index) => {
                          return typeof item === "string" &&
                            getExtension(item) === "jpg" ? (
                            <img src={baseURL + item} width={100} height={70} />
                          ) : (
                            item !== "" && (
                              <StyledTableCell key={index} align="left">
                                {item}
                              </StyledTableCell>
                            )
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    </>
  );
}
