import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { updateUser } from "../users/user.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRolessBytenantId } from "../manageMembers/manageMember.api";
import {addMiniSurvey, updateMiniSurvey} from './miniSurvey.api'
import { useSelector } from "react-redux";
// import { getAllPanel } from "../panel/panel.api";
import {
  getActivepanels,
} from "../managePanel/managePanel.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";

const ConfUser = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
    miniSurveyCode,
    selectedMiniSurveyName,
    selectedMiniSurveyPoints,
    selectedMiniSurveyLoi,
    activePanels,
    setDataUpdated,
    selectedPanelId,
    dataUpdated,
  } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPanels, setSelectedPanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [currentEmail, setCurrentEmail] = useState("");
  const [selectedPanel, setselectedPanel] = useState("");

  const [userData, setUserData] = useState({
    miniSurveyName: "",
    panelId: null,
    points: "",
    loi: "",
    createdBy: adminId,
  });

  useEffect(() => {
    getActivepanels(adminId).then((res) => {
      if (res?.status === 200) {
        setPanels(res.data.data);
      }
    });
    return () => {
      setroles([]);
      setPanels([]);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("slected item", selectedItem);
    // console.log("roles", roles);
    // console.log("type", type);
    if (
      selectedItem &&
      selectedItem.roleId &&
      type === "EDIT" &&
      roles &&
      roles.length > 0
    ) {
      const role = roles.find(
        (item) => Number(item.roleId) === Number(selectedItem.roleId)
      );
      console.log("role", role);
      if (role) {
        setSelectedRole(role);
      }
    }
    if (editId && type === "EDIT") {
      setPageLoading(true);
      if (selectedItem) {
        setUserData(selectedItem);
        // setCurrentEmail(selectedItem?.email);
        setPageLoading(false);
      }
    }
    return () => {
      setUserData({
        miniSurveyName: "",
        panelId: null,
        points: "",
        loi: "",
        createdBy: adminId,
      });
      setSelectedPanels([]);
      setLoading(false);
    };
  }, [editId, selectedItem, open, roles]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let panelIds = [];
    if (selectedPanels && selectedPanels.length > 0) {
      selectedPanels.map((item) => panelIds.push(item.panelId));
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      let newData = {
        miniSurveyName: userData.miniSurveyName,
        panelId: selectedPanel.panelId,
        points: userData.points,
        loi: userData.loi,
        createdBy: adminId,
      };
      callMethod = addMiniSurvey(newData);
    } else {
      let data = {
        miniSurveyName: userData.miniSurveyName,
        panelId: selectedItem.panelId,
        points: userData.points,
        loi: userData.loi,
        updatedBy: adminId,
      };
      callMethod = updateMiniSurvey(editId, data);
    }

    callMethod
      .then((res) => {
        console.log("added", res);
        if (res?.status === 201 || res?.status === 200) {
          setDataUpdated(!dataUpdated);
          setSuccess();
          setUserData({
            miniSurveyName: "",
            panelId: null,
            points: "",
            loi: "",
            createdBy: adminId,
          });
        } else if (res.response.data.message === "MiniSurvey already exist") {
          setAlert("MiniSurvey already exist");
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePanelDropDownChange = (value) => {
    setselectedPanel(value);
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>
          {type === "ADD" ? "Add Member" : "Edit Member"}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
              {type === "ADD" && (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <AsyncDropdown
                    label={"Panel Name"}
                    disabled={props.disableDropDown}
                    value={selectedPanel}
                    handleChange={(value) => handlePanelDropDownChange(value)}
                    name="panelId"
                    objKey="panelName"
                    options={activePanels}
                  />
                </Stack>
              )}
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"text"}
                    label={"Mini Survey Name"}
                    name="miniSurveyName"
                    required
                    value={userData.miniSurveyName}
                  />
                {type === "EDIT" && (
                  <TextField
                    fullWidth
                    label={miniSurveyCode}
                    inputProps={{ readOnly: true }}
                  />
                )}
              </Stack>

              
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"number"}
                    label={"Points"}
                    name="points"
                    required
                    value={userData.points}
                  />
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"number"}
                    label={"LOI"}
                    name="loi"
                    required
                    value={userData.loi}
                  />
                </Stack>
              
              
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              ></Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {type === "ADD" ? "Save" : "Update"}
          </LoadingButton>
          <Button variant="outlined" onClick={hadnleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfUser;
