import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import CreateIcon from "@mui/icons-material/Create";
import { SnackBarAlert } from "../../utils/SnackBar";
import { Stack } from "@mui/system";
import ConfirmAlert from "../../utils/ConfirmAlert";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import { deleteMember } from "../users/user.api";
import ConfUser from "../users/ConfUser";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import ConfCompanyOnBoard from "./confCompanyOnBoard";
import AnimatedPage from "../animatedPage/AnimatedPage";
import ViewDetailsModal from "./ViewDetailsModal";
import { Link, Outlet } from "react-router-dom";

const CompanyOnboard = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
  } = props;

  const [members, setmembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setsearchLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [selectedMember, setSelectedMember] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const roleSelected = {
    roleId: 3,
    roleGuid: "d96e4d13-a27a-4437-9e63-e84a2851e75e",
    role: "Tenant Admin",
    tenantId: null,
    isActive: true,
    createdBy: 1,
    updatedBy: null,
    createdAt: "2022-11-11T13:08:38.648Z",
    updatedAt: "2022-11-11T13:08:38.648Z",
  };
  // useEffect(() => {
  //   if (searchQuery !== " ") {
  //     setsearchLoading(true);
  //     searchUser(searchQuery)
  //       .then((res) => {
  //         console.log(res.data.products);
  //         setSearchResult(res.data.products);
  //         setsearchLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setsearchLoading(false);
  //       });
  //   }
  //   return () => {
  //     setsearchLoading(false);
  //   };
  // }, [searchQuery]);

  useEffect(() => {
    if (editId && members && members.length > 0) {
      const item = members.find((member) => member.adminId === editId);
      if (item) {
        setSelectedMember(item);
      }
    }
    return () => {
      setSelectedMember({});
    };
  }, [editId, members]);

  useEffect(() => {
    setmembers(data);
  }, [data]);

  const handleDelete = (id) => {
    deleteMember(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };
  // useEffect(() => {
  //   if (userId) {
  //     console.log("search result", userId);
  //     setUsers([userId]);
  //   }
  // }, [userId]);

  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const labels = ["Name", "Email", "Contact", "Status", "Actions"];
  const rows = [];
  members &&
    members.length > 0 &&
    members.map((member) =>
      rows.push([
        <Typography
          onClick={() => {
            handleOpenViewModal();
            setEditId(member.adminId);
          }}
          variant="p"
          component={"h3"}
          sx={{
            textTransform: "capitalize",
            cursor: "pointer",
          }}
        >
          {member.firstName}
        </Typography>,
        member.email,
        member.contact,
        <CustomMuiSwitch checked={member.isActive} />,
        <Stack direction="row" spacing={0} alignItems={"center"}>
          <IconButton
            onClick={() => {
              setEditId(member.adminId);
              handleOpenEditModal();
            }}
          >
            <CreateIcon />
          </IconButton>
          <Link
            style={{
              padding: "2px",
              marginTop: "4px",
              textDecoration: "none",
              color: "#555555",
            }}
            to={`/admin/tenant-panels/${member.adminId}`}
          >
            <Tooltip title={"View panels"}>
              <RemoveRedEye />
            </Tooltip>
          </Link>
          <ConfirmAlert
            onClickEvent={() => handleDelete(member.adminId)}
            msg={"Are you sure?"}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          openModal={() => handleOpenModal()}
          // keyCode="title"
          // searchLoading={searchLoading}
          // options={searchResult}
          heading={"Tenant Onboard"}
          // setSearchQuery={setSearchQuery}
          // searchQuery={searchQuery}
          // showSearchBar={true}
          // setSearchresultId={setUserId}
        />
        <ConfCompanyOnBoard
          disableDropDown
          loading={loading}
          type="ADD"
          open={openModal}
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setAlert={setAlert}
          roleSelected={roleSelected}
        />
        <ConfCompanyOnBoard
          disableDropDown
          loading={loading}
          type="EDIT"
          open={openEditModal}
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedMember}
          setEditId={setEditId}
          roleSelected={roleSelected}
        />

        <ViewDetailsModal
          handleClose={handleCloseViewModal}
          open={openViewModal}
          selectedTenantId={editId}
          tenantAdminDetails={selectedMember}
        />
        <CustomTable
          labels={labels}
          data={rows}
          page={page}
          totalPages={pageCount}
          setPage={setPage}
          pageLoading={loading}
        />
      </AnimatedPage>
    </>
  );
};

export const EnhancedCompanyBoard = Hoc(CompanyOnboard, {
  name: "company-on-board",
});
