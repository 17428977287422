import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import LanguageIcon from "@mui/icons-material/Language";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import { fetchAllCountry, getCountryById } from "../country/country.api";
import { getPanelProfile } from "../panelProfile/panelProfile.api";

function LanguageModal(props) {
  const { countryid, countryName } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={"View Languages"}>
        <IconButton onClick={handleOpen} sx={{ ml: "auto" }}>
          <LanguageIcon fontSize="24" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClick={handleClose}>
        <DialogTitle>
          <Typography sx={{ textTransform: "capitalize" }}>
            {countryName}'s Languages
          </Typography>
        </DialogTitle>
      </Dialog>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ViewDetailsModal = (props) => {
  const { open, handleClose, selectedPanelId, selectedItem } = props;
  const [allFeatures, setAllFeatures] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [value, setValue] = useState(0);
  const handleChange = (e, val) => {
    setValue(val);
  };

  const [panelAdminDetail, setPanelAdminDetail] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    isActive: "",
  });
  const [panelProfileDetails, setPanelProfileDetails] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    countryId: "",
  });

  useEffect(() => {
    if (selectedItem) {
      setPanelAdminDetail(selectedItem);
    }
    return () => {
      setPanelAdminDetail({
        firstName: "",
        lastName: "",
        contact: "",
        email: "",
        isActive: "",
      });
    };
  }, [selectedItem]);

  // get country by id
  const [panelCountry, setPanelCountry] = useState({});
  useEffect(() => {
    console.log("panel profile details", panelProfileDetails);
    if (panelProfileDetails && panelProfileDetails.countryId) {
      getCountryById(panelProfileDetails.countryId)
        .then((res) => {
          if (res?.status === 200) {
            setPanelCountry(res.data.data);
          }
        })
        .catch((err) => {
          console.log("error fetchin country", err.message);
        });
    }
    return () => {
      setPanelCountry({});
    };
  }, [panelProfileDetails]);

  useEffect(() => {
    console.log("panel id", selectedPanelId);
    if (selectedPanelId) {
      getFeaturesByTenantId(selectedPanelId)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setAllFeatures(res.data.data);
          } else {
            console.log("error fetching data");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      fetchAllCountry()
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setallCountries(res.data.data);
          } else {
            console.log("error fetching data");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getPanelProfile(selectedPanelId)
        .then((res) => {
          if (res?.status === 200) {
            console.log("panel profile", res.data.data);
            setPanelProfileDetails(res.data.data[0]);
          } else {
            console.log("error fetching data");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return () => {
      setAllFeatures([]);
      setallCountries([]);
      setValue(0);
      setPanelProfileDetails({
        address: "",
        city: "",
        state: "",
        zip: "",
        countryId: "",
      });
    };
  }, [selectedPanelId]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Dialog PaperProps={{
      sx: {
        height: 400,
      },
    }} onClose={handleClose} maxWidth={"md"} fullWidth={true} open={open}>
      <DialogTitle sx={{ pb: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "capitalize" }}
            label="Panel Admin"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: "capitalize" }}
            label="Panel"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ textTransform: "capitalize" }}
            label="Features and Countries"
            {...a11yProps(2)}
          />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        <TabPanel value={value} index={0}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Name"}
                InputProps={{ readOnly: true }}
                value={
                  panelAdminDetail?.firstName + " " + panelAdminDetail?.lastName
                }
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Email"}
                InputProps={{ readOnly: true }}
                value={panelAdminDetail?.email}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Contact"}
                InputProps={{ readOnly: true }}
                value={panelAdminDetail?.contact}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} direction={"row"}>
              <TextField
                label={"Status"}
                InputProps={{ readOnly: true }}
                value={panelAdminDetail?.isActive ? "Active" : "Inactive"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container
            direction={"row"}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            p={2}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label={"Address"}
                InputProps={{ readOnly: true }}
                value={panelProfileDetails?.address}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"State"}
                InputProps={{ readOnly: true }}
                value={panelProfileDetails?.state}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"City"}
                InputProps={{ readOnly: true }}
                value={panelProfileDetails?.city}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"Zip"}
                InputProps={{ readOnly: true }}
                value={panelProfileDetails?.zip}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                label={"Country"}
                InputProps={{ readOnly: true }}
                value={panelCountry?.countryName}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Stack>
            <Stack>
              <Typography variant="h6" component={"p"}>
                Features
              </Typography>
              <Grid
                container
                direction={"row"}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                p={2}
              >
                {allFeatures &&
                  allFeatures.length > 0 &&
                  allFeatures.map((item, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <RadioButtonCheckedIcon fontSize="12" />
                        <Typography>{item.features.feature}</Typography>
                      </Stack>
                    </Grid>
                  ))}
              </Grid>
            </Stack>
            <Stack>
              <Typography variant="h6" component={"p"}>
                Countries
              </Typography>
              <Grid
                container
                direction={"row"}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                p={2}
              >
                {allCountries &&
                  allCountries.length > 0 &&
                  allCountries.map((item, index) => (
                    <Grid
                      direction={"row"}
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                    >
                      <>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <RadioButtonCheckedIcon fontSize="12" />
                            <Typography>{item?.countryName}</Typography>
                          </Stack>
                          {/* <LanguageModal
                            countryName={item?.countryName}
                            countryid={item.countryId}
                          /> */}
                        </Stack>
                      </>
                    </Grid>
                  ))}
              </Grid>
            </Stack>
          </Stack>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default ViewDetailsModal;
