import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const QualificationOptions = (props) => {
  const { option, checkedOptions, setCheckedOptions } = props;

  const [options, setOptions] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (option?.length > 0) {
      option?.map((item) => {
        item["selected"] = false;
      });
      setOptions(option);
    } else {
      setOptions([]);
    }
    return () => {
      setAllChecked(false);
    };
  }, [option]);

  const handleSelectAll = (e, value) => {
    console.log("val", value);
    setAllChecked(Boolean(value));
    if (options?.length > 0) {
      let duplicate = [...options];
      if (value) {
        duplicate?.map((item) => {
          item["selected"] = true;
        });
        setOptions(duplicate);
      } else {
        duplicate?.map((item) => {
          item["selected"] = false;
        });
        setOptions(duplicate);
      }
    }
  };

  useEffect(() => {
    setCheckedOptions(options);
  }, [options]);

  const handleCheck = (e, value, index) => {
    let dups = [...options];
    dups[index]["selected"] = value;
    setOptions(dups);
  };
  return (
    <Stack>
      <FormControlLabel
        control={
          <Checkbox checked={Boolean(allChecked)} onChange={handleSelectAll} />
        }
        label={<Typography sx={{ fontSize: "15px" }}>Select All</Typography>}
      />
      <Stack>
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                onChange={(e, value) => handleCheck(e, value, index)}
                sx={{ fontSize: "6px" }}
                checked={item?.selected}
              />
            }
            label={
              <Typography sx={{ fontSize: "15px" }}>
                {item["optionStatement"]}
              </Typography>
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default QualificationOptions;
