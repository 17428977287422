import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { TENANT_PANEL_DETAIS } from "./confCompanyOnBoard";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PanelDetailsFields = (props) => {
  const { data, handleChange, index, handleRemovePanel, validation, type } =
    props;

  const handleDropDownChange = (e) => {
    console.log(e.target);
  };

  return (
    <Stack sx={{ mb: 2 }}>
      <Typography
        variant="p"
        fontWeight={600}
        sx={{ mb: 0.5, background: "#f5f5f5" }}
      >
        Panel {index + 1}
      </Typography>
      <Box
        padding={2}
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
          borderRadius: "0.5rem",
        }}
      >
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
            >
              <TextField
                onChange={(e) => handleChange(e, TENANT_PANEL_DETAIS, index)}
                fullWidth
                type={"text"}
                label={"Panel name"}
                // error={Boolean(validation[index].panelName)}
                // helperText={validation[index].panelName}
                name="panelName"
                required
                value={data.panelName}
              />
              <FormControl
                // error={Boolean(validation[index].panelType)}
                // helperText={validation[index].panelType}
                fullWidth
              >
                <InputLabel>Type *</InputLabel>
                <Select
                  required
                  value={data.panelType}
                  // error={Boolean(validation[index].panelType)}
                  // helperText={validation[index].panelType}
                  label="Type"
                  name="panelType"
                  onChange={(e) => handleChange(e, TENANT_PANEL_DETAIS, index)}
                >
                  <MenuItem value={"Consumer"}>Consumer</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                onChange={(e) => handleChange(e, TENANT_PANEL_DETAIS, index)}
                fullWidth
                type={"text"}
                //   error={Boolean(secondFormValidation.type)}
                //   helperText={secondFormValidation.type}
                label={"Type"}
                name="type"
                required
                value={data.type}
              /> */}
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              alignItems="flex-end"
            >
              <TextField
                onChange={(e) => handleChange(e, TENANT_PANEL_DETAIS, index)}
                fullWidth
                type={"text"}
                // error={Boolean(validation[index].webAppLink)}
                // helperText={validation[index].webAppLink}
                label={"Web app link"}
                name="webAppLink"
                value={data.webAppLink}
              />
              <TextField
                onChange={(e) => handleChange(e, TENANT_PANEL_DETAIS, index)}
                fullWidth
                type={"text"}
                //   error={Boolean(secondFormValidation.mobileAppLink)}
                //   helperText={secondFormValidation.mobileAppLink}
                label={"Mobile app link"}
                name="mobileAppLink"
                value={data.mobileAppLink}
              />
            </Stack>
          </Stack>
          {type === "ADD" && (
            <IconButton onClick={() => handleRemovePanel(index)}>
              <HighlightOffIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default PanelDetailsFields;
