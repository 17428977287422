import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import graphDataReducer from "../features/graphDataSlice";
import sideBarReducer from "../features/sideBarSlice";
import modeReducer from "../features/mode.slice";
import masterQuestionSlice from "../features/masterQuestionSlice";
import panelSidebarItemSlice from "../features/panelSidebarItemSlice";
import panelMemberPermissions from "../features/panelMemberPermissions";
import listReducer from "../features/listSlice";

export const store = configureStore({
  reducer: {
    sideBar: sideBarReducer,
    graphData: graphDataReducer,
    auth: authReducer,
    mode: modeReducer,
    masterQuestion: masterQuestionSlice,
    panelSidebarItems: panelSidebarItemSlice,
    panelMemberPermissions: panelMemberPermissions,
    list: listReducer,
  },
});
