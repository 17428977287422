import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../features/authSlice";
import { SnackBarAlert } from "../../../utils/SnackBar";
import AnimatedPage from "../../animatedPage/AnimatedPage";
import { loginAndRegister, loginMember, verifyOtp } from "../auth.api";
import styles from "./styles.module.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumberSent, setPhoneNumberSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [alert, setAlert] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: 1,
  });

  const handleCloseAlert = () => {
    setAlert(false);
  };

  function checkIfValidIndianMobileNumber(str) {
    // Regular expression to check if string is a Indian mobile number
    const regexExp = /^[6-9]\d{9}$/gi;

    return regexExp.test(str);
  }

  const usernameRef = useRef();
  const errRef = useRef();
  const otpRef = useRef();

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const handleChnageInput = (e) => {
    const { name, value } = e.target;
    if (name && value) {
      setUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    loginMember(user)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(login(res.data));
          console.log(res.data);
        } else {
          setAlert("Something went wrong!");
        }
      })
      .catch((err) => {
        setAlert("Something went wrong!");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <AnimatedPage>
        <Box className={styles.loginFormContainer}>
          <Typography
            color={grey[800]}
            style={{ margin: "16px 0" }}
            variant="h5"
            component={"p"}
          >
            Login
          </Typography>
          <form onSubmit={handleSubmit} className={styles.loginForm}>
            <TextField
              type={"email"}
              required
              onChange={handleChnageInput}
              inputRef={usernameRef}
              autoComplete="off"
              style={{ marginBottom: "10px" }}
              label="Email "
              name="email"
            />
            <TextField
              type={"password"}
              required
              onChange={handleChnageInput}
              autoComplete="off"
              style={{ marginBottom: "10px" }}
              label="Password "
              name="password"
            />
            <LoadingButton
              type="submit"
              className={
                loading
                  ? (styles.loginBtn, styles.loginBtnLoading)
                  : styles.loginBtn
              }
              sx={{
                transition: "all 200ms ease-in-out",
              }}
              loading={loading}
              variant="contained"
            >
              Login
            </LoadingButton>
          </form>
          {/* <Typography
          align="right"
          mt={2}
          fontSize={14}
          className={styles.forgortLink}
        >
          Forgot Password?
        </Typography> */}
          <Typography
            align="right"
            fontSize={14}
            className={styles.forgortLink}
          >
            {/* <Link to={'/register'}>Sign up</Link> */}
          </Typography>
          <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
        </Box>
      </AnimatedPage>
    </>
  );
};

export default Login;
