import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRolessBytenantId } from "../manageMembers/manageMember.api";
import { useSelector } from "react-redux";
// import { getAllPanel } from "../panel/panel.api";
import { getActivepanels } from "../managePanel/managePanel.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";
import { approveRequest } from "./Redemption.api";

const ConfRedemption = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    editId,
    selectedItem,
    roleSelected,
    dataUpdated,
    setDataUpdated,
    panelGuid,
    actionStatus
  } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPanels, setSelectedPanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [currentEmail, setCurrentEmail] = useState("");
  const [selectedPanel, setselectedPanel] = useState("");

  const [userData, setUserData] = useState({
    utid:"",
    status:"",
    redemptionStatus:"",
    disapprovedBy: null,
    disapprovedReason: "",
    updatedBy: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let newData={};

    if(actionStatus=="reject"){
      newData = {
        utid: selectedItem.utid,
        status: "disapproved",
        redemptionStatus: "failed",
        disapprovedBy: adminId,
        disapprovedReason: userData.disapprovedReason,
        updatedBy: adminId,
    };
    }
    else if(actionStatus=="hold"){
      newData={
            utid:selectedItem.utid,
            status: "hold",
            redemptionStatus: "complete",
            disapprovedReason: userData.disapprovedReason,
            approvedBy: adminId,
            updatedBy: adminId,
        };
    }

    approveRequest(selectedItem.id, newData,panelGuid)
      .then((res) => {
        
        if ((res?.status === 201 || res?.status === 200) && res?.data?.data?.status=="disapproved" || res?.data?.data?.status==="hold") {
          setDataUpdated(!dataUpdated);
          setSuccess();
          setUserData({
            utid: "",
            status: "",
            redemptionStatus: "",
            disapprovedBy: null,
            disapprovedReason: "",
            updatedBy: null,
          });
        }
        else if (res?.data?.message == "Invalid IP") {
          hadnleCloseModal();
          setError(res?.data?.message);
        }
        else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog  fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>
          Reason for disapproval
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
              <TextField
                onChange={handleInputChange}
                fullWidth
                type={"text"}
                label={"Reason"}
                name="disapprovedReason"
                required
                value={userData.disapprovedReason}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            // onClick={() => {handleSubmit()}}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            Disapprove
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              hadnleCloseModal();
            }}
          
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfRedemption;
