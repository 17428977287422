import {
    Grid,
    Paper,
    Tab
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Divider from '@mui/material/Divider';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useParams } from "react-router-dom";
import SampleCount from "../Qualification/SampleCount";
import SurveyEmailContents from "./EmailContents";
import { getSurveyDetails } from "../../survey.api";
const SurveyEmail = (props) => {
    const { id } = useParams();
    const [value, setValue] = useState('E-mail');
    const [surveyDetails, setSurveyDetails] = useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [sampleData, setSampleData] = useState([
        {
            count: 0,
            label: "Country",
        },
        {
            count: 0,
            label: "Complete Require",
        },
        {
            count: 0,
            label: "Sample Count",
        },
    ]);
    useEffect(() => {
        if (id) {
            getSurveyDetails(id)
                .then((res) => {
                    if (res?.status === 200) {

                        setSurveyDetails(res.data.data);
                        let sampleCount=res.data.data.sampleCount!=null?res.data.data.sampleCount:0
                        setSampleData([
                            {
                                count: res.data.data.countryLanguage.countryName+"("+res.data.data.countryLanguage.countryCode+")",
                                label: "Country",
                            },
                            {
                                count: res.data.data.completeRequire,
                                label: "Complete Require",
                            },
                            {
                                count: sampleCount,
                                label: "Sample Count",
                            },
                        ]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [id]);

    return (
        <>

            <Stack direction={"row"} alignItems={"flex-start"} className="himanshu-email">
                <Paper elevation={2} sx={{ my: 2, p: 2, mr: 2 }}>
                    <Grid container className="surveyEmail-parent">
                        <Grid container item xs={12} sm={12} md={12} className="surveyEmail-header">
                            <Grid sx={{ mx: 2, }}>
                                SID : <strong>{id}</strong>
                            </Grid>
                            <Divider orientation="vertical" flexItem>
                            </Divider>
                            <Grid sx={{ mx: 1 }}>
                                SurveyCode : <strong>{surveyDetails.surveyCode}</strong>
                            </Grid>
                            <Divider orientation="vertical" flexItem>
                            </Divider>
                            <Grid sx={{ mx: 1 }} >
                                ClientSurveyId : <strong>{surveyDetails.clientApiSurveyId}</strong>
                            </Grid>

                        </Grid>
                        <Divider orientation="horizontal">
                        </Divider>
                        <SampleCount sampleData={sampleData}  />

                    </Grid>
                </Paper>
            </Stack>
            <TabContext value={value}>
                <Box sx={{ borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="E-mail" value="E-mail" />
                    </TabList>
                </Box>
                <TabPanel value="E-mail">
                    <SurveyEmailContents surveyDetails={surveyDetails}
                    />
                </TabPanel>
            </TabContext>
        </>
    );
}
export default SurveyEmail;
