import { Create, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConfirmAlert from "../../../../utils/ConfirmAlert";
import CustomTable from "../../../../views/CustomTable/CustomTable";
import {
  deleteQualification,
  getQualificationQuestions,
  getSurveyDetails,
  getSurveyQualification,
  getSurveyQualificationQuestions,
  notification,
  updateSampleCount,
} from "../../survey.api";
import AddQualification from "./AddQualification";
import SampleCount from "./SampleCount";
import ViewQualification from "./ViewQualification";
import "./styles.css";

const Qualification = (props) => {
  const { data, surveyResdata, handleChange, setAlert, alert } = props;
  const [existingSurveyDetails, setExistingSurveyDetails] = useState({});

  const { id } = useParams();
  const [dataAltered, setDataAltered] = useState(false);

  useEffect(() => {
    if (id) {
      getSurveyDetails(id)
        .then((res) => {
          if (res?.status === 200) {
            setExistingSurveyDetails(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [qualifications, setQualifications] = useState([]);
  const [qualificationQuestions, setQualificationQuestions] = useState([]);

  const [openQualification, setOpenQualification] = useState(false);
  const [fetchingSample, setFetchingSample] = useState(false);
  const [notificationLoading, setnotificationLoading] = useState(false);

  const handleCloseQualification = () => {
    setOpenQualification(false);
  };
  const handleOpenQualification = () => {
    setOpenQualification(true);
  };

  const [sampleData, setSampleData] = useState([
    {
      icon: "bi bi-list",
      count: 0,
      label: "Complete Require",
    },
    {
      icon: "bi bi-list",
      count: 0,
      label: "Sample Match",
    },
    {
      icon: "bi bi-person-plus",
      count: 0,
      label: "Quota Exclude",
    },
    {
      icon: "bi bi-person",
      count: 0,
      label: "Group Exclude",
    },
    {
      icon: "bi bi-list",
      count: 0,
      label: "Sample Available",
    },
    {
      icon: "bi bi-list",
      count: 0,
      label: "Mobile Count",
    }
  ]);

  const [selectedQualification, setSelectedQualification] = useState(null);

  useEffect(() => {
    if (id) {
      getSurveyQualification(id)
        .then((res) => {
          if (res?.status === 200) {
            console.log("sur res", res.data.data);
            const { data } = res.data;
            setQualifications(data?.panelQsn);
            let duplicate = [...sampleData];
            duplicate[0]["count"] = data?.completeRequire;
            duplicate[1]["count"] = data?.sampleCount;
            duplicate[5]["count"] = data?.mobileCount;
            setSampleData(duplicate);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      setSampleData([
        {
          icon: "bi bi-list",
          count: 0,
          label: "Complete Require",
        },
        {
          icon: "bi bi-list",
          count: 0,
          label: "Sample Match",
        },
        {
          icon: "bi bi-person-plus",
          count: 0,
          label: "Quota Exclude",
        },
        {
          icon: "bi bi-person",
          count: 0,
          label: "Group Exclude",
        },
        {
          icon: "bi bi-list",
          count: 0,
          label: "Sample Available",
        },
        {
          icon: "bi bi-list",
          count: 0,
          label: "Mobile Count",
        },
      ]);
    };
  }, [id, alert]);

  useEffect(() => {
    if (existingSurveyDetails?.languageId && existingSurveyDetails?.countryId) {
      getSurveyQualificationQuestions(
        existingSurveyDetails?.languageId,
        existingSurveyDetails?.countryId
      )
        .then((res) => {
          if (res?.status === 200) {
            setQualificationQuestions(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      setQualificationQuestions([]);
    };
  }, [existingSurveyDetails]);

  const handleDeleteQualification = (id) => {
    deleteQualification(id)
      .then((res) => {
        if (res?.status === 200) {
          setAlert("Qualification deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert("Something went wrong");
      });
  };

  const handleUpdateSaampleCount = () => {
    setFetchingSample(true);
    updateSampleCount(id)
      .then((res) => {
        if (res?.status === 200) {
          console.log("resposne", res);
          setAlert("New sample count fetched.");
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setFetchingSample(false));
  };

  const notificationHandler = () => {
    setnotificationLoading(true);
    notification(id)
      .then((res) => {
        if (res?.status === 200) {
          console.log(id);
          // console.log("notifications");
          setAlert("Notifications sent");
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setnotificationLoading(false));
  };

  const labels = ["Name", "Description", "Status", "Action"];
  const rows = [];
  qualifications?.map((item) =>
    rows.push([
      item.qsnShortForm,
      item.qualDescription,
      item.isActive ? "Active" : "InActive",
      <Stack direction={"row"}>
        <IconButton
          onClick={() => {
            setSelectedQualification(item.qualificationId);
            handleOpenQualification();
          }}
        >
          <Create />
        </IconButton>
        <ConfirmAlert
          msg={"Confirm delete?"}
          onClickEvent={() => handleDeleteQualification(item.qualificationId)}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </ConfirmAlert>
      </Stack>,
    ])
  );

  return (
    <Box>
      <Stack direction={"row"} alignItems={"flex-start"}>
        <Stack sx={{ flexGrow: 1, width: "70%" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <button
              disabled={notificationLoading}
              onClick={notificationHandler}
              className="addButton get-sample"
            >
              {notificationLoading ? (
                <CircularProgress
                  size={"21px"}
                  sx={{ margin: "0 20px", color: "white", padding: 0 }}
                />
              ) : (
                "   Notification   "
              )}
            </button>
            <button
              disabled={fetchingSample}
              onClick={handleUpdateSaampleCount}
              className="addButton get-sample"
            >
              {fetchingSample ? (
                <CircularProgress
                  size={"21px"}
                  sx={{ margin: "0 20px", color: "white", padding: 0 }}
                />
              ) : (
                "Get Sample Match"
              )}
            </button>
          </Stack>
          <SampleCount sampleData={sampleData} surveyResdata={surveyResdata} />
          <CustomTable data={rows} auto noPadding labels={labels} />
        </Stack>
        <Box
          sx={{
            height: 500,
            overflowY: "auto",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            py: 2,
          }}
        >
          <Stack>
            <AddQualification
              setAlert={setAlert}
              handleChange={handleChange}
              surveyResdata={existingSurveyDetails}
              questions={qualificationQuestions}
            />
          </Stack>
        </Box>
      </Stack>
      <ViewQualification
        setAlert={setAlert}
        questions={qualificationQuestions}
        selectedQualification={selectedQualification}
        open={openQualification}
        handleClose={handleCloseQualification}
      />
    </Box>
  );
};

export default Qualification;
