import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Paper } from "@mui/material";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function AsyncDropdown(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...props.options]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const dropDownStyles = React.useMemo(() => {
    return {
      background: "#FFFFFF",
      borderRadius: "10px",
      width: props.width ? 220 : "100%",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        border: props.stylized && "2px solid #F0F0F0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        border: props.stylized && "2px solid #F0F0F0",
      },
    };
  }, [props.stylized]);

  return (
    <Autocomplete
      // style={dropDownStyles}
      sx={dropDownStyles}
      disableClearable
      size={props.small && "small"}
      disabled={props.disabled}
      id="asynchronous-demo"
      fullWidth={props.fullWidth}
      value={props?.value}
      onChange={(event, value) => {
        props.handleChange(value);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option) =>
        option[props.objKey] === props.value[props.objKey]
      }
      getOptionLabel={(option) =>
        option[props.objKey] ? option[props.objKey] : ""
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          fullWidth={props.fullWidth}
          error={props.error}
          helperText={props.helperText}
          {...params}
          label={props.label}
          placeholder={props.label}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
