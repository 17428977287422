import {
  Create,
  Delete,
  RemoveRedEye,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import ConfUserProfileQuestionaire from "./ConfUserProfileQuestionaire";
import { allProfileQuestions } from "./data";
import { deleteUserProfileQuestion } from "./userProfileQuestionaire.api";
const UserProfileQuestionaire = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [profileQuestions, setprofileQuestions] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (data) {
      setprofileQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.id === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (profileId) => {
    deleteUserProfileQuestion(profileId)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rows = [];
  const labels = ["Panel", "Country", "Language", "Actions"];

  profileQuestions.map((item) =>
    rows.push([
      item?.panelName,
      item?.countryName,
      item?.language,
      <Stack direction={"row"}>
        <Link
          style={{ textDecoration: "none", color: "#555" }}
          state={{
            previousPage: location.pathname,
            panelName: item.panelName,
            panelId: item.panelId,
            countryId: item.countryId,
            languageId: item.languageId,
            profile_id: item?.panelUserProfileConfigId,
          }}
          to={`${item.panelId}`}
        >
          <Tooltip title="View details">
            <IconButton>
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        </Link>
        <ConfirmAlert
          msg={"Are you sure you want to delete?"}
          onClickEvent={() => handleDelete(item?.panelUserProfileConfigId)}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </ConfirmAlert>
      </Stack>,
    ])
  );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          showButton={Boolean(false)}
          addLink={"/admin/user-profile-questionaire/create"}
          openModal={handleOpenModal}
          heading="User Profile Questionnaire"
        />
        {/* <ConfUserProfileQuestionaire
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
        />
        <ConfUserProfileQuestionaire
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
        /> */}
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedUserProfileQuestionaire = Hoc(UserProfileQuestionaire, {
  name: "panel-profile",
});
