import axios from "axios";

export const addTangoCard = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createTangoCardDetail", data);
    return res;
  } catch (error) {
    console.log("failed creating member", error.message);
    return error;
  }
};

export const getTangoCards = async (id) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getTangoCardDetail?tenantId=${id}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateTangoCard = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/systemUser/updateTangoCardDetail?id=${id}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteTangoCard = async (id) => {
  try {
    const res = await axios.delete(
      `/api/systemUser/deleteTangoCardDetail?id=${id}`
    );
    return res;
  } catch (error) {
    console.log("failed deleting", error);
  }
};
