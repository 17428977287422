import {
  Create,
  Delete,
  RemoveRedEye,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { Button, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConfirmAlert from "../../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import AnimatedPage from "../../animatedPage/AnimatedPage";
import { getAllanswerTypes } from "../../answerType/answerType.api";
import { getActiveCategories } from "../../category/category.api";
import Hoc from "../../HOC/Hoc";
import { getPanelCountryLanguage } from "../../survey/survey.api";
import ConfTranslateQuestion from "../../translateQuestions/ConfTranslateQuestion";
import {
  deleteTranslation,
  getPanelQuestionDetail,
  getPanelQuestionTranslations,
} from "../../translateQuestions/panelQuestions.api";
import { getTranslatedQuestions } from "../masterQuestion.api";
import ViewOptionsModal from "../ViewOptionsModal";

const QuestionTranslations = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [translatedQuestions, setTranslatedQuestions] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const { id } = useParams();
  const [tableLoading, setTableLoading] = useState(true);
  const [answerTypes, setAnswerTypes] = useState([]);
  const [activecategories, setActivecategories] = useState([]);
  const [countryLanguage, setCountryLanguage] = useState([]);

  const [dataFetched, setDataFetched] = useState(false);
  const [editOptions, setEditOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);
  const [openViewOptionsModal, setOpenViewOptionsModal] = useState(false);
  const location = useLocation();
  const [mainQuestion, setMainQuestion] = useState({
    qsnShortForm: "",
  });

  useEffect(() => {
    return () => {
      setDataFetched(false);
    };
  }, [translatedQuestions]);

  useEffect(() => {
    if (id) {
      setTableLoading(true);
      getPanelQuestionDetail(id)
        .then((res) => {
          if (res?.status === 200) {
            setMainQuestion(res.data.data[0]);
          }
        })
        .catch((err) => {
          console.log("something went wrong");
        });
      getPanelQuestionTranslations(location.state?.panelId, id)
        .then((res) => {
          if (res?.status === 200) {
            const translations = res.data.data[0];
            setTranslatedQuestions(translations?.translation);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          console.log((err) => console.log(err.message));
        });

      getPanelCountryLanguage(location.state?.panelId)
        .then((res) => {
          if (res?.status === 200) {
            setCountryLanguage(res.data.data);
          }
        })
        .catch((err) => console.log(err));

      getActiveCategories()
        .then((res) => {
          if (res?.status === 200) {
            setActivecategories(res.data.data);
          }
        })
        .catch((err) => console.log(err.message));
    }

    getAllanswerTypes()
      .then((res) => {
        if (res?.status === 200) {
          setAnswerTypes(res.data.data);
        }
      })
      .catch((err) => console.log(err.message));

    return () => {
      setAnswerTypes([]);
      setActivecategories([]);
      setTranslatedQuestions(data);
      setTableLoading(false);
      setMainQuestion({
        qsnShortForm: "",
      });
    };
  }, [id, dataFetched]);

  useEffect(() => {
    if (translatedQuestions && translatedQuestions.length > 0) {
      const item = translatedQuestions.find(
        (item) => item.qsnTranslationId === editId
      );

      console.log("item", item);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, openViewOptionsModal]);

  const handleEditOptions = (value, index) => {
    let duplicate = [...editOptions];
    if (index > duplicate.length - 1) {
      let newEditOption = {
        optionStatement: value,
      };
      duplicate.push(newEditOption);
    } else {
      duplicate[index]["optionStatement"] = value;
    }
    setEditOptions(duplicate);
  };

  const handleDelete = (id) => {
    deleteTranslation(id)
      .then((res) => {
        if (res?.status === 200) {
          setDataFetched(true);
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  function checkIfUs(countryId, languageId) {
    if (countryId === 4 && languageId === 1) {
      return true;
    } else {
      return false;
    }
  }

  const rows = [];
  const labels = ["Id", "Question", "Country", "Language", "Actions"];
  translatedQuestions &&
    translatedQuestions.length > 0 &&
    translatedQuestions.map((item) =>
      rows.push([
        item?.qsnTranslationId,
        item.questionStatement,
        item?.countrytranslation?.countryCode,
        item?.languagetranslation?.language,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item.qsnTranslationId);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>
          {item?.optiontranslation?.length > 0 && (
            <IconButton
              onClick={() => {
                setOpenViewOptionsModal(true);
                setEditId(item.qsnTranslationId);
              }}
            >
              <Tooltip title={"Edit options"}>
                <RemoveRedEye />
              </Tooltip>
            </IconButton>
          )}
          {!checkIfUs(
            item?.countrytranslation?.countryId,
            item?.languagetranslation?.id
          ) && (
            <ConfirmAlert
              msg={"Are you sure you want to delete?"}
              onClickEvent={() => handleDelete(item.qsnTranslationId)}
            >
              <IconButton>
                <Delete />
              </IconButton>
            </ConfirmAlert>
          )}
        </Stack>,
      ])
    );

  return (
    <>
      <AnimatedPage>
        <TopHeading
          openModal={handleOpenModal}
          link={"/admin/panel-questions"}
          heading="Questions Translations"
        />

        <Stack sx={{ px: 4, mb: 2 }}>
          <TextField label={"Question"} value={mainQuestion?.qsnShortForm} />
        </Stack>
        <ConfTranslateQuestion
          setDataFetched={setDataFetched}
          hadnleCloseModal={handleCloseModal}
          type={"TRANSLATE"}
          open={openModal}
          answerTypes={answerTypes}
          setAlert={setAlert}
          countryLanguage={countryLanguage}
          selectedItem={mainQuestion}
          setSuccess={setSuccess}
        />
        <ConfTranslateQuestion
          setDataFetched={setDataFetched}
          hadnleCloseModal={handleCloseEditModal}
          type={"TRANSLATE_EDIT"}
          open={openEditModal}
          answerTypes={answerTypes}
          setAlert={setAlert}
          countryLanguage={countryLanguage}
          selectedItem={mainQuestion}
          setSuccess={setSuccess}
          selectedTranslation={selectedItem}
        />
        <CustomTable labels={labels} data={rows} pageLoading={tableLoading} />
        <ViewOptionsModal
          obj={"optiontranslation"}
          onLyView={checkIfUs(
            selectedItem?.countrytranslation?.countryId,
            selectedItem?.languagetranslation?.id
          )}
          selectedItem={selectedItem}
          open={openViewOptionsModal}
          setDataFetched={setDataFetched}
          setAlert={setAlert}
          handleClose={() => setOpenViewOptionsModal(false)}
          handleChange={handleEditOptions}
        />
      </AnimatedPage>
    </>
  );
};

export const EnhancedQuestionTranslations = Hoc(QuestionTranslations, {
  name: "translated-questions",
});
