import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfUser from "./ConfUser";
import { deleteMember, searchUser } from "./user.api";
import CreateIcon from "@mui/icons-material/Create";
import { SnackBarAlert } from "../../utils/SnackBar";
import { Stack } from "@mui/system";
import ConfirmAlert from "../../utils/ConfirmAlert";
import { Delete } from "@mui/icons-material";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import AnimatedPage from "../animatedPage/AnimatedPage";

const Users = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
  } = props;

  const [members, setmembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setsearchLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [selectedMember, setSelectedMember] = useState({});

  const roleSelected = {
    roleId: 2,
    roleGuid: "2936d039-1989-41a7-892d-9b70e03866b9",
    role: "System Admin",
    tenantId: null,
    isActive: true,
    createdBy: 1,
    updatedBy: null,
    createdAt: "2022-11-11T13:08:24.580Z",
    updatedAt: "2022-11-11T13:08:24.580Z",
  };

  // useEffect(() => {
  //   if (searchQuery !== " ") {
  //     setsearchLoading(true);
  //     searchUser(searchQuery)
  //       .then((res) => {
  //         console.log(res.data.products);
  //         setSearchResult(res.data.products);
  //         setsearchLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setsearchLoading(false);
  //       });
  //   }
  //   return () => {
  //     setsearchLoading(false);
  //   };
  // }, [searchQuery]);

  useEffect(() => {
    if (editId && members && members.length > 0) {
      const item = members.find((member) => member.adminId === editId);
      if (item) {
        setSelectedMember(item);
      }
    }
    return () => {
      setSelectedMember({});
    };
  }, [editId, members]);

  useEffect(() => {
    setmembers(data);
  }, [data]);

  const handleDelete = (id) => {
    deleteMember(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };
  // useEffect(() => {
  //   if (userId) {
  //     console.log("search result", userId);
  //     setUsers([userId]);
  //   }
  // }, [userId]);

  const labels = ["Name", "Email", "Contact", "Status", "Actions"];
  const rows = [];
  members &&
    members.length > 0 &&
    members.map((member) =>
      rows.push([
        member.firstName + " "+ member.middleName +" "+ member.lastName,
        member.email,
        member.contact,
        <CustomMuiSwitch checked={member.isActive} />,
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              setEditId(member.adminId);
              handleOpenEditModal();
            }}
          >
            <CreateIcon />
          </IconButton>
          <ConfirmAlert
            onClickEvent={() => handleDelete(member.adminId)}
            msg={"Are you sure?"}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          openModal={() => handleOpenModal()}
          // keyCode="title"
          // searchLoading={searchLoading}
          // options={searchResult}
          heading={"System Admin"}
          // setSearchQuery={setSearchQuery}
          // searchQuery={searchQuery}
          // showSearchBar={true}
          // setSearchresultId={setUserId}
        />

        <ConfUser
          disableDropDown
          loading={loading}
          type="ADD"
          open={openModal}
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setAlert={setAlert}
          roleSelected={roleSelected}
          setError={setError}
        />
        <ConfUser
          disableDropDown
          loading={loading}
          type="EDIT"
          open={openEditModal}
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedMember}
          setEditId={setEditId}
          roleSelected={roleSelected}
          setError={setError}
        />

        <CustomTable
          showPagination
          labels={labels}
          data={rows}
          page={page}
          totalPages={pageCount}
          setPage={setPage}
          pageLoading={loading}
        />

        <SnackBarAlert open={alert} handleClose={closeAlert} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedUsers = Hoc(Users, { name: "users" });
