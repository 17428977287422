import axios from "axios";

export const addRole = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createRole", data);
    return res;
  } catch (error) {
    console.log("failed creating role", error);
  }
};

export const createRoleRelation = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/createRoleRelation", data);
    return res;
  } catch (error) {
    console.log("failed creating role", error);
  }
};

export const getAllRoles = async (id) => {
  try {
    const res = await axios.get(`/api/systemUser/getRoleByTenant/${id}`);
    return res;
  } catch (error) {
    console.log("could not fetch roles", error);
  }
};

export const getRoles = async () => {
  try {
    const res = await axios.get("/api/systemUser/getRole");
    return res;
  } catch (error) {
    console.log("could not fetch roles", error);
  }
};

export const deleteRole = async (id) => {
  try {
    const res = await axios.delete(`/api/systemUser/deleteRole/${id}`);
    return res;
  } catch (error) {
    console.log("failed to delete", error);
  }
};

export const updateRole = async (id, data) => {
  try {
    const res = await axios.patch(`/api/systemUser/updateRole/${id}`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllFeaturePermission = async () => {
  try {
    const res = await axios.get("/api/systemUser/getAllFeaturePermission");
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getFeaturePermissions = async (id) => {
  try {
    const res = await axios.get("/api/systemUser/getPermission", {
      params: {
        featureId: id,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getContentPermissions = async (id) => {
  try {
    const res = await axios.get("/api/systemUser/getPermission", {
      params: {
        contentId: id,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getRoleById = async (id) => {
  try {
    const res = await axios.get(`/api/systemUser/getRole/${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
