import React, { useState, useEffect } from 'react'
import { CircularProgress, Box } from "@mui/material";
import SuccessPage from './SuccessPage';
import FailurePage from './FailurePage';
import { verifyEmail } from './EmailVerification.api';

const EmailVerification = () => {
    const [status, setstatus] = useState("pending");
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");

    useEffect(() => {
      verifyEmail(token)
      .then(res =>{
        console.log(res);
        if (res?.status >= 200 && res?.status <= 299) {
          setstatus("success");
        } else {
          setstatus("failure");
        }
      })
      .catch(err =>{
        console.log(err);
        setstatus("failure");
      })
    }, [])
    

    if(status==='pending'){
        return (
          <Box
            display="flex"
            width="100vw"
            height="100vh"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size="5rem" />
          </Box>
        );
    }
    else if(status==='failure'){
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100vw"
            height="100vh"
            justifyContent="center"
            alignItems="center"
          >
            <FailurePage />
          </Box>
        );
    }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <SuccessPage/>
    </Box>
  );
}

export default EmailVerification