import React from 'react';
import { Link } from 'react-router-dom';
import { utils, write } from 'xlsx';

const ExcelDownloadButton = ({ getDataCallback, headers, filename, val }) => {
  const generateExcelFile = () => {
    const workbook = utils.book_new();

    const headerStyle = { font: { bold: true } };

    const data = getDataCallback();
    const valuesArray = data.map(obj => Object.values(obj));
    debugger
    const worksheetData = [headers.map(header => ({ v: header, s: headerStyle })), ...valuesArray];
    const worksheet = utils.aoa_to_sheet(worksheetData, { cellStyles: true });

    const headerRange = utils.decode_range(worksheet['!ref']);
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const headerCell = utils.encode_cell({ r: 0, c: col });
      const cell = worksheet[headerCell];
      cell.s = headerStyle;
    }

    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelFile = write(workbook, { type: 'binary', bookType: 'xlsx' });

    const blob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });
    const downloadUrl = URL.createObjectURL(blob);
    return downloadUrl;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const handleDownload = () => {
    const url = generateExcelFile();
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.xlsx`;
    link.click();
  };

  return (
    <Link to="#" onClick={handleDownload} style={{ cursor: 'pointer' }}>
      {val}
    </Link>
  );
};

export default ExcelDownloadButton;