import { Stack, Typography } from '@mui/material'
import React from 'react'
import CustomMuiSwitch from '../../../utils/customMuiSwicth/CustomMuiSwitch';

const PermissionLabelSwitch = (props) => {
    const {name, value, handleSwitchChange, checked} = props;
  return (
    <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'}>
        <Typography sx={{ textTransform: 'capitalize' }}>{name}</Typography>
        <CustomMuiSwitch checked={checked} value={value} handleChange={handleSwitchChange} />
    </Stack>
  )
}

export default PermissionLabelSwitch