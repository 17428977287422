import { Button, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AnimatedPage from "../animatedPage/AnimatedPage";

const ChanegPassword = (props) => {
  const { changePassword, handleShowChanegPassword } = props;
  return (
    <Box sx={{ alignItems: 'flex-end', width: 200 }}>
      <Button
        onClick={handleShowChanegPassword}
        variant="contained"
        sx={{ textTransform: "capitalize", }}
      >
        Change Password
      </Button>
      {changePassword && (
        <Stack spacing={2} sx={{ mt: 7 }}>
          <TextField label="Current Password" />
          <TextField label="New Password" />
          <TextField label="Confirm Password" />
          <Button variant="contained" sx={{ textTransform: "capitalize" }}>
            Submit
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ChanegPassword;
