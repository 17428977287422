import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfPanel from "./ConfPanel";
import { deletePanel, toggleIsActivePanel } from "./panel.api";

const Panel = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [panels, setpanels] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      setpanels(data);
    }
    return () => {
      setpanels(data);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.panelId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (id) => {
    deletePanel(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const handleToggleActive = (id, isActive) => {
    console.log("value", id);
    console.log('value', isActive)
    let data;
    if (isActive) {
      data = {
        isActive: false,
      };
    }else{
      data = {
        isActive: true,
      };
    }

    toggleIsActivePanel(id, data)
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rows = [];
  const labels = ["Name", "Status", "Actions"];
  panels &&
    panels.length > 0 &&
    panels.map((item) =>
      rows.push([
        item.panelName,
        <CustomMuiSwitch
          value={item.status}
          handleChange={(value) => handleToggleActive(item.panelId, item.isActive)}
          checked={item.isActive}
        />,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item.panelId);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>
          <ConfirmAlert
            msg={"Are you sure you want to delete?"}
            onClickEvent={() => handleDelete(item.panelId)}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
      <TopHeading openModal={handleOpenModal} heading="Panels" />
      <ConfPanel
        open={openModal}
        loading={loading}
        type="ADD"
        hadnleCloseModal={handleCloseModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
      />
      <ConfPanel
        open={openEditModal}
        loading={loading}
        type="EDIT"
        hadnleCloseModal={handleCloseEditModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
        editId={editId}
        selectedItem={selectedItem}
      />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
    </>
  );
};

export const EnhancedPanel = Hoc(Panel, { name: "panel" });
