import axios from "axios";

export const getAllPanelistByPanelId = async (panelId,apiName) => {
  try {
    const res = await axios.get(
      `/api/user/${apiName}?panelId=${panelId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getPanelistById = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/systemUser/getPanelistById?panelistId=${panelistId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getAllLanguage = async (panelistId) => {
  try {
    const res = await axios.get(`/api/systemUser/getLanguage`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getPanelistCountryLanguage = async (data) => {
  try {
    const res = await axios.post(`/api/panelist/getPanelistLanguage`, data);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getActiveCountry = async (data) => {
  try {
    const res = await axios.get(`/api/systemUser/getActiveCountry`, data);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getPanelistPointDetail = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getPanelistPointDetail?panelistId=${panelistId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getSurveyParticipationPointsDetail = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getSurveyParticipationPointsDetail?panelistId=${panelistId}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getSurveyParticipationPoints = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getSurveyParticipationPoints?panelistId=${panelistId}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
export const getPanelistPointSummary = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getPanelistPointSummary?panelistId=${panelistId}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getPanelistPointRedeemed = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getPanelistPointRedeemed?panelistId=${panelistId}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const getPanelistPointRedeemedDetail = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/user/getPanelistPointRedeemedDetail?panelistId=${panelistId}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const updatePanelist = async (data) => {
  try {
    const res = await axios.post(`/api/company/updatePanelist`, data);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};
export const getPanelistDetails = async (panelistId) => {
  try {
    const res = await axios.get(
      `/api/company/getPanelistDetails?panelistId=${panelistId}`);
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

