import { Box, Checkbox, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import AnimatedPage from "../../animatedPage/AnimatedPage";
import { getPanelUserProfileQsn } from "../userProfileQuestionaire.api";
import SelectedCategories from "./SelectedCategories";
import SelectedLanguages from "./SelectedLanguages";

const ViewUserProfileQuestionaire = () => {
  const labels = ["Question", "Mini Survey", "Category", "Type", "Required"];

  const location = useLocation();
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [country, setCountry] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    const { state } = location;
    console.log("state", state);
    if (state?.panelId && state?.countryId && state?.languageId) {
      setTableLoading(true);
      getPanelUserProfileQsn(state.panelId, state.countryId, state.languageId)
        .then((res) => {
          const { data } = res.data;
          setQuestions(data?.questions);

          // console.log("questions", data?.questions);
          // let dupSelectedlang = [];
          // data[0]?.language?.map((item) => {
          //   if (item.isSelected) {
          //     dupSelectedlang.push(item);
          //   }
          // });
          // setLanguages(dupSelectedlang);
          setCountry(data?.country);
          setLanguage(data?.language);
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => setTableLoading(false));
    }

    return () => {
      setQuestions([]);
      // setLanguages([]);
      setCategories([]);
    };
  }, [location]);

  const rows = [];
  questions?.map((item) =>
    rows.push([
      item?.qsnShortForm,
      item && item.miniSurveyName ? item.miniSurveyName : "---",
      item?.category,
      item?.questionType,
      <Checkbox checked={Boolean(item?.isRequired)} />,
    ])
  );
  return (
    <AnimatedPage>
      <Box>
        <TopHeading
          showButton={Boolean(false)}
          link={location.state?.previousPage}
          heading={`${location.state?.panelName} Question Configurations`}
          nextPageBtn={{
            link: "edit",
            text: "Edit",
            stateData: {
              profile_id: location.state?.profile_id,
              panel_id: location.state?.panelId,
              previousPage: location.pathname,
            },
          }}
        />
        <Box sx={{ px: 4 }}>
          <Stack direction={"row"} sx={{ width: "100%", mb: 2 }} spacing={2}>
            {/* <MultipleValueDropDown
              value={languages}
              label={"Selected Languages"}
              options={languages}
              objkey={"languageName"}
            /> */}
            <TextField
              value={language ? language : ""}
              fullWidth
              label={"Selected Language"}
            />
            <TextField
              value={country ? country : ""}
              fullWidth
              label={"Selected Country"}
            />
            {/* <MultipleValueDropDown
              value={categories}
              label={"Selected Categories"}
              options={categories}
              objkey={"category"}
            /> */}
            {/* // <SelectedLanguages options={languages} />
            // <SelectedCategories options={} /> */}
          </Stack>
          <Typography sx={{ px: 1, my: 1 }} component={"h4"} variant="p">
            Selected Qestions
          </Typography>
          <CustomTable noPadding auto data={rows} labels={labels} />
        </Box>
      </Box>
    </AnimatedPage>
  );
};

export default ViewUserProfileQuestionaire;
