import axios from "axios";

export const getData = async (url) => {
  try {
    const res = await axios.get(url);
    return res;
  } catch (error) {
    return null;
  }
};
