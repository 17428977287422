import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import styles from "./styles.modules.css";
import { PuffLoader } from "react-spinners"
import { css } from '@emotion/react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3F72AF",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const loaderCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9F7F7",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#DBE2EF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomDataTable = ({ columns, data, progressPending, conditionalRowStyles }) => {
  const showData = data.length > 0;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <style>
        {
          `.data-table .rdt_TableHeadRow {
            background-color: #3F72AF;
            color: #fff;
          }`
        }
      </style>

      {
        isLoading ? (
          <div css={loaderCss}>
            <PuffLoader color='#123abc' size={50} style={{position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%,-50%)"}} />
          </div>
        ) : (
          <>
            {showData ? (
              <div className='data-table'>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination={true}
                  progressPending={progressPending}
                  highlightOnHover
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[100,200,300,400,500]}
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  conditionalRowStyles={conditionalRowStyles}
                />
              </div>) : (
              <Box pt={0} pb={0}>
                <TableContainer
                  style={{
                    height: "auto",
                    position: "relative",
                    overflowY: "scroll",
                  }}
                  className={styles.table}
                  component={Paper}
                >
                  <Table
                    stickyHeader={Boolean(true)}
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableRow>
                        {columns &&
                          columns.length > 0 &&
                          columns.map((coumn, index) => (
                            <StyledTableCell key={index}>{coumn.name}</StyledTableCell>
                          ))}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow >
                        <StyledTableCell colSpan={columns.length} align="center">
                          No data available
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
            }
          </>
        )
      }
    </>
  )
}

export default CustomDataTable
