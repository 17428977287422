import {
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../../../utils/asyncDropdown/AsyncDropdown";
import CustomMuiSwitch from "../../../../utils/customMuiSwicth/CustomMuiSwitch";
import { createSurveyQualification } from "../../survey.api";
import QualificationOptions from "./QualificationOptions";
import "./styles.css";

const AddQualification = (props) => {
  const { surveyResdata, index, handleChange, questions, setAlert } = props;
  const [surveyCode, setSurveyCode] = useState("");
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [isRange, setIsRange] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [qualification, setQualification] = useState({
    from: "",
    to: "",
    inputValue: "",
    createdBy: adminId,
  });

  const handleChangeInputType = () => {
    setIsRange((prev) => !prev);
    let duplicate = { ...qualification };
    if (selectedQuestions?.questionType === "Date") {
      duplicate["from"] = moment().format("YYYY-MM-DD");
      duplicate["to"] = moment().format("YYYY-MM-DD");
      duplicate["inputValue"] = moment().format("YYYY-MM-DD");
    } else {
      duplicate["from"] = "";
      duplicate["to"] = "";
      duplicate["inputValue"] = "";
    }
    setQualification(duplicate);
  };
  const handleChangeQuestion = (value) => {
    if (value) {
      setSelectedQuestions(value);
    }
  };

  const handleInputChnage = (e) => {
    const { name, value } = e.target;
    setQualification((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let duplicate = { ...qualification };
    if (selectedQuestions?.questionType === "Date") {
      duplicate["from"] = moment().format("YYYY-MM-DD");
      duplicate["to"] = moment().format("YYYY-MM-DD");
      duplicate["inputValue"] = moment().format("YYYY-MM-DD");
    } else {
      duplicate["from"] = "";
      duplicate["to"] = "";
      duplicate["inputValue"] = "";
    }

    console.log("changed question", selectedQuestions);
    setOptions(selectedQuestions.option);
    setQualification(duplicate);
  }, [selectedQuestions]);

  useEffect(() => {
    if (surveyResdata?.surveyCode) {
      setSurveyCode(surveyResdata?.surveyCode);
    }
    return () => {
      setSurveyCode("");
    };
  }, [surveyResdata]);

  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    console.log("checked options", checkedOptions);
  }, [checkedOptions]);

  const handleSubmit = () => {
    let data = { ...qualification };
    // data["panelQsnId"] = selectedQuestions.panelQsnId;
    data["masterQsnId"] = selectedQuestions.masterQsnId;
    data["surveyId"] = surveyResdata.id;
    // range input option

    // console.log("selected question", selectedQuestions);
    if (!selectedQuestions?.masterQsnId) {
      setAlert("Select Qualification");
      return;
    }

    if (!multiType.includes(selectedQuestions?.questionType)) {
      data["qualificationType"] = isRange ? "range" : "input";
      if (isRange) {
        data["inputValue"] = "";
      } else {
        data["from"] = "";
        data["to"] = "";
      }
    } else {
      data["qualificationType"] = "option";
    }

    let selectedOptionsDup = [];
    let dupCheckedQuestions = [...checkedOptions];

    let allOptionsDup = [...dupCheckedQuestions];
    allOptionsDup?.map((item) => {
      if (item?.selected) {
        let obj = {
          // qsnOptionTransId: item?.qsnOptionTranslationId,
          masterQsnOptionId: item?.masterQsnOptionId,
        };
        selectedOptionsDup.push(obj);
      }
    });
    data["option"] = [...selectedOptionsDup];
    if (data?.qualificationType === "option" && data?.option?.length <= 0) {
      setAlert("Select atleast one option");
      return;
    }

    setLoading(true);
    createSurveyQualification(data)
      .then((res) => {
        if (res?.status === 200) {
          setAlert("Successfully Added");
          setQualification({
            from: "",
            to: "",
            inputValue: "",
            createdBy: adminId,
          });
          setCheckedOptions([]);
          setOptions([]);
          setSelectedQuestions({});
        } else if (
          res?.response?.data?.message ===
          "Qualificaton Already Created on this Question"
        ) {
          setAlert("Qualification already exists.");
        }
      })
      .catch((err) => {
        setAlert("Something went wrong");
        return err;
      })
      .finally(() => setLoading(false));
  };

  const multiType = ["Single Punch", "Multi Punch"];

  return (
    <Card sx={{ mx: 4, mt: 2, p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="p" component={"h4"}>
          New Qualification
        </Typography>
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #f0f0f0",
            },
          }}
          value={surveyCode}
          label={"Survey ID"}
        />
        <AsyncDropdown
          value={selectedQuestions}
          options={questions}
          objKey={"qsnShortForm"}
          stylized
          label={"Select Qualifications"}
          handleChange={handleChangeQuestion}
        />
        {!multiType.includes(selectedQuestions?.questionType) && (
          <Stack
            sx={{ border: "1px solid #f5f5f5", p: 1 }}
            direction={"row"}
            spacing={2}
            justifyContent={"flex-end"}
          >
            <Typography>Input Field</Typography>
            <CustomMuiSwitch
              handleChange={handleChangeInputType}
              checked={isRange}
            />
            <Typography>Range</Typography>
          </Stack>
        )}

        {isRange
          ? !multiType.includes(selectedQuestions?.questionType) && (
              <Stack direction={"column"} spacing={2}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"From"}
                  name="from"
                  value={qualification.from}
                  onChange={handleInputChnage}
                  type={
                    selectedQuestions?.questionType === "Date" ? "date" : "text"
                  }
                />
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"To"}
                  name="to"
                  type={
                    selectedQuestions?.questionType === "Date" ? "date" : "text"
                  }
                  value={qualification.to}
                  onChange={handleInputChnage}
                />
              </Stack>
            )
          : !multiType.includes(selectedQuestions?.questionType) && (
              <TextField
                value={qualification.inputValue}
                type={
                  selectedQuestions?.questionType === "Date" ? "date" : "text"
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label="Enter Value"
                name="inputValue"
                onChange={handleInputChnage}
              />
            )}

        {multiType.includes(selectedQuestions?.questionType) && (
          <QualificationOptions
            checkedOptions={checkedOptions}
            setCheckedOptions={setCheckedOptions}
            option={options}
          />
        )}
        {surveyResdata && surveyResdata?.surveyCode && (
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="addButton"
          >
            {loading ? (
              <CircularProgress
                size={"21px"}
                sx={{ margin: "0 14px", color: "white", padding: 0 }}
              />
            ) : (
              "Add"
            )}
          </button>
        )}
      </Stack>
    </Card>
  );
};

export default AddQualification;

// {
//   /* <Grid
//           container
//           spacing={{ xs: 2, md: 1 }}
//           columns={{ xs: 6, sm: 8, md: 12 }}
//           sx={{ mb: 4 }}
//         >
//           <Grid item xs={6} sm={6} md={6}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   defaultChecked
//                   sx={{
//                     color: "#1a1a1a",
//                     "&.Mui-checked": {
//                       color: "#1a1a1a",
//                     },
//                   }}
//                 />
//               }
//               label="Phone"
//             />
//           </Grid>
//           <Grid item xs={2} sm={6} md={6}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   defaultChecked
//                   sx={{
//                     color: "#1a1a1a",
//                     "&.Mui-checked": {
//                       color: "#1a1a1a",
//                     },
//                   }}
//                 />
//               }
//               label="Phone"
//             />
//           </Grid>
//           <Grid item xs={2} sm={6} md={6}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   defaultChecked
//                   sx={{
//                     color: "#1a1a1a",
//                     "&.Mui-checked": {
//                       color: "#1a1a1a",
//                     },
//                   }}
//                 />
//               }
//               label="Phone"
//             />
//           </Grid>
//           <Grid item xs={2} sm={6} md={6}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   defaultChecked
//                   sx={{
//                     color: "#1a1a1a",
//                     "&.Mui-checked": {
//                       color: "#1a1a1a",
//                     },
//                   }}
//                 />
//               }
//               label="Phone"
//             />
//           </Grid>
//         </Grid> */
// }
