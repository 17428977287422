import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import ConfFeature from "./ConfFeature";
import { deleteFeature } from "./feature.api";

const Feature = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [features, setfeatures] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      setfeatures(data);
    }
    return () => {
      setfeatures(data);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.featureId === editId);
      console.log(item);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  // const handleDelete = (id) => {
  //   deleteFeature(id)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         setSuccess();
  //       } else {
  //         setError("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       setError("Something went wrong");
  //     });
  // };

  const rows = [];
  const labels = ["Name", "Type", "Status", "Actions"];
  features &&
    features.length > 0 &&
    features.map((item) =>
      rows.push([
        item?.feature,
        item?.featureType,
        <CustomMuiSwitch checked={item?.isActive} />,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item?.featureId);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>
          {/* <ConfirmAlert msg={'Are you sure you want to delete?'} onClickEvent={() => handleDelete(item.featureId)}>
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert> */}
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading openModal={handleOpenModal} heading="Feature" />
        <ConfFeature
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
        />
        <ConfFeature
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
        />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedFeature = Hoc(Feature, { name: "feature" });
