import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { useSelector } from "react-redux";
import {
  createPanelQuestionTranslation,
  updateQuestionTranslation,
} from "./panelQuestions.api";

const ConfTranslateQuestion = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    editId,
    selectedItem,
    answerTypes,
    setAlert,
    setSuccess,
    setDataFetched,
    selectedTranslation,
    countryLanguage,
  } = props;

  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [translatedata, settranslatedata] = useState({
    questionStatement: "",
    questionTranslation: "",
    countryId: "",
    languageId: "",
    category: "",
    answerType: "",
    createdBy: adminId,
  });
  const [answerType, setAnswerType] = useState({});
  const [selecftedCountryLanguage, setSelecftedCountryLanguage] = useState({});
  const [newOptions, setNewOptions] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (type === "TRANSLATE") {
      if (countryLanguage?.length > 0) {
        const item = countryLanguage.find(
          (i) => Number(i.countryId) === Number(4) && i.languageId === 1
        );
        if (item) {
          setSelecftedCountryLanguage(item);
        }
      }
    } else {
      if (selectedTranslation) {
        const item = countryLanguage.find(
          (item) =>
            item.countryId ===
              selectedTranslation?.countrytranslation?.countryId &&
            item.languageId === selectedTranslation?.languagetranslation?.id
        );
        if (item) {
          setSelecftedCountryLanguage(item);
        }
      }
    }
    return () => {
      setSelecftedCountryLanguage({});
    };
  }, [countryLanguage, selectedTranslation, open]);

  const handleNewTranslatedOptions = (e, index, data) => {
    let { value } = e.target;
    const id = data.qsnOptionTranslationId;
    const masterOptionId = data.masterQsnOptionId;
    let duplicate = [...newOptions];
    duplicate[index]["optionStatement"] = value;
    duplicate[index]["qsnOptionId"] = id;
    duplicate[index]["masterQsnOptionId"] = masterOptionId;
    setNewOptions(duplicate);
  };

  useEffect(() => {
    if (selectedItem) {
      let duplicate = { ...translatedata };
      duplicate["questionStatement"] = selectedItem?.qsnStatement;
      duplicate["category"] = selectedItem?.category;
      if (selectedItem?.questionTypeId) {
        const item = answerTypes?.find(
          (item) => item?.questionTypeId === selectedItem?.questionTypeId
        );
        setAnswerType(item);
      }
      if (type === "TRANSLATE") {
        setOptions(selectedItem?.option);
      } else {
        console.log("translation data", selectedTranslation);
        duplicate["questionTranslation"] =
          selectedTranslation?.questionStatement;
      }
      settranslatedata(duplicate);
    }
  }, [open, selectedItem, selectedTranslation]);

  useEffect(() => {
    let duplicateNewoptions = [];
    options?.map((item, index) => {
      let obj = {
        qsnOptionId: "",
        optionStatement: "",
        masterQsnOptionId: "",
      };
      duplicateNewoptions.push(obj);
    });
    setNewOptions(duplicateNewoptions);

    return () => {
      setNewOptions([]);
      setOptions([]);
    };
  }, [open]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    settranslatedata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropDownChange = (name, value) => {
    if (value) {
      switch (name) {
        case "language":
          setSelecftedCountryLanguage(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === "TRANSLATE") {
      let finalOptions = [];
      let duplicateNewQs = [...newOptions];
      duplicateNewQs?.map((item) => {
        if (item.optionStatement !== "") {
          finalOptions.push(item);
        }
      });
      let data = {
        masterQsnId: selectedItem.masterQsnId,
        panelQsnId: selectedItem.panelQsnId,
        questionStatement: translatedata.questionTranslation,
        countryId: selecftedCountryLanguage?.countryId,
        languageId: selecftedCountryLanguage?.languageId,
        createdBy: adminId,
        option: [...finalOptions],
      };
      setLoading(true);
      createPanelQuestionTranslation(data)
        .then((res) => {
          if (res?.status >= 200 && res?.status <= 299) {
            console.log(res);
            setSuccess();
            setDataFetched(true);
          } else {
            if (
              res?.response?.data?.message ===
              "Panel Question Translation Already Created"
            ) {
              setAlert("Translation already exists");
            }
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        })
        .finally(() => setLoading(false));
    } else {
      const data = {
        masterQsnId: selectedItem?.panelQsnId,
        questionStatement: translatedata.questionTranslation,
      };

      // console.log("selected question", selectedItem);
      // console.log("update data", data);
      // return;
      setLoading(true);
      updateQuestionTranslation(selectedTranslation.qsnTranslationId, data)
        .then((res) => {
          if (res?.status >= 200 && res?.status <= 299) {
            setAlert("Translation Updated Successfully");
            setSuccess();
            setDataFetched(true);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"sm"}
        sx={{ p: 2 }}
        open={open}
        onClose={() => {
          hadnleCloseModal();
          setNewOptions([]);
          settranslatedata({
            questionStatement: "",
            questionTranslation: "",
            languageId: "",
            category: "",
            createdBy: adminId,
          });
        }}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>
                {type === "TRANSLATE"
                  ? "Translate Question"
                  : "Edit Translation"}
              </h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                {type === "TRANSLATE" && (
                  <Stack direction={"row"} spacing={2}>
                    <TextField
                      required
                      disabled
                      onChange={handleOnInputchange}
                      value={translatedata.questionStatement}
                      fullWidth
                      label="Question Statement"
                      name="questionStatement"
                    />
                  </Stack>
                )}
                <AsyncDropdown
                  disabled={type === "TRANSLATE" ? false : true}
                  value={selecftedCountryLanguage}
                  options={countryLanguage}
                  objKey={"countryLanguage"}
                  label={"Select Language"}
                  handleChange={(value) =>
                    handleDropDownChange("language", value)
                  }
                />
                <TextField
                  required
                  onChange={handleOnInputchange}
                  value={translatedata.questionTranslation}
                  fullWidth
                  label="Question Translation"
                  name="questionTranslation"
                />
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    disabled
                    aria-readonly
                    fullWidth
                    value={translatedata?.category}
                    label={"Category"}
                  />
                  <TextField
                    disabled
                    aria-readonly
                    fullWidth
                    value={selectedItem?.questionType}
                    label={"Question Type"}
                  />
                </Stack>
              </Stack>
              {type === "TRANSLATE" && options && options.length > 0 ? (
                <>
                  <Stack direction={"row"} spacing={2}>
                    <Stack sx={{ width: "100%" }}>
                      <Typography sx={{ mb: 2 }}>Current options</Typography>
                      <Stack direction={"column"} spacing={2}>
                        {options.map((item, index) => (
                          <TextField
                            key={index}
                            aria-readonly
                            //   onChange={(e) => handleOptionInputChange(e, index)}
                            value={item?.optionStatement}
                            fullWidth
                            label={`Option ${index + 1}`}
                            name="optionStatement"
                          />
                        ))}
                      </Stack>
                    </Stack>
                    <Stack sx={{ width: "100%" }}>
                      <Typography sx={{ mb: 2 }}>Translate Options</Typography>
                      <Stack spacing={2}>
                        {options.map((item, index) => (
                          <TextField
                            value={newOptions[index]?.optionStatement}
                            onChange={(e) =>
                              handleNewTranslatedOptions(e, index, item)
                            }
                            type={"text"}
                            key={index}
                            fullWidth
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "TRANSLATE" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfTranslateQuestion;
