import axios from "axios";

export const getUserSurveyParticipation = async (token, status) => {
  try {
    const res = await axios.get(
      `/api/user/getUserSurveyParticipation?token=${token}&status=${status}`
    );
    return res;
  } catch (error) {
    debugger
    console.log("error", error.message);
  }
};

export const updateS2SSurveyParticipation = async (token, status) => {
  try {
    const res = await axios.put(
      `/api/user/updateS2SSurveyParticipation?token=${token}&status=${status}`
    );
    return res;
  } catch (error) {
    return error;
    console.log("error", error.message);
  }
};

