import axios from "axios";

export const getTenantProfile = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getProfile?adminId=${id}`);
    return res;
  } catch (error) {
    console.log("failed fetching tenant profile", error.message);
  }
};
export const getCountryById = async (id) => {
  try {
    const res = await axios.get(`/api/systemUser/getCountry/${id}`);
    return res;
  } catch (error) {
    console.log("failed fetching", error.message);
  }
};
export const updateTenantProfile = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/Company/updateTenantProfile/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("failed updating", error.message);
  }
};
export const createTenantProfile = async (data) => {
  try {
    const res = await axios.post(`/api/Company/createTenantProfile`, data);
    return res;
  } catch (error) {
    console.log("failed updating", error.message);
  }
};
