import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import AnimatedPage from "../../components/animatedPage/AnimatedPage";
import NewSideBar from "../../utils/sideBar/NewSideBar";
import { DrawerHeader } from "../../utils/sideBar/SideBar";
import "./index.css";

const HomeLayout = () => {
  const open = useSelector((state) => state.sideBar.sideBarOpen);
  const drawerWidth = useSelector((state) => state.sideBar.drawerWidth);
  return (
    <>
      <NewSideBar />
      <Box
        className="home-container"
        component="main"
        style={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100% - ${drawerWidth}px)` : `auto`,
          marginLeft: open ? `calc(${drawerWidth}px)` : "70px",
          overflow: !open ? "hidden" : "auto",
        }}
      >
        <AnimatedPage>
          <Outlet />
        </AnimatedPage>
      </Box>
    </>
  );
};

export default HomeLayout;
