import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CreateIcon from "@mui/icons-material/Create";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfManageMember from "./ConfManageMember";
import { useSelector } from "react-redux";
import { deleteMember } from "../users/user.api";
import AnimatedPage from "../animatedPage/AnimatedPage";

const ManageMember = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [members, setmembers] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  console.log("loading status", loading);
  useEffect(() => {
    if (data) {
      console.log(data)
      setmembers(data);
    }
    return () => {
      setmembers(data);
    };
  }, [data]);

  useEffect(() => {
    if (editId && members && members.length > 0) {
      const item = members.find((member) => member.adminId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }
    return () => {
      setSelectedItem({});
    };
  }, [editId, members]);

  const handleDelete = (id) => {
    deleteMember(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rows = [];
  const labels = ["Name", "Email", "Contact", 'Role', "Status", "Actions"];
  members &&
    members.length > 0 &&
    members.map((member) =>
      rows.push([
        member.firstName + " " + member.lastName,
        member.email,
        member.contact,
        member.role.role,
        <CustomMuiSwitch checked={member.isActive} />,
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              setEditId(member.adminId);
              handleOpenEditModal();
            }}
          >
            <CreateIcon />
          </IconButton>
          <ConfirmAlert
            onClickEvent={() => handleDelete(member.adminId)}
            msg={"Are you sure?"}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
    <AnimatedPage>
      <TopHeading openModal={handleOpenModal} heading="Manage Member" />
      <ConfManageMember
        open={openModal}
        type="ADD"
        hadnleCloseModal={handleCloseModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
      />
      <ConfManageMember
        open={openEditModal}
        type="EDIT"
        hadnleCloseModal={handleCloseEditModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
        editId={editId}
        selectedItem={selectedItem}
      />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedManageMember = Hoc(ManageMember, {
  name: "manage-member",
});
