import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { addUser, getUserById, updateUser } from "../users/user.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRoles } from "../roles/roles.api";
import { useDispatch, useSelector } from "react-redux";
import { setPanelAdminAdded } from "../../features/panelSidebarItemSlice";
import { fetchAllCountry } from "../country/country.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";
import { getAllFeature } from "../feature/feature.api";
import { getFeaturesByTenantId } from "../companyFeatures/companyFeatures.api";
import { createPanelAdmin } from "./managePanel.api";
import { useDebounce } from "../../hooks/useDebounce";
import { validateEmail } from "../../assets/js/validateEmail";
import { checkEmail } from "../companyOnBoard/companyOnBoard.api";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ConfPanelAdmin = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const loggedInUserId = useSelector((state) => state.auth.userData.adminId);
  const [countries, setCountries] = useState([]);
  const [features, setFeatures] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [featuresSelected, setFeaturesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    contact: "",
    email: "",
    password: "",
    createdBy: loggedInUserId,
    roleId: roleSelected.roleId,
    tenantId: loggedInUserId,
  });

  // Panel deatils
  const [panelDetails, setPanelDetails] = useState({
    address: "",
    city: "",
    zip: "",
    state: "",
    country_id: "",
    created_by: loggedInUserId,
  });

  const handlePanelDetailsChnage = (e) => {
    const { name, value } = e.target;
    setPanelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    setSecondFormValidation((prev) => ({
      ...prev,
      [name]: "",
    }));
  };
  // stepper states and functions
  const steps = [
    "Create panel Admin",
    "Panel Detail",
    "Select countries and features",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // form validation

  // first form
  const [firstFormValidation, setFirstFormValidation] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    password: "",
  });
  // second form
  const [secondFormValidation, setSecondFormValidation] = useState({
    address: "",
    city: "",
    zip: "",
    state: "",
    country_id: null,
  });
  // third form
  const [thirdFormValidation, setThirdFormValidation] = useState({
    countries: "",
    features: "",
  });

  // check if mail exists or not
  useDebounce(() => checkIfMailExists(), 1000, [userData.email]);
  function checkIfMailExists() {
    if (userData.email && validateEmail(userData.email)) {
      console.log("checking mail for validation");
      let data = {
        email: userData.email,
      };
      checkEmail(data)
        .then((res) => {
          if (res?.status != 200) {
            setFirstFormValidation((prev) => ({
              ...prev,
              email: "Mail already exists.",
            }));
          }
        })
        .catch((err) => {
          console.log("error checking the mail", err.message);
        });
    }
  }

  const handleComplete = () => {
    if (activeStep === 0) {
      let duplicate = { ...firstFormValidation };
      if (userData.firstName === "") {
        duplicate["firstName"] = "First name is required";
      }
      if (userData.lastName === "") {
        duplicate["lastName"] = "Last name is required";
      }
      if (userData.email === "") {
        duplicate["email"] = "Email is required";
      }
      if (userData.contact === "") {
        duplicate["contact"] = "Contact is required";
      }
      if (userData.password === "") {
        duplicate["password"] = "Password is required";
      }
      if (userData.password !== "" && userData.password !== confirmPassword) {
        duplicate["password"] = "Password doesn't match";
      }

      setFirstFormValidation(duplicate);
      const noError = Object.values(duplicate).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }
    } else if (activeStep === 1) {
      // validation for second form;
      let duplicate = { ...secondFormValidation };
      if (panelDetails.address === "") {
        duplicate["address"] = "Address is required";
      }
      if (panelDetails.city === "") {
        duplicate["city"] = "City is required";
      }
      if (panelDetails.state === "") {
        duplicate["state"] = "State is required";
      }
      if (panelDetails.zip === "") {
        duplicate["zip"] = "Zip is required";
      }
      if (!panelDetails.country_id) {
        duplicate["country_id"] = "Country is required";
      }
      setSecondFormValidation(duplicate);
      const noError = Object.values(duplicate).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }
    } else if (activeStep == 2) {
      let duplicate = { ...thirdFormValidation };
      if (countriesSelected && countriesSelected.length < 1) {
        duplicate["countries"] = "Selecte Atleast One Country";
      }
      if (featuresSelected && featuresSelected.length < 1) {
        duplicate["features"] = "Selecte Atleast One Feature";
      }
      setThirdFormValidation(duplicate);
      const noError = Object.values(duplicate).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  // end stepper states and functions

  useEffect(() => {
    if (roleSelected) {
      setSelectedRole(roleSelected);
      setUserData((prev) => ({
        ...prev,
        roleId: roleSelected.roleId,
      }));
    }
    return () => {
      setSelectedRole({});
    };
  }, [roleSelected, open]);
  useEffect(() => {
    getRoles()
      .then((res) => {
        if (res?.status === 200) {
          setroles(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // change to fetch country by tenant admin
    fetchAllCountry()
      .then((res) => {
        if (res?.status === 200) {
          setCountries(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getFeaturesByTenantId(loggedInUserId)
      .then((res) => {
        if (res?.status === 200) {
          console.log("features fetched", res.data.data);
          setFeatures(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setroles([]);
      setCountries([]);
      setFeatures([]);
      setFeaturesSelected([]);
      setCountriesSelected([]);
      setActiveStep(0);
      setCompleted({});
    };
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFirstFormValidation((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem.roleId &&
      editId &&
      type === "EDIT" &&
      roles &&
      roles.length > 0
    ) {
      const role = roles.find((item) => item.roleId === selectedItem.roleId);
      if (role) {
        setSelectedRole(role);
      }
    }
    if (editId && type === "EDIT") {
      setPageLoading(true);
      if (selectedItem) {
        setUserData(selectedItem);
        setPageLoading(false);
      }
    }
    return () => {
      setUserData({
        firstName: "",
        lastName: "",
        middleName: "",
        contact: "",
        email: "",
        password: "",
        createdBy: loggedInUserId,
        roleId: roleSelected.roleId,
        tenantId: loggedInUserId,
      });
      setPanelDetails({
        firstName: "",
        lastName: "",
        middleName: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        country_id: "",
        created_by: loggedInUserId,
      });
      setSelectedCountry({});
      setFirstFormValidation({
        firstName: "",
        lastName: "",
        contact: "",
        email: "",
        password: "",
      });
      setSecondFormValidation({
        address: "",
        city: "",
        zip: "",
        state: "",
        country_id: null,
      });
      setThirdFormValidation({ countries: "", features: "" });
    };
  }, [editId, selectedItem, open, roles]);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === "ADD" && confirmPassword !== userData.password) {
      setAlert("Password doesn't match");
      return;
    }
    if (userData.password) {
      if (checkPassword(userData.password) === false) {
        setAlert(
          "Password must contain min 8 letter, with at least a symbol, upper and lower case letters and a number"
        );
        return;
      }
    }
    if (userData.roleId === "") {
      setAlert("Please select a role");
      return;
    }

    let selectedCountriesIds = [];
    let selectedFeaturesIds = [];

    if (featuresSelected && featuresSelected.length > 0) {
      featuresSelected.map((item) => selectedFeaturesIds.push(item.featureId));
    }
    if (countriesSelected && countriesSelected.length > 0) {
      countriesSelected.map((item) =>
        selectedCountriesIds.push(item.countryId)
      );
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      let panelAdminData = {
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        email: userData.email,
        password: userData.password,
        contact: userData.contact,
        tenantId: loggedInUserId,
        roleId: userData.roleId,
        createdBy: loggedInUserId,
        profilePicUrl: "pic url",
        address: panelDetails.address,
        city: panelDetails.city,
        state: panelDetails.state,
        zip: panelDetails.zip,
        countryid: panelDetails.country_id,
        countryId: selectedCountriesIds,
        featureId: selectedFeaturesIds,
      };
      callMethod = createPanelAdmin(panelAdminData);
    } else {
      let data = {
        contact: userData.contact,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        middleName: userData.middleName,
        updatedBy: loggedInUserId,
        roleId: selectedRole.roleId,
      };
      callMethod = updateUser(editId, data);
    }

    callMethod
      .then((res) => {
        console.log("added", res);
        if (res?.status === 201 || res?.status === 200) {
          setSuccess();
          setUserData({
            firstName: "",
            lastName: "",
            middleName: "",
            contact: "",
            email: "",
            password: "",
            createdBy: loggedInUserId,
            roleId: "",
            // tenantId: 6,
          });
          dispatch(setPanelAdminAdded());
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRoleDropDownChange = (value) => {
    setSelectedRole(value);
    setUserData((prev) => ({
      ...prev,
      roleId: value.roleId,
    }));
  };
  const handleCountryDropDownChange = (value) => {
    setSelectedCountry(value);
    setPanelDetails((prev) => ({
      ...prev,
      country_id: value.countryId,
    }));
    if (value.countryId) {
      setSecondFormValidation((prev) => ({
        ...prev,
        country_id: null,
      }));
    }
  };
  return (
    <Dialog
      fullWidth={"true"}
      maxWidth={"md"}
      open={open}
      onClose={hadnleCloseModal}
    >
      <form onSubmit={handleSubmit}>
        {/* <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span> */}
        <DialogTitle my={4}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        {activeStep === 0 && (
          <DialogContent style={{ paddingBottom: 0 }}>
            <Box padding={2}>
              <Stack direction={"column"} spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    label={"First Name"}
                    name="firstName"
                    error={Boolean(firstFormValidation.firstName)}
                    helperText={firstFormValidation.firstName}
                    type={"text"}
                    required
                    value={userData.firstName}
                  />
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    label={"Middle Name"}
                    name="middleName"
                    type={"text"}
                    value={userData.middleName}
                  />
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    error={Boolean(firstFormValidation.lastName)}
                    helperText={firstFormValidation.lastName}
                    label={"Last Name"}
                    name="lastName"
                    type={"text"}
                    required
                    value={userData.lastName}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    type={"number"}
                    label={"Phone Number"}
                    error={Boolean(firstFormValidation.contact)}
                    helperText={firstFormValidation.contact}
                    name="contact"
                    required
                    value={userData.contact}
                  />
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    error={Boolean(firstFormValidation.email)}
                    helperText={firstFormValidation.email}
                    type={"email"}
                    label={"Email"}
                    name="email"
                    required
                    value={userData.email}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems="flex-end"
                >
                  <Stack sx={{ width: "100%" }}>
                    <p>Role</p>
                    <AsyncDropdown
                      disabled
                      value={selectedRole}
                      handleChange={handleRoleDropDownChange}
                      objKey="role"
                      options={roles}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems="flex-end"
                >
                  {type === "ADD" && (
                    <TextField
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                      label={"Password"}
                      error={Boolean(firstFormValidation.password)}
                      helperText={firstFormValidation.password}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      required
                      value={confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <TextField
                    onChange={handleInputChange}
                    fullWidth
                    label={"Confirm Password"}
                    type={showConfirmPassword ? "text" : "password"}
                    name="password"
                    required
                    value={userData.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
        )}
        {activeStep === 1 && (
          <DialogContent style={{ paddingBottom: 0 }}>
            <Box padding={2}>
              <Stack direction={"column"} spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <TextField
                    onChange={handlePanelDetailsChnage}
                    fullWidth
                    type={"text"}
                    label={"Address"}
                    error={Boolean(secondFormValidation.address)}
                    helperText={secondFormValidation.address}
                    name="address"
                    required
                    value={panelDetails.address}
                  />
                  <TextField
                    onChange={handlePanelDetailsChnage}
                    fullWidth
                    type={"text"}
                    error={Boolean(secondFormValidation.city)}
                    helperText={secondFormValidation.city}
                    label={"City"}
                    name="city"
                    required
                    value={panelDetails.city}
                  />
                  <TextField
                    onChange={handlePanelDetailsChnage}
                    fullWidth
                    type={"text"}
                    error={Boolean(secondFormValidation.state)}
                    helperText={secondFormValidation.state}
                    label={"State"}
                    name="state"
                    required
                    value={panelDetails.state}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems="flex-end"
                >
                  <TextField
                    onChange={handlePanelDetailsChnage}
                    fullWidth
                    type={"number"}
                    error={Boolean(secondFormValidation.zip)}
                    helperText={secondFormValidation.zip}
                    label={"Zip"}
                    name="zip"
                    required
                    value={panelDetails.zip}
                  />
                  <AsyncDropdown
                    value={selectedCountry}
                    label={"Country"}
                    objKey={"countryName"}
                    error={Boolean(secondFormValidation.country_id)}
                    helperText={secondFormValidation.country_id}
                    options={countries}
                    handleChange={handleCountryDropDownChange}
                  />
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
        )}

        {activeStep === 2 && (
          <DialogContent>
            <Stack py={2} spacing={2}>
              <MultipleValueDropDown
                value={countriesSelected}
                options={countries}
                objkey={"countryName"}
                label={"Select Countries"}
                onChange={(value) => {
                  setCountriesSelected(value);
                  if (value) {
                    setThirdFormValidation((prev) => ({
                      ...prev,
                      countries: "",
                    }));
                  }
                }}
                error={Boolean(thirdFormValidation.countries)}
                helperText={thirdFormValidation.countries}
              />
              <MultipleValueDropDown
                value={featuresSelected}
                objkey={"features"}
                childobjkey={"feature"}
                options={features}
                label={"Select Features"}
                onChange={(value) => {
                  setFeaturesSelected(value);
                  if (value) {
                    setThirdFormValidation((prev) => ({
                      ...prev,
                      features: "",
                    }));
                  }
                }}
                error={Boolean(thirdFormValidation.features)}
                helperText={thirdFormValidation.features}
              />
              {/* <Stack>
                <Typography>Select Features</Typography>
                <Grid container direction={'column'}>
                  {features &&
                    features.length > 0 &&
                    features.map((item, index) => (
                      <Grid key={index} item xs={4}>
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                        <FeaturesSelect
                          handleChange={handleFetureSelectChange}
                          data={item}
                          key={index}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Stack> */}
            </Stack>
          </DialogContent>
        )}

        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          {allStepsCompleted() ? (
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type={"submit"}
            >
              {type === "ADD" ? "Save" : "Update"}
            </LoadingButton>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>Complete Step</Button>
                ))}
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfPanelAdmin;
