import axios from "axios";

export const getPnaelQuestions = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getPanelQsn?panelId=${id}`);
    return res;
  } catch (error) {
    console.log("error geting pnael Questions", error.message);
  }
};

export const createPanelQuestionTranslation = async (data) => {
  try {
    const res = await axios.post(
      "/api/Company/createQuestionTranslation",
      data
    );
    return res;
  } catch (error) {
    console.log("error add question translation", error);
    return error;
  }
};
export const getPanelQuestionTranslations = async (panelId, panelQsnId) => {
  try {
    const res = await axios.get(
      `/api/Company/getPanelQsnTranslation?panelId=${panelId}&panelQsnId=${panelQsnId}`
    );
    return res;
  } catch (error) {
    console.log("error fetching panel translated questions", error.message);
  }
};

// /api/Company/getPanelQuestionDetail?panelQsnId=91
export const getPanelQuestionDetail = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getPanelQuestionDetail?panelQsnId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

// localhost:5000/api/company/updateOptionTranslation/?qsnTranslationId=15
export const updateOptionTranslation = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/company/updateOptionTranslation/?qsnTranslationId=${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const deleteTranslation = async (id) => {
  try {
    const res = await axios.delete(
      `/api/company/deleteTranslation/?qsnTranslationId=${id}`
    );
    return res;
  } catch (error) {
    console.log("err", error.message);
  }
};

export const updateQuestionTranslation = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/company/updateQsnTranslation/?qsnTranslationId=${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

// 3.7.27.74:5000/api/company/getOptionTranslation?qsnTranslationId=68&panelQsnId=119
export const getOptionTranslation = async (qsnTranslationId, panelQsnId) => {
  try {
    const res = await axios.get(
      `/api/company/getOptionTranslation?qsnTranslationId=${qsnTranslationId}&panelQsnId=${panelQsnId}`
    );
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
