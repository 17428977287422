import styled from "@emotion/styled";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../hooks/useDebounce";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import RichTextEditor from "../../../utils/richTextEditor/RichTextEditor";
import { getActivepanels } from "../../managePanel/managePanel.api";
import {
  createSurvey,
  getClient,
  getIncentive,
  getIndustries,
  getPanelCountryLanguage,
  getStudyTypes,
  updateSurveyDetails,
} from "../survey.api";

const dropDowns = {
  PANEL: "PANEL",
  CLIENT: "CLIENT",
  COUNTRYLANG: "COUNTRYLANG",
  INDUSTRY: "INDUSTRY",
  STUDYTYPE: "STUDYTYPE",
};

const SurveyForm = (props) => {
  const {
    setAlert,
    handleCompleteStep,
    completedSteps,
    selectedStep,
    handleChangeSelected,
    setSurveyResdata,
    editData,
    type,
  } = props;
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const MyComponent = styled("button")({
    backgroundColor: "white",
    color: "black",
    padding: "16px",
    borderWidth: "1px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  });

  const [activePanels, setActivePanels] = useState([]);
  const [clients, setClients] = useState([]);
  const [studyType, setStudyType] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [panelCountryLanguage, setPanelCountryLanguage] = useState([]);

  const [selectedClient, setSelectedClient] = useState({});
  const [selectedStudyType, setSelectedStudyType] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [selectedPanelCountryLanguage, setSelectedPanelCountryLanguage] =
    useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [surveyCode, setSurveyCode] = useState("");

  const [selectedCountryLanguage, setSelectedCountryLanguage] = useState({});
  const [loading, setLoading] = useState(false);

  const [surveyData, setSurveyData] = useState({
    surveyDescription: "",
    testClientUrl: "",
    clientUrl: "",
    LOI: "",
    countryLanguage: [],
    CPI: 0,
    clientId: null,
    industryId: null,
    studyTypeId: null,
    quotaType: "starts",
    incentive: 0,
    isMobile: true,
    isDesktop: true,
    isIpad: true,
    completeRequire: 0,
    surveyStatus: "live",
    createdBy: adminId,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [validationError, setvalidationError] = useState({
    surveyDescription: "",
    testClientUrl: "",
    clientUrl: "",
    LOI: "",
    CPI: "",
    completeRequire: "",
    selectedPanel: null,
    countryLang: "",
  });

  useEffect(() => {
    if (editData?.id) {
      console.log("edit data", editData);
      if (editData?.countryLanguage?.length > 0) {
        setSelectedPanelCountryLanguage(editData?.countryLanguage);
      }

      let duplicate = { ...surveyData };
      duplicate.surveyDescription = editData?.surveyDescription;
      duplicate.testClientUrl = editData?.testClientUrl;
      duplicate.clientUrl = editData?.clientUrl;
      // duplicate.LOI = editData?.LOI;
      duplicate.CPI = editData?.CPI;
      duplicate.incentive = editData?.incentive;
      duplicate.quotaType = editData?.quotaType;
      duplicate.completeRequire = editData?.completeRequire;
      duplicate.isMobile = editData?.isMobile;
      duplicate.isDesktop = editData?.isDesktop;
      duplicate.isIpad = editData?.isIpad;
      duplicate.surveyStatus = editData?.surveyStatus;
      duplicate.clientId = editData?.clientId;
      duplicate.studyTypeId = editData?.studyTypeId;
      duplicate.industryId = editData?.industryId;
      duplicate.startDate = editData?.startDate;
      duplicate.endDate = editData?.endDate;

      setSelectedCountryLanguage(editData?.countryLanguage);

      setSurveyCode(editData?.surveyCode);
      let txt = editData?.LOI;
      if (txt) {
        let numb = txt.match(/\d/g);
        numb = numb.join("");
        duplicate.LOI = numb;
      }

      // set selected panel
      if (activePanels?.length > 0) {
        const item = activePanels.find((i) => i.panelId === editData.panelId);
        if (item) {
          setSelectedPanel(item);
        }
      }

      if (clients?.length > 0) {
        const item = clients.find((i) => i.id === editData.clientId);
        if (item) {
          setSelectedClient(item);
        }
      }

      if (industry?.length > 0) {
        const item = industry.find((i) => i.id === editData.industryId);
        if (item) {
          setSelectedIndustry(item);
        }
      }
      if (studyType?.length > 0) {
        const item = studyType.find((i) => i.id === editData.studyTypeId);
        if (item) {
          setSelectedStudyType(item);
        }
      }

      setSurveyData(duplicate);
    }

    return () => {
      setSurveyData({
        surveyDescription: "",
        testClientUrl: "",
        clientUrl: "",
        LOI: "",
        countryLanguage: [],
        CPI: 0,
        clientId: null,
        industryId: null,
        studyTypeId: null,
        quotaType: "starts",
        incentive: 0,
        isMobile: true,
        isDesktop: true,
        isIpad: true,
        completeRequire: 0,
        surveyStatus: "live",
        createdBy: adminId,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
      setSurveyCode("");
    };
  }, [editData, activePanels, industry, studyType]);

  console.log("res data");

  useEffect(() => {
    if (selectedPanel?.panelId) {
      setvalidationError((prev) => ({
        ...prev,
        selectedPanel: "",
      }));

      getPanelCountryLanguage(selectedPanel.panelId)
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setPanelCountryLanguage(data);
            {
              type !== "EDIT" && setSelectedCountryLanguage(data[0]);
            }
            // if (data?.length > 0) {
            //   setSelectedPanelCountryLanguage([...data]);
            // }
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {
      setPanelCountryLanguage([]);
      setSelectedPanelCountryLanguage([]);
    };
  }, [selectedPanel]);

  useEffect(() => {
    if (adminId) {
      getActivepanels(adminId)
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setActivePanels(data);
            setSelectedPanel(data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getStudyTypes()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setStudyType(data);
            setSelectedStudyType(data[0]);
          }
        })
        .catch((err) => console.log(err));

      getClient()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setClients(data);
            setSelectedClient(data[0]);
          }
        })
        .catch((err) => console.log(err));

      getIndustries()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setIndustry(data);
            setSelectedIndustry(data[0]);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      // setActivePanels([]);
      // setSelectedPanel({});
      // setSelectedClient({});
      // setSelectedIndustry({});
      // setSelectedIndustry({});
      // setSelectedStudyType({});
      // setClients([]);
      // setIndustry([]);
      // setStudyType([]);
      // setvalidationError({
      //   surveyDescription: "",
      //   testClientUrl: "",
      //   clientUrl: "",
      //   LOI: "",
      //   CPI: "",
      //   completeRequire: "",
      //   selectedPanel: null,
      // });
    };
  }, [adminId]);

  useDebounce(() => getIncent(), 1000, [surveyData.CPI]);

  function getIncent() {
    if (selectedPanel?.panelId) {
      let duplicate = { ...surveyData };
      getIncentive(selectedPanel.panelId, surveyData.CPI)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data);
            const { completePoints } = res.data;
            if (completePoints) {
              duplicate["incentive"] = completePoints;
            }
            setSurveyData(duplicate);
          }
        })
        .catch((err) => console.log("err"));
    } else {
      return;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSurveyData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setvalidationError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleToggleCheckBox = (e, value) => {
    const { name } = e.target;
    setSurveyData((prev) => ({
      ...prev,
      [name]: Boolean(value),
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("clicked", type);
    let duplicate = { ...validationError };

    const startDate = new Date(surveyData?.startDate);
    const endDate = new Date(surveyData?.endDate);
    if (endDate < startDate) {
      setAlert("End date cannot be less than Start date");
      return;
    }

    if (!selectedPanel.panelId) {
      duplicate["selectedPanel"] = "Select atleast one panel";
    }

    if (surveyData.surveyDescription === "") {
      duplicate["surveyDescription"] = "Survey description is required";
    }
    if (surveyData.testClientUrl === "") {
      duplicate["testClientUrl"] = "Test url is required";
    }
    if (surveyData.clientUrl === "") {
      duplicate["clientUrl"] = "Client url is required";
    }
    if (!surveyData.CPI) {
      duplicate["CPI"] = "CPI is required";
    }
    if (surveyData.LOI === "") {
      duplicate["LOI"] = "LOI is required";
    }
    if (!surveyData.completeRequire) {
      duplicate["completeRequire"] = "Completes required is required";
    }
    if (Object.keys(selectedCountryLanguage).length === 0) {
      console.log("ajdlkajsldjla", selectedCountryLanguage);
      duplicate["countryLang"] = "Country Language is required";
    }

    let data = { ...surveyData };
    data.LOI = `${data.LOI} mins`;

    setvalidationError(duplicate);
    const noError = Object.values(duplicate).every(
      (x) => x === null || x === ""
    );
    if (!noError) {
      return;
    }
    // if (selectedPanelCountryLanguage.length > 0) {
    //   data.countryLanguage = [...selectedPanelCountryLanguage];
    // } else {
    //   return;
    // }

    data.clientId = selectedClient?.id;
    data.industryId = selectedIndustry?.id;
    data.studyTypeId = selectedStudyType?.id;
    data["completedPoints"] = data?.incentive;
    data["countryId"] = selectedCountryLanguage?.countryId;
    data["languageId"] = selectedCountryLanguage?.languageId;
    setLoading(true);
    if (type === "EDIT") {
      updateSurveyDetails(editData.id, data)
        .then((res) => {
          if (res?.status === 200) {
            setAlert("Survey Updated successfully");
            handleCompleteStep(selectedStep);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        })
        .finally(() => setLoading(false));
    } else {
      createSurvey(selectedPanel.panelId, data)
        .then((res) => {
          if (res?.status === 200) {
            setSurveyResdata(res.data.data);
            navigate(`/admin/edit-survey/${res?.data?.data?.id}`, {
              state: { type: "EDIT", surveyId: res?.data?.data?.id },
            });
            setAlert("Survey created successfully");
            setSurveyData({
              surveyDescription: "",
              testClientUrl: "",
              clientUrl: "",
              LOI: "",
              countryLanguage: [],
              CPI: 0,
              clientId: null,
              industryId: null,
              studyTypeId: null,
              quotaType: "starts",
              incentive: 0,
              isMobile: true,
              isDesktop: true,
              isIpad: true,
              completeRequire: 0,
              surveyStatus: "live",
              createdBy: adminId,
              startDate: moment().format("YYYY-MM-DD"),
              endDate: moment().format("YYYY-MM-DD"),
            });
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        })
        .catch(() => setLoading(false));
    }
  };

  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case dropDowns.PANEL:
        console.log("selected panel", value);
        setSelectedPanel(value);
        break;
      case dropDowns.CLIENT:
        setSelectedClient(value);
        handleAssignId("clientId", value.id);
        break;
      case dropDowns.INDUSTRY:
        setSelectedIndustry(value);
        handleAssignId("industryId", value.id);
        break;
      case dropDowns.STUDYTYPE:
        setSelectedStudyType(value);
        handleAssignId("studyTypeId", value.id);
        break;
      case dropDowns.COUNTRYLANG:
        setSelectedCountryLanguage(value);
        setvalidationError((prev) => ({
          ...prev,
          countryLang: "",
        }));
        break;
      default:
        break;
    }
  };

  const handleAssignId = (keyName, id) => {
    if (keyName && id) {
      setSurveyData((prev) => ({
        ...prev,
        [keyName]: id,
      }));
    }
  };

  const handleToggleSelectAll = (e, value) => {
    if (value) {
      setSelectedPanelCountryLanguage([...panelCountryLanguage]);
    } else {
      setSelectedPanelCountryLanguage([]);
    }
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"flex-start"}>
        <Paper elevation={2} sx={{ my: 4, p: 2, mr: 2, width: "100%" }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 2, md: 15 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ mb: 4 }}
          >
            {type === "EDIT" && (
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"Survey ID"}
                  value={surveyCode}
                />
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={4}>
              <AsyncDropdown
                value={selectedClient}
                options={clients}
                objKey={"clientName"}
                stylized
                label={"Select Client name"}
                handleChange={(value) =>
                  handleDropDownChange(value, dropDowns.CLIENT)
                }
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AsyncDropdown
                handleChange={(value) =>
                  handleDropDownChange(value, dropDowns.PANEL)
                }
                value={selectedPanel}
                options={activePanels}
                objKey={"panelName"}
                stylized
                label={"Select Panel"}
                error={Boolean(validationError.selectedPanel)}
                helperText={validationError.selectedPanel}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <TextField
                type={"number"}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label={"Enter LOI(in minutes)"}
                value={surveyData?.LOI}
                name={"LOI"}
                onChange={handleInputChange}
                error={Boolean(validationError.LOI)}
                helperText={validationError.LOI}
              />
            </Grid>
            {/* <Grid item xs={2} sm={4} md={4}>
              
            </Grid> */}

            <Grid item xs={2} sm={12} md={12}>
              <TextField
                fullWidth
                multiline
                name={"surveyDescription"}
                value={surveyData.surveyDescription}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label={"Survey Description"}
                onChange={handleInputChange}
                error={Boolean(validationError.surveyDescription)}
                helperText={validationError.surveyDescription}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <TextField
                fullWidth
                name={"startDate"}
                value={surveyData.startDate}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label={"Start Date"}
                type={"date"}
                onChange={handleInputChange}
                // error={Boolean(validationError.surveyDescription)}
                // helperText={validationError.surveyDescription}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                fullWidth
                type={"date"}
                name={"endDate"}
                value={surveyData.endDate}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label={"End Date"}
                onChange={handleInputChange}
                // error={Boolean(validationError.surveyDescription)}
                // helperText={validationError.surveyDescription}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label="Test Link"
                value={surveyData.testClientUrl}
                name={"testClientUrl"}
                onChange={handleInputChange}
                error={Boolean(validationError.testClientUrl)}
                helperText={validationError.testClientUrl}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label="Client Link"
                name={"clientUrl"}
                value={surveyData.clientUrl}
                onChange={handleInputChange}
                error={Boolean(validationError.clientUrl)}
                helperText={validationError.clientUrl}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              {/* {panelCountryLanguage?.length > 0 && (
                <FormControlLabel
                  label={"Select All"}
                  control={<Checkbox onChange={handleToggleSelectAll} />}
                />
              )} */}
              <AsyncDropdown
                value={selectedCountryLanguage}
                options={panelCountryLanguage}
                objKey={"countryLanguage"}
                stylized
                error={Boolean(validationError?.countryLang)}
                helperText={validationError?.countryLang}
                label={"Select Country-Language"}
                handleChange={(value) =>
                  handleDropDownChange(value, dropDowns.COUNTRYLANG)
                }
              />

              {/* <MultipleValueDropDown
                value={selectedPanelCountryLanguage}
                options={panelCountryLanguage}
                objkey={"countryLanguage"}
                stylized
                label={"Select Country"}
                onChange={(value) => setSelectedPanelCountryLanguage(value)}
              /> */}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AsyncDropdown
                value={selectedIndustry}
                options={industry}
                objKey={"industryName"}
                stylized
                label={"Select Industry"}
                handleChange={(value) =>
                  handleDropDownChange(value, dropDowns.INDUSTRY)
                }
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
              >
                <InputLabel>Select Quota type</InputLabel>
                <Select
                  onChange={handleInputChange}
                  name={"quotaType"}
                  value={surveyData?.quotaType}
                  label="Select Quota type"
                >
                  <MenuItem value={"starts"}>Start</MenuItem>
                  <MenuItem value={"completes"}>Complete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AsyncDropdown
                value={selectedStudyType}
                options={studyType}
                objKey={"studyType"}
                stylized
                label={"Select Study Type"}
                handleChange={(value) =>
                  handleDropDownChange(value, dropDowns.STUDYTYPE)
                }
              />
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <TextField
                fullWidth
                type={"number"}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
                label="Completes required"
                name={"completeRequire"}
                value={surveyData.completeRequire}
                onChange={handleInputChange}
                error={Boolean(validationError.completeRequire)}
                helperText={validationError.completeRequire}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #f0f0f0",
                  },
                }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  onChange={handleInputChange}
                  name={"surveyStatus"}
                  value={surveyData?.surveyStatus}
                  label="Status"
                >
                  <MenuItem value={"live"}>Live</MenuItem>
                  <MenuItem value={"onhold"}>On hold</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={12}>
              <Typography
                sx={{
                  background: "#f5f5f5",
                  color: "#1a1a1a",
                  borderRadius: 1.5,
                }}
              >
                Available in
              </Typography>
              <FormGroup>
                <Stack direction={"row"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleToggleCheckBox}
                        checked={surveyData?.isMobile}
                        name={"isMobile"}
                        sx={{
                          color: "#1a1a1a",
                          "&.Mui-checked": {
                            color: "#1a1a1a",
                          },
                        }}
                      />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleToggleCheckBox}
                        checked={surveyData?.isIpad}
                        name={"isIpad"}
                        sx={{
                          color: "#1a1a1a",
                          "&.Mui-checked": {
                            color: "#1a1a1a",
                          },
                        }}
                      />
                    }
                    label="Tablet"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleToggleCheckBox}
                        name={"isDesktop"}
                        checked={surveyData?.isDesktop}
                        sx={{
                          color: "#1a1a1a",
                          "&.Mui-checked": {
                            color: "#1a1a1a",
                          },
                        }}
                      />
                    }
                    label="Desktop"
                  />
                </Stack>
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
      <Paper elevation={2} sx={{ p: 2, width: "100%" }}>
        <Typography sx={{ mb: 2 }} variant="p" component={"h4"}>
          Cost & Field Dates
        </Typography>
        <Stack direction={"row"} spacing={2}>
          <TextField
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #f0f0f0",
              },
            }}
            fullWidth
            label="CPI $"
            value={surveyData?.CPI}
            name={"CPI"}
            type={"number"}
            onChange={handleInputChange}
            error={Boolean(validationError.CPI)}
            helperText={validationError.CPI}
          />
          <TextField
            type={"number"}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #f0f0f0",
              },
            }}
            fullWidth
            aria-readonly
            label="Complete Points"
            value={surveyData.incentive}
            name={"incentive"}
          />
        </Stack>
        <Stack mt={2} direction={"row"} spacing={2} justifyContent="flex-end">
          <MyComponent disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <CircularProgress
                size={"21px"}
                sx={{ margin: "0 14px", color: "black", padding: 0 }}
              />
            ) : type === "EDIT" ? (
              "Update"
            ) : (
              "Save"
            )}
          </MyComponent>
          {/* <MyComponent>Save & Qualification</MyComponent> */}
          <MyComponent>Save & Add Quota</MyComponent>
        </Stack>
      </Paper>
    </>
  );
};

export default SurveyForm;
