import axios from "axios";

export const verifyEmail = async (token) => {
  try {
    const res = await axios.post(
      `/api/user/verifyEmail?token=${token}`
    );
    return res;
  } catch (error) {
    console.log("Failed verifying email", error.message);
    return error;
  }
};
