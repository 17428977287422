import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Default from "../components/Default";

const RoleRoutes = (props) => {
  const { allowedRoles } = props;
  const currentRoleId = useSelector((state) => state.auth.userData?.roleId);

  return allowedRoles?.includes(currentRoleId) ? <Outlet /> : <Default />;
};

export default RoleRoutes;
