import axios from "axios";

export const loginAndRegister = async (data) => {
  try {
    const res = await axios.post("auth/register-and-login", data);
    return res;
  } catch (error) {
    return error;
  }
};

export const verifyOtp = async (data) => {
  try {
    const res = await axios.post("auth/varify-otp", data);
    return res;
  } catch (error) {
    return error;
  }
};
export const loginMember = async (data) => {
  try {
    const res = await axios.post("/api/systemUser/loginMember", data);
    return res;
  } catch (error) {
    return error;
  }
};

export const completeSurvey = async (data) => {
  try {
    const res = await axios.post(`/api/user/surveyCompletionCallback`, data);
    return res;
  } catch (error) {
    return error;
  }
};
