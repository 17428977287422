import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useMemo } from "react";

const Step = (props) => {
  const { step, selected, handleChange } = props;
  const stepStyles = useMemo(() => {
    return {
      background: selected ? "#000000" : "#F5F5F5",
      border: "1.5px solid #757575",
      borderRadius: "8px",
      padding: "0.75rem",
      width: 130,
      textAlign: "center",
      overflow: "hidden",
      color: selected ? "#ffffff" : "#757575",
      userSelect: "none",
    };
  }, [selected]);
  return (
    <div style={stepStyles} onClick={handleChange}>
      <Typography sx={{ textTransform: "capitalize" }} fontWeight={400}>
        {step}
      </Typography>
    </div>
  );
};

const SurveySteps = (props) => {
  const { steps, selectedStep, handleChange } = props;

  return (
    <Stack direction={"row"} spacing={2}>
      {steps &&
        steps.length > 0 &&
        steps.map((item, index) => (
          <Step
            handleChange={() => handleChange(index)}
            key={index}
            selected={Number(selectedStep) === Number(index) ? true : false}
            step={item}
          />
        ))}
    </Stack>
  );
};

export default SurveySteps;
