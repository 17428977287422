import axios from "axios"

export const getAllLanguages = async () => {
    try {
        const res = await axios.get('/api/systemUser/getLanguage');
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const addLanguage = async(data) => {
    try {
        const res = await axios.post('/api/systemUser/createLanguage', data)
        return res;
    } catch (error) {
        console.log(error);
    }
}
export const updateLanguage = async(id, data) => {
    try {
        const res = await axios.patch(`/api/systemUser/updateLanguage/${id}`, data)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const deleteLanguage = async(id) => {
    try {
        const res=  await axios.delete(`/api/systemUser/deleteLanguage/${id}`)
        return res;
    } catch (error) {
        console.log(error)
    }
}