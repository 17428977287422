import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfContent from "./ConfContent";
import { deleteContent } from "./content.api";

const Language = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [contents, setContents] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      setContents(data);
    }
    return () => {
      setContents(data);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.contentId === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (id) => {
    deleteContent(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const rows = [];
  const labels = ["Content type","Content code", "Status", "Actions"];
  contents &&
    contents.length > 0 &&
    contents.map((item) =>
      rows.push([
        item.contentType,
        item.contentCode,
        <CustomMuiSwitch checked={item.isActive} />,
        <Stack direction={"row"}>
          <IconButton
            onClick={() => {
              setEditId(item.contentId);
              handleOpenEditModal();
            }}
          >
            <Create />
          </IconButton>
          <ConfirmAlert msg={'Are you sure you want to delete?'} onClickEvent={() => handleDelete(item.contentId)}>
            <IconButton>
              <Delete />
            </IconButton>
          </ConfirmAlert>
        </Stack>,
      ])
    );
  return (
    <>
      <TopHeading openModal={handleOpenModal} heading="Content" />
      <ConfContent
        open={openModal}
        loading={loading}
        type="ADD"
        hadnleCloseModal={handleCloseModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
      />
      <ConfContent
        open={openEditModal}
        loading={loading}
        type="EDIT"
        hadnleCloseModal={handleCloseEditModal}
        setSuccess={setSuccess}
        setError={setError}
        setAlert={setAlert}
        editId={editId}
        selectedItem={selectedItem}
      />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
    </>
  );
};

export const EnhancedContent = Hoc(Language, { name: "content" });
