import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getFeaturesAndPermissionsOfMember,
  getPanelById,
} from "./assignedPanel.api";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/authSlice";
import { EnhancedCategory } from "../category/Category";
import { EnhancedMasterQuestion } from "../masterQuestions/MasterQuestion";
import AnimatedPage from "../animatedPage/AnimatedPage";
import {
  setMemberFeatuers,
  setMemberPermissions,
  setPanelMember,
} from "../../features/panelMemberPermissions";
import { EnhancedRoles } from "../roles/Roles";
import { EnhancedSurvey } from "../survey/Survey";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AssignedPanelPage = () => {
  const { id } = useParams();
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const tenantId = useSelector((state) => state.auth.userData?.tenantId);

  const [featuresAndPermissions, setFeaturesAndPermissions] = useState([]);
  const [features, setFeatures] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [paneldata, setPaneldata] = useState({});
  const [tab, setTab] = useState(features[0]?.code);

  const dispatch = useDispatch();

  const handleTabChange = (e, value) => {
    setTab(value);
    console.log(e.target);
    console.log(value);
  };

  useEffect(() => {
    if (roleId && id) {
      getFeaturesAndPermissionsOfMember(roleId)
        .then((res) => {
          if (res?.status === 200) {
            setFeaturesAndPermissions(res.data.data);
            dispatch(setPanelMember(true))
          }
        })
        .catch((err) => console.log(err.message));
    }
    return () => {
      setTab(features[0]?.code);
      setFeaturesAndPermissions([]);
      setFeatures([]);
      setPermissions([]);
      dispatch(setMemberFeatuers([]));
      dispatch(setMemberPermissions([]));
      dispatch(setPanelMember(false))
    };
  }, [id, roleId]);

  useEffect(() => {
    if (featuresAndPermissions && featuresAndPermissions.length > 0) {
      let duplicate = [...featuresAndPermissions];
      let featuresDup = [...features];
      let permissionsDup = [...permissions];
      duplicate.map((item) => {
        if (
          item.perm.features &&
          featuresDup.filter((i) => i.name === item.perm.features?.feature)
            .length == 0
        ) {
          featuresDup.push({
            name: item.perm.features?.feature,
            code: item.perm.features?.featureCode,
          });
        }
        if (
          item.perm.contents &&
          featuresDup.filter((i) => i.name === item.perm.contents?.contentType)
            .length == 0
        ) {
          featuresDup.push({
            name: item.perm.contents?.contentType,
            code: item.perm.contents?.contentCode,
          });
        }

        permissionsDup.push(item.perm.permissionCode);
      });
      const memberPermissons = Array.from(new Set(permissionsDup));
      setFeatures(featuresDup);
      setPermissions(memberPermissons);
      dispatch(setMemberFeatuers(featuresDup));
      dispatch(setMemberPermissions(memberPermissons));
    }
    return () => {
      setFeatures([]);
      setPermissions([]);
    };
  }, [featuresAndPermissions]);

  useEffect(() => {
    console.log("features", features);
    if (features.length > 0) {
      setTab(features[0]?.code);
    }
  }, [features]);

  useEffect(() => {
    console.log("permissions", permissions);
  }, [permissions]);

  let componentToRender;

  const ToRender = (props) => {
    const { permissions } = props;
    switch (tab) {
      case "poll":
        return <h1>Poll</h1>;
        break;
      case "cont_04_qsn":
        return <EnhancedMasterQuestion panelId={id} panelMember permissions={permissions} />;
        break;
      case "cat":
        return <EnhancedCategory />;
        break;
      case "FE_01_SUR":
        return <EnhancedSurvey />;
        break;
      case "cont_01_role":
        return <EnhancedRoles userId={tenantId} />;
        break;

      default:
        break;
    }
  };
  return (
    <AnimatedPage>
      <Box mx={2}>
        <Paper elevation={2} sx={{ pl: 4, py: 1, pb: 1 }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {features &&
                features.length > 0 &&
                features.map((item, index) => (
                  <Tab
                    value={item.code}
                    sx={{ textTransform: "capitalize" }}
                    key={index}
                    label={item.name}
                  />
                ))}
            </Tabs>
            <IconButton onClick={() => dispatch(logout())}>
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Paper>
        <Box py={2}>
          <ToRender permissions={permissions} />
        </Box>
      </Box>
    </AnimatedPage>
  );
};

export default AssignedPanelPage;
