import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Pagination, Stack, TextField,Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import MultipleValueDropDown from "../../../utils/multipleValueDropDown/MultipleValueDropDown";
import { SnackBarAlert } from "../../../utils/SnackBar";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import CustomTable from "../../../views/CustomTable/CustomTable";
import {
  getCategoryByPanelId,
  getlanguageByCountryId,
  getPanelUserProfileQsn,
  getUserProfileQuestion,
  updateUserProfileQuestion,
  getAllMiniSurveys,
} from "../userProfileQuestionaire.api";
import { getActiveCategories } from "../../category/category.api";
import { fetchCategories, fetchMiniSurveys } from "../../../features/listSlice";

const EditUserProfileQuestionaire = () => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [profileQuestions, setProfileQuestions] = useState([]);
  // const [categories, setCategories] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [dbSelectedQuesIds, setDbSelectedQuesIds] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [userProfileQsnId, setUserProfileQsnId] = useState(null);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataAltered, setDataAltered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [qsnsPerPage, setQsnsPerPage] = useState(10);
  const [miniSurveys, setminiSurveys] = useState([]);
  const [miniSurveyIdData, setminiSurveyIdData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [oldProfileQuestions, setOldProfileQuestions] = useState([]);

  const dispatch = useDispatch();

  const location = useLocation();

  const { miniSurvey, categories } = useSelector((state) => state.list);

  useEffect(() => {
    setDataAltered(false);
  }, [dbSelectedQuesIds]);

  useEffect(() => {
    const { state } = location;
    console.log("state", state);
    setTableLoading(true);
    if (state?.profile_id) {
      dispatch(fetchCategories());
      // getActiveCategories()
      //   .then((res) => {
      //     if (res?.status === 200) {
      //       setCategories(res?.data?.data);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("err", err.messsage);
      //   });
      getUserProfileQuestion(state.profile_id)
        .then((res) => {
          if (res?.status === 200) {
            console.log("question data", res.data);
            const { data } = res.data;
            // setUserProfileQsnId(data?.userProfileQsnId);
            setProfileQuestions(data);
            setOldProfileQuestions(data);
            let arr = [];
            for (var i = 0; i < data.length; i++) {
              arr.push(-1);
            }
            setminiSurveyIdData(arr);
            setLanguages(data?.panelLanguage);
            let dupSelectedlang = [];
            data?.panelLanguage?.map((item) => {
              if (item.isSelected) {
                dupSelectedlang.push(item);
              }
            });
            setSelectedLanguages(dupSelectedlang);

            let selected = [];
            data?.map((item) => {
              if (item.isSelected) {
                selected.push(item?.masterQsnId);
              }
            });
            setDbSelectedQuesIds(selected);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => setTableLoading(false));
    }
    return () => {
      setSelectedLanguages([]);
      setLanguages([]);
      setDbSelectedQuesIds([]);
      setDeSelectedIds([]);
      setProfileQuestions([]);
      setUserProfileQsnId(null);
    };
  }, [location, dataAltered]);

  useEffect(() => {
    console.log("dbSelected", dbSelectedQuesIds);
  }, [dbSelectedQuesIds]);

  useEffect(() => {
    console.log("deSelected", deSelectedIds);
  }, [deSelectedIds]);

  const handleQuestionCategoryDropDownChange = (value, id) => {
    // let duplicate = [...profileQuestions];
    // duplicate[index]["categoryId"] = value?.categoryIds;
    // setProfileQuestions(duplicate);

    setProfileQuestions(
      profileQuestions?.map((item) => {
        if (item?.masterQsnId === id) {
          return {
            ...item,
            ["categoryId"]: value?.categoryId,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleToggleCheckBox = (value, index, id, checkboxName) => {
    if (checkboxName === "isSelected") {
      if (!value) {
        if (dbSelectedQuesIds.includes(id) && !deSelectedIds.includes(id)) {
          setDeSelectedIds((prev) => [...prev, id]);
        }
      } else {
        if (dbSelectedQuesIds.includes(id)) {
          setDeSelectedIds(deSelectedIds.filter((item) => item !== id));
        }
      }
    }

    setProfileQuestions(
      profileQuestions.map((item) => {
        if (item?.masterQsnId === id) {
          return { ...item, [checkboxName]: value };
        } else {
          return item;
        }
      })
    );
  };

  const indexOfLastPost = currentPage * qsnsPerPage;
  const indexOfFirstPost = indexOfLastPost - qsnsPerPage;
  const currentProfileQsns = profileQuestions?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  useEffect(() => {
    console.log(profileQuestions);
  }, [profileQuestions]);

  const handlePageChange = (e, value) => {
    setCurrentPage(value);
  };

  const handleMiniSurveyDropDownChange = (value, id) => {
    // let duplicate = [...profileQuestions];
    // duplicate[index]["miniSurveyId"] = value?.miniSurveyId;
    // setProfileQuestions(duplicate);
    setProfileQuestions(
      profileQuestions?.map((item) => {
        if (item?.masterQsnId === id) {
          return {
            ...item,
            ["miniSurveyId"]: value?.miniSurveyId,
          };
        } else {
          return item;
        }
      })
    );
  };

  useEffect(() => {
    const { state } = location;
    console.log("panel id", state);
    if (state?.panel_id) {
      dispatch(fetchMiniSurveys({ panel_id: state.panel_id }));
    }
  }, [location]);

  const getMiniSurveyObj = (miniSurvs, id) => {
    if (miniSurvs?.length > 0) {
      const item = miniSurvs?.find((item) => item?.miniSurveyId === id);
      return item;
    }
  };
  const getCategoryObj = (catsArr, id) => {
    if (catsArr?.length > 0) {
      const item = catsArr?.find((item) => item?.categoryId === id);
      return item;
    }
  };

  const getMiniSurveyValue = (id) => {
    return getMiniSurveyObj(miniSurvey, id);
  };

  const getCategoryValue = (id) => {
    return getCategoryObj(categories, id);
  };


  const handleSearch = (e) => {
    const query = e.target.value;
     setSearchFilter(e.target.value);
     filteredData(e.target.value);
  };

  const labels = ["", "Question", "Mini Survey", "Category", "Type", "Requied"];
  const rows = [];

  const filteredData = (searchValue) => {
    setSearchFilter(searchValue)
    if (searchValue !== '') {
        const filteredData = oldProfileQuestions.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase().trim
            ())
        })
        setProfileQuestions(filteredData)
        setCurrentPage(1);
      
    }
    else{
      setProfileQuestions(oldProfileQuestions)
    }
}
  currentProfileQsns.map((item, index) => {
    rows.push([
      <Checkbox
        onChange={(e, value) =>
          handleToggleCheckBox(value, index, item?.masterQsnId, "isSelected")
        }
        checked={Boolean(item?.isSelected)}
      />,
      item?.qsnShortForm,
      <AsyncDropdown
        fullWidth={false}
        handleChange={(value) =>
          handleMiniSurveyDropDownChange(value, item?.masterQsnId)
        }
        value={getMiniSurveyValue(item?.miniSurveyId)}
        objKey={"miniSurveyName"}
        options={[
          { miniSurveyName: "None", miniSurveyId: null },
          ...miniSurvey,
        ]}
        small
        label={"Select Mini Survey"}
      />,
      <AsyncDropdown
        label={"Select Category"}
        value={getCategoryValue(item?.categoryId)}
        objKey={"category"}
        options={categories}
        small
        handleChange={(value) =>
          handleQuestionCategoryDropDownChange(value, item?.masterQsnId)
        }
      />,
      item?.questionType,
      <Checkbox
        onChange={(e, value) =>
          handleToggleCheckBox(value, index, item?.masterQsnId, "isRequired")
        }
        checked={Boolean(item?.isRequired)}
      />,
    ]);
  });

  const handleSubmit = () => {
    let finalData = {};

    let selectedLanguagesIds = [];
    let dupSelectedLanguages = [...selectedLanguages];
    dupSelectedLanguages.map((item) => {
      selectedLanguagesIds.push(item.languageId);
    });

    let duplicateProfileQuestions = [...profileQuestions];
    duplicateProfileQuestions.map(
      (item, index) =>
        (duplicateProfileQuestions[index]["miniSurveyKey"] =
          miniSurveyIdData[index] === -1 ? null : miniSurveyIdData[index])
    );

    let selectedQuestions = duplicateProfileQuestions.filter(
      (item) => item.isSelected === true
    );

    console.log("min sur dat", miniSurveyIdData);
    console.log("dup qs", duplicateProfileQuestions);

    let finalQuestions = [];
    selectedQuestions?.map((item) => {
      finalQuestions.push({
        masterQsnId: item?.masterQsnId,
        categoryId: item?.categoryId,
        isRequired: Boolean(item?.isRequired),
        miniSurveyId: item?.miniSurveyId === -1 ? null : item?.miniSurveyId,
      });
    });
    finalData["deselected"] = [...deSelectedIds];
    finalData["qsnConfig"] = [...finalQuestions];
    finalData["updatedBy"] = adminId;
    setLoading(true);
    updateUserProfileQuestion(location?.state?.profile_id, finalData)
      .then((res) => {
        if (res?.status >= 200 && res?.status <= 299) {
          console.log("success");
          setAlert("Data Updated Successfully.");
          setDataAltered(true);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        setAlert("Something went wrong! Try again later");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TopHeading showButton={false} heading={"Edit"} />
      <Stack sx={{ mx: 3, mb: 2 }} spacing={1.5}>
        {/* <MultipleValueDropDown
          small
          value={selectedLanguages}
          label={"Edit Languages"}
          options={languages}
          objkey={"languageName"}
          onChange={(val) => setSelectedLanguages(val)}
        /> */}
        {/* <TextField label={"Filter by Question"} size={"small"} /> */}
        <Stack alignItems={"center"} direction={"row"}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
        <TextField
            type="search"
            value={searchFilter}
            fullWidth
            label="Search"
            name="Search"
           onChange={handleSearch}
          />

</Grid>

<Grid spacing={2}  item xs={6} sm={6} md={6}>

          <Pagination
            sx={{ flexGrow: 1 }}
            count={Math.ceil(profileQuestions.length / qsnsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
         </Grid>
        
         <Grid spacing={2} xs={5} sm={5} md={5} sx={{my:1}}>
          <LoadingButton
            loading={loading}
            disabled={tableLoading}
            onClick={handleSubmit}
            sx={{ float: "right", mx: 1.5, my: 1 }}
            variant="contained"
          >
            Update
          </LoadingButton>
          </Grid>
          </Grid>
        </Stack>
      </Stack>

      <CustomTable data={rows} pageLoading={tableLoading} labels={labels} />

      <SnackBarAlert open={alert} handleClose={() => setAlert(false)} />
    </>
  );
};

export default EditUserProfileQuestionaire;
