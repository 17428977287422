import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  addRole,
  createRoleRelation,
  getContentPermissions,
  getFeaturePermissions,
  updateRole,
} from "./roles.api";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";

import "./styles.css";
import {
  rolesAndFeatures,
  staticPermissions,
  staticPermissionsFeatureTwo,
} from "../../data/rolesAndFeatures";
import PermissionLabelSwitch from "./permissionsMenu/PermissionLabelSwitch";
import { useSelector } from "react-redux";

const ConfRole = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setError,
    setAlert,
    setEditId,
    editId,
    selectedItem,
    // featurePermissions,
    featureList,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [roledata, setRoledata] = useState({
    role: "",
    createdBy: adminId,
    updatedBy: "",
    tenantId: adminId,
  });

  const [user, setUser] = useState(false);
  const [userView, setUserView] = useState(false);
  const [userCreate, setUserCreate] = useState(false);
  const [permissions, setpermissions] = useState([]);
  const [grantedPermissions, setgrantedPermissions] = useState([]);

  const [selectedFeaturetab, setSelectedFeatureTab] = useState({});
  const [permissionsList, setPermissionsList] = useState([]);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [featuresList, setfeaturesList] = useState([]);

  // get all the feature list
  useEffect(() => {
    if (open && featureList && featureList.length > 0) {
      setfeaturesList(featureList);
      console.log("feature list", featureList);
    }
    return () => {
      setfeaturesList([]);
      setSelectedFeatureTab({});
      setpermissions([]);
    };
  }, [featureList, open]);

  useEffect(() => {
    if (featuresList && featuresList.length > 0) {
      setSelectedFeatureTab(featureList[0]);
    }
    return () => {
      setSelectedFeatureTab({});
    };
  }, [featuresList]);

  useEffect(() => {
    if (editId && type === "EDIT") {
      setFormLoading(true);
      if (selectedItem) {
        setRoledata(selectedItem);
        if (selectedItem.permission && selectedItem.permission.length > 0) {
          let permissionIds = [];
          selectedItem.permission.map((item) => {
            permissionIds.push(item.permissionId);
          });
          setpermissions(permissionIds);
        }
        setFormLoading(false);
      }
    }
    return () => {
      setRoledata({
        role: "",
        createdBy: adminId,
        updatedBy: adminId,
        tenantId: adminId,
      });
      setUser(false);
      setUserView(false);
      setUserCreate(false);
      setgrantedPermissions([]);
      setpermissions([]);
      setPermissionsList([]);
      setSelectedFeatureTab({});
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setRoledata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    // let permissions = [];

    // userCreate && permissions.push(USERCREATE);
    // userView && permissions.push(USERVIEW);

    if (type === "ADD") {
      let data = {
        role: roledata.role,
        createdBy: roledata.createdBy,
        permissionId: permissions,
        tenantId: adminId,
      };
      callMethod = createRoleRelation(data);
    } else {
      let data = {
        role: roledata.role,
        updatedBy: adminId,
        tenantId: adminId,
        permissionId: permissions,
      };

      console.log("update role permissions", data);
      return;
      callMethod = updateRole(editId, data);
    }

    callMethod
      .then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          console.log(res);
          setSuccess();
          setRoledata({
            role: "",
            createdBy: 1,
            updatedBy: adminId,
            updatedBy: "",
          });
          setUser(false);
          setUserView(false);
          setUserCreate(false);
        } else {
          setError("Something went Wrong! Please try agian.");
        }
      })
      .catch((err) => {
        setError("Something went Wrong! Please try agian.");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSwitchChange = (e, value) => {
    let id = e.target.value;
    let duplicate = [...permissions];

    // if true add check key in the permissions list object
    if (value) {
      if (duplicate.includes(Number(id))) return;
      duplicate.push(Number(id));
      setpermissions(duplicate);
      if (
        duplicate &&
        duplicate.length > 0 &&
        permissionsList &&
        permissionsList.length > 0
      ) {
        let dup = permissionsList;
        for (let i = 0; i < duplicate.length; i++) {
          for (let k = 0; k < dup.length; k++) {
            if (Number(duplicate[i]) === dup[k]["permissionId"]) {
              dup[k]["checked"] = true;
            }
          }
        }
        setPermissionsList(dup);
      }
    }

    // if false set check key in the object as false
    if (!value) {
      const index = duplicate.indexOf(Number(id));
      console.log("index", index);
      console.log("duplicate", duplicate);
      if (index > -1) {
        duplicate.splice(index, 1);
      }

      setpermissions(duplicate);

      if (permissionsList && permissionsList.length > 0) {
        let dups = [...permissionsList];
        const idx = dups.findIndex(
          (item) => Number(item.permissionId) === Number(id)
        );
        if (idx > -1) {
          dups[idx]["checked"] = false;
        }
        setPermissionsList(dups);
      }
    }
  };

  const [finalPermissions, setFinalPermissions] = useState([]);

  // on permissions list change check if there are any macthcing permissions ids that has alreay been slected and set the check field as true
  useEffect(() => {
    if (
      permissions &&
      permissions.length > 0 &&
      permissionsList &&
      permissionsList.length > 0
    ) {
      let dup = [...permissionsList];
      for (let i = 0; i < permissions.length; i++) {
        for (let k = 0; k < dup.length; k++) {
          if (Number(permissions[i]) === Number(dup[k]["permissionId"])) {
            dup[k]["checked"] = true;
          }
        }
      }
      console.log("permissions", permissions);
      setFinalPermissions(dup);
    }
  }, [permissionsList, permissions]);

  const handleTabChange = (e, newValue) => {
    setSelectedFeatureTab(newValue);
  };

  // get new permissions list on tabs change
  useEffect(() => {
    // get permissions from feature
    if (selectedFeaturetab && selectedFeaturetab?.featureId) {
      setPermissionsLoading(true);
      getFeaturePermissions(selectedFeaturetab.featureId)
        .then((res) => {
          if (res?.status === 200) {
            setPermissionsList(res.data.data);
            setFinalPermissions(res.data.data);
          }
        })
        .catch((err) => {
          setError("Something went wrong");
          console.log(err);
        })
        .finally(() => setPermissionsLoading(false));
    }
    // get features from content
    if (selectedFeaturetab && selectedFeaturetab?.contentId) {
      getContentPermissions(selectedFeaturetab.contentId)
        .then((res) => {
          if (res?.status === 200) {
            setPermissionsList(res.data.data);
            setFinalPermissions(res.data.data);
          }
        })
        .catch((err) => {
          setError("Something went wrong");
          console.log(err);
        })
        .finally(() => setPermissionsLoading(false));
    }
    return () => {
      setPermissionsList([]);
    };
  }, [selectedFeaturetab]);

  useEffect(() => {
    console.log("permissions selected", permissions);
  }, [permissions]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Role" : "Edit Role"}</h4>
            </DialogTitle>
            <DialogContent>
              <Stack sx={{ py: 2 }} spacing={2}>
                <input
                  className="field-border"
                  fullWidth
                  placeholder="Role Name"
                  name="role"
                  value={roledata.role}
                  onChange={handleOnInputchange}
                />

                <Stack direction={"row"} spacing={2}>
                  <Stack sx={{ borderRight: "1px solid #D6E4E5", pr: 0.3 }}>
                    <Typography
                      sx={{
                        backgroundColor: "#D6E4E5",
                        textAlign: "center",
                        py: 1,
                        borderRadius: 2,
                      }}
                    >
                      Features
                    </Typography>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={selectedFeaturetab ? selectedFeaturetab : ""}
                      onChange={handleTabChange}
                      aria-label="Vertical tabs example"
                      sx={{
                        borderRight: 0,
                        borderColor: "divider",
                        width: "150px",
                      }}
                    >
                      {selectedFeaturetab &&
                        featuresList &&
                        featuresList.length > 0 &&
                        featuresList.map((item, index) => (
                          <Tab
                            key={index}
                            sx={{ textTransform: "capitalize" }}
                            value={item}
                            label={
                              item.contentType
                                ? item.contentType
                                : item.features.feature
                            }
                          />
                        ))}
                    </Tabs>
                  </Stack>

                  <Stack
                    sx={{ width: "100%", position: "relative" }}
                    direction={"column"}
                    spacing={2}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#D6E4E5",
                        p: 1,
                        borderRadius: 2,
                      }}
                    >
                      Permissions
                    </Typography>
                    {permissionsLoading ? (
                      <CircularProgress className="loader" />
                    ) : finalPermissions && finalPermissions.length > 0 ? (
                      finalPermissions.map((item, index) => (
                        <PermissionLabelSwitch
                          checked={item.checked ? true : false}
                          value={item.permissionId}
                          handleSwitchChange={handleSwitchChange}
                          key={index}
                          name={item.permission && item.permission}
                        />
                      ))
                    ) : (
                      <Typography>No Permissions</Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                variant="contained"
                type={"submit"}
                sx={{ textTransform: "capitalize" }}
              >
                {type === "ADD" ? "Create" : "Update"}
              </LoadingButton>
              {/* <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button> */}
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfRole;
