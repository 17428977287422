import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    data: {
        label: [],
        count: [],
    },
    isFirstRender: true,
    isLoading: true,
    isSuccess: false,
}

export const getGraphDataRedux = createAsyncThunk('graphData/getGraphData', async(args) => {
    try {
        const res = await axios.get('api/data?fields=label,number');
        return res.data.data;
    } catch (error) {
        console.log(error);
    }
})

const graphDataSlice = createSlice({
    name: 'graphData',
    initialState,
    reducers: {},
    extraReducers: {
        [getGraphDataRedux.pending]: (state, {payload}) => {
            state.isLoading = true
        },
        [getGraphDataRedux.fulfilled]: (state, {payload}) => {
            let data = payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isFirstRender = false;
            state.data = {
                label: [],
                count: [],
            }
            data.map((i) => (
                state.data.label = [...state.data.label, i.attributes.label],
                state.data.count = [...state.data.count, i.attributes.number]
              ))
        },
        [getGraphDataRedux.rejected]:(state, {payload}) => {
            state.data = payload;
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default graphDataSlice.reducer;