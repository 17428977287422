import { Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { logout } from "../features/authSlice";

const Default = () => {
  const naviate = useNavigate();

  const dispatch = useDispatch();

  return (
    <div className="progress not-found-page">
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page Not Found</Typography>
      <Button
        variant={"contained"}
        sx={{ textTransform: "capitalize" }}
        onClick={() => dispatch(logout())}
      >
        Logout
      </Button>
    </div>
  );
};

export default Default;
