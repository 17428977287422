import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Stack, TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import TopHeading from "../../utils/TopHeading/TopHeading";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { getActivepanels } from "../managePanel/managePanel.api";
import { getAllAffiliateDetail } from "./affiliateMaster.api"
import CustomDataTable from "../../views/CustomTable/CustomDataTable";
import moment from "moment/moment";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";

const AffiliateMaster = () => {
  const [selectedPanel, setSelectedPanel] = useState({});
  const [affiliateMasterData, setAffiliateMasterData] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setPageloading(true);
    getAllAffiliateDetail()
      .then((res) => {
        if (res?.status === 200) {
          console.log(res.data.data);
          setAffiliateMasterData(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err.message);
      })
      .finally(() => setPageloading(false));

    return () => {
      setAffiliateMasterData([]);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: 'Affiliate ID',
          selector: (row) => row.id,
          sortable: true,
          cell: (row) => (
            <Stack direction={"row"}>
              <Link
                to={`/admin/edit-affiliate/${row.id}`} target="_blank">
                {row.id}
              </Link>
            </Stack>
          ),
        },
        {
          name: 'Affiliate Name',
          selector: (row) => row.affiliateName,
          sortable: true,
        },
        {
          name: 'Callback Type',
          selector: (row) => row.callbackTypeId,
          sortable: true,
          cell:(row) => row.callbackTypeId === 1 ? "s2s" : row.callbackTypeId === 2 ? "iframe" : "imagePixel"
        },
        {
          name: 'Payout Type',
          selector: (row) => row.payoutType,
          sortable: true,
          cell:(row) => row.payoutType === 1 ? "Signup" : "DOI"
        },
        {
          name: 'Request Type',
          selector: (row) => row.requestType,
          sortable: true,
        },
        {
          name: 'Cost',
          selector: (row) => row.cost,
          sortable: true,
        },
        {
          name: 'Created On',
          selector: (row) => moment(row?.createdAt).format("YYYY-MM-DD"),
          sortable: true,
        },
      ]);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    //setPage(1);
  };

  const filteredData = affiliateMasterData.filter((item) => {
    const values = Object.values(item);
    return values.find((value) =>
      value != null &&
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      <AnimatedPage>
        <TopHeading addLink={"/admin/create-affiliate"} showButton={false} heading="Affiliate Master" />

        <Stack spacing={2} sx={{ mx: 4, my: 2 }}>
          <TextField
            type="search"
            value={searchQuery}
            fullWidth
            label="Search"
            name="Search"
            onChange={handleSearch}
          />
          <div>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              pagination={true}
              progressPending={pending}
              highlightOnHover
            />
          </div>
        </Stack>
      </AnimatedPage>
    </>
  )
}

export const EnhancedAffliateMaster = Hoc(AffiliateMaster, { name: "" });