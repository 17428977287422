import { Stack, TextField, Paper, Grid,Select, MenuItem,InputLabel,
  ListItemIcon,ListItemText,FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { getActivepanels } from "../managePanel/managePanel.api";
import { getAllPanelistByPanelId } from "./panelistDetail.api";
import CustomDataTable from "../../views/CustomTable/CustomDataTable";
// import CustomDataTableNew from "../../views/CustomTable/CustomDataTableNew";

const Panelists = () => {
  const [selectedPanel, setSelectedPanel] = useState({});
  const [panelists, setPanelists] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = useState(true);
  const [activeUser, setActiveUser] = useState(true);
  const [userActiveStatusData, setUserActiveStatusData] = useState([{ "id":0, "userActiveStatus":"Active","ApiName":"getAllPanelistByPanelId" }, { "id": 1, "userActiveStatus": "Inactive", "ApiName":"getInactiveUser"}]);
  const [selectedUserStatusType, setSelectedUserStatusType] = useState(userActiveStatusData[0]);

  const [totalRecords, setTotalRecords] = useState(8000000);

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setSelectedPanel({});
    };
  }, [adminId]);

  useEffect(() => {
    if (Object.keys(selectedPanel).length > 0) {
      setPageloading(true);
      getAllPanelistByPanelId(selectedPanel?.panelId,'getAllPanelistByPanelId')
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setPanelists(res.data.data);
            setTotalRecords(res.data.data.length);
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => setPageloading(false));
    }
    return () => {
      setPanelists([]);
    };
  }, [selectedPanel]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: 'PanelistId',
          selector: (row) => row.panelistId,
          sortable: true,
          cell: (row) => (
            <Stack direction={"row"}>
              <Link
                to={`/admin/panelist-detail/${row.panelistId}`}
                state={{ type: "EDIT", panelistId: row.panelistId }} target="_blank">
                {row.panelistId}
              </Link>
            </Stack>
          ),
        },
        {
          name: 'First Name',
          selector: (row) => row.firstName,
          sortable: true,
        },
        {
          name: 'Last Name',
          selector: (row) => row.lastName,
          sortable: true,
        },
        {
          name: 'E-mail Address',
          selector: (row) => row.email,
          sortable: true,
          width: '25%',
        },
        {
          name: 'Country/Language',
          selector: (row) => row.countryName+"/"+row.language,
          sortable: true,
        }
      ]);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    //setPage(1);
  };

  const filteredData = panelists.filter((item) => {
    const values = Object.values(item);
    return values.find((value) =>
      value != null &&
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const callActiveOrInActiveUserApi=(apiName)=>{
    if (Object.keys(selectedPanel).length > 0) {
      setPending(true);
      setPageloading(true);
      getAllPanelistByPanelId(selectedPanel?.panelId,apiName)
        .then((res) => {
          if (res?.status === 200) {
            setPanelists(res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() =>{setPending(false);setPageloading(false)});
    }
  }
  const handleDropDownChange = (e) => {
    if (e.target.value == "Inactive") {
      setSelectedUserStatusType(userActiveStatusData[1]);
      callActiveOrInActiveUserApi(userActiveStatusData[1].ApiName);

    }
    else {
      setSelectedUserStatusType(userActiveStatusData[0]);
      callActiveOrInActiveUserApi(userActiveStatusData[0].ApiName);
    }
  }
  return (
    <>
      <AnimatedPage>
        <TopHeading showButton={false} heading="Panelist Detail" />

        <Paper sx={{ px: 6, py: 1, mx: 2, mb: 2 }} elevation={1}>
          <Stack
            direction={"row"}
            sx={{ mb: 2, my: 2 }}
            spacing={2}
            alignItems={"flex-start"}
          >
            <Grid className="panelistBox" container >
              <Grid item xs={3} sm={3} md={3}>
              <FormControl sx={{ width: 205 }}>
              <InputLabel id="demo-userStatus-label">Status</InputLabel>
                  <Select
                    id="demo-multiple-checkbox"
                    value={selectedUserStatusType}
                    onChange={handleDropDownChange}
                    renderValue={(selectedUserStatusType) => selectedUserStatusType.userActiveStatus}
                    sx={{
                      width: 150,
                      height: 50,
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label="Status"
                  >
                    {userActiveStatusData.map((option) => (
                      <MenuItem key={option.userActiveStatus} value={option.userActiveStatus}>
                        <ListItemText primary={option.userActiveStatus} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={9} sm={9} md={9}>
                <TextField
                  type="search"
                  value={searchQuery}
                  fullWidth
                  label="Search"
                  name="Search"
                  onChange={handleSearch}
                />
              </Grid>
            </Grid>
          </Stack>
        </Paper>

        <Stack spacing={2} sx={{ mx: 4, my: 2 }}>
          <div>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              pagination={true}
              progressPending={pending}
              highlightOnHover
            />
          </div>
        </Stack>
      </AnimatedPage>
    </>
  );
};

export const EnhancedPanelistsPage = Hoc(Panelists, { name: "" });
