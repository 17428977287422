import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import RichTextEditor from "../../utils/richTextEditor/RichTextEditor";
import moment from "moment";
import { getAllLanguages } from "../language/language.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { setUseProxies } from "immer";
import { createSurvey, updateSurvey } from "./survey.api";

const ConfSurvey = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const tenantId = useSelector((state) => state.auth.userData?.tenantId);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});

  const [surveyData, setsurveyData] = useState({
    surveyName: "",
    surveyDescription: "",
    clientLink: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    LOI: "",
    languageId: null,
    countryCode: "",
    createdBy: adminId,
    completedPoints: 100,
    totalUserSelected: 100,
    panelId: adminId,
    accountId: tenantId,
    createdBy: adminId,
  });

  useEffect(() => {
    if (open) {
      getAllLanguages()
        .then((res) => {
          if (res?.status === 200) {
            setLanguages(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    if (editId && type === "EDIT") {
      if(selectedItem){
        let duplicate = {...selectedItem};
        duplicate['startDate'] = moment(duplicate['startDate']).format('YYYY-MM-DD')
        duplicate['endDate'] = moment(duplicate['endDate']).format('YYYY-MM-DD')
        setsurveyData(duplicate);
      }
    }

    return () => {
      setFormLoading(false);
      setLoading(false);
      setLanguages([]);
      setSelectedLanguage({});
      setsurveyData({
        surveyName: "",
        surveyDescription: "",
        clientLink: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        LOI: "",
        languageId: null,
        countryCode: "",
        createdBy: adminId,
        completedPoints: 100,
        totalUserSelected: 100,
        panelId: adminId,
        accountId: tenantId,
        createdBy: adminId,
      });
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setsurveyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if(surveyData.languageId && type ==='EDIT'){
      if(languages.length > 0){
        const item = languages.find(i => i.id === surveyData.languageId);
        console.log('lnaguage', selectedLanguage);
        setSelectedLanguage(item);
      }
    }
  }, [surveyData, languages]);
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    if (type === "ADD") {
      let duplicate = { ...surveyData };
      callMethod = createSurvey(duplicate);
    } else {
      let data = {
        ...surveyData,
      }
        callMethod = updateSurvey(editId, data);
    }

    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
          console.log(res);
          setSuccess();
          setsurveyData({
            surveyName: "",
            surveyDescription: "",
            clientLink: "",
            startDate: "",
            endDate: "",
            LOI: "",
            languageId: null,
            countryCode: "",
            createdBy: adminId,
            completedPoints: 100,
            totalUserSelected: 100,
            panelId: adminId,
            createdBy: adminId,
            accountId: tenantId,
          });
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      })
      .finally(setLoading(false));
  };

  const handleDropDownChange = (value) => {
    console.log("value", value);
    if (value && value.id) {
      setSelectedLanguage(value);
      setsurveyData((prev) => ({
        ...prev,
        languageId: value.id,
      }));
    }
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"md"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Survey" : "Edit Survey"}</h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.surveyName}
                    fullWidth
                    label="Name"
                    name="surveyName"
                  />
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.clientLink}
                    fullWidth
                    label="Client link"
                    name="clientLink"
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <RichTextEditor
                    value={surveyData.surveyDescription}
                    handleChange={(value) => {
                      setsurveyData((prev) => ({
                        ...prev,
                        surveyDescription: value,
                      }));
                    }}
                    label={"Description"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.startDate}
                    fullWidth
                    type={"date"}
                    label="Start date"
                    name="startDate"
                  />
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.endDate}
                    type={"date"}
                    fullWidth
                    label="End date"
                    name="endDate"
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.LOI}
                    fullWidth
                    type={"text"}
                    label="LOI"
                    name="LOI"
                  />
                  <AsyncDropdown
                    value={selectedLanguage}
                    label={"Select language"}
                    objKey={"language"}
                    options={languages}
                    handleChange={handleDropDownChange}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.completedPoints}
                    fullWidth
                    type={"text"}
                    label="Completed Points"
                    name="completedPoints"
                  />
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.totalUserSelected}
                    fullWidth
                    label="Total users selected"
                    name="totalUserSelected"
                  />
                </Stack>
                {/* <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={surveyData.countryCode}
                    fullWidth
                    type={"text"}
                    label="Country code"
                    name="countryCode"
                  />
                </Stack> */}
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfSurvey;
