import axios from "axios";

export const getAllPanelAdmins = async (tenantId, roleId) => {
  try {
    const res = await axios.get(
      `/api/Company/getMemberByRoleAndTenant/?tenantId=${tenantId}&roleId=${roleId}`,
      {}
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createPanelAdmin = async(data) => {
  try {
    const res = await axios.post('/api/systemUser/createPanelAdmin', data);
    return res;
  } catch (error) {
    console.log('error creating panel admin', error.message);
  }
}

export const getPanelsofMember = async(id) => {
  try {
    const res = await axios.get(`/api/Company/getMemberPanel/?panelMemberId=${id}`)
    return res;
  } catch (error) {
    console.log('error fetching', error.message);
  }
}

export const getActivepanels = async(id) => {
  try {
    const res = await axios.get(`/api/Company/getActiveTenantPanel?tenantId=${id}`)
    return res;
  } catch (error) {
    console.log(error.message);
  }
}