import {
    Grid,
    Card,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItem,
    ListItemText,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDataTable from "../../../../views/CustomTable/CustomDataTable";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SnackBarAlert } from "../../../../utils/SnackBar";
import moment from "moment/moment";
import { getEmailSamples, createNoToSendEmailBatch, getEmailBatchHistory } from "../../survey.api";
// import ConfirmAlert from "../../../../utils/ConfirmAlert";

const SurveyEmailContents = (props) => {
    const { id } = useParams();
    const [isSurveyNumberSent, setIsSurveyNumberSent] = useState(false);
    const [maxSurveyNumberSent, setMaxSurveyNumberSent] = useState(0);
    const [surveyNumberSent, setSurveyNumberSent] = useState(true);
    const [emailSamples, setEmailSamples] = useState({});
    const [alert, setAlert] = useState(false);
    const [emailBatchHistoryData, setEmailBatchHistoryData] = useState([]);
    const [pending, setPending] = useState(true);
    const [columns, setColumns] = useState([]);
    const [sampleAvilable, setSampleAvilable] = useState(0);
    const [sentIn4, setSentIn4] = useState(0);
    const [emailOptOut, setEmailOptOut] = useState(0);
    const [emailNotVerified, setEmailNotVerified] = useState(0);
    const [surveyParticipated, setSurveyParticipated] = useState(0);
    const [invitationSent, setInvitationSent] = useState(0);
    const [totalRemoved, setTotalRemoved] = useState(0);
    
    
   // const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (id && Object.keys(emailSamples).length == 0) {
            getEmailSamples(id)
                .then((res) => {
                    if (res?.status === 200) {
                        setEmailSamples(res.data.data[0]);
                        setSampleAvilable(res.data.data[0].SampleAvailable);
                        setSentIn4(res.data.data[0].SentIn4);
                        setEmailOptOut(res.data.data[0].EmailOptOut);
                        setEmailNotVerified(res.data.data[0].EmailNotVerified);
                        setSurveyParticipated(res.data.data[0].SurveyParticipated);
                        setInvitationSent(res.data.data[0].InvitationSent);
                        setTotalRemoved(res.data.data[0].TotalRemoved)
                        if (res.data.data.length > 0) {
                            let maxSurveyEmailBatch = res.data.data[0].SampleAvailable-(res.data.data[0].TotalRemoved)
                            setMaxSurveyNumberSent(maxSurveyEmailBatch);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [emailSamples]);

    const handleNoToSendEmail = () => {
        if (id) {
            const requestData = {
                surveyId: id.toString(),
                batchSize: surveyNumberSent.toString()
            }
            createNoToSendEmailBatch(requestData)
                .then((res) => {
                    // setOpen(false);
                    if (res?.status === 200 && res.data.success) {
                        setAlert("Email Batch Updated Successfully");
                        document.getElementById('standard-basic').value="";
                        setIsSurveyNumberSent(false);
                        callEmailBatchHistory(id);
                    }
                    else {
                        setAlert(res.data.message)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };

    const validateNumberToSent = (event) => {
        event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setSurveyNumberSent(event.target.value);
        if (parseInt(event.target.value) > 0 && event.target.value <= maxSurveyNumberSent) {
            setIsSurveyNumberSent(true);
        }
        else {
            setIsSurveyNumberSent(false);
        }
    };
    
    const handleCloseAlert = () => {
        setAlert(false);
    };

    useEffect(() => {
        if (id && Object.keys(emailBatchHistoryData).length == 0) {
            callEmailBatchHistory(id);
        }
    }, [emailBatchHistoryData]);

    const callEmailBatchHistory = () => {
        getEmailBatchHistory(id)
            .then((res) => {
                if (res?.status === 200 && res?.data?.data?.length > 0) {
                    setEmailBatchHistoryData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setColumns([
                {
                    name: <div>Survey Id</div>,
                    selector: (row) => row.surveyId,
                    sortable: true,
                },
                {
                    name: <div>Number To Send</div>,
                    selector: (row) => row.batchSize,
                    sortable: true,
                },
                {
                    name: <div>Actual Email Sent</div>,
                    selector: (row) => row.actualSent,
                    sortable: true,
                },
                {
                    name: <div>Survey Attempt</div>,
                    selector: (row) => row.surveyAttempt,
                    sortable: true,
                },
                {
                    name: <div>Email Type</div>,
                    selector: (row) => row.emailType,
                    sortable: true,
                },
                {
                    name: <div>Status</div>,
                    selector: (row) => row.status,
                    sortable: true,
                },
                {
                    name: <div>Send Date</div>,
                    selector: (row) => row.updatedAt != null ? row.updatedAt : "---",
                    sortable: true,
                },
                {
                    name: <div>Created Date</div>,
                    selector: (row) =>row.createdAt,
                    sortable: true,
                },
            ]);
            setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
            <Grid container spacing={2} className="survey-email">
                <Grid item xs={6}>
                    <Card>
                        <ListItem sx={{ backgroundColor: 'lightgray' }}>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Send Details" />
                                </Grid>
                            </Grid>
                            <ListItemText primary="#" />
                        </ListItem>
                        <ListItem>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Sample Available" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={sampleAvilable} />
                        </ListItem>
                        <Divider />
                        <ListItem divider>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Total Removed" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={totalRemoved} />
                        </ListItem>
                        <ListItem>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Total Available" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={sampleAvilable-totalRemoved} />
                        </ListItem>
                        <Divider light />
                    </Card> </Grid>
                <Grid item xs={6}>
                    <Card>
                        <ListItem sx={{ backgroundColor: 'lightgray' }}>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Remove Breakdown" />
                                </Grid>
                            </Grid>
                            <ListItemText primary="#" />
                        </ListItem>
                        <ListItem>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Survey Participated User" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={surveyParticipated} />
                        </ListItem>
                        <Divider />
                        <ListItem divider>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Invitation Sent" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={invitationSent} />
                        </ListItem>
                        <ListItem>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Email Optout" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={emailOptOut} />
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Email Not Verified" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={emailNotVerified} />
                        </ListItem>
                        <Divider />
                        
                        <ListItem >
                            <Grid item xs={12}>
                                <Grid item xs={5}>
                                    <ListItemText primary="Exclude in (4) hrs" />
                                </Grid>
                            </Grid>
                            <ListItemText primary={sentIn4} />
                        </ListItem>
                        <Divider light />
                    </Card> </Grid>
            </Grid>
            <Grid container spacing={2} className="survey-email">
                <Grid item xs={3} sx={{ my: 1 }}>
                    <Typography className="survey-emailNum" maxlength="10">Number to Send</Typography>
                </Grid>
                <Grid item xs={3} sx={{ my: 0 }}>
                    <TextField id="standard-basic" type="Text" onInput={validateNumberToSent} inputProps={{ maxLength: 15 }} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className="survey-email">
                <Grid item xs={3} sx={{ my: 1 }}>
                </Grid>
                <Grid item xs={3} sx={{ my: 0 }}>
                    <LoadingButton variant="contained" sx={{ px: 4, textTransform: "capitalize", my: 1 }} disabled={!isSurveyNumberSent} onClick={handleNoToSendEmail}>
                        SEND INVITES
                    </LoadingButton>
                </Grid>
            </Grid>

            <Accordion sx={{ mx: 0, my: 2, width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography variant="h6">Email History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div className="survey-emailHistory">
                            <CustomDataTable
                                columns={columns}
                                data={emailBatchHistoryData}
                                pagination={true}
                                progressPending={pending}
                                highlightOnHover
                            />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
export default SurveyEmailContents;
