import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";

const CATEGORY = "CATEGORY";
const ANSWER_TYPES = "ANSWERTYPES";

const ConfQuestionStatment = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    editId,
    selectedItem,
    activecategories,
    answerTypes,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedQuestionType, setSelectedQuestionType] = useState({});
  const [questionStatmentData, setquestionStatmentData] = useState({
    questionStatement: "",
    createdBy: 1,
  });

  const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log("selected item", selectedItem);
    if (editId && type === "ADD") {
      if (selectedItem) {
        setOptions(selectedItem?.option);
        if (activecategories.length > 0) {
          const item = activecategories.find(
            (i) => i.categoryId === selectedItem?.questionCatId
          );
          setSelectedCategory(item);
        }
        if (answerTypes.length > 0) {
          const item = answerTypes.find(
            (item) => item.questionTypeId === selectedItem.questionTypeId
          );
          setSelectedQuestionType(item);
        }
      }
    }

    return () => {
      setSelectedCategory({});
      setSelectedQuestionType({});
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setquestionStatmentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (value, type) => {
    if (value) {
      switch (type) {
        case CATEGORY:
          setSelectedCategory(value);
          break;
        case ANSWER_TYPES:
          setSelectedQuestionType(value);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"sm"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>
                {type === "ADD" ? "Change Question Statement" : "Edit Language"}
              </h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    aria-readonly
                    value={selectedItem?.qsnStatement}
                    fullWidth
                    label="Current Question Statement"
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={questionStatmentData.questionStatement}
                    fullWidth
                    label="Question Statement"
                    name="language"
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <AsyncDropdown
                    value={selectedCategory}
                    handleChange={(value) =>
                      handleDropdownChange(value, CATEGORY)
                    }
                    options={activecategories}
                    objKey={"category"}
                    label={"Select Category"}
                  />
                  <AsyncDropdown
                    value={selectedQuestionType}
                    handleChange={(value) =>
                      handleDropdownChange(value, ANSWER_TYPES)
                    }
                    options={answerTypes}
                    objKey={"questionType"}
                    label={"Select Question type"}
                  />
                </Stack>

                {options && options.length > 0 && (
                  <>
                    <Typography>Options</Typography>
                    {options.map((item, index) => (
                      <TextField
                        label={`Option ${index+1}`}
                        key={index}
                        value={item?.optionStatement}
                      />
                    ))}
                  </>
                )}
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfQuestionStatment;
