import React from 'react'
import {
  useParams,
} from "react-router-dom";
import Default from './Default';
import { EnhancedTenantProfile } from './tenantProfile/TenantProfile';
import VerifyReturnStatus from './verifyReturnStatus/VerifyReturnStatus';

function VerifiedUser() {
  let { id } = useParams();
  let userId = id.match(/^[0-9]*[1-9][0-9]*$/);
  if (userId) {
    return <VerifyReturnStatus />;
  }
//   return <EnhancedTenantProfile />;
}

export default VerifiedUser