import axios from "axios";

export const getAllMasterQuestion = async (id) => {
  try {
    let res;
    if (id) {
      res = await axios.get("/api/Company/getAllQsnWithOption", {
        params: {
          tenantId: id,
        },
      });
    } else {
      res = await axios.get("/api/Company/getAllQsnWithOption");
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAllMasterQuestionByCategory = async (id) => {
  try {
    const res = await axios.get(`/api/Company/getAllQsnWithOption/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addMasterQuestion = async (data) => {
  try {
    const res = await axios.post("/api/Company/createMasterQuestion", data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateMasterQuestion = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/Company/updateMasterQuestion/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMasterQuestion = async (id) => {
  try {
    const res = await axios.delete(`/api/Company/deleteMasterQuestion/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const chnageQuestionStatus = async (id, data) => {
  try {
    const res = await axios.patch(
      `/api/Company/approveDisapproveQuestion/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createQuestionProfile = async (data) => {
  try {
    const res = await axios.post("/api/Company/createQsnProfile", data);
    return res;
  } catch (error) {
    console.log("failed adding question profile", error.message);
  }
};

export const getTranslatedQuestions = async (id) => {
  try {
    const res = await axios.get(
      `/api/Company/getTranslatedMasterQsn?questionId=${id}`
    );
    return res;
  } catch (error) {
    console.log("error fetching questions", error.message);
  }
};

export const createMasterQuestion = async (data) => {
  try {
    const res = await axios.posy("/api/Company/createMasterQuestion", data);
    return res;
  } catch (error) {
    console.log("error creating master question", error.message);
  }
};
