import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfMasterQuestion from "./ConfMasterQuestion";
import {
  chnageQuestionStatus,
  deleteMasterQuestion,
  getAllMasterQuestionByCategory,
} from "./masterQuestion.api";
import { getAllanswerTypes } from "../answerType/answerType.api";
import { getAllPanel } from "../panel/panel.api";
import { getAllLanguages } from "../language/language.api";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import {
  getActiveCategories,
  getAllCategories,
  getSystemTenantCategories,
} from "../category/category.api";
import AnimatedPage from "../animatedPage/AnimatedPage";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { setFilterId } from "../../features/masterQuestionSlice";
import { getAllPanelAdmins } from "../managePanel/managePanel.api";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewOptionsModal from "./ViewOptionsModal";

const MasterQuestion = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
    permissions,
    panelMember,
    panelId,
  } = props;
  const [masterQuestions, setmasterQuestions] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [answerTypes, setAnswerTypes] = useState([]);
  const [languagetypes, setlanguagetypes] = useState([]);
  const [paneltypes, setpaneltypes] = useState([]);
  const [categoryTypes, setcategoryTypes] = useState([]);
  const [openAddToPanelModal, setOpenAddToPanelModal] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);

  const handleOpenAddToPanelModal = () => {
    setOpenAddToPanelModal(true);
  };
  const handleCloseAddToPanelModal = () => {
    setOpenAddToPanelModal(false);
  };
  const dispatch = useDispatch();
  const selectedCategoryFilter = useSelector(
    (state) => state.masterQuestion?.filterId
  );

  const memberPermissons = Array.from(new Set(permissions));
  console.log("master questions permissions", memberPermissons);
  console.log("panelMember", panelMember);

  useEffect(() => {
    if (selectedCategoryFilter) {
      getAllMasterQuestionByCategory(selectedCategoryFilter)
        .then((res) => {
          if (res?.status === 200) {
            console.log("questions by category", res.data.data);
            setmasterQuestions(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      dispatch(setFilterId(null));
    };
  }, [selectedCategoryFilter]);
  useEffect(() => {
    
    getAllanswerTypes()
      .then((res) => {
        if (res?.status === 200) {
          setAnswerTypes(res.data.data);
        }
      })
      .catch((err) => console.log(err));
    if (roleId === 3) {
      getAllPanelAdmins(adminId, 4)
        .then((res) => {
          if (res?.status === 200) {
            setpaneltypes(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
    getAllLanguages()
      .then((res) => {
        if (res?.status === 200) {
          setlanguagetypes(res.data.data);
        }
      })
      .catch((err) => console.log(err));

    getActiveCategories()
      .then((res) => {
        if (res?.status === 200) {
          setcategoryTypes(res.data.data);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      setlanguagetypes([]);
      setAnswerTypes([]);
      setpaneltypes([]);
      setcategoryTypes([]);
    };
  }, []);
  useEffect(() => {
    if (data) {
      setmasterQuestions(data);
      console.log("questions", data);
    }
    return () => {
      setmasterQuestions(data);
    };
  }, [data]);

  useEffect(() => {
    console.log("found", editId);
    if (data && data.length > 0) {
      const item = data.find((item) => item.masterQsnId === editId);
      if (item) {
        console.log("found", item);
        setSelectedItem(item);
      }
    }
    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const handleDelete = (id) => {
    deleteMasterQuestion(id)
      .then((res) => {
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };

  const handleQuestionStatus = (id, status) => {
    let data = {
      status: status,
    };
    chnageQuestionStatus(id, data)
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      });
  };
  const SYSTEM_ROLE_IDS = [1, 2];

  const EditButton = (props) => {
    const { item } = props;
    return (
      <IconButton
        onClick={() => {
          setEditId(item.masterQsnId);
          handleOpenEditModal();
        }}
      >
        <Create />
      </IconButton>
    );
  };

  const DeleteButton = (props) => {
    const { item } = props;
    return (
      <ConfirmAlert
        msg={"Are you sure you want to delete?"}
        onClickEvent={() => handleDelete(item.masterQsnId)}
      >
        <IconButton>
          <Delete />
        </IconButton>
      </ConfirmAlert>
    );
  };

  const ModifiedTypography = ({ children }) => {
    return (
      <Typography variant="p" sx={{ textTransform: "capitalize" }}>
        {children}
      </Typography>
    );
  };

  const [openViewModal, setOpenViewModal] = useState(false);
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };

  const rows = [];
  const labels = ["Id", "Question", "Category", "Type", "Active", "Actions"];

  masterQuestions &&
    masterQuestions?.length > 0 &&
    masterQuestions?.map((item) =>
      rows.push([
        <ModifiedTypography>{item.masterQsnId}</ModifiedTypography>,
        <ModifiedTypography>{item.qsnShortForm}</ModifiedTypography>,
        <ModifiedTypography>{item?.category}</ModifiedTypography>,
        <ModifiedTypography>{item?.questionType}</ModifiedTypography>,
        <CustomMuiSwitch checked={item?.isActive} />,
        <Stack direction={"row"} spacing={0} alignItems="center">
          {item?.option?.length > 0 && (
            <IconButton
              onClick={() => {
                handleOpenViewModal();
                setEditId(item.masterQsnId);
              }}
            >
              <Tooltip title={"View options"}>
                <RemoveRedEyeIcon />
              </Tooltip>
            </IconButton>
          )}
          {panelMember ? (
            memberPermissons.includes("per_ed_pf_qsn") &&
            item.masterQsnType !== "system" && <EditButton item={item} />
          ) : item.masterQsnType !== "system" ? (
            <EditButton item={item} />
          ) : (
            SYSTEM_ROLE_IDS.includes(roleId) && <EditButton item={item} />
          )}
          {panelMember ? (
            memberPermissons.includes("per_del_pf_qsn") &&
            item.masterQsnType !== "system" && <DeleteButton item={item} />
          ) : item.masterQsnType !== "system" ? (
            <DeleteButton item={item} />
          ) : (
            SYSTEM_ROLE_IDS.includes(roleId) &&
            item.masterQsnType === "system" && <DeleteButton item={item} />
          )}

          {/* {item.status === "pending" && (
            <Stack direction={"row"} spacing={2} sx={{ ml: "auto" }}>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize", ml: "auto" }}
                onClick={() =>
                  handleQuestionStatus(item.masterQsnId, "approve")
                }
              >
                Approve
              </Button>
              <Button
                variant="outlined"
                sx={{ textTransform: "capitalize", ml: "auto" }}
                color="error"
                onClick={() =>
                  handleQuestionStatus(item.masterQsnId, "disapprove")
                }
              >
                Reject
              </Button>
            </Stack>
          )} */}
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading
          parentPage={"master-questions"}
          memberPermissons={memberPermissons}
          showFilter
          options={categoryTypes}
          openModal={handleOpenModal}
          heading="Master Questions"
        />
        <ConfMasterQuestion
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          answerTypes={answerTypes}
          languagetypes={languagetypes}
          paneltypes={paneltypes}
          categoryTypes={categoryTypes}
          panelId={panelId}
        />
        <ConfMasterQuestion
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
          answerTypes={answerTypes}
          languagetypes={languagetypes}
          paneltypes={paneltypes}
          categoryTypes={categoryTypes}
          panelId={panelId}
        />
        <ConfMasterQuestion
          open={openAddToPanelModal}
          loading={loading}
          type="ADD_TO_PANEL"
          hadnleCloseModal={handleCloseAddToPanelModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
          answerTypes={answerTypes}
          languagetypes={languagetypes}
          paneltypes={paneltypes}
          categoryTypes={categoryTypes}
          setOpenAddToPanelModal={setOpenAddToPanelModal}
        />
        <CustomTable labels={labels} data={rows} pageLoading={loading} />
        <ViewOptionsModal
          open={openViewModal}
          handleClose={handleCloseViewModal}
          selectedItem={selectedItem}
        />
      </AnimatedPage>
    </>
  );
};

export const EnhancedMasterQuestion = Hoc(MasterQuestion, {
  name: "masterQuestion",
});

// {roleId && roleId === 3 && (
//   <>
//     <IconButton
//       onClick={() => {
//         setEditId(item.questionId);
//         handleOpenAddToPanelModal();
//       }}
//     >
//       <Tooltip title="Question translation">
//         <AddIcon />
//       </Tooltip>
//     </IconButton>
//     {/* <IconButton> */}
//     <Link
//       style={{
//         padding: "2px",
//         margin: 0,
//         textDecoration: "none",
//         color: "#555555",
//       }}
//       to={`${item.questionId}`}
//     >
//       <Tooltip title="View translations">
//         <RemoveRedEyeIcon />
//       </Tooltip>
//     </Link>
//     {/* </IconButton> */}
//   </>
// )}
