import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { addUser, getUserById, updateUser } from "../users/user.api";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { addPanelMember, getRolessBytenantId } from "./manageMember.api";
import { useSelector } from "react-redux";
import { getAllPanel } from "../panel/panel.api";
import {
  getActivepanels,
  getAllPanelAdmins,
} from "../managePanel/managePanel.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";
import { useDebounce } from "../../hooks/useDebounce";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../assets/js/validateEmail";
import { checkEmail } from "../companyOnBoard/companyOnBoard.api";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ConfUser = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
  } = props;
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    contact: "",
    email: "",
    password: "",
    createdBy: adminId,
    roleId: "",
    tenantId: adminId,
    panelId: null,
  });

  const [validationError, setValidationError] = useState({
    email: "",
    contact: "",
  });

  useDebounce(() => checkIfMailExists(), 1000, [userData.email]);
  useDebounce(() => checkPhoneNumber(), 1000, [userData.contact]);

  function checkPhoneNumber() {
    if (userData.contact) {
      if (!validatePhoneNumber(userData.contact)) {
        setValidationError((prev) => ({
          ...prev,
          contact: "Please enter a valid phone number",
        }));
        return;
      }
    }
  }

  function checkIfMailExists() {
    if (
      userData.email &&
      validateEmail(userData.email) &&
      userData?.email !== currentEmail
    ) {
      console.log("checking mail for validation");
      let data = {
        email: userData.email,
      };
      checkEmail(data)
        .then((res) => {
          if (res?.status != 200) {
            setValidationError((prev) => ({
              ...prev,
              email: "Mail already exists.",
            }));
          }
        })
        .catch((err) => {
          console.log("error checking the mail", err.message);
        });
    }
  }

  useEffect(() => {
    if (roleSelected) {
      setSelectedRole(roleSelected);
      setUserData((prev) => ({
        ...prev,
        roleId: roleSelected.roleId,
      }));
    }
    return () => {
      setSelectedRole({});
    };
  }, [roleSelected, open]);
  useEffect(() => {
    getRolessBytenantId(adminId)
      .then((res) => {
        if (res?.status === 200) {
          console.log("roles", res.data.data);
          setroles(res.data.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getActivepanels(adminId).then((res) => {
      if (res?.status === 200) {
        setPanels(res.data.data);
      }
    });
    return () => {
      setroles([]);
      setPanels([]);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "email" || name === "contact") {
      setValidationError((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    console.log("slected item", selectedItem);
    console.log("roles", roles);
    console.log("type", type);
    if (
      selectedItem &&
      selectedItem.roleId &&
      type === "EDIT" &&
      roles &&
      roles.length > 0
    ) {
      const role = roles.find(
        (item) => Number(item.roleId) === Number(selectedItem.roleId)
      );
      console.log("role", role);
      if (role) {
        setSelectedRole(role);
      }
    }
    if (editId && type === "EDIT") {
      setPageLoading(true);
      if (selectedItem) {
        setUserData(selectedItem);
        setCurrentEmail(selectedItem?.email);
        setPageLoading(false);
      }
    }
    return () => {
      setCurrentEmail("");
      setUserData({
        firstName: "",
        lastName: "",
        middleName: "",
        contact: "",
        email: "",
        password: "",
        createdBy: adminId,
        roleId: "",
        tenantId: adminId,
      });
      setSelectedPanels([]);
      setValidationError({
        email: "",
        contact: "",
      });
      setLoading(false);
    };
  }, [editId, selectedItem, open, roles]);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationError.email !== "") {
      return;
    }

    if (type === "ADD" && confirmPassword !== userData.password) {
      setAlert("Password doesn't match");
      return;
    }
    if (userData.password) {
      if (checkPassword(userData.password) === false) {
        setAlert(
          "Password must contain min 8 letter, with at least a symbol, upper and lower case letters and a number"
        );
        return;
      }
    }
    if (userData.roleId === "") {
      setAlert("Please select a role");
      return;
    }

    let panelIds = [];
    if (selectedPanels && selectedPanels.length > 0) {
      selectedPanels.map((item) => panelIds.push(item.panelId));
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      let duplicate = { ...userData };
      duplicate["panelId"] = panelIds;
      callMethod = addPanelMember(duplicate);
    } else {
      let data = {
        contact: userData.contact,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        middleName: userData.middleName,
        updatedBy: adminId,
        roleId: selectedRole.roleId,
      };
      callMethod = updateUser(editId, data);
    }

    callMethod
      .then((res) => {
        console.log("added", res);
        if (res?.status === 201 || res?.status === 200) {
          setSuccess();
          setUserData({
            firstName: "",
            lastName: "",
            middleName: "",
            contact: "",
            email: "",
            password: "",
            createdBy: adminId,
            roleId: "",
            tenantId: adminId,
          });
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRoleDropDownChange = (value) => {
    setSelectedRole(value);
    setUserData((prev) => ({
      ...prev,
      roleId: value.roleId,
    }));
  };
  const handlePanelDropDownChange = (value) => {
    setSelectedPanels(value);
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>
          {type === "ADD" ? "Add Member" : "Edit Member"}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  label={"First Name"}
                  name="firstName"
                  type={"text"}
                  required
                  value={userData.firstName}
                />
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  label={"Middle Name"}
                  name="middleName"
                  type={"text"}
                  value={userData.middleName}
                />
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  label={"Last Name"}
                  name="lastName"
                  type={"text"}
                  required
                  value={userData.lastName}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"number"}
                  label={"Phone Number"}
                  error={Boolean(validationError.contact)}
                  helperText={validationError?.contact}
                  name="contact"
                  required
                  value={userData.contact}
                />
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"email"}
                  label={"Email"}
                  error={Boolean(validationError.email)}
                  helperText={validationError?.email}
                  name="email"
                  required
                  value={userData.email}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                <AsyncDropdown
                  label={"Assign Role"}
                  disabled={props.disableDropDown}
                  value={selectedRole}
                  handleChange={handleRoleDropDownChange}
                  objKey="role"
                  options={roles}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                <MultipleValueDropDown
                  label={"Assign Panels"}
                  value={selectedPanels}
                  onChange={(value) => setSelectedPanels(value)}
                  objkey="panelName"
                  options={panels}
                />
              </Stack>
              {/* <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                <Stack sx={{ width: "100%" }}>
                  <p>Panel</p>
                  <AsyncDropdown
                    disabled={props.disableDropDown}
                    value={selectedPanel}
                    handleChange={handlePanelDropDownChange}
                    objKey="firstName"
                    options={panels}
                  />
                </Stack>
              </Stack> */}
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              >
                {type === "ADD" && (
                  <TextField
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    label={"Password"}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    required
                    value={confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  label={"Confirm Password"}
                  type={showConfirmPassword ? "text" : "password"}
                  name="password"
                  required
                  value={userData.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {type === "ADD" ? "Save" : "Update"}
          </LoadingButton>
          <Button variant="outlined" onClick={hadnleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfUser;
