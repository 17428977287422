import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import Hoc from "../HOC/Hoc";
import ConfCountry from "./ConfCountry";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteCountry } from "./country.api";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import AnimatedPage from "../animatedPage/AnimatedPage";

const Country = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;

  const [countries, setCountries] = useState([]);
  const [editId, setEditId] = useState("");
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (data.length > 0) {
      setCountries(data);
    }
    return () => {
      setCountries([]);
    };
  }, [data]);

  useEffect(() => {
    console.log(editId);
    if (editId && countries && countries.length > 0) {
      const item = countries.find((country) => country.countryId === editId);
      console.log(item);
      if (item) {
        setSelectedItem(item);
      }
    }
    return () => {
      setSelectedItem({});
    };
  }, [editId]);

  const handleDelete = (id) => {
    deleteCountry(id)
      .then((res) => {
        console.log("delres", res);
        if (res?.status === 200) {
          setSuccess();
        } else {
          setError("Something went Wrong! Please try agian.");
        }
      })
      .catch((err) => {
        setError("Something went Wrong! Please try agian.");
        console.log(err);
      });
  };
  const labels = ["Name", "Code","Currency", "ISDCode", "Active", "Action"];
  const row = [];

  data &&
    data.length > 0 &&
    data.map((item) =>
      row.push([
        item?.countryName,
        item?.countryCode,
        item?.currency,
        item.ISDCode,
        <CustomMuiSwitch checked={item.isActive} />,
        <Stack direction={"row"} spacing={1}>
          <IconButton
            onClick={() => {
              setEditId(item.countryId);
              handleOpenEditModal();
            }}
          >
            <CreateIcon />
          </IconButton>
        </Stack>,
      ])
    );
  return (
    <>
      <AnimatedPage>
        <TopHeading showButton={false} openModal={handleOpenModal} heading="Countries" />
        <ConfCountry
          type="ADD"
          open={openModal}
          setSuccess={setSuccess}
          setError={setError}
          hadnleCloseModal={handleCloseModal}
        />

        <ConfCountry
          selectedCountry={selectedItem}
          type="EDIT"
          open={openEditModal}
          setSuccess={setSuccess}
          setError={setError}
          hadnleCloseModal={handleCloseEditModal}
          editId={editId}
          setEditId={setEditId}
        />
        <CustomTable data={row} pageLoading={loading} labels={labels} />
      </AnimatedPage>
    </>
  );
};

export const EnhancedCountry = Hoc(Country, { name: "country" });
