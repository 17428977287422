import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { addPanel, updatePanel } from "./panel.api";
import { useSelector } from "react-redux";

const ConfPanel = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [panelData, setpanelData] = useState({
    panelName: "",
    tenantId: adminId, 
    createdBy: adminId,
  });

  useEffect(() => {
    if (editId && type === "EDIT") {
        if(selectedItem){
            setpanelData(selectedItem);
        }
    }

    return () => {
        setpanelData({
            panelName: "",
            tenantId: adminId, 
            createdBy: adminId,
        })
    };
  }, [editId, open, selectedItem]);

  const handleOnInputchange = (e) => {
    const { value, name } = e.target;
    setpanelData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let callMethod;
    if (type === "ADD") {
      callMethod = addPanel(panelData);
    } else {
        let data = {
            panelName: panelData.panelName,
            updatedBy: adminId,
        }
        callMethod = updatePanel(editId, data);
    }

    callMethod
      .then((res) => {
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
          console.log(res);
          setSuccess();
          setpanelData({
            panelName: "",
            tenantId: adminId, 
            createdBy: adminId,
          });
        }
      })
      .catch((err) => {
        setError('Something went wrong')
        console.log(err)})
      .finally(setLoading(false));
  };

  return (
    <>
      <Dialog
        fullWidth={"true"}
        maxWidth={"sm"}
        sx={{ p: 2 }}
        open={open}
        onClose={hadnleCloseModal}
      >
        {formLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <span className={"closeButton"} onClick={hadnleCloseModal}>
              <i className="bi bi-x"></i>
            </span>
            <DialogTitle>
              <h4>{type === "ADD" ? "Add Panel" : "Edit Panel"}</h4>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} py={2}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    required
                    onChange={handleOnInputchange}
                    value={panelData.panelName}
                    fullWidth
                    label="Name"
                    name="panelName"
                  />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions style={{ padding: "0 24px 24px 20px" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type={"submit"}
              >
                {type === "ADD" ? "Save" : "Update"}
              </LoadingButton>
              <Button variant="outlined" onClick={hadnleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ConfPanel;
