import React from "react";
import { Box } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ColorButton } from "../StyledComponents/ColorButton";

const FailurePage = () => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="red"
        height="60px"
        width="60px"
        borderRadius="50%"
        marginBottom="5rem"
      >
        <CloseRoundedIcon
          style={{ color: "white", fontSize: "3.5rem", fontWeight: "bold" }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginBottom="1rem"
        >
          <text
            style={{ color: "red", fontWeight: "bold", fontSize: "1.6rem" }}
          >
            Verification Failed
          </text>
          <text style={{ textAlign: "center" }}>
            Sorry! We could not
            <br /> verify your account
          </text>
        </Box>
      </Box>
    </>
  );
};

export default FailurePage;
