import React, { useEffect, useMemo, useState } from 'react'
import { IconButton } from '@mui/material'
import {useSelector} from "react-redux";
import CustomTable from '../../views/CustomTable/CustomTable';
import { deleteRestaurant, getRestaurants } from './data.api';
import TopHeading from '../../utils/TopHeading/TopHeading'
import AddData from './AddData';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { SnackBarAlert } from '../../utils/SnackBar';
import ConfirmAlert from '../../utils/ConfirmAlert';

const Data = () => {
  const open = useSelector((state) => state.sideBar.sideBarOpen);
  const drawerWidth = 240;

  const [restaurants, setRestaurants] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const pageSize = 5;
  const [pageLoading, setPageLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [alert, setAlert] = useState(false);

  const [restaurantId, setRestaurantId] = useState('');

  const getALlRestaurants = () => {
    // setTimeout(() => {
      setPageLoading(true)
      getRestaurants(page, pageSize)
      .then((res) => {
        console.log(res.data)
        setRestaurants(res.data.data);
        setPage(res.data.meta.pagination.page)
        settotalPages(res.data.meta.pagination.pageCount)
        setPageLoading(false)
      })
    // }, 5000);
  }

  useEffect(() => {
    getALlRestaurants();
    return () => {
      setRestaurants([])
    }
  }, [page, alert])

  const handleDeleteItem = (id) => {
    deleteRestaurant(id)
    .then((res) => {
      setAlert("Deleted");
    })
    .catch(error => console.log())
  }

  const formatText = (str) => {
    if(str.length > 100){
      return str.substring(0, 100)+'...'
    }
    else{
      return str;
    }
  }

  const labels = ["Name", 'Description', 'Created at', 'Actions'];
  const data = [];
  restaurants.map((restaurant) => 
    data.push([
      restaurant.attributes.name,
      formatText(restaurant.attributes.description),
      moment(restaurant.attributes.createdAt).format('DD-MM-YYYY'),
      <div style={{ display: 'flex',  }}>
        <IconButton onClick={() => {
              setRestaurantId(restaurant.id)
              handleOpenEditModal()
              }}>
            <EditIcon  />
        </IconButton>
        <ConfirmAlert
          msg={"Are You Sure?"}
          onClickEvent = {() => handleDeleteItem(restaurant.id)}
        children={
          <IconButton >
              <DeleteIcon />
          </IconButton>
          
        }
        />
      </div>,
    ])
  )
  const themeStyles = useMemo(() => {
    return {
        flexGrow: 1, p: 3,
        width: open ? `calc(100% - ${drawerWidth}px)` : `auto`,
        marginLeft: open ? `calc(${drawerWidth}px)`: '70px',
        padding: '0 10px',
    }
  }, [open])

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  const hadnleCloseModal = () => {
    setOpenModal(false);
  }
  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  }
  const hadnleCloseEditModal = () => {
    setOpenEditModal(false);
  }


  const [openEg, setOpenEg] = useState(false);
  const openEgModal = () => {
    setOpenEg(true)
  }
  const closeEgModal = () => {
    setOpenEg(false)
  }

  return (
    <>
    {/* <ExampleModal 
      open={openEg}
      handleClose={() => closeEgModal()}
     />
     <Button onClick={openEgModal}>
      Open new modal
     </Button> */}
    <TopHeading
        heading="Restaurants"
        openModal = {() => handleOpenModal()}
        />
        <ConfirmAlert />
        <CustomTable
          labels={labels}
          data={data} 
          page={page}
          totalPages={totalPages}
          setPage = {setPage}
          pageLoading={pageLoading}
        />
        <AddData
          type="ADD"
          open={openModal}
          setAlert={(msg) => setAlert(msg)}
          hadnleCloseModal={() => hadnleCloseModal()} />

        <AddData
          type="EDIT"
          open={openEditModal}
          restaurantId={restaurantId}
          setAlert={(msg) => setAlert(msg)}
          hadnleCloseModal={() => hadnleCloseEditModal()} />

          <SnackBarAlert
            open={alert}
            handleClose={() => setAlert(false)}
           />
    </>
  )
}

export default Data