import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Default from "../components/Default";

const PrivateRoutes = (props) => {
  const { allowedRoles } = props;
  // const navigate = useNavigate();
  const currentUserRoleId = useSelector((state) => state.auth.userData?.roleId);
  const superAdminPaths = [
    "/admin/users",
    "/admin/company-on-board",
    "/admin/country",
    "/admin/language",
    "/admin/feature",
    "/admin/category",
  ];
  const systemAdminPaths = [
    "/admin/company-on-board",
    "/admin/master-questions",
    "/admin/category",
  ];
  const companyAdminPaths = [
    "/admin/tenant-profile",
    "/admin/roles",
    "/admin/manage-members",
    "/admin/panel-admin",
    "/admin/tenant-features",
  ];
  const panelAdminPaths = [
    "/admin/panel-profile",
    "/admin/category",
    "/admin/master-questions",
    "/admin/assigned-survey-countries",
    "/admin/assigned-features",
    "/admin/associated-panel-members",
    "/admin/master-questions/2",
  ];

  const panelMemberPaths = ["/admin/assigned-panels"];

  const panelSidebarItems = useSelector(
    (state) => state.panelSidebarItems.panelSidebarItems
  );

  let currentPaths;
  if (currentUserRoleId === 1) {
    currentPaths = [...superAdminPaths];
  } else if (currentUserRoleId === 2) {
    currentPaths = [...systemAdminPaths];
  } else if (currentUserRoleId === 3) {
    currentPaths = [...companyAdminPaths];
  }
  // else if (currentUserRoleId === 4) {
  //   currentPaths = [...panelAdminPaths];
  // }
  // else{
  //   currentPaths = [...panelMemberPaths];
  //   panelSidebarItems &&
  //   panelSidebarItems.length > 0 &&
  //   panelSidebarItems.map((item) => currentPaths.push(item.path));
  // }

  const user = useSelector((state) => state.auth?.user);
  const location = useLocation();
  console.log(location.pathname);
  // if (currentPaths && !currentPaths.includes(location.pathname)) {
  //   return <Navigate to={currentPaths[0]} />;
  // }

  return user !== null ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoutes;
