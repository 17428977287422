import { Stack, Typography } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = (props) => {
  const { value, handleChange, label } = props;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      <Typography>{label}</Typography>
      <ReactQuill
        style={{ marginBottom:'20px' }}
        onChange={(value) => handleChange(value)}
        value={value}
        theme="snow"
        modules={modules}
        formats={formats}
      />
    </Stack>
  );
};

export default RichTextEditor;
