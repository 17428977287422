import axios from "axios";

export const getAffiliatesByPanelId = async (panelId) => {
    try {
      const res = await axios.get(
        `/api/user/getAffiliatesByPanelId?panelId=${panelId}`
      );
      return res;
    } catch (error) {
      console.log("err", error.message);
    }
  };

  export const getAffiliateDetailbyId = async (affiliateId,country,startDate,endDate) => {

    try {
      const res = await axios.get(
        `/api/user/getAffiliateDetailbyId?affiliateId=${affiliateId}&country=${country}&startDate=${startDate}&endDate=${endDate}`
      );
      return res;
    } catch (error) {
      console.log("err", error.message);
    }
  };
  export const getAffiliateReportsByFilter = async (data) => {
    try {
      const res = await axios.post(
        `/api/company/getAffiliatesByPanelId`,
        data
      );
      return res;
    } catch (error) {
      console.log("err", error.message);
    }
  };