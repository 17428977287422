import { Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import TopHeading from "../../utils/TopHeading/TopHeading";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import { getActivepanels } from "../managePanel/managePanel.api";
import ConfSurvey from "./ConfSurvey";
import { deleteSurvey, getSurveyByPanelId } from "./survey.api";
import ViewCountryLanguageModal from "./ViewCountryLanguageModal";
import SurveyLink from "./createSurvey/SurveyLink"
import CustomDataTable from "../../views/CustomTable/CustomDataTable";
const Survey = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
  } = props;
  const [surveys, setsurveys] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [activePanels, setActivePanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  const [openViewCountryModal, setOpenViewCountryModal] = useState(false);
  const handleOpenCountryModal = () => {
    setOpenViewCountryModal(true);
  };
  const handleCloseCountryModal = () => {
    setOpenViewCountryModal(false);
  };

  const isPanelMember = useSelector(
    (state) => state.panelMemberPermissions.isPanelMember
  );
  const memberPermissions = useSelector(
    (state) => state.panelMemberPermissions.memberPermissions
  );

  const [selectedPanel, setSelectedPanel] = useState({});
  const [pageloading, setPageloading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setActivePanels(data);
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setActivePanels([]);
      setSelectedPanel({});
      setDataUpdated(false);
    };
  }, [adminId]);

  const filteredData = surveys.filter((item) => {
    const values = Object.values(item);
    return values.find((value) =>
      value !== null &&
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  useEffect(() => {
    if (surveys && surveys.length > 0) {
      const item = surveys.find((item) => item.id === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId]);

  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case "PANEL":
        console.log("selected panel", value);
        setSelectedPanel(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(selectedPanel).length > 0) {
      setPageloading(true);
      getSurveyByPanelId(selectedPanel?.panelId)
        .then((res) => {
          if (res?.status === 200) {
            console.log(res.data.data);
            setsurveys(res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        })
        .finally(() => setPageloading(false));
    }
    return () => {
      setsurveys([]);
    };
  }, [selectedPanel, dataUpdated]);

  const handleDeleteSurvey = (id) => {
    console.log("id", id);
    setDataUpdated(false);
    if (id) {
      deleteSurvey(id)
        .then((res) => {
          if (res?.status === 200) {
            setAlert("Successfully deleted");
            setDataUpdated(true);
          }
        })
        .catch((err) => {
          setAlert("Something went wrong");
        });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: <div>SID</div>,
          selector: (row) => row.SurveyId,
          sortable: true,
          cell: (row) => (
            <Stack direction={"row"}>
              <SurveyLink item={row} />
            </Stack>
          ),
          minWidth: '70px', // Set the minimum width of the column
          maxWidth: '70px',
        },
        {
          name: 'SCID',
          selector: (row) => row.surveyCode,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.surveyCode}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '120px', // Set the minimum width of the column
          maxWidth: '120px',
        },
        {
          name: <div>CSID</div>,
          selector: (row) => row.ClientSurveyId,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.ClientSurveyId}
                </span>
              </Tooltip>
            </>
          ),
        },
        {
          name: <div>Complete Require</div>,
          selector: (row) => row.completeRequire,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.completeRequire}
                </span>
              </Tooltip>
            </>
          ),
        },
        {
          name: <div>Country</div>,
          selector: (row) => row.countryCode,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {`${row.countryCode}-${row.language}`}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '100px', // Set the minimum width of the column
          maxWidth: '100px',
        },
        {
          name: <div>Sample Count</div>,
          selector: (row) => row.sampleCount,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.sampleCount}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '90px', // Set the minimum width of the column
          maxWidth: '90px',
        },
        {
          name: <div>Starts</div>,
          selector: (row) => row.starts,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.starts}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '90px', // Set the minimum width of the column
          maxWidth: '90px',
        },
        {
          name: <div>Completes</div>,
          selector: (row) => row.completes,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.completes}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '100px', // Set the minimum width of the column
          maxWidth: '100px',
        },
        {
          name: <div>OverQuota</div>,
          selector: (row) => row.clientoverquota,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.clientoverquota}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '70px', // Set the minimum width of the column
          maxWidth: '100px',
        },
        {
          name: <div>Terminate</div>,
          selector: (row) => row.clientterminate,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.clientterminate}
                </span>
              </Tooltip>
            </>
          ),
          minWidth: '70px', // Set the minimum width of the column
          maxWidth: '100px',
        },
        {
          name: <div>SCE Term</div>,
          selector: (row) => row.clientsecurityterminate,
          sortable: true,
          cell: (row) => (
            <>
              <Tooltip title={`Completed Points: ${row.completedPoints}, LOI: ${row.LOI}`}>
                <span>
                  {row.clientsecurityterminate}
                </span>
              </Tooltip>
            </>
          ),
        },
      ]);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const conditionalRowStyles = [
    {
      when: row => row.sampleCount === 0,
      style: {
        backgroundColor: 'rgb(255 0 0 / 14%)', // Apply your desired highlight color
      },
    },
  ];
  return (
    <>
      <AnimatedPage>
        <TopHeading
          addLink={"/admin/create-survey"}
          parentPage={"survey"}
          showButton={false}
          heading="Survey"
        />
        <Stack spacing={2} sx={{ mx: 4, my: 2 }}>
          <AsyncDropdown
            handleChange={(value) => handleDropDownChange(value, "PANEL")}
            value={selectedPanel}
            options={activePanels}
            objKey={"panelName"}
            stylized
            label={"Select Panel"}
          />
          <TextField
            type="search"
            value={searchQuery}
            fullWidth
            label="Search"
            name="Search"
            onChange={handleSearch}
          />
        </Stack>
        <Stack spacing={2} sx={{ mx: 4, my: 2 }}>
          <CustomDataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            progressPending={pending}
            highlightOnHover
            conditionalRowStyles={conditionalRowStyles}
          />
        </Stack>
        <ViewCountryLanguageModal
          open={openViewCountryModal}
          handleClose={handleCloseCountryModal}
          selectedItem={selectedItem}
        />
        <ConfSurvey
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleCloseModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
        />
        <ConfSurvey
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleCloseEditModal}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedItem={selectedItem}
        />

      </AnimatedPage>
    </>
  );
};

export const EnhancedSurvey = Hoc(Survey, { name: "" });
