import { Create, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAlert from "../../utils/ConfirmAlert";
import CustomMuiSwitch from "../../utils/customMuiSwicth/CustomMuiSwitch";
import TopHeading from "../../utils/TopHeading/TopHeading";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";

const CompanyFeatures = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const [languages, setLanguages] = useState([]);
  const [editId, setEditId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (data) {
      setLanguages(data);
    }
    return () => {
      setLanguages(data);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const item = data.find((item) => item.id === editId);
      if (item) {
        setSelectedItem(item);
      }
    }

    return () => {
      setSelectedItem({});
    };
  }, [editId, data]);

  const rows = [];
  const labels = ["Feature"];
  languages &&
    languages.length > 0 &&
    languages.map((item) =>
      rows.push([
        item.features.feature,
      ])
    );
  return (
    <>
    <AnimatedPage>
      <TopHeading showButton={false} openModal={handleOpenModal} heading="Tenant Features" />
      <CustomTable labels={labels} data={rows} pageLoading={loading} />
    </AnimatedPage>
    </>
  );
};

export const EnhancedCompanyFeatures = Hoc(CompanyFeatures, { name: "company-features" });
