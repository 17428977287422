import React from "react"
import ExcelDownloadButton from "../../views/Excel/ExcelDownload";
import { Stack } from "@mui/material";
import useExcelCell from "../../hooks/useExcelCell"

const ExcelCell = ({ fetchDataCallback, headers, filename, value }) => {
    const { data, handleClick } = useExcelCell(fetchDataCallback);
    if (data === null) {
      // Render a loading state or placeholder while the data is being fetched
      return <span>Loading...</span>;
    }
  
    return (
      <Stack direction="row">
        <ExcelDownloadButton
          getDataCallback={handleClick}
          headers={headers}
          filename={filename}
          val={value}
        />
      </Stack>
    );
  };

  export default ExcelCell;