import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomMuiSwitch from "../../../utils/customMuiSwicth/CustomMuiSwitch";
import { Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PermissionMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = useState(false);
  const [userView, setUserView] = useState(false);
  const [userCreate, setUserCreate] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { featurePermissions, grantedPermission } = props;

  // useEffect(() => {
  //   let permission = [];
  //   if (featurePermissions && grantedPermission.length > 0) {
  //     featurePermissions.map((feature) => {
  //       for (let i = 0; i < feature.permissions.length; i++) {
  //         for (let j = 0; j < grantedPermission.length; j++) {
  //           if (
  //             Number(feature.permissions[i].permissionId) ===
  //             Number(grantedPermission[j].permissionId)
  //           ) {
  //             feature.permissions[i]["checked"] = true;
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, [grantedPermission, featurePermissions, open]);

  return (
    <>
      <Button
        className="persmission-button"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        Permissions
      </Button>
      <Menu
        PaperProps={{
          style: {
            width: 260,
          },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box>
          <Stack sx={{ py: 1, px: 2 }} spacing={2}>
            {grantedPermission &&
              grantedPermission.map((item, idx) => (
                <Stack className="field-border" key={idx} direction={"row"} spacing={2}>
                  <CustomMuiSwitch
                    // value={permission.permissionId}
                    checked={true}
                    // handleChange={handleSwitchChange}
                  />
                  <p className="form-label">{item?.permission}</p>
                </Stack>
              ))}

                {grantedPermission.length === 0 && 
                <Typography variant="p" component={'p'}>No permissions</Typography>}

            {/* {featurePermissions &&
              featurePermissions.map((item, index) => (
                <>
                  <Stack
                    key={index}
                    className="field-border"
                    direction={"row"}
                    spacing={2}
                  >
                    <CustomMuiSwitch
                    // handleChange={() => setUser((prev) => !prev)}
                    // checked={user}
                    />
                    <p className="form-label">{item?.feature}</p>
                  </Stack>

                  {item.permissions.map((permission, idx) => {
                    return (
                      <Stack
                        key={idx}
                        sx={{ pl: 4 }}
                        direction={"row"}
                        spacing={2}
                      >
                        <CustomMuiSwitch
                          value={permission.permissionId}
                          checked={permission.checked}
                          // handleChange={handleSwitchChange}
                        />
                        <p className="form-label">{permission?.permission}</p>
                      </Stack>
                    );
                  })}
                </>
              ))} */}
          </Stack>
        </Box>
      </Menu>
    </>
  );
};

export default PermissionMenu;
