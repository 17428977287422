import { LoadingButton } from '@mui/lab'
import { Alert, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Container } from '@mui/system'
import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from  '../Login/styles.module.css'

const Register = () => {

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
      email:'',
      username: '',
      password: ''
    });

    const emailref = useRef();
    const errRef = useRef();

    useEffect(() => {
      emailref.current.focus();
    }, []);

    const handleChnageInput = (e) => {
      const {name, value} = e.target;
      if(name && value){
        setUser((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    }

    useEffect(() => {
      console.log(user);
    }, [user])

    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        navigate('/login')
      }, 5000);
    }

  return (
    <Container maxWidth="sm" className={styles.container}>
        {error && errMsg &&
        <Alert ref={errRef} className={styles.errorMsg} severity="error">
          This is an error alert — <strong>check it out!</strong>
        </Alert>
        }
        <Typography color={grey[800]} style={{ margin: '16px 0' }} variant='h5' component={'p'} >Register</Typography>
            <form onSubmit={handleSubmit} className={styles.loginForm}>
                <TextField type={'text'} onChange={handleChnageInput} inputRef={emailref} autoComplete='off' style={{ marginBottom: '10px' }} label='Email' name='email' />
                <TextField type={'text'} onChange={handleChnageInput} autoComplete='off' style={{ marginBottom: '10px' }} label='Username' name='username' />
                <TextField type={'password'} onChange={handleChnageInput} autoComplete='off' style={{ marginBottom: '10px' }} label='Password' name='password' />
                <LoadingButton type='submit' className={styles.loginBtn} loading={loading} variant="contained">Register</LoadingButton>
            </form>
            <Typography align='right' mt={2} fontSize={14} className={styles.forgortLink}>Forgot Password?</Typography>
            <Typography align='right' fontSize={14} className={styles.forgortLink}>
            <Link to={'/login'}>Login</Link>
            </Typography>
      </Container>
  )
}

export default Register