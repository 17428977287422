import axios from "axios"

export const getAllPanel = async () => {
    try {
        const res = await axios.get('/api/Company/getAllPanel');
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const addPanel = async(data) => {
    try {
        const res = await axios.post('/api/Company/createPanel', data)
        return res;
    } catch (error) {
        console.log(error);
    }
}
export const updatePanel = async(id, data) => {
    try {
        const res = await axios.patch(`/api/Company/updatePanel/${id}`, data)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const deletePanel = async(id) => {
    try {
        const res=  await axios.delete(`/api/Company/deletePanel/${id}`)
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const toggleIsActivePanel = async(id, data) => {
    try {
        const res=  await axios.patch(`/api/Company/activeInactivePanel/${id}`, data)
        return res;
    } catch (error) {
        console.log(error)
    }
}