import axios from "axios"

export const getAllContent = async() => {
    try {
        const res = await axios.get('/api/systemUser/getContent');
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const addNewContent = async (data) => {
    try {
        const res = await axios.post('/api/systemUser/createContent', data);
        return res;
    } catch (error) {
        console.log(error)
    }
}
export const updateContent = async (id, data) => {
    try {
        const res = await axios.patch(`/api/systemUser/updateContent/${id}`, data);
        return res;
    } catch (error) {
        console.log(error)
    }
}
export const deleteContent = async (id) => {
    try {
        const res = await axios.delete(`/api/systemUser/deleteContent/${id}`);
        return res;
    } catch (error) {
        console.log(error)
    }
}